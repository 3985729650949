import { HStack, Skeleton, VStack } from '@chakra-ui/react';

export const ThreeHStackSkeletons = () => {
  return (
    <HStack padding={4} spacing={1} w={'100%'} p={0}>
      <Skeleton w={'100%'} height="40px" />
      <Skeleton
        w={'100%'}
        height="40px"
        bg="green.500"
        color="white"
        fadeDuration={1}
      />
      <Skeleton
        w={'100%'}
        height="40px"
        fadeDuration={4}
        bg="blue.500"
        color="white"
      />
    </HStack>
  );
};

export const VStackSkeletonsGroup = () => {
  return (
    <VStack w={'100%'}>
      <ThreeHStackSkeletons />
      <ThreeHStackSkeletons />
      <ThreeHStackSkeletons />
    </VStack>
  );
};

export const FormSkeleton = () => {
  return (
    <VStack gap={4}>
      <Skeleton height="40px" w={60} mx={'auto'} mb={2} />
      <VStackSkeletonsGroup />
      <Skeleton height="40px" w={60} mx={'auto'} mb={2} />
      <VStackSkeletonsGroup />
      <Skeleton height="40px" w={60} mx={'auto'} mb={2} />
      <VStackSkeletonsGroup />
    </VStack>
  );
};
