import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  ModalContent,
  ModalHeader,
  ModalBody,
  Tooltip,
  Container,
  Input,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchBrokerageUserData } from '../../../Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import { BrokerageCLformSchemaTabel } from '../../../ValidationSchema/Auth/Index';
import { BrokerageClupdate } from '../../../Redux/Brokerage/BrokerageProfile/BrokerageProfile';
import 'rc-datepicker/lib/style.css';
import { useFormik } from 'formik';
import { RootState } from '../../../Redux/Store';
import { useToast } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

function BrokerageCLform() {
  const dispatch = useDispatch();
  const Toast = useToast();
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const useruidData = useSelector((state: RootState) => state?.brokerage?.user);
  const [isInitializePage, setIsInitializePage] = useState(false);
  let userGetidData = useruidData && useruidData.data;
  let userData = userGetidData && userGetidData.corporate_license;

  useEffect(() => {
    if (userData) {
      const fetchData = async () => {
        if (!!localStorage.getItem('Token')) {
          setIsInitializePage(false);
          const actionResult = await dispatch(fetchBrokerageUserData() as any);
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          }
          setIsInitializePage(true);
        } else {
          navigate('/login');
        }
      };
      fetchData();
    }
  }, [dispatch]);

  interface FormValues {
    corporate_license: {
      province: string;
      license: number | null;
      // expiryData: Date | null;
    }[];
  }

  const initialValues: FormValues = {
    // corporate_license: [{ province: "", license: null, expiryData: null }],
    corporate_license: [{ province: '', license: null }],
  };

  const formik = useFormik({
    initialValues,
    validationSchema: BrokerageCLformSchemaTabel,

    onSubmit: async (values: FormValues, { resetForm }) => {
      let res = '';
      try {
        const formValues = {
          corporate_license: [
            {
              province: values?.corporate_license?.[0]?.province?.trim(),
              license: values?.corporate_license?.[0]?.license,
            },
          ],
        };
        const updatedCorporateLicense = userData === null ? [] : [...userData];

        if (Array.isArray(formValues.corporate_license)) {
          updatedCorporateLicense.push(...formValues.corporate_license);
        }

        const data = {
          ...formValues,
          corporate_license: updatedCorporateLicense,
        };

        const actionResult = await dispatch(BrokerageClupdate(data) as any);
        const response = actionResult?.payload;
        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 200) {
          setIsOpen(false);
          resetForm();
          Toast({
            title: response.msg,
            status: 'success',
            isClosable: true,
            duration: 1000,
            position: 'top-right',
          });
        } else {
          if (resStatus !== 401) {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          } else {
            navigate('/login');
          }
        }
      } catch (error: any) {
        Toast({
          title: res,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      } finally {
        if (!!localStorage.getItem('Token')) {
          const actionResult = await dispatch(fetchBrokerageUserData() as any);
          if (actionResult?.payload?.status === 401) {
            navigate('/login');
          }
        } else {
          navigate('/login');
        }
      }
    },
  });

  const handleOpenPopup = () => {
    setIsOpen(true);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClosePopup}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={'#114684'}>Add Corporate License</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Container textAlign="center" h="auto" p="0px" borderRadius="20px">
              <form onSubmit={formik.handleSubmit}>
                <Box>
                  <Box display="flex">
                    <Tooltip label="Province" placement="right" hasArrow>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Province"
                        name="corporate_license[0].province"
                        borderRadius="5px"
                        value={
                          formik.values.corporate_license[0].province ?? ''
                        }
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        focusBorderColor="transparent"
                        bg={'#f0f5f9'}
                        border="none"
                      />
                    </Tooltip>
                  </Box>
                  {formik.errors.corporate_license &&
                    typeof formik.errors.corporate_license[0] !== 'string' &&
                    formik.errors.corporate_license[0]?.province && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.corporate_license[0].province}
                      </div>
                    )}

                  <Box display="flex" mb={['20px', '5px']} mt={2}>
                    <Tooltip label="License" placement="right" hasArrow>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        onWheel={(e) => (e.target as HTMLInputElement).blur()}
                        placeholder="License"
                        borderRadius="5px"
                        name="corporate_license[0].license"
                        value={formik.values.corporate_license[0].license ?? ''}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        focusBorderColor="transparent"
                        bg={'#f0f5f9'}
                        border="none"
                      />
                    </Tooltip>
                  </Box>
                  {formik.errors.corporate_license &&
                    typeof formik.errors.corporate_license[0] !== 'string' &&
                    formik.errors.corporate_license[0]?.license && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.corporate_license[0].license}
                      </div>
                    )}
                  {/* <Tooltip label="Expiry Date" placement="right" hasArrow>
                    <Box display="flex" mb={["20px", "5px"]} mt={2}>
                      <DatePickerInput
                        value={
                          formik.values.corporate_license[0].expiryData !== null
                            ? new Date(
                                formik.values.corporate_license[0].expiryData
                              )
                            : undefined
                        }
                        onChange={(date: Date | null) => {
                          formik.setFieldValue(
                            "corporate_license[0].expiryData",
                            date
                          );
                        }}
                        locale="en"
                        placeholder="Expiry Date"
                        className="react-datepicker-component react-datepicker-input input nulll-border"
                      />
                    </Box>
                  </Tooltip> */}
                </Box>
                {/* {formik.errors.corporate_license &&
                  typeof formik.errors.corporate_license[0] !== "string" &&
                  formik.errors.corporate_license[0]?.expiryData && (
                    <div
                      style={{
                        color: "red",
                        fontSize: "13px",
                        textAlign: "start",
                        fontWeight: "500",
                      }}
                    >
                      {formik.errors.corporate_license[0].expiryData}
                    </div>
                  )} */}
                <Box display={'flex'} gap={2} mt={5}>
                  <Button
                    bg="#114684"
                    color="white"
                    w="100%"
                    _hover={{ bg: '#114684' }}
                    onClick={handleClosePopup}
                  >
                    Close
                  </Button>
                  <Button
                    type="submit"
                    bg="#114684"
                    color="white"
                    w="100%"
                    _hover={{ bg: '#114684' }}
                  >
                    Submit
                  </Button>
                </Box>
              </form>
            </Container>
          </ModalBody>
          <ModalFooter gap={2}></ModalFooter>
        </ModalContent>
      </Modal>
      <Box display={'flex'} justifyContent={'end'} p={5}>
        <Button
          bg="#114684"
          color={'white'}
          _hover={{ bg: '#114684' }}
          onClick={handleOpenPopup}
        >
          Add Corporate License
        </Button>
      </Box>
      <TableContainer border="0px solid black" p={2}>
        <Table size="md">
          <Thead border="1px solid black" bg="#114684">
            <Tr>
              <Th color="white" border="1px solid black" fontSize={'9px'}>
                Province
              </Th>
              <Th color="white" border="1px solid black" fontSize={'9px'}>
                License
              </Th>
              {/* <Th color="white" border="1px solid black" fontSize={"9px"}>
                Expiry date
              </Th> */}
            </Tr>
          </Thead>
          <Tbody>
            {Array?.isArray(userData) && userData?.length > 0
              ? userData?.map((user?: any, index?: number) => (
                  <Tr key={index}>
                    <Td border="1px solid #0000002e" fontSize={'13px'}>
                      {user?.province}
                    </Td>
                    <Td border="1px solid #0000002e" fontSize={'13px'}>
                      {user?.license}
                    </Td>
                    {/* <Td border="1px solid #0000002e">
                    {" "}
                    {formatDate(user.expiryData)}
                  </Td> */}
                  </Tr>
                ))
              : isInitializePage &&
                userGetidData?.length === 0 && (
                  <Tr>
                    <Td
                      colSpan={10}
                      border="1px solid #0000002e"
                      textAlign="center"
                    >
                      No data available
                    </Td>
                  </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default BrokerageCLform;
