import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callAPI from '../../utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Reports/WorkInProgress/slice';
import initialState, { PdfCreatePayload } from './state';

export const pdfContentCreate = createAsyncThunk(
  'pdfSotre/pdfContentCreate',
  async (payload: PdfCreatePayload, { rejectWithValue }) => {
    try {
      const response = await callAPI('pdfSummary/create', 'POST', payload);
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getPdfContent = createAsyncThunk(
  'pdfSotre/getPdfContent',
  async (payload: { id: number }, { rejectWithValue }) => {
    try {
      const response = await callAPI(
        `pdfSummary/find?applicationId=${payload?.id}`,
        'GET'
      );
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const UploadPDFLogo = createAsyncThunk(
  'pdfSotre/UploadPDFLogo',
  async (payload: FormData, { rejectWithValue }) => {
    console.log('logo', payload);

    try {
      const response = await callAPI(
        'upload/file/bucket',
        'POST',
        payload,
        false,
        'multipart/form-data'
      );
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const pdfSotre = createSlice({
  name: 'pdfContent',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(pdfContentCreate.pending, (state, action) => {
        state.type = 'PDF_CONTENT_CREATE';
        state.status = 'loading';
      })
      .addCase(pdfContentCreate.fulfilled, (state, action) => {
        state.type = 'PDF_CONTENT_CREATE';
        state.status = 'succeed';
        // state.data = action.payload;
      })
      .addCase(pdfContentCreate.rejected, (state, action) => {
        state.type = 'PDF_CONTENT_CREATE';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(getPdfContent.pending, (state, action) => {
        state.type = 'GET_PDF_DATA';
        state.status = 'loading';
      })
      .addCase(getPdfContent.fulfilled, (state, action) => {
        state.type = 'GET_PDF_DATA';
        state.status = 'succeed';
        state.data = action.payload;
      })
      .addCase(getPdfContent.rejected, (state, action) => {
        state.type = 'GET_PDF_DATA';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const PdfContentSlice = pdfSotre.reducer;

export default PdfContentSlice;
