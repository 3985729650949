import { Box, Button, Flex, Grid, Stack, Text } from '@chakra-ui/react';
import { ActionIcon, Divider, Progress, Title, Tooltip } from '@mantine/core';
import { ModalsProvider, modals } from '@mantine/modals';
import {
  IconDownload,
  IconEdit,
  IconRefresh,
  IconTrash,
} from '@tabler/icons-react';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import {
  MRT_ColumnDef,
  MRT_EditActionButtons,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TableOptions,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { IApplicantData } from '../../../../Redux/Broker/Reports/WorkInProgress/state';
import {
  addWorkingCapitalRow,
  refreshWorkingCapital,
  removeworkingCapitalRow,
  updateWorkingCapitalRow,
} from '../../../../Redux/Broker/Reports/WorkingCapital/slice';
import { TFilterOption } from '../../../../Redux/Broker/Reports/WorkingCapital/state';
import { useAppDispatch, useAppSelector } from '../../../../Redux/Store';
import useAppToast from '../../../../hooks/useAppToast';
import { fixedNumber } from '../../../../utils/helpers';
import {
  ITotalCapital,
  ITotalWICData,
  TCapitalRow,
  getCapitalTotalData,
} from '../../WorkingCapital';
import { formatNumber } from '../MantineTable';
import moment from 'moment';

export interface IMantineAssetsTableProps {
  // columns: MRT_ColumnDef<Record<string, any>>[];
  tableData: Record<string, any>[];
  tableOptions?: MRT_TableOptions<Record<string, any>>;
  filterType: TFilterOption;
  selectedApplicantData: IApplicantData;
  isInitialLoading: boolean;
  assetsData: Record<string, any>[];
  liabilitiesData: Record<string, any>[];
  totalAssetsAndLiabilities: ITotalWICData;
  totalCapital: ITotalCapital;
}

const MantineAssetsTable = ({
  tableData,
  tableOptions,
  filterType,
  selectedApplicantData,
  isInitialLoading,
  assetsData,
  liabilitiesData,
  totalAssetsAndLiabilities,
  totalCapital,
}: IMantineAssetsTableProps) => {
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});

  const brokerWorkingCapital = useAppSelector(
    (state) => state.brokerWorkingCapital
  );
  const applicantProfile = useAppSelector((state) => state.applicantProfile);
  const dispatch = useAppDispatch();

  // const [tableData, setTableData] = useState<any>([]);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoading, setIsLoading] = useState<Record<string, boolean>>({
    isCreatingUser: false,
    isUpdatingUser: false,
    isDeletingUser: false,
  });
  const toast = useAppToast();
  const isLoadingUsersError = false;
  const isFetchingUsers = false;

  useEffect(() => {
    switch (brokerWorkingCapital.status) {
      case 'succeed': {
        if (
          brokerWorkingCapital.type === 'ADD_WIC_ROW' &&
          brokerWorkingCapital.meta?.type === 'CA'
        ) {
          setIsLoading((state) => ({ ...state, isCreatingUser: false }));
        }
        if (
          (brokerWorkingCapital.type === 'UPDATE_WIC_ROW' &&
            brokerWorkingCapital.meta?.type === 'CA') ||
          brokerWorkingCapital.type === 'REFRESH_WIC_API'
        ) {
          setIsLoading((state) => ({ ...state, isUpdatingUser: false }));
        }
        if (
          brokerWorkingCapital.type === 'REMOVE_WIC_API' &&
          brokerWorkingCapital.meta?.type === 'CA'
        ) {
          setIsLoading((state) => ({ ...state, isDeletingUser: false }));
        }
        if (
          brokerWorkingCapital.type === 'ADD_WIC_ROW' &&
          brokerWorkingCapital.meta?.type === 'CA'
        ) {
          setIsLoading((state) => ({ ...state, isCreatingUser: false }));
        }
        break;
      }
      case 'loading': {
        if (
          brokerWorkingCapital.type === 'GET_WIC_DATA_BY_APPLICATIONID_API' &&
          brokerWorkingCapital.meta?.type === 'CA'
        ) {
        }
        if (
          (brokerWorkingCapital.type === 'UPDATE_WIC_ROW' &&
            brokerWorkingCapital.meta?.type === 'CA') ||
          brokerWorkingCapital.type === 'REFRESH_WIC_API'
        ) {
          setIsLoading((state) => ({ ...state, isUpdatingUser: true }));
        }
        if (
          brokerWorkingCapital.type === 'REMOVE_WIC_API' &&
          brokerWorkingCapital.meta?.type === 'CA'
        ) {
          setIsLoading((state) => ({ ...state, isDeletingUser: true }));
        }
        if (
          brokerWorkingCapital.type === 'ADD_WIC_ROW' &&
          brokerWorkingCapital.meta?.type === 'CA'
        ) {
          setIsLoading((state) => ({ ...state, isCreatingUser: true }));
        }
        break;
      }
      case 'failed': {
        if (brokerWorkingCapital.type === 'GET_WIC_DATA_BY_APPLICATIONID_API') {
          toast({
            title: brokerWorkingCapital.error,
            status: 'error',
          });
        }
        const wiCActionTypes = [
          'UPDATE_WIC_ROW',
          'ADD_WIC_ROW',
          'REMOVE_WIC_API',
        ];

        if (
          typeof brokerWorkingCapital.type === 'string' &&
          wiCActionTypes.includes(brokerWorkingCapital.type) &&
          brokerWorkingCapital.meta?.type === 'CA'
        ) {
          setIsLoading((state) => ({
            isCreatingUser: false,
            isDeletingUser: false,
            isUpdatingUser: false,
          }));
        }
        break;
      }
      default:
        break;
    }
  }, [brokerWorkingCapital.status]);

  const columns = useMemo<MRT_ColumnDef<TCapitalRow>[]>(
    () => [
      {
        accessorKey: 'keyName',
        header: 'Allowable Assets',
        size: 50,
        maxSize: 100,
        mantineEditTextInputProps: ({ cell, column, row, table }) => ({
          disabled: filterType === 'Last Fiscal Year' && row.index !== -1,
          error: validationErrors?.projectName,
        }),
        footer: `Total Allowable Assets`,
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        size: 50,
        maxSize: 100,
        mantineEditTextInputProps: ({ cell, column, row, table }) => ({
          display:
            filterType === 'Last Fiscal Year' && row.index !== -1
              ? 'none'
              : 'block',
          error: validationErrors?.projectName,
          type: 'number',
        }),
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        footer: `${formatNumber(
          totalAssetsAndLiabilities.totalAssets.totalAssetsAmount
        )}`,
      },
      {
        accessorKey: 'adjustment',
        header: 'Adjustment (%)',
        size: 50,
        maxSize: 100,
        mantineEditTextInputProps: {
          error: validationErrors?.adjustment,
          type: 'number',
          min: 0,
          max: 100,
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)} %</>,
        footer: `${formatNumber(
          totalAssetsAndLiabilities.totalAssets.totalAssetsAdjustment
        )} %`,
      },
      {
        accessorKey: 'adjustmentAmount',
        header: 'Revised Amount',
        size: 50,
        maxSize: 100,
        mantineEditTextInputProps: {
          error: validationErrors?.adjustmentAmount,
          disabled: true,
          display: 'none',
        },
        Cell: ({ cell }) => <>{formatNumber(cell.getValue() as number)}</>,
        footer: `${formatNumber(
          totalAssetsAndLiabilities.totalAssets.totalAssetsAdjustmentAmount
        )}`,
      },
    ],
    [validationErrors, filterType, JSON.stringify(totalAssetsAndLiabilities)]
  );

  const replaceNullUndefined = (obj: Record<string, any>) => {
    if (typeof obj === 'object' && obj !== null) {
      const newObj: any = Array.isArray(obj) ? [] : {};

      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          // Recursively call replaceNullUndefined for each element
          newObj[i] = replaceNullUndefined(obj[i]);
        }
      } else {
        // If obj is an object, iterate through its properties
        for (let key in obj) {
          // Recursively call replaceNullUndefined for each property value
          newObj[key] = replaceNullUndefined(obj[key]);
        }
      }
      return newObj;
    } else {
      return obj === null || obj === undefined ? '' : obj;
    }
  };

  function findSumByKey(array: Record<string, any>[], key: string) {
    // Check if the array is empty
    if (array.length === 0) {
      return 0; // Return 0 if array is empty to avoid division by zero
    }

    // Calculate sum of values for the specified key
    const sum = array.reduce((acc: any, obj: Record<string, any>) => {
      return acc + obj[key];
    }, 0);

    // Calculate average
    // const average = sum / (array.length - 4);
    // return average.toFixed(2);
    return sum.toFixed(2);
  }

  const handleExportData = (...data: any) => {
    const dt = new Date().toDateString();
    // const s = dt.toLocaleString("en-CA", { timeZone: "America/Edmonton" });
    console.log('selectedApplicantData', selectedApplicantData);

    const csvConfig = mkConfig({
      fieldSeparator: ',',
      decimalSeparator: '.',
      // useKeysAsHeaders: true,
      filename: `${selectedApplicantData?.companyName} Working Capital Report`,
      replaceUndefinedWith: '',
      showTitle: true,
      showColumnHeaders: true,
      columnHeaders: columns.map((column) => ({
        key: column.accessorKey as string,
        displayLabel: column.header,
      })),
      // title: `Working capital report of ${
      //   selectedApplicantData?.companyName
      // } for ${filterType} as on ${
      //   brokerWorkingCapital?.data
      //     ? brokerWorkingCapital?.data.updatedInfo
      //     : new Date()
      // }`,
      title: `${brokerWorkingCapital?.data?.reportInfo?.qboCompanyName} \r\n${brokerWorkingCapital?.data?.reportInfo?.reportName} Summary \r\n${filterType} \r\nAccounting data last updated at ${brokerWorkingCapital?.data?.updatedInfo ? brokerWorkingCapital?.data?.updatedInfo : new Date()} \r\nDate extracted at ${moment(new Date()).format('DD-MM-YYYY hh:mm A')} \r\n`,
    });

    const { totalAssets, totalLiabilities } = totalAssetsAndLiabilities;

    const allData = [
      ...assetsData,
      {
        keyName: 'Total Allowable Assets',
        amount: fixedNumber(totalAssets.totalAssetsAmount),
        adjustmentAmount: fixedNumber(totalAssets.totalAssetsAdjustmentAmount),
        adjustment: fixedNumber(totalAssets.totalAssetsAdjustment),
      },
      {},
      {
        keyName: 'Current Liabilities',
        amount: 'Amount',
        adjustment: 'Adjustment',
        adjustmentAmount: 'Adjustment Amount',
      },
      ...liabilitiesData,
      {
        keyName: 'Total Current Liabilities',
        amount: fixedNumber(totalLiabilities.totalLiabilitiesAmount),
        adjustmentAmount: fixedNumber(
          totalLiabilities.totalLiabilitiesAdjustmentAmount
        ),
        adjustment: fixedNumber(totalLiabilities.totalLiabilitiesAdjustment),
      },
      {},
      {
        keyName: 'Working Capital',
        amount: fixedNumber(totalCapital.totalCapitalAmount),
        adjustmentAmount: fixedNumber(
          totalCapital.totalCapitalAdjustmentAmount
        ),
        adjustment: fixedNumber(totalCapital.totalCapitalAdjustment),
      },
    ];

    const refineData = allData?.map((element: Record<string, any>) => {
      const adjustedElement = {
        ...element,
        amount:
          typeof element.amount === 'number'
            ? fixedNumber(element.amount)
            : element.amount,
        adjustment:
          typeof element.adjustment === 'number'
            ? fixedNumber(element.adjustment / 100, 4)
            : element.adjustment,
        adjustmentAmount:
          typeof element.adjustmentAmount === 'number'
            ? fixedNumber(element.adjustmentAmount)
            : element.adjustmentAmount,
      };

      return replaceNullUndefined(adjustedElement);
    });

    const csv = generateCsv(csvConfig)(refineData);
    download(csvConfig)(csv);
  };

  //CREATE action
  const handleCreateWICRow: MRT_TableOptions<TCapitalRow>['onCreatingRowSave'] =
    async ({ values, exitCreatingMode }) => {
      const newValidationErrors = validateUser(values as any);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      // await updateUser(values);
      let applicantId = null;

      if (selectedApplicantData?.applicantId) {
        applicantId = selectedApplicantData.applicantId;
      } else if (applicantProfile.user?.data?.applicantId)
        applicantId = applicantProfile.user?.data.applicantId;
      if (brokerWorkingCapital.data?.id && applicantId) {
        const { totalAssets, totalLiabilities } = totalAssetsAndLiabilities;
        const {
          totalLiabilitiesAmount,
          totalLiabilitiesAdjustment,
          totalLiabilitiesAdjustmentAmount,
        } = totalLiabilities;
        // const progressInfo = calculatedData(values as any);
        const adjustment = fixedNumber(Number(values.adjustment));
        const amount = fixedNumber(Number(values.amount));
        const adjustmentAmount = fixedNumber(
          Number(amount * (1 - Number(adjustment ?? 0) / 100)) || amount
        );

        const totalAssetsAmount = fixedNumber(
          Number(totalAssets.totalAssetsAmount) + Number(values.amount)
        );

        const totalAssetsAdjustmentAmount = fixedNumber(
          Number(totalAssets.totalAssetsAdjustmentAmount) +
            Number(adjustmentAmount)
        );
        const totalAssetsAdjustment = fixedNumber(
          Number((1 - totalAssetsAdjustmentAmount / totalAssetsAmount) * 100)
        );

        const {
          totalCapitalAmount,
          totalCapitalAdjustment,
          totalCapitalAdjustmentAmount,
        } = getCapitalTotalData({
          totalAssetsAmount,
          totalAssetsAdjustment,
          totalAssetsAdjustmentAmount,
          totalLiabilitiesAmount,
          totalLiabilitiesAdjustment,
          totalLiabilitiesAdjustmentAmount,
        });

        dispatch(
          addWorkingCapitalRow({
            documentId: Number(brokerWorkingCapital.data.id),
            totalWIC: {
              amount: totalCapitalAmount,
              adjustment: totalCapitalAdjustment,
              adjustmentAmount: totalCapitalAdjustmentAmount,
            },
            currentAssets: {
              ...values,
              amount,
              adjustment,
              adjustmentAmount,
            },
            totalAss: {
              amount: totalAssetsAmount,
              adjustment: totalAssetsAdjustment,
              adjustmentAmount: totalAssetsAdjustmentAmount,
            },
            meta: {
              type: 'CA',
            },
          })
        );
      }
      exitCreatingMode();
    };

  //UPDATE action
  const handleEditWICRow: MRT_TableOptions<TCapitalRow>['onEditingRowSave'] =
    async ({ values, exitEditingMode, row }) => {
      const newValidationErrors = validateUser(values as any);
      if (Object.values(newValidationErrors).some((error) => error)) {
        setValidationErrors(newValidationErrors);
        return;
      }
      setValidationErrors({});
      // await updateUser(values);
      let applicantId = null;
      if (selectedApplicantData.applicantId) {
        applicantId = selectedApplicantData.applicantId;
      } else if (applicantProfile.user?.data.applicantId)
        applicantId = applicantProfile.user?.data.applicantId;
      if (brokerWorkingCapital.data?.id && applicantId) {
        const { totalAssets, totalLiabilities } = totalAssetsAndLiabilities;
        const {
          totalAssetsAmount,
          totalAssetsAdjustment,
          totalAssetsAdjustmentAmount,
        } = totalAssets;
        const {
          totalLiabilitiesAmount,
          totalLiabilitiesAdjustment,
          totalLiabilitiesAdjustmentAmount,
        } = totalLiabilities;
        const { original } = row;
        const adjustment = fixedNumber(Number(values.adjustment));
        const amount = fixedNumber(Number(values.amount));

        const adjustmentAmount = fixedNumber(
          Number(amount * (1 - Number(adjustment ?? 0) / 100)) || amount
        );

        const newAssetsAmount =
          totalAssetsAmount - Number(original.amount) + Number(values.amount);
        const newAssetsAdjustmentAmount = fixedNumber(
          totalAssetsAdjustmentAmount -
            Number(original.adjustmentAmount) +
            adjustmentAmount
        );
        const newAssetsAdjustment = fixedNumber(
          (1 - newAssetsAdjustmentAmount / newAssetsAmount) * 100
        );

        const {
          totalCapitalAmount,
          totalCapitalAdjustment,
          totalCapitalAdjustmentAmount,
        } = getCapitalTotalData({
          totalAssetsAmount: newAssetsAmount,
          totalAssetsAdjustment: newAssetsAdjustment,
          totalAssetsAdjustmentAmount: newAssetsAdjustmentAmount,
          totalLiabilitiesAmount,
          totalLiabilitiesAdjustment,
          totalLiabilitiesAdjustmentAmount,
        });

        dispatch(
          updateWorkingCapitalRow({
            documentId: Number(brokerWorkingCapital.data.id),
            totalWIC: {
              amount: totalCapitalAmount,
              adjustment: totalCapitalAdjustment,
              adjustmentAmount: totalCapitalAdjustmentAmount,
            },
            currentAssets: {
              ...values,
              amount,
              adjustment,
              adjustmentAmount,
            },
            capitalId: row.original.capitalId,
            totalAss: {
              amount: newAssetsAmount,
              adjustment: newAssetsAdjustment,
              adjustmentAmount: newAssetsAdjustmentAmount,
            },
            meta: {
              type: 'CA',
            },
          })
        );
      }
      exitEditingMode();
    };

  //DELETE action
  const openDeleteConfirmModal = (row: MRT_Row<TCapitalRow>) =>
    modals.openConfirmModal({
      title: `DELETE`,
      children: (
        <Text>
          Are you sure you want to delete {row.original.keyName}? This action
          cannot be undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        if (
          brokerWorkingCapital.data?.id &&
          Number(row.original.capitalId) >= 0
        ) {
          const { totalAssets, totalLiabilities } = totalAssetsAndLiabilities;
          const { original } = row;
          const {
            totalLiabilitiesAmount,
            totalLiabilitiesAdjustment,
            totalLiabilitiesAdjustmentAmount,
          } = totalLiabilities;
          // const progressInfo = calculatedData(values as any);
          const amount = fixedNumber(Number(original.amount));
          const adjustment = fixedNumber(Number(original.adjustment));
          const adjustmentAmount = fixedNumber(
            Number(original.adjustmentAmount)
          );

          const totalAssetsAmount = fixedNumber(
            Number(totalAssets.totalAssetsAmount - amount)
          );

          const totalAssetsAdjustmentAmount = fixedNumber(
            Number(totalAssets.totalAssetsAdjustmentAmount - adjustmentAmount)
          );
          const totalAssetsAdjustment = fixedNumber(
            Number((1 - totalAssetsAdjustmentAmount / totalAssetsAmount) * 100)
          );

          const {
            totalCapitalAmount,
            totalCapitalAdjustment,
            totalCapitalAdjustmentAmount,
          } = getCapitalTotalData({
            totalAssetsAmount,
            totalAssetsAdjustment,
            totalAssetsAdjustmentAmount,
            totalLiabilitiesAmount,
            totalLiabilitiesAdjustment,
            totalLiabilitiesAdjustmentAmount,
          });

          dispatch(
            removeworkingCapitalRow({
              documentId: brokerWorkingCapital.data.id,
              capitalId: Number(row.original.capitalId),
              totalAss: {
                amount: totalAssetsAmount,
                adjustment: totalAssetsAdjustment,
                adjustmentAmount: totalAssetsAdjustmentAmount,
              },
              type: 'CA',
              totalWIC: {
                amount: totalCapitalAmount,
                adjustment: totalCapitalAdjustment,
                adjustmentAmount: totalCapitalAdjustmentAmount,
              },
              meta: {
                type: 'CA',
              },
            })
          );
        }
      },
    });

  const table = useMantineReactTable({
    columns: columns as any,
    data: tableData as any,
    mantineCreateRowModalProps: {
      className: 'progress-capital',
    },
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row, index) => '' + index,
    enableDensityToggle: false,
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions', 'projectName'] },
    },
    enableStickyFooter: true,
    defaultColumn: { minSize: 40, maxSize: 70, size: 40 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 2, //make actions column wider
        maxSize: 2,
        minSize: 2,
        mantineTableHeadCellProps: {
          sx: {
            zIndex: 999,
          },
        },
      },
    },
    mantineToolbarAlertBannerProps: false
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: isInitialLoading ? 'hidden' : 'auto',
      },
      className: 'capital-assets-table',
    },
    mantineTableHeadCellProps: {
      className: 'custom-column',
    },
    enableColumnPinning: true,
    enablePagination: true,
    positionPagination: 'none',
    enableStickyHeader: true,
    enableBottomToolbar: false,
    mantineProgressProps: ({ isTopToolbar }) => ({
      color: 'orange',
      variant: '', //if you want to show exact progress value
      sx: {
        display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
      },
    }),
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateWICRow,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleEditWICRow,
    renderCreateRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Add New Row</Title>
        <Grid gridTemplateColumns={'1fr'} gap={4}>
          {internalEditComponents}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderEditRowModalContent: ({ table, row, internalEditComponents }) => (
      <Stack>
        <Title order={3}>Edit Data</Title>
        <Grid gridTemplateColumns={'1fr'} gap={4}>
          {internalEditComponents}
        </Grid>
        <Flex justify="flex-end" mt="xl">
          <MRT_EditActionButtons variant="text" table={table} row={row} />
        </Flex>
      </Stack>
    ),
    renderRowActions: ({ row }) => (
      <Flex gap="md">
        <Tooltip label="Edit" position="right">
          <ActionIcon onClick={() => table.setEditingRow(row)} size={'sm'}>
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete" position="right">
          <ActionIcon
            color="red"
            size={'sm'}
            onClick={() => openDeleteConfirmModal(row as any)}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    renderTopToolbar: ({ table }) => (
      <Flex direction={'column'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Button
              onClick={() => {
                table.setCreatingRow(true); //simplest way to open the create row modal with no default values
              }}
              className="primary-btn"
            >
              Add New Row
            </Button>
            <Button
              color="lightblue"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={(...data) => handleExportData(data)}
              leftIcon={<IconDownload />}
              variant="filled"
              className="primary-btn"
            >
              Export All Data
            </Button>
            {brokerWorkingCapital?.data && (
              <Flex flexDirection={'column'}>
                <Flex alignItems={'center'} gap={1}>
                  <Text fontSize={'smaller'}>
                    Accounting data last updated at
                  </Text>
                  <ActionIcon
                    style={{ color: '#114684' }}
                    onClick={() =>
                      dispatch(
                        refreshWorkingCapital({
                          applicantId: selectedApplicantData?.applicantId,
                          filter: filterType,
                        })
                      )
                    }
                    size={'sm'}
                  >
                    <Tooltip
                      label={'Refresh Data'}
                      bg="gray.300"
                      color="black"
                      position="right"
                    >
                      <IconRefresh />
                    </Tooltip>
                  </ActionIcon>
                </Flex>
                <Text>{brokerWorkingCapital?.data.updatedInfo}</Text>
              </Flex>
            )}
          </Box>
          <Flex py={1} alignItems={'center'}>
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box>
          </Flex>
        </Flex>
        {Object.keys(isLoading).some((key) => isLoading[key]) && (
          <Progress value={100} animate={true} />
        )}
      </Flex>
    ),
    onIsFullScreenChange: (value) => setIsFullScreen(value),
    state: {
      isSaving: Object.keys(isLoading).some((key) => isLoading[key]),
      showAlertBanner: isLoadingUsersError,
      isFullScreen: isFullScreen,
      isLoading:
        isInitialLoading ||
        Object.keys(isLoading).some((key) => isLoading[key]),
    },
  });

  return (
    <>
      <ModalsProvider>
        <Box>
          <MantineReactTable table={table} key={'working-capital'} />
        </Box>
      </ModalsProvider>
    </>
  );
};

export default MantineAssetsTable;

const validateRequired = (value: string) => !!value?.length;

const validateUser = (user: TCapitalRow) => {
  return {
    // projectName: !validateRequired(user.keyName)
    //   ? "First Name is Required"
    //   : "",
    // lastName: !validateRequired(user.lastName) ? 'Last Name is Required' : '',
    // email: !validateEmail(user.email) ? 'Incorrect Email Format' : '',
  };
};
