import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import accessRequestSlice from './AccessRequest/slice';
import accountReducer from './Applicant/Accountant/Accountant';
import brokerageIDSlice from './Applicant/AddBrokerage/AddBrokerage';
import applicantDashboardSlice from './Applicant/ApplicantDashboard/slice';
import ApplicantProfileSlice from './Applicant/ApplicantProfile/ApplicantProfile';
import ownerReducer from './Applicant/Owner/Owner';
import AccountantQboSlice from './Applicant/Qbo/AccountantQbo';
import QboReducer from './Applicant/Qbo/Qbo';
import appliactionsSlice from './Applications/slice';
import authReducer from './Auth/Auth';
import brokerSlice from './Broker/BrokerProfile/BrokerProfile';
import CreateApplicationFormSlice from './Broker/CreateApplicationForm/CreateApplicationForm';
import BrokerageDashboardSlice from './Broker/Dashboard/BrokerageDashboard';
import HisotrySlice from './Broker/Dashboard/History';
import documentSlice from './Broker/Documents/slice';
import brokerAccountPayableSlice from './Broker/Reports/APAgingSummary/slice';
import brokerReportProgressSlice from './Broker/Reports/WorkInProgress/slice';
import brokerWorkingCapitalSlice from './Broker/Reports/WorkingCapital/slice';
import brokerageSlice from './Brokerage/BrokerageProfile/BrokerageProfile';
import notificationFormStateSlice from './Notification/Notification';
import PdfContentSlice from './PdfContent/slice';
import plansSlice from './Plans/slice';
import uploadReportsSlice from './UploadReports/slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    qbo: QboReducer,
    owner: ownerReducer,
    accountant: accountReducer,
    brokerageID: brokerageIDSlice,
    brokerage: brokerageSlice,
    broker: brokerSlice,
    applicantProfile: ApplicantProfileSlice,
    brokerageDashboard: BrokerageDashboardSlice,
    createApplicationFormSlice: CreateApplicationFormSlice,
    notification: notificationFormStateSlice,
    history: HisotrySlice,
    AccountantQbo: AccountantQboSlice,
    brokerReportProgress: brokerReportProgressSlice,
    brokerWorkingCapital: brokerWorkingCapitalSlice,
    documentSlice: documentSlice,
    uploadReportsSlice,
    PdfContentSlice,
    accountPayable: brokerAccountPayableSlice,
    plans: plansSlice,
    appliactionsSlice,
    applicantDashboardSlice,
    accessRequestSlice,
  },
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = useDispatch.withTypes<AppDispatch>(); // Export a hook that can be reused to resolve types
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
