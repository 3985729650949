import {
  Box,
  Container,
  Divider,
  Heading,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useLocation, useNavigate } from 'react-router-dom';
import { IPlanBase } from 'src/data/plans';
import PaymentForm from './PaymentForm';

const Checkout = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const plan = (location.state as { plan?: IPlanBase })?.plan;

  const [isVerifying, setIsVerifying] = useState(true);

  useEffect(() => {
    if (!plan) navigate('/brokerage/plans');
  }, [plan]);

  const handleProceedToPayment = () => {
    setIsVerifying(false);
  };

  const handleGoBack = () => {
    setIsVerifying(true);
  };

  return (
    <Container
      maxW="container.md"
      mt={8}
      display={'flex'}
      flexWrap={'wrap'}
      justifyContent={'space-between'}
      gap={4}
    >
      <PaymentForm onBack={handleGoBack} />
      <Box
        borderWidth="1px"
        borderRadius={'xl'}
        p={6}
        boxShadow="lg"
        bg={'white'}
        minW={330}
        shadow="base"
        borderColor={'gray.300'}
      >
        <VStack spacing={4} align="start">
          <Text as={'h1'} fontSize={18} lineHeight={2} fontWeight={600} m={0}>
            Selected Plan
          </Text>
          <Divider borderColor={'gray.700'} />
          <Box>
            <Text fontWeight="bold" fontSize="2xl">
              {plan?.planName}
            </Text>
            <HStack>
              <Text fontSize="2xl" fontWeight="600">
                {plan?.currency}
              </Text>
              <Text fontSize="3xl" fontWeight="900">
                {plan?.price}
              </Text>
              {plan?.payby && (
                <Text fontSize="2xl" color="gray.500">
                  /{plan?.payby === 'M' ? 'month' : 'year'}
                </Text>
              )}
            </HStack>
          </Box>
          <Divider borderColor={'gray.700'} />
          <Heading as="h3" size="md">
            Features
          </Heading>
          <List spacing={3}>
            {plan?.summary.map((feature, index) => (
              <ListItem key={index}>
                <ListIcon as={FaCheckCircle} color="green.500" />
                {feature}
              </ListItem>
            ))}
          </List>
          <Divider borderColor={'gray.700'} />
        </VStack>
      </Box>
    </Container>
  );
};

export default Checkout;
