export const keyMapping: { [key: string]: string } = {
  hasGuarantees: 'Guarantees for Indebtedness',
  hasChangesInManagement: 'Change in Control',
  hasLiensFiledAgainstCompany: 'Liens Against You',
  hasLiensFiledByCompany: 'Liens By You',
  hasStateFederalTax: 'Federal Tax',
  hasOutstandingJudgments: 'Judgments',
  hasOutstandingSuits: 'Suits',
  hasOutstandingClaims: 'Claims',
  hasBuySellAgreement: 'Buy-Sell Agreements',
  guaranteeDetails: 'Guarantee Details',
  termsOfIndebtedness: 'Terms of Indebtedness',
  legalAndRegulatoryCompliance: 'Legal and Regulatory Compliance',
  legalDocuments: 'Legal Documents',
  legalDocumentation: 'Legal Documentation',
  shareholderAgreements: 'Shareholder Agreements',
  regulatoryFilings: 'Regulatory Filings',
  agreementCopy: 'Agreement Copy',
  courtFilings: 'Court Filing',
  paymentPlanUpload: 'Payment Plan',
};

export const renameKeys = (obj: any): any => {
  if (obj && typeof obj === 'object') {
    if (Array.isArray(obj)) {
      return obj.map(renameKeys);
    } else {
      const newObj: any = {};
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const newKey = keyMapping[key] || key;
          newObj[newKey] = renameKeys(obj[key]);
        }
      }
      return newObj;
    }
  }
  return obj;
};

export const cleanObject = (obj: any): any => {
  // Iterate over the object keys
  for (const key in obj) {
    const value = obj[key];

    // Recursively clean nested objects
    if (typeof value === 'object' && value !== null) {
      // If the value is an array, clean it and check if it's empty
      if (Array.isArray(value)) {
        obj[key] = value
          .map(cleanObject) // Recursively clean each object in the array
          .filter((item) => {
            // Filter out arrays with empty objects or objects whose last key has null
            if (typeof item === 'object' && item !== null) {
              const itemKeys = Object.keys(item);
              // Check if the object is empty or the last key has null value
              if (
                itemKeys.length === 0 ||
                item[itemKeys[itemKeys.length - 1]] === null
              ) {
                return false; // Remove the object if it's empty or last key is null
              }
            }
            return true; // Keep the object otherwise
          });

        if (obj[key].length === 0) {
          delete obj[key]; // Delete the array if it's empty
        }
      } else {
        // Recursively clean nested objects
        obj[key] = cleanObject(value);

        // Remove the object if all keys have been removed or are empty
        if (Object.keys(obj[key]).length === 0) {
          delete obj[key]; // Delete the object if it's empty
        }
      }
    } else if (value === null) {
      // Delete key if the value is null
      delete obj[key];
    }
  }

  // Return the cleaned object
  return obj;
};

export const cleanCaseNumberObjects = (obj: any): any => {
  if (Array.isArray(obj)) {
    return obj
      .map(cleanCaseNumberObjects) // Recursively apply the function to elements of the array
      .filter(
        (item) =>
          !(
            typeof item === 'object' &&
            Object.keys(item).length === 1 &&
            item.hasOwnProperty('caseNumber')
          )
      ); // Remove objects with only 'caseNumber'
  } else if (typeof obj === 'object' && obj !== null) {
    const cleanedObj: any = {};
    for (const key in obj) {
      cleanedObj[key] = cleanCaseNumberObjects(obj[key]); // Recursively clean nested objects and arrays
    }
    return cleanedObj;
  } else {
    return obj; // Return non-object/non-array values as is
  }
};

// export function restructureObject(obj: any, parentKey = '') {
//   let result: any = {};

//   // Count the number of keys in the current object
//   const keys = Object.keys(obj);

//   // If it's a single key, continue without adding a folder
//   if (keys.length === 1 && typeof obj === 'object') {
//     const key = keys[0];
//     const value = obj[key];

//     // If it's an array (file array), add the key as a folder and keep the array
//     if (Array.isArray(value)) {
//       result[key] = value;
//     } else if (typeof value === 'object') {
//       // Recursively go down if it's an object
//       result = { ...restructureObject(value, key) };
//     }
//   } else {
//     // If multiple keys, treat them as folders
//     keys.forEach((key) => {
//       const value = obj[key];

//       // If the value is an object, continue recursion
//       if (typeof value === 'object' && !Array.isArray(value)) {
//         result[key] = restructureObject(value, key);
//       } else {
//         // If it's a file array, add the key as a folder
//         result[key] = value;
//       }
//     });
//   }

//   return result;
// }

export function restructureObject(obj: any, parentKey = '') {
  //for "Guarantees for Indebtedness" don't check object has multiple key or not directly add "Guarantee Details" folder
  let result: any = {};

  // Count the number of keys in the current object
  const keys = Object.keys(obj);

  // Special handling for "Guarantee Details"
  if (keys.includes('Guarantee Details')) {
    // Always add "Guarantee Details" as a folder, regardless of sibling keys
    const guaranteeDetailsValue = obj['Guarantee Details'];
    if (Array.isArray(guaranteeDetailsValue)) {
      result['Guarantee Details'] = { folder: guaranteeDetailsValue };
    } else if (typeof guaranteeDetailsValue === 'object') {
      result['Guarantee Details'] = {
        folder: restructureObject(guaranteeDetailsValue, 'Guarantee Details'),
      };
    }
  }

  // If key is "Guarantees for Indebtedness", treat it as a folder directly
  if (parentKey === 'Guarantees for Indebtedness') {
    keys.forEach((key) => {
      const value = obj[key];
      if (typeof value === 'object' && !Array.isArray(value)) {
        // Always add keys within "Guarantees for Indebtedness" as folders
        result[key] = restructureObject(value, key);
      } else {
        // If it's an array, treat as a folder containing files
        result[key] = value;
      }
    });
    return result; // Exit here since we handled "Guarantees for Indebtedness"
  }

  // If it's a single key (and not "Guarantees for Indebtedness"), continue without adding a folder
  if (
    keys.length === 1 &&
    typeof obj === 'object' &&
    !keys.includes('Guarantee Details')
  ) {
    const key = keys[0];
    const value = obj[key];

    // If it's an array (file array), add the key as a folder and keep the array
    if (Array.isArray(value)) {
      result[key] = value;
    } else if (typeof value === 'object') {
      // Recursively go down if it's an object
      result = { ...restructureObject(value, key) };
    }
  } else {
    // If multiple keys, treat them as folders
    keys.forEach((key) => {
      // Skip "Guarantee Details" as we already processed it
      if (key === 'Guarantee Details') return;

      const value = obj[key];

      // If the value is an object, continue recursion
      if (typeof value === 'object' && !Array.isArray(value)) {
        result[key] = restructureObject(value, key);
      } else {
        // If it's a file array, add the key as a folder
        result[key] = value;
      }
    });
  }

  return result;
}

export const getfolderNamePrefix: { [key: string]: string } = {
  'Federal Tax': 'Lien',
  Judgments: 'Judgement',
  Suits: 'Suit',
  Claims: 'Claim',
  'Liens Against You': 'Lien',
  'Liens By You': 'Lien',
};

// Helper function to generate folder names based on index for Judgements, suits, liens, federal tax and claims
export const getFolderName = (
  key: string,
  mainKey: string,
  obj: Record<string, any>
): string => {
  // console.log('getFolderName', key, mainKey);

  const numericKey = parseInt(key, 10);
  if (!isNaN(numericKey)) {
    // return `${getfolderNamePrefix[mainKey]} ${numericKey}`;     //folder name like Lien0,Lien1...
    return obj['caseNumber']; // folder name by caseNumber
  }
  return key;
};
