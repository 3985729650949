import { Box } from '@chakra-ui/react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_Row,
  useMantineReactTable,
} from 'mantine-react-table';

const LegalFormEditableTable = ({
  tableData,
  columns,
}: {
  tableData: LegalFormLiensFiledAgainstCompanyTable[];
  columns: MRT_ColumnDef<LegalFormLiensFiledAgainstCompanyTable>[];
}) => {
  //   const getbodycss = ({
  //     row,
  //   }: {
  //     row: MRT_Row<LegalFormLiensFiledAgainstCompanyTable>;
  //   }) => {
  //     const isParentRow = row?.original?.parentKey;
  //     console.log(
  //       'test',
  //       row,
  //       row?.original,
  //       row?.original?.parentKey,
  //       isParentRow
  //     );

  //     return {
  //       style: {
  //         // outline: 'none !important',
  //         fontWeight: isParentRow ? 'bold' : 'normal',
  //       },
  //     };
  //   };

  const table = useMantineReactTable({
    columns: columns as any,
    data: tableData ?? [],
    enableEditing: (row: MRT_Row<LegalFormLiensFiledAgainstCompanyTable>) => {
      // console.log('row', row);
      return row?.original?.id === 'courtFilings' ||
        row?.original?.id === 'nameOfLienHolder' ||
        row?.original?.id === 'nameOfDebtor' ||
        row?.original?.id === 'hasPaymentPlan' ||
        row?.original?.id === 'nameOfPlaintiff' ||
        row?.original?.id === 'nameOfDefendant' ||
        row?.original?.id === 'nameOfDebtors' ||
        row?.original?.id === 'nameOfCreditors' ||
        row?.original?.id === 'jurisdiction'
        ? false
        : row?.original?.parentKey
          ? false
          : true;
    },

    editDisplayMode: 'table',
    getRowId: (row, index) => row?.id ?? index + '',
    mantineTableContainerProps: {
      className: 'legal-form-table editable-table-parentRow',
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: 'auto',
      },
    },
    // mantineTableBodyRowProps: getbodycss,
    enableToolbarInternalActions: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableRowActions: false,
    enableColumnPinning: true,
    defaultColumn: { minSize: 200, maxSize: 200, size: 200 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['name'] },
    },
    // enableTableHead: false,
    // mantineEditTextInputProps: ({ cell, column, row, table }) => ({
    //   onChange: (event: any) => {
    //     handleSaveguaranteesForOtherPartiesCell(cell, event.target.value);
    //   },
    //   type: 'text',
    //   variant: 'unstyled',
    // }),
  });

  return (
    <Box
      maxW={'1550px'}
      width={'100%'}
      // minW={'500px'}
      mx={'auto'}
    >
      <MantineReactTable table={table} />
    </Box>
  );
};

export default LegalFormEditableTable;
