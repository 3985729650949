import { uniqueId } from 'lodash';
import moment from 'moment';

// SubscriptionPlan Types
export enum SubscriptionPlanTypes {
  INTRO = 'Intro',
  STARTER = 'Starter',
  GROWTH = 'Growth',
  SCALE = 'Scale',
  OPTIMIZE = 'Optimize',
}

// SubscriptionPlan Category
export enum PlanCategory {
  Subscription = 'subscription',
  OneTime = 'one-time',
}

// SubscriptionPlan Cycle
export enum SubscriptionPlanCycles {
  monthly = 'M',
  yearly = 'Y',
}

export interface IPlanBase {
  // subscriptionPlanId: number;
  planName: SubscriptionPlanTypes;
  actualPlanName: string;
  planCategory: PlanCategory;
  price: number;
  originalPrice: number;
  stripeKey: string;
  features: {
    totalAmount: number;
    avgCostPerApplication: number;
    pricePerMonth: number;
    pricePerYear: number;
    expiry: number;
    // totalApplicationsPerYear: number;
    // totalApplicationsPerMonth: number;
  };
  totalApplications: number;
  billingCycle: SubscriptionPlanCycles;
  summary: string[];
  payby: SubscriptionPlanCycles;
  yearlyPlanId?: number;
  isAddonsEnable: Boolean;
  isPopular: Boolean;
  currency: string;
  topupPlanId: number;
}

interface IOneTimePlan {
  planName: string;
  actualPlaneName: string;
  price: number;
  summary: string[];
  isPopular: boolean;
  currency: string;
  unique: boolean;
}

export const brokragePlans: {
  yearly: IPlanBase[];
  monthly: IPlanBase[];
} = {
  yearly: [
    {
      planName: SubscriptionPlanTypes.STARTER,
      actualPlanName: 'Starter',
      price: 406,
      summary: [
        '$4,875 per year',
        '65 applications included',
        'Average cost per application $75',
      ],
      isPopular: false,
      currency: 'USD',
      features: {
        avgCostPerApplication: 75,
        pricePerMonth: 470,
        pricePerYear: 406,
        expiry: 365,
        totalAmount: 4875,
      },
      billingCycle: SubscriptionPlanCycles.yearly,
      stripeKey: 'stater-yearly-v1',
      isAddonsEnable: false,
      originalPrice: 406,
      payby: SubscriptionPlanCycles.yearly,
      planCategory: PlanCategory.Subscription,
      yearlyPlanId: 45,
      totalApplications: 65,
      topupPlanId: 10,
    },
    {
      planName: SubscriptionPlanTypes.GROWTH,
      actualPlanName: 'Growth',
      price: 813,
      summary: [
        '$9,750 per year',
        '150 applications included',
        'Average cost per application $65 ',
      ],
      isPopular: true,
      currency: 'USD',
      payby: SubscriptionPlanCycles.yearly,
      features: {
        avgCostPerApplication: 65,
        pricePerMonth: 984,
        pricePerYear: 813,
        expiry: 365,
        totalAmount: 9750,
      },
      billingCycle: SubscriptionPlanCycles.yearly,
      totalApplications: 150,
      isAddonsEnable: false,
      originalPrice: 813,
      planCategory: PlanCategory.Subscription,
      stripeKey: 'growth-yearly-v1',
      topupPlanId: 12,
    },
    {
      planName: SubscriptionPlanTypes.SCALE,
      actualPlanName: 'Scale',
      price: 1633,
      summary: [
        '$19,600 per year',
        '400 applications included',
        'Average cost per application $49',
      ],
      isPopular: false,
      currency: 'USD',
      payby: SubscriptionPlanCycles.yearly,
      features: {
        avgCostPerApplication: 49,
        pricePerMonth: 1980,
        pricePerYear: 1633,
        expiry: 365,
        totalAmount: 19600,
      },
      totalApplications: 400,
      billingCycle: SubscriptionPlanCycles.yearly,
      isAddonsEnable: false,
      originalPrice: 1633,
      planCategory: PlanCategory.Subscription,
      stripeKey: 'growth-yearly-v1',
      topupPlanId: 12,
    },
    {
      planName: SubscriptionPlanTypes.OPTIMIZE,
      actualPlanName: 'Optimize',
      price: 3333,
      summary: [
        '$40,000 per year',
        '1000 applications included',
        'Average cost per application $40',
      ],
      isPopular: false,
      currency: 'USD',
      payby: SubscriptionPlanCycles.yearly,
      features: {
        avgCostPerApplication: 40,
        pricePerMonth: 4000,
        pricePerYear: 3333,
        expiry: 365,
        totalAmount: 40000,
      },
      totalApplications: 1000,
      billingCycle: SubscriptionPlanCycles.yearly,
      isAddonsEnable: false,
      originalPrice: 3333,
      planCategory: PlanCategory.Subscription,
      stripeKey: 'growth-yearly-v1',
      topupPlanId: 12,
    },
  ],
  monthly: [
    {
      planName: SubscriptionPlanTypes.STARTER,
      actualPlanName: 'Starter',
      price: 470,
      summary: [
        // '$5,850 per year',
        '5 applications included',
        'Average cost per application $94',
      ],
      isPopular: false,
      currency: 'USD',
      payby: SubscriptionPlanCycles.monthly,
      features: {
        avgCostPerApplication: 94,
        pricePerMonth: 470,
        pricePerYear: 406,
        expiry: 30,
        totalAmount: 5850,
      },
      billingCycle: SubscriptionPlanCycles.monthly,
      totalApplications: 5,
      stripeKey: 'starter-monthly-v1',
      isAddonsEnable: false,
      originalPrice: 470,
      planCategory: PlanCategory.Subscription,
      topupPlanId: 45,
    },
    {
      planName: SubscriptionPlanTypes.GROWTH,
      actualPlanName: 'Growth',
      price: 984,
      summary: [
        //'$11,700 per year',
        '12 applications included',
        'Average cost per application $82',
      ],
      isPopular: true,
      currency: 'USD',
      payby: SubscriptionPlanCycles.monthly,
      billingCycle: SubscriptionPlanCycles.monthly,
      totalApplications: 12,
      features: {
        avgCostPerApplication: 82,
        pricePerMonth: 984,
        pricePerYear: 813,
        expiry: 30,
        totalAmount: 11700,
      },
      stripeKey: 'growth-monthly-v1',
      isAddonsEnable: false,
      originalPrice: 984,
      planCategory: PlanCategory.Subscription,
      topupPlanId: 45,
    },
    {
      planName: SubscriptionPlanTypes.SCALE,
      actualPlanName: 'Scale',
      price: 1980,
      summary: [
        //'$23,520 per year',
        '33 applications included',
        'Average cost per application $60',
      ],
      isPopular: false,
      currency: 'USD',
      payby: SubscriptionPlanCycles.monthly,
      billingCycle: SubscriptionPlanCycles.monthly,
      totalApplications: 33,
      features: {
        avgCostPerApplication: 60,
        pricePerMonth: 1980,
        pricePerYear: 1633,
        expiry: 30,
        totalAmount: 23520,
      },
      stripeKey: 'scale-monthly-v1',
      isAddonsEnable: false,
      originalPrice: 1980,
      planCategory: PlanCategory.Subscription,
      topupPlanId: 45,
    },
    {
      planName: SubscriptionPlanTypes.OPTIMIZE,
      actualPlanName: 'Optimize',
      price: 4000,
      summary: [
        // '$48,000 per year',
        '80 applications included',
        'Average cost per application $50 ',
      ],
      isPopular: false,
      currency: 'USD',
      payby: SubscriptionPlanCycles.monthly,
      billingCycle: SubscriptionPlanCycles.monthly,
      totalApplications: 80,
      features: {
        avgCostPerApplication: 50,
        pricePerMonth: 4000,
        pricePerYear: 3333,
        expiry: 30,
        totalAmount: 48000,
      },
      stripeKey: 'optimize-monthly-v1',
      isAddonsEnable: false,
      originalPrice: 4000,
      planCategory: PlanCategory.Subscription,
      topupPlanId: 45,
    },
  ],
};

export const oneTimePurchasePlans: IOneTimePlan[] = [
  {
    planName: '1 Application',
    actualPlaneName: '1 Application',
    price: 125,
    summary: [
      '1 application included',
      // 'Average cost per application $125'
    ],
    isPopular: false,
    currency: 'USD',
    unique: false,
  },
  // {
  //   planName: '10 Applications',
  //   actualPlaneName '10 Applications',
  //   price: 1190,
  //   features: ['10 applications included', 'Average cost per application $119'],
  //   isPopular: true,
  //   currency: 'USD',
  //   unique: false,
  // },
  // {
  //   planName: '25 Applications',
  //   actualPlaneName '25 Applications',
  //   price: 2825,
  //   features: ['25 applications included', 'Average cost per application $113'],
  //   isPopular: false,
  //   currency: 'USD',
  //   unique: true,
  // },
];

interface IInvoice {
  id: string;
  amount: number;
  currency: string;
  date: string;
  status: 'Success' | 'Faild';
}

export const invoicesData: IInvoice[] = [
  {
    id: uniqueId('10000000'),
    amount: 1633,
    currency: 'USD',
    status: 'Success',
    date: moment(new Date()).format('D MMMM, YYYY'),
  },
  {
    id: uniqueId('10000000'),
    amount: 70,
    currency: 'USD',
    status: 'Faild',
    date: '07 June, 2024',
  },
];
