import { Avatar, Tooltip, VStack } from '@chakra-ui/react';

const AvatarComponent = ({ name }: { name: string }) => {
  return (
    <VStack>
      <Tooltip fontSize="md" label={name} placement={'bottom'} hasArrow>
        <Avatar
          name={name}
          getInitials={(name) => {
            const [firstName, lastName] = name.split(' ');
            return `${firstName[0]}${lastName ? lastName[0] : firstName[1]}`;
          }}
          color="#114684"
          border={'1px solid #114684'}
          bg={'white'}
          size={'md'}
          height={'26px'}
          width={'26px'}
          p={'17px'}
          marginTop={'5px'}
        ></Avatar>
      </Tooltip>
    </VStack>
  );
};

export default AvatarComponent;
