import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { ActionIcon, Divider, Progress, Tooltip } from '@mantine/core';
import { ModalsProvider } from '@mantine/modals';
import { IconDownload, IconRefresh } from '@tabler/icons-react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  useMantineReactTable,
} from 'mantine-react-table';
import React, { useEffect, useMemo, useState } from 'react';
import { TFilterAccountPayable } from 'src/Redux/Broker/Reports/APAgingSummary/state';
import { formatNumber } from '../MantineTable';
import { download, generateCsv, mkConfig } from 'export-to-csv';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { useAppToast } from 'src/hooks';
import {
  getBalanceSheetData,
  refreshBSPLReport,
} from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import { getCSVConfigByReportName } from 'src/utils/reports/generateAllReports';
import moment from 'moment';

interface APTable {
  tableData: Record<string, any>[] | undefined;
  filterType: TFilterAccountPayable;
  isInitialLoading: boolean;
  selectApplicantData: Record<string, any>;
  applicantId: string;
}

interface TBalanceSheetRow {
  AccountPayableId: number;
  amount: string | null;
  title: string;
}

const BalanceSheetTable = ({
  tableData,
  filterType,
  isInitialLoading,
  selectApplicantData,
  applicantId,
}: APTable) => {
  const accountPayableReport = useAppSelector((state) => state.accountPayable);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isRefresh, setIsRefresh] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const toast = useAppToast();

  useEffect(() => {
    switch (accountPayableReport.status) {
      case 'succeed': {
        if (accountPayableReport.type === 'REFRESH_BS_REPORT') {
          setIsRefresh(false);
          dispatch(
            getBalanceSheetData({
              applicantId: Number(applicantId),
              filter: filterType,
            })
          );
        }
        return;
      }
      case 'loading': {
        if (accountPayableReport.type === 'REFRESH_BS_REPORT') {
          setIsRefresh(true);
        }
        return;
      }
      case 'failed': {
        if (accountPayableReport.type === 'REFRESH_BS_REPORT') {
          toast({
            title: accountPayableReport.error,
            status: 'error',
          });
          setIsRefresh(false);
        }
        return;
      }
    }
  }, [accountPayableReport.status]);

  const columns = useMemo<MRT_ColumnDef<TBalanceSheetRow>[]>(
    () => [
      {
        accessorKey: 'title',
        header: 'Name',
      },
      {
        accessorKey: 'amount',
        header: 'Amount',
        Cell: ({ cell }) => (
          <>
            {cell.getValue() !== '' && cell.getValue() !== null
              ? formatNumber(Number(cell.getValue()))
              : cell.getValue()}
          </>
        ),
      },
    ],
    [filterType]
  );

  const checkTotalInclude = (row: Record<string, any>) => {
    return row?.title?.toLowerCase().includes('total');
  };

  const getTableBodyRowProps = ({ row }: { row: MRT_Row }) => {
    if (tableData) {
      const isLastRow = row.index === tableData?.length - 1;
      const isTotal = checkTotalInclude(row?.original);
      return {
        style: {
          fontWeight: isLastRow || isTotal ? 'bold' : 'normal',
        },
      };
    } else return {};
  };

  const replaceNullUndefined = (obj: Record<string, any>) => {
    if (typeof obj === 'object' && obj !== null) {
      const newObj: any = Array.isArray(obj) ? [] : {};

      if (Array.isArray(obj)) {
        for (let i = 0; i < obj.length; i++) {
          // Recursively call replaceNullUndefined for each element
          newObj[i] = replaceNullUndefined(obj[i]);
        }
      } else {
        // If obj is an object, iterate through its properties
        for (let key in obj) {
          // Recursively call replaceNullUndefined for each property value
          newObj[key] = replaceNullUndefined(obj[key]);
        }
      }
      return newObj;
    } else {
      return obj === null || obj === undefined ? '' : obj;
    }
  };

  const handleExportData = (...data: any) => {
    const config = getCSVConfigByReportName({
      type: 'Balance Sheet',
      title: `${accountPayableReport?.data?.reportInfo?.qboCompanyName} \r\n${accountPayableReport?.data?.reportInfo?.reportName} Summary \r\n${filterType} \r\nAccounting data last updated at ${accountPayableReport?.data?.lastUpdateAt ? accountPayableReport?.data?.lastUpdateAt : new Date()} \r\nDate extracted at ${moment(new Date()).format('DD-MM-YYYY hh:mm A')} \r\n`,
      filename: `${selectApplicantData?.companyName} Balance Sheet Report`,
    });

    const csvConfig = mkConfig(config);

    const refineData = tableData?.map((element: Record<string, any>) => {
      return replaceNullUndefined(element);
    });

    if (refineData && refineData.length > 0) {
      const csv = generateCsv(csvConfig)(refineData);
      download(csvConfig)(csv);
    }
  };

  const table = useMantineReactTable({
    columns: columns as any,
    data: (tableData ?? []) as any,
    mantineCreateRowModalProps: {
      className: 'progress-capital',
    },
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'modal', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: false,
    getRowId: (row, index) => '' + index,
    enableDensityToggle: false,
    initialState: {
      density: 'xs',
    },
    enableStickyFooter: true,
    defaultColumn: { minSize: 50, maxSize: 50, size: 100 },
    mantineTableProps: {
      sx: {
        tableLayout: 'fixed',
      },
    },
    mantineToolbarAlertBannerProps: false
      ? {
          color: 'red',
          children: 'Error loading data',
        }
      : undefined,
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: isInitialLoading ? 'hidden' : 'auto',
      },
      className: 'capital-assets-table',
    },
    mantineTableHeadCellProps: {
      className: 'custom-column',
    },
    mantineTableBodyRowProps: getTableBodyRowProps,
    enableColumnPinning: true,
    enablePagination: true,
    positionPagination: 'none',
    enableStickyHeader: true,
    enableBottomToolbar: false,
    mantineProgressProps: ({ isTopToolbar }) => ({
      color: 'orange',
      variant: '', //if you want to show exact progress value
      sx: {
        display: isTopToolbar ? 'block' : 'none', //hide bottom progress bar
      },
    }),
    renderTopToolbar: ({ table }) => (
      <Flex direction={'column'}>
        <Flex justifyContent={'space-between'} alignItems={'center'}>
          <Box
            sx={{
              display: 'flex',
              gap: '16px',
              padding: '8px',
              flexWrap: 'wrap',
            }}
          >
            <Button
              color="lightblue"
              //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
              onClick={(...data) => handleExportData(data)}
              leftIcon={<IconDownload />}
              variant="filled"
              className="primary-btn"
            >
              Export All Data
            </Button>
            {accountPayableReport?.data && (
              <Flex flexDirection={'column'}>
                <Flex alignItems={'center'} gap={1}>
                  <Text fontSize={'smaller'}>
                    Accounting data last updated at
                  </Text>
                  <ActionIcon
                    style={{ color: '#114684' }}
                    onClick={() =>
                      dispatch(
                        refreshBSPLReport({
                          applicantId: applicantId,
                          filter: filterType,
                          reportName: 'Balance Sheet',
                        })
                      )
                    }
                    size={'sm'}
                  >
                    <Tooltip
                      label={'Refresh Data'}
                      bg="gray.300"
                      color="black"
                      position="right"
                    >
                      <IconRefresh />
                    </Tooltip>
                  </ActionIcon>
                </Flex>
                <Text>{accountPayableReport?.data?.lastUpdateAt}</Text>
              </Flex>
            )}
          </Box>
          <Flex py={1} alignItems={'center'}>
            <Flex alignItems={'center'} gap={4} p={3}>
              <MRT_GlobalFilterTextInput table={table} />
              <MRT_ToolbarInternalButtons table={table} />
            </Flex>
            <Divider
              size="md"
              orientation="vertical"
              h={40}
              style={{ alignSelf: 'center' }}
            />
            <Box className="pagination-wrapper">
              <MRT_TablePagination position="top" table={table} />
            </Box>
          </Flex>
        </Flex>
        {isRefresh && <Progress value={100} animate={true} />}
      </Flex>
    ),
    onIsFullScreenChange: (value) => setIsFullScreen(value),
    state: {
      isFullScreen: isFullScreen,
      isLoading: isInitialLoading || isRefresh,
    },
  });
  return (
    <>
      <ModalsProvider>
        <Box>
          <MantineReactTable table={table} key={'balance_sheet_report'} />
        </Box>
      </ModalsProvider>
    </>
  );
};

export default BalanceSheetTable;
