import { useEffect } from 'react';
import { socket } from '../../Socket';
import { useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { CheckQboStatus } from '../../Redux/Applicant/Qbo/Qbo';

const SocketEvents = ({ children }: any) => {
  const Toast = useToast();
  const dispatch = useDispatch();

  // Dependency array is not provided intentionally
  useEffect(() => {
    const userID = localStorage.getItem('userID');
    const userType = localStorage.getItem('userType');

    if (userType) {
      // Emit the socket_register event to the server
      socket.emit('socket_register', { user_id: userID, user_type: userType });

      // Handle the response from the server if needed
      socket.on('socket_register', (response) => {
        // Handle the registration response from the server
      });
    }

    if (userType === 'broker') {
      // 1
      socket.on('permissionGranted', (data) => {
        if (data?.applicationId)
          dispatch(CheckQboStatus(data?.applicationId) as any);
        Toast({
          title: data.message,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      });

      // 2
      socket.on('permissionDenied', (data) => {
        if (data?.applicationId)
          dispatch(CheckQboStatus(data?.applicationId) as any);
        Toast({
          title: data?.message,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      });

      // 3
      socket.on('updateFormByApplicant', (data) => {
        Toast({
          title: data?.message,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      });

      // 4
      socket.on('updateFormByAccountant', (data) => {
        Toast({
          title: data.message,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      });

      // 5
      socket.on('updateFormByOwner', (data) => {
        Toast({
          title: data.message,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      });
    }

    if (userType === 'applicant') {
      // 1
      socket.on('askPermission', (data) => {
        Toast({
          title: data.message,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      });

      // 2
      socket.on('reviewFormByApplicant', (data) => {
        Toast({
          title: data.message,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      });

      // 3
      socket.on('updateFormByAccountant', (data) => {
        Toast({
          title: data.message,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      });

      // 4
      socket.on('initializeApplication', (data) => {
        Toast({
          title: data.message,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      });
    }

    // Cleanup function
    return () => {
      if (userType === 'broker') {
        socket.off('permissionGranted');
        socket.off('permissionDenied');
        socket.off('updateFormByApplicant');
        socket.off('updateFormByAccountant');
        socket.off('updateFormByOwner');
      }
      if (userType === 'applicant') {
        socket.off('askPermission');
        socket.off('reviewFormByApplicant');
        socket.off('updateFormByAccountant');
        socket.off('initializeApplication');
      }
    };
  });

  return <>{children}</>;
};

export default SocketEvents;
