import { APIStatus } from '../../../../types/unions';
import { IReportInfo } from '../APAgingSummary/state';

export interface ICurrentLiabilities {
  amount: number;
  keyName: string;
  capitalId: number;
  adjustment: number;
  adjustmentAmount: number;
}

export interface ICurrentAssets {
  amount: number;
  keyName: string;
  capitalId: number;
  adjustment: number;
  adjustmentAmount: number;
}

export interface ITotalWIC {
  amount: number;
  adjustmentAmount: number;
  adjustment: number;
}

export type TFilterOption = 'Last Fiscal Year' | 'This Fiscal Year-to-date';

export type TWICData = {
  id: number;
  applicantId: number;
  currentLiabilities: ICurrentLiabilities[];
  currentAssets: ICurrentAssets[];
  totalWIC: ITotalWIC;
  updatedInfo: string;
  filter: TFilterOption;
  editable: boolean;
  updatedAt: string;
  totalAss?: ITotalWIC;
  totalLib?: ITotalWIC;
  // only for all report from under waiting
  reportInfo?: IReportInfo;
};

export interface IAllWCData {
  status: APIStatus | null;
  error: any;
  msg?: string;
  data?: TWICData | null;
  type?:
    | 'GET_WIC_DATA_BY_APPLICATIONID_API'
    | 'UPDATE_WIC_ROW'
    | 'ADD_WIC_ROW'
    | 'REFRESH_WIC_API'
    | 'REMOVE_WIC_API';
  meta?: {
    type: 'CA' | 'CL';
  };
}

// export const allWCdataAPI = {
//   progressInfo: [],
//   applicantId: null,
//   id: null,
// };

const initialState: IAllWCData = {
  error: null,
  status: null,
};

export default initialState;
