import React from 'react';
import { Container, Box, Text, Button } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';

function QboSuccess() {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      p={2}
    >
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        // w={["96%", "96%", "auto  "]}
        p="0px 22px 0px 22px"
        borderRadius="20px"
      >
        <Box>
          <Box
            mt={5}
            fontSize={'100px'}
            display={'flex'}
            justifyContent={'center'}
            color={'green'}
          >
            <FaCheckCircle />
          </Box>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={30}
            pt="20px"
            pb="20px"
          >
            Success
          </Text>
        </Box>
      </Container>
    </Box>
  );
}

export default QboSuccess;
