import {
  Box,
  Button,
  Flex,
  Heading,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tooltip,
  Tr,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { IconCircleX } from '@tabler/icons-react';
import { FcApproval } from 'react-icons/fc';
import { useDispatch } from 'react-redux';
import QBOReqDeniedModal, {
  QBODeniedFormValues,
} from 'src/Component/Common/QBO/QBOReqDeniedModal';
import { useAppToast } from 'src/hooks';
import {
  getAccessRequest,
  permissionAction,
  resendConsentRequest,
} from 'src/Redux/AccessRequest/slice';
import { RootState, useAppSelector } from 'src/Redux/Store';
import Swal from 'sweetalert2';

const BrokerAccessRequest = () => {
  const accessRequest = useAppSelector(
    (state: RootState) => state.accessRequestSlice
  );
  const data = accessRequest && accessRequest?.requests;
  const userType = localStorage.getItem('userType');

  const toast = useAppToast();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<{ [key: number]: boolean }>({});

  const [initialValues, setInitialValues] = useState<QBODeniedFormValues>({
    id: null,
    token: null,
    userType: userType,
    permission: true,
    deniedReason: null,
  });

  useEffect(() => {
    dispatch(getAccessRequest() as any);
  }, []);

  useEffect(() => {
    const { status, error, type, user } = accessRequest;
    switch (status) {
      case 'loading':
        if (type === 'GET_ACCESS_REQUESTS') {
          setIsLoading(true);
        }
        break;
      case 'succeed':
        if (type === 'GET_ACCESS_REQUESTS') {
          setIsLoading(false);
        }
        if (type === 'RESEND_CONSENT_REQUEST') {
          setLoading((prevState) => ({
            ...prevState,
            [user?.applicationId as number]: false,
          }));
          toast({
            title: user?.msg,
            status: 'success',
          });
          dispatch(getAccessRequest() as any);
        }
        if (type === 'PERMISSION_APPROVED_OR_DENIED') {
          setLoading((prevState) => ({
            ...prevState,
            [user?.applicationId as number]: false,
          }));
          Swal.fire({
            icon: 'success',
            title: user?.msg,
            position: 'center',
            showConfirmButton: false,
            showCloseButton: true,
          });
          dispatch(getAccessRequest() as any);
        }
        break;
      case 'failed':
        if (type === 'GET_ACCESS_REQUESTS') {
          toast({
            title: error,
            status: 'error',
          });
          setIsLoading(false);
        }
        if (type === 'RESEND_CONSENT_REQUEST') {
          toast({
            title: error,
            status: 'error',
          });
          setLoading({});
        }
        if (type === 'PERMISSION_APPROVED_OR_DENIED') {
          setLoading({});
          Swal.fire({
            icon: 'error',
            title: error,
            showCloseButton: true,
            position: 'center',
            showConfirmButton: false,
          });
        }
        break;
    }
  }, [accessRequest?.status]);

  const handleResendRequest = (id: number) => {
    setLoading((prevState) => ({ ...prevState, [id]: true }));
    dispatch(resendConsentRequest({ applicationId: id }) as any);
  };

  const getSearchParams = (url: string) => {
    const urlObj = new URL(url);
    return new URLSearchParams(urlObj.search);
  };

  const handleRejectReq = (user: Record<string, any>) => {
    const params = getSearchParams(user?.consentUrl);
    const id = params.get('id');
    const parsedId = id ? parseInt(id, 10) : null;
    const token = params.get('token');
    const userType = params.get('userType');

    setInitialValues({ ...initialValues, id: parsedId, token, userType });
    setIsOpen(true);
  };
  const handleApproveReq = (user: Record<string, any>) => {
    const params = getSearchParams(user?.consentUrl);
    const id = params.get('id');
    const parsedId = id ? parseInt(id, 10) : null;
    const token = params.get('token');
    const userType = params.get('userType');
    const payload = {
      id: parsedId,
      token,
      userType,
      permission: initialValues?.permission,
      deniedReason: initialValues?.deniedReason,
    };
    setLoading((prevState) => ({ ...prevState, [user?.applicationId]: true }));
    dispatch(permissionAction(payload) as any);
  };

  return (
    <Box>
      <Heading
        p="4"
        fontSize={'24px'}
        fontWeight={'600'}
        borderBottomWidth="1px"
      >
        Access Request
      </Heading>
      {isLoading ? (
        <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          {/* <Box display={'flex'} flexDirection={'column'} gap={2} mt={2}>
            <Box pl={[2, 2, 4, 4, 4]} pr={[2, 2, 4, 4, 4]}>
              <Grid
                bg="#1B538F"
                display={'grid'}
                // justifyContent={'space-between'}
                templateColumns="repeat(12, 1fr)"
                h={'auto'}
                borderRadius={3}
                alignItems={'center'}
                p={2}
                gap={[1, 1, 2, 2, 2]}
                w={'100%'}
                color={'white'}
                fontWeight={500}
                fontSize={[10, 13, 13, 13, 13]}
              >
                <GridItem colSpan={2}>
                  <Text textAlign={'start'}>Applicant Name</Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <Text textAlign={'start'}>Broker Name</Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <Text textAlign={'start'}>QBO Permission</Text>
                </GridItem>
                <GridItem colSpan={2}>
                  <Text textAlign={'start'}>Last Updated Date</Text>
                </GridItem>
                <GridItem
                  colSpan={3}
                  display={'flex'}
                  justifyContent={'center'}
                >
                  <Text textAlign={'start'}>Actions</Text>
                </GridItem>
              </Grid>
            </Box>
            {Array.isArray(data) && data?.length > 0 ? (
              data?.map((user: any) => (
                <>
                  <Box
                    key={user?.userId}
                    pl={[2, 2, 4, 4, 4]}
                    pr={[2, 2, 4, 4, 4]}
                  >
                    <Box>
                      <Grid
                        bg="#1146844a"
                        gap={2}
                        h={'auto'}
                        borderRadius={3}
                        p={2}
                        color={'black'}
                        fontWeight={500}
                        fontSize={13}
                        templateColumns={'repeat(12, 1fr)'}
                      >
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>{user.applicantName}</Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>{user?.brokerName}</Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Text
                            textAlign={'start'}
                            textTransform={'capitalize'}
                          >
                            {user?.consentStatus === 'rejected'
                              ? 'Decliend'
                              : user?.consentStatus}
                          </Text>
                        </GridItem>
                        <GridItem colSpan={2}>
                          <Text textAlign={'start'}>
                            {user?.lastUpdatedDate}
                          </Text>
                        </GridItem>
                        <GridItem
                          colSpan={3}
                          display={'flex'}
                          justifyContent={'center'}
                        >
                          {userType === 'applicant' &&
                            (user?.consentStatus === 'pending' ? (
                              <Box display={'flex'} gap={1}>
                                <Tooltip
                                  label="Approve"
                                  placement={'top'}
                                  hasArrow
                                >
                                  <Box
                                    minW={'30px'}
                                    cursor={'pointer'}
                                    onClick={() => handleApproveReq(user)}
                                  >
                                    <FcApproval size={25} />
                                  </Box>
                                </Tooltip>
                                <Tooltip
                                  label="Decline"
                                  placement={'top'}
                                  hasArrow
                                >
                                  <Box
                                    minW={'30px'}
                                    cursor={'pointer'}
                                    onClick={() => handleRejectReq(user)}
                                  >
                                    <IconCircleX color="red" size={25} />
                                  </Box>
                                </Tooltip>
                              </Box>
                            ) : (
                              <Text
                                textAlign={'start'}
                                textTransform={'capitalize'}
                              >
                                {user?.consentStatus}
                              </Text>
                            ))}
                          {userType === 'broker' && (
                            <Button
                              type="button"
                              height={'18px'}
                              pt={4}
                              pb={4}
                              bg={'#1B538F'}
                              color={'white'}
                              _hover={{ bg: '#1B538F', color: 'white' }}
                              isDisabled={user?.consentStatus !== 'rejected'}
                              pointerEvents={'auto'}
                              cursor={
                                user?.consentStatus !== 'rejected'
                                  ? 'not-allowed'
                                  : 'pointer'
                              }
                              onClick={() =>
                                handleResendRequest(user?.applicationId)
                              }
                              // _disabled={{
                              //   opacity: 0.7,
                              // }}
                            >
                              Resend Request
                            </Button>
                          )}
                        </GridItem>
                      </Grid>
                    </Box>
                  </Box>
                </>
              ))
            ) : (
              <>
                <Box pl={4} pr={4}>
                  <Box
                    bg="#1146844a"
                    display={'flex'}
                    justifyContent={'space-between'}
                    gap={2}
                    h={10}
                    borderRadius={3}
                    alignItems={'center'}
                    p={2}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      w={'100%'}
                      color={'black'}
                      fontWeight={500}
                      fontSize={15}
                    >
                      <Box width={'100%'}>
                        <Text textAlign={'center'}>Not found</Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box> */}

          <TableContainer
            border="0px solid black"
            p={5}
            whiteSpace={'break-spaces'}
          >
            <Table size="sm">
              <Thead border="1px solid black" bg="#114684">
                <Tr>
                  <Th
                    color="white"
                    border="1px solid #0000002e"
                    fontSize={'9px'}
                  >
                    Applicant Name
                  </Th>
                  <Th
                    color="white"
                    border="1px solid #0000002e"
                    fontSize={'9px'}
                  >
                    Broker Name
                  </Th>
                  <Th
                    color="white"
                    border="1px solid #0000002e"
                    fontSize={'9px'}
                  >
                    QBO Permission
                  </Th>
                  <Th
                    color="white"
                    border="1px solid #0000002e"
                    fontSize={'9px'}
                  >
                    Last Updated Date
                  </Th>
                  <Th
                    color="white"
                    border="1px solid #0000002e"
                    fontSize={'9px'}
                  >
                    Actions
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {Array?.isArray(data) && data?.length > 0 ? (
                  data?.map((user?: any, index?: number) => (
                    <Tr key={index}>
                      <Td border="1px solid #0000002e">
                        <Text textAlign={'start'}>{user.applicantName}</Text>
                      </Td>
                      <Td border="1px solid #0000002e">
                        <Text textAlign={'start'}>{user?.brokerName}</Text>
                      </Td>
                      <Td border="1px solid #0000002e">
                        <Text textAlign={'start'} textTransform={'capitalize'}>
                          {user?.consentStatus}
                        </Text>
                      </Td>
                      <Td border="1px solid #0000002e">
                        <Text textAlign={'start'}>{user?.lastUpdatedDate}</Text>
                      </Td>
                      <Td border="1px solid #0000002e">
                        {userType === 'applicant' &&
                          (user?.consentStatus === 'pending' ? (
                            loading[user?.applicationId] && !isOpen ? (
                              <Flex
                                justifyContent={'flex-start'}
                                alignItems={'center'}
                                marginLeft={3}
                              >
                                <Spinner size="sm" />
                              </Flex>
                            ) : (
                              <Box display={'flex'} gap={1}>
                                <Tooltip
                                  label="Approve"
                                  placement={'top'}
                                  hasArrow
                                >
                                  <Box
                                    minW={'30px'}
                                    cursor={'pointer'}
                                    onClick={() => handleApproveReq(user)}
                                  >
                                    <FcApproval size={25} />
                                  </Box>
                                </Tooltip>
                                <Tooltip
                                  label="Decline"
                                  placement={'top'}
                                  hasArrow
                                >
                                  <Box
                                    minW={'30px'}
                                    cursor={'pointer'}
                                    onClick={() => handleRejectReq(user)}
                                  >
                                    <IconCircleX color="red" size={25} />
                                  </Box>
                                </Tooltip>
                              </Box>
                            )
                          ) : (
                            <Text
                              textAlign={'start'}
                              textTransform={'capitalize'}
                            >
                              {user?.consentStatus}
                            </Text>
                          ))}
                        {userType === 'broker' && (
                          <Button
                            type="button"
                            height={'18px'}
                            pt={4}
                            pb={4}
                            bg={'#1B538F'}
                            color={'white'}
                            _hover={{ bg: '#1B538F', color: 'white' }}
                            isDisabled={user?.consentStatus !== 'decliend'}
                            pointerEvents={'auto'}
                            cursor={
                              user?.consentStatus !== 'decliend'
                                ? 'not-allowed'
                                : 'pointer'
                            }
                            onClick={() =>
                              handleResendRequest(user?.applicationId)
                            }
                            isLoading={loading[user.applicationId]}
                            // _disabled={{
                            //   opacity: 0.7,
                            // }}
                          >
                            Resend Request
                          </Button>
                        )}
                      </Td>
                    </Tr>
                  ))
                ) : (
                  <Tr>
                    <Td colSpan={10} textAlign="center">
                      No data available
                    </Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </TableContainer>
          <QBOReqDeniedModal
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            initialValues={initialValues}
            loading={loading}
            setLoading={setLoading}
          />
        </>
      )}
    </Box>
  );
};

export default BrokerAccessRequest;
