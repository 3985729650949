import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASEURL } from 'src/App';
import { tokenExipry } from 'src/Redux/tokenExpiry';
import callAPI from 'src/utils/callAPI';
import initialState from './state';

export const getApplicationsList = createAsyncThunk(
  'applicantDashboardStore/getApplicationsList',
  async () => {
    try {
      try {
        const response = await callAPI(
          `${BASEURL}/application/applicant-all-app`,
          'GET'
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const applicantDashboardStore = createSlice({
  name: 'applicantDashboardStore',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get applicant dashboard applications
    builder
      .addCase(getApplicationsList.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_APPLICANT_APPLICATIONS_LIST';
      })
      .addCase(getApplicationsList.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_APPLICANT_APPLICATIONS_LIST';
        state.applications = action?.payload?.data;
      })
      .addCase(getApplicationsList.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_APPLICANT_APPLICATIONS_LIST';
        state.error = action?.payload as null;
      });
  },
});

const applicantDashboardSlice = applicantDashboardStore.reducer;

export default applicantDashboardSlice;
