export const liensFiledAgainstCompanyTableFields = [
  { name: 'General Information', key: '', parentKey: true },
  {
    name: 'Lien Details',
    key: 'lienDetails',
    parentKey: false,
  },
  {
    name: 'Date of Filing',
    key: 'dateOfFiling',
    parentKey: false,
  },
  {
    name: 'Jurisdiction',
    key: 'jurisdiction',
    parentKey: false,
  },
  {
    name: 'Case Number',
    key: 'caseNumber',
    parentKey: false,
  },
  {
    name: 'Court Filings',
    key: 'courtFilings',
    parentKey: false,
  },
  {
    name: 'Lien Status',
    key: 'lienStatus',
    parentKey: false,
  },

  { name: 'Debtor / Secured Party', key: '', parentKey: true },
  {
    name: 'Name of Lien Holder',
    key: 'nameOfLienHolder',
    parentKey: false,
  },
  {
    name: 'Name of Debtor',
    key: 'nameOfDebtor',
    parentKey: false,
  },

  {
    name: 'Lien Amount',
    key: '',
    parentKey: true,
  },
  { name: 'Filing Amount', key: 'filingAmount', parentKey: false },

  { name: 'Project Details', key: '', parentKey: true },
  {
    name: 'Project Involved',
    key: 'projectInvolved',
    parentKey: false,
  },
  {
    name: 'Scope of Work',
    key: 'scopeOfWork',
    parentKey: false,
  },
  {
    name: 'Completion Status',
    key: 'completionStatus',
    parentKey: false,
  },

  { name: 'Dispute Information', key: '', parentKey: true },
  {
    name: 'Reason for Lien',
    key: 'reasonForLien',
    parentKey: false,
  },
];

const outStandingJudgementsSuitsGenetralInfoSecFields = [
  { name: 'General Information', key: '', parentKey: true },
  {
    name: 'Type of Legal Action',
    key: 'typeOfLegalAction',
    parentKey: false,
  },
  {
    name: 'Date of Filing',
    key: 'dateOfFiling',
    parentKey: false,
  },
  {
    name: 'Jurisdiction',
    key: 'jurisdiction',
    parentKey: false,
  },
  {
    name: 'Case Number',
    key: 'caseNumber',
    parentKey: false,
  },
];

const outStandingJudgementsSuitsOtherSecFields = [
  { name: 'Details of the Legal Action', key: '', parentKey: true },
  {
    name: 'Nature of Claim',
    key: 'natureOfClaim',
    parentKey: false,
  },
  {
    name: 'Filing Amount',
    key: 'filingAmount',
    parentKey: false,
  },

  { name: 'Status of Legal Action', key: '', parentKey: true },
  {
    name: 'Current Status',
    key: 'currentStatus',
    parentKey: false,
  },
  {
    name: 'Court Filings',
    key: 'courtFilings',
    parentKey: false,
  },

  { name: 'Financial Impact', key: '', parentKey: true },
  {
    name: 'Potential Financial Exposure',
    key: 'potentialFinancialExposure',
    parentKey: false,
  },
  {
    name: 'Impact on Financial Statements',
    key: 'impactOnFinancialStatements',
    parentKey: false,
  },

  { name: 'Resolution and Settlement Efforts', key: '', parentKey: true },
  {
    name: 'Settlement Attempts',
    key: 'settlementAttempts',
    parentKey: false,
  },
  {
    name: 'Outcome or Resolution',
    key: 'outcomeOrResolution',
    parentKey: false,
  },

  // { name: 'Risk Assessment and Mitigation', key: '', parentKey: true },
  // {
  //   name: 'Mitigation Strategies',
  //   key: 'mitigationStrategies',
  //   parentKey: false,
  // },

  { name: 'Operational Impact', key: '', parentKey: true },
  {
    name: 'Impact on Operations',
    key: 'impactOnOperations',
    parentKey: false,
  },
  {
    name: 'Employee Impact',
    key: 'employeeImpact',
    parentKey: false,
  },

  { name: 'Communication', key: '', parentKey: true },
  {
    name: 'Regulatory Compliance',
    key: 'regulatoryCompliance',
    parentKey: false,
  },
];

export const outstandingJudgementsTableFields = [
  ...outStandingJudgementsSuitsGenetralInfoSecFields,
  { name: 'Debtor / Creditors Details', key: '', parentKey: true },
  {
    name: 'Name of Debtors',
    key: 'nameOfDebtors',
    parentKey: false,
  },
  {
    name: 'Name of Creditors',
    key: 'nameOfCreditors',
    parentKey: false,
  },
  ...outStandingJudgementsSuitsOtherSecFields,
];

export const outstandingSuitsTableFields = [
  ...outStandingJudgementsSuitsGenetralInfoSecFields,
  { name: 'Plaintiff / Defendant Details', key: '', parentKey: true },
  {
    name: 'Name of Plaintiff',
    key: 'nameOfPlaintiff',
    parentKey: false,
  },
  {
    name: 'Name of Defendant',
    key: 'nameOfDefendant',
    parentKey: false,
  },
  ...outStandingJudgementsSuitsOtherSecFields,
];

export const stateFederalTaxTableFields = [
  ...liensFiledAgainstCompanyTableFields,
  { name: 'Payment Plan', key: '', parentKey: true },
  {
    name: 'Is there a payment plan in place?',
    key: 'hasPaymentPlan',
    parentKey: false,
  },
  // {
  //     name: 'Date of Filing',
  //     key: 'paymentPlan',
  //     parentKey: false,
  // },
];

export const hasBankruptcyHistoryTableFields = [
  { name: 'Date of Filing', key: 'dateOfFiling' },
  { name: 'Jurisdiction / Court', key: 'jurisdictionCourt' },
  { name: 'Case Number', key: 'caseNumber' },
  { name: 'Bankruptcy Chapter', key: 'bankruptcyChapter' },
  { name: 'Total Debt Amount', key: 'totalDebtAmount' },
  { name: 'List of Major Creditors', key: 'listOfMajorCreditors' },
  // { name: 'Assets at Time of Filing', key: 'assetsAtTimeOfFiling' },
  { name: 'Outcome/Disposition', key: 'outcome' },
  { name: 'Reason for Bankruptcy', key: 'reasonForBankruptcy' },
  { name: 'Duration of Proceedings', key: 'durationOfProceedings' },
  {
    name: 'Impact on Business Operations',
    key: 'impactOnBusinessOperations',
  },
  { name: 'Date of Discharge', key: 'dateOfDischarge' },
  { name: 'Discharge Conditions', key: 'dischargeConditions' },
  { name: 'Filing Status', key: 'filingStatus' },
];
