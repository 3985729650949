import { Text } from '@chakra-ui/react';

const AccordianInputLabel = ({ label }: { label: string }) => {
  return (
    <Text fontWeight="600" color="#114684" textAlign="start" fontSize={13}>
      <li>{label}</li>
    </Text>
  );
};

export default AccordianInputLabel;
