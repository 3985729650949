import { Box } from '@chakra-ui/react';
import AccordianInput from './AccordianInput';
import AccordianInputLabel from './AccordianInputLabel';

const AccordianFieldWithInput = ({
  label,
  name,
  value,
  type,
  isHistoryMode,
  isReadOnly,
  currentHistoryData,
  formik,
}: {
  label: string;
  name: string;
  value: string;
  type: string;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
  formik: Record<string, any>;
}) => {
  return (
    <Box
      flex={{ base: '1', md: '1' }}
      display={'flex'}
      flexDirection={'column'}
      justifyContent={'space-between'}
      gap={'5px'}
    >
      <AccordianInputLabel label={label} />

      <AccordianInput
        name={name}
        value={value}
        label={label}
        isHistoryMode={isHistoryMode}
        isReadOnly={isReadOnly}
        currentHistoryData={currentHistoryData}
        formik={formik}
      />
    </Box>
  );
};

export default AccordianFieldWithInput;
