import { Accordion } from '@mantine/core';
import { useEffect, useState } from 'react';
import {
  getAccordianSectionName,
  getFormikStateOfAccordianState,
  getHistoryDataOfAccordianState,
} from '../../utils';
import AccordianContent from './AccordianContent';
import GuaranteesForOtherPartiesAccordian from './GuaranteesForOtherPartiesAccordian';

const LegalFormAccordin = ({
  formik,
  accordianState,
  accordianName,
  accordianSectionName,
  isOpen,
  accordianParentSectionName = '',
  fileList,
  setFileList,
  handleRemoveFile,
  isHistoryMode,
  isReadOnly,
  currentHistoryData,
}: {
  formik: Record<string, any>;
  accordianState: any[];
  accordianName: string;
  accordianSectionName?: string;
  isOpen?: boolean;
  accordianParentSectionName?: string;
  fileList?: Record<string, any>;
  setFileList?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  handleRemoveFile?: (
    arg1: {
      link: string;
      linkId: string;
      urlKey: string;
    },
    arg2: string,
    arg3: string
  ) => void;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
}) => {
  const [defaultOpenAcc, setDefaultOpenAcc] = useState<string[] | null>(null);
  const [accordianSection, setAccordianSection] = useState<string | null>(null);

  // const defaultOpenAccSection = useCallback(() => {
  //   const formikStateOfAcc = getFormikStateOfAccordianState[accordianName];
  //   const historyDataOfAcc = getHistoryDataOfAccordianState(
  //     currentHistoryData?.updatedData,
  //     formikStateOfAcc
  //   );
  //   return historyDataOfAcc ? Object.keys(historyDataOfAcc) : [];
  // }, [JSON.stringify(currentHistoryData?.updatedData)]);

  useEffect(() => {
    setAccordianSection(accordianSectionName ?? null);
  }, [accordianSectionName]);

  useEffect(() => {
    const formikStateOfAcc = getFormikStateOfAccordianState[accordianName];
    const historyDataOfAcc = getHistoryDataOfAccordianState(
      currentHistoryData?.updatedData,
      formikStateOfAcc
    );
    const defaultOpenAccSection = historyDataOfAcc
      ? Object.keys(historyDataOfAcc)
      : null;
    setDefaultOpenAcc(defaultOpenAccSection);

    return () => {};
  }, [JSON.stringify(currentHistoryData?.updatedData)]);

  if (
    accordianName === 'guaranteesForOtherPartiesAcc' ||
    accordianName === 'relatedCompaniesAcc'
  ) {
    console.log(
      'isHistoryMode',
      isHistoryMode,
      'accordianSection',
      accordianSection,
      'currentHistoryData',
      currentHistoryData,
      'defaultOpenAcc',
      defaultOpenAcc
    );

    return (
      <Accordion
        variant="filled"
        multiple={!!isHistoryMode}
        className="help_accordian_section"
        value={!!isHistoryMode ? defaultOpenAcc : accordianSection}
        onChange={(value) => {
          // console.log('val', value);
          if (typeof value === 'string') {
            setAccordianSection(value);
          } else {
            setDefaultOpenAcc(value);
          }
        }}
      >
        {accordianState?.map((item: any, index: number) => {
          const isUploadField = item?.subSection
            ?.map((item: any) =>
              item?.fields?.filter((self: any) => self?.type === 'file')
            )
            .flat();

          if (isOpen && isUploadField?.length > 0) {
            return (
              <Accordion.Item
                key={item?.id}
                value={item?.name}
                className={'help_item'}
              >
                <Accordion.Control
                // disabled={isOpen && item.name !== accordianSectionName}
                >
                  {getAccordianSectionName[item?.name]}
                </Accordion.Control>
                <Accordion.Panel className="accordion-content">
                  <GuaranteesForOtherPartiesAccordian
                    formik={formik}
                    AccordianSection={item?.subSection}
                    accordianSectionName={item?.name}
                    isOpen={isOpen ?? false}
                    accordianState={accordianState}
                    accordianName={accordianName}
                    fileList={fileList}
                    setFileList={setFileList}
                    handleRemoveFile={handleRemoveFile}
                    isHistoryMode={isHistoryMode}
                    isReadOnly={isReadOnly}
                    currentHistoryData={currentHistoryData}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            );
          } else if (!isOpen) {
            return (
              <Accordion.Item
                key={item?.id}
                value={item?.name}
                className={'help_item'}
              >
                <Accordion.Control
                // disabled={isOpen && item.name !== accordianSectionName}
                >
                  {getAccordianSectionName[item?.name]}
                </Accordion.Control>
                <Accordion.Panel className="accordion-content">
                  <GuaranteesForOtherPartiesAccordian
                    formik={formik}
                    AccordianSection={item?.subSection}
                    accordianSectionName={item?.name}
                    isOpen={isOpen ?? false}
                    accordianState={accordianState}
                    accordianName={accordianName}
                    isHistoryMode={isHistoryMode}
                    isReadOnly={isReadOnly}
                    currentHistoryData={currentHistoryData}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            );
          }
        })}
      </Accordion>
    );
  }
  // else if (accordianName === 'relatedCompaniesAcc') {
  //   console.log(
  //     'accordianParentSectionName',
  //     accordianParentSectionName,
  //     accordianSectionName
  //   );
  //   if (accordianState && accordianState?.length > 0) {
  //     return (
  //       <Accordion
  //         variant="filled"
  //         className="help_accordian_section"
  //         defaultValue={accordianParentSectionName}
  //       >
  //         {accordianState?.map((item1: any, index: number) => {
  //           return (
  //             <Accordion.Item
  //               key={item1?.id}
  //               value={item1?.parentAccName}
  //               className={'help_item'}
  //             >
  //               <Accordion.Control>{item1?.parentAccName}</Accordion.Control>
  //               <Accordion.Panel className="accordion-content">
  //                 <Accordion
  //                   variant="filled"
  //                   // className="help_accordian_section"
  //                   defaultValue={accordianSectionName}
  //                 >
  //                   {item1?.parentAccContent?.map(
  //                     (item: any, index: number) => {
  //                       const isUploadField = item?.fields?.filter(
  //                         (self: any) => self?.type === 'file'
  //                       );
  //                       if (isOpen && isUploadField?.length > 0) {
  //                         return (
  //                           <Accordion.Item
  //                             key={item?.id}
  //                             value={item?.name}
  //                             className={'help_item'}
  //                           >
  //                             <Accordion.Control
  //                             // disabled={isOpen && item.name !== accordianSectionName}
  //                             >
  //                               {item?.name}
  //                             </Accordion.Control>
  //                             <Accordion.Panel className="accordion-content">
  //                               <AccordianContent
  //                                 formik={formik}
  //                                 AccordianFields={item?.fields}
  //                                 accordianSectionName={item?.name}
  //                                 isOpen={isOpen ?? false}
  //                                 accordianState={accordianState}
  //                                 accordianName={accordianName}
  //                                 accordianParentSectionName={
  //                                   item1?.parentAccName
  //                                 }
  //                               />
  //                             </Accordion.Panel>
  //                           </Accordion.Item>
  //                         );
  //                       } else if (!isOpen) {
  //                         return (
  //                           <Accordion.Item
  //                             key={item?.id}
  //                             value={item?.name}
  //                             className={'help_item'}
  //                           >
  //                             <Accordion.Control
  //                             // disabled={isOpen && item.name !== accordianSectionName}
  //                             >
  //                               {item?.name}
  //                             </Accordion.Control>
  //                             <Accordion.Panel className="accordion-content">
  //                               <AccordianContent
  //                                 formik={formik}
  //                                 AccordianFields={item?.fields}
  //                                 accordianSectionName={item?.name}
  //                                 isOpen={isOpen ?? false}
  //                                 accordianState={accordianState}
  //                                 accordianName={accordianName}
  //                                 accordianParentSectionName={
  //                                   item1?.parentAccName
  //                                 }
  //                               />
  //                             </Accordion.Panel>
  //                           </Accordion.Item>
  //                         );
  //                       }
  //                     }
  //                   )}
  //                 </Accordion>
  //               </Accordion.Panel>
  //             </Accordion.Item>
  //           );
  //         })}
  //       </Accordion>
  //     );
  //   }
  // }
  else {
    return (
      <Accordion
        variant="filled"
        className={'help_accordian_section'}
        defaultValue={accordianSection}
      >
        {accordianState?.map((item: any, index: number) => {
          const isUploadField = item?.fields?.filter(
            (self: any) => self?.type === 'file'
          );
          if (isOpen && isUploadField?.length > 0) {
            return (
              <Accordion.Item
                key={item?.id}
                value={item?.name}
                className={'help_item'}
              >
                <Accordion.Control
                // disabled={isOpen && item.name !== accordianSectionName}
                >
                  {item?.name}
                </Accordion.Control>
                <Accordion.Panel className="accordion-content">
                  <AccordianContent
                    formik={formik}
                    AccordianFields={item?.fields}
                    accordianSectionName={item?.name}
                    isOpen={isOpen ?? false}
                    accordianState={accordianState}
                    accordianName={accordianName}
                    fileList={fileList}
                    setFileList={setFileList}
                    handleRemoveFile={handleRemoveFile}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            );
          } else if (!isOpen) {
            return (
              <Accordion.Item
                key={item?.id}
                value={item?.name}
                className={'help_item'}
              >
                <Accordion.Control
                // disabled={isOpen && item.name !== accordianSectionName}
                >
                  {item?.name}
                </Accordion.Control>
                <Accordion.Panel className="accordion-content">
                  <AccordianContent
                    formik={formik}
                    AccordianFields={item?.fields}
                    accordianSectionName={item?.name}
                    isOpen={isOpen ?? false}
                    accordianState={accordianState}
                    accordianName={accordianName}
                  />
                </Accordion.Panel>
              </Accordion.Item>
            );
          }
        })}
      </Accordion>
    );
  }
};

export default LegalFormAccordin;
