import { Box } from '@chakra-ui/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

const PriceWrapper = (props: Props) => {
  const { children } = props;

  return (
    <Box
      shadow="base"
      borderWidth="1px"
      borderColor={'gray.300'}
      borderRadius={'xl'}
      minW={220}
    >
      {children}
    </Box>
  );
};

export default PriceWrapper;
