import React from 'react';
import { Box, Button, Container, Heading, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

interface RoutingProps {
  token: string | null;
  userType: string | null;
}

export default function NotFoundPage({ token, userType }: RoutingProps) {
  const navigate = useNavigate();
  const userTypeLatest = localStorage.getItem('userType');
  const tokenLatest = Cookies.get('Token');
  const userResponse = userType || userTypeLatest;
  const tokenResponse = token || tokenLatest;

  const handleBackHomeClick = () => {
    if (tokenResponse) {
      if (userResponse === 'applicant') {
        navigate('/');
      } else if (userResponse === 'broker') {
        navigate('/');
      } else if (userResponse === 'brokerage') {
        navigate('/');
      }
    } else {
      navigate('/login');
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      minHeight="100vh"
    >
      <Container maxW="md">
        <Box textAlign={'center'}>
          <Heading as="h1" size="2xl">
            404
          </Heading>
          <Text fontSize="xl">The page you’re looking for doesn’t exist.</Text>
          <Button
            colorScheme="blue"
            variant="solid"
            onClick={handleBackHomeClick}
          >
            Back Home
          </Button>
        </Box>
        <Box>
          <img
            src="https://cdn.pixabay.com/photo/2017/03/09/12/31/error-2129569__340.jpg"
            alt=""
            width={500}
            height={250}
          />
        </Box>
      </Container>
    </Box>
  );
}
