import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';

interface BrokerageState {
  brokerManager: any;
  user: any;
  loading: boolean;
  error: null;
}

const initialState: BrokerageState = {
  brokerManager: [],
  user: [],
  loading: false,
  error: null,
};

export const Brokerage = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

interface RequestData {
  legalName: string;
  contactNumber: number | null;
  officeAddress: string;
  email: string;
}
interface RequestDataCLTabel {
  corporate_license: {
    province: string | null;
    license: number | null;
    // expiryData: Date | null;
  }[];
}

interface RequestDataCL {
  reprimanded_info: string | null;
  hasLicenseSuspensionFine: boolean | null | undefined;
  reprimanded: boolean | null | undefined;
}

interface RequestDataEO {
  insurer: string | null;
  policy: string | null;
  limit: string | null;
  expiryDate: Date | null;
  claims_EO: boolean;
  claims_EO_info: string | null;
}

// brokerage profile update
export const BrokerageUpdate = createAsyncThunk(
  'brokerage/BrokerageUpdate',
  async (requestData: RequestData, { rejectWithValue }) => {
    try {
      // const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/brokerage/update/profile`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// brokerage eo update
export const RequestDataEO = createAsyncThunk(
  'brokerage/BrokerageUpdate',
  async (requestData: RequestDataEO, { rejectWithValue }) => {
    try {
      const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/brokerage/update/profile`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// brokerage cl update
export const BrokerageCl = createAsyncThunk(
  'brokerage/BrokerageUpdate',
  async (requestData: RequestDataCL, { rejectWithValue }) => {
    try {
      // const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/brokerage/update/profile`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

// brokerage cl update
export const BrokerageClupdate = createAsyncThunk(
  'brokerage/BrokerageUpdate',
  async (requestData: RequestDataCLTabel, { rejectWithValue }) => {
    try {
      // const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/brokerage/update/profile`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// update get id

export const fetchBrokerageUserData = createAsyncThunk(
  'qbo/fetchBrokerageUserData',
  async () => {
    try {
      // const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `${BASEURL}/user/brokerage/get/profile`,
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        };
        try {
          const response = await axios.request(config);
          if (!!response?.headers['token']) {
            localStorage.setItem('Token', response?.headers['token']);
          }
          return response.data;
        } catch (error: any) {
          if (error?.response?.status === 401) {
            const result = tokenExipry();
            return result;
          }
          return error?.response?.data;
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
      }
    } catch (error) {
      throw error;
    }
  }
);

//broker alldata
export const BrokerAllData = createAsyncThunk(
  'brokerage/BrokerAllData',
  async () => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/user/broker/getall`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

const brokerageSlice = createSlice({
  name: 'brokerage',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // brokerage update
      .addCase(BrokerageUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        BrokerageUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(BrokerageUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // Brokerage get api

      .addCase(fetchBrokerageUserData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchBrokerageUserData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchBrokerageUserData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //broker all data
      .addCase(BrokerAllData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(BrokerAllData.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.brokerManager = action.payload;
      })
      .addCase(BrokerAllData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      });
  },
});

export default brokerageSlice.reducer;
