import React, { useEffect, useState } from 'react';
import {
  Container,
  Box,
  Text,
  Select,
  Flex,
  Button,
  CircularProgress,
} from '@chakra-ui/react';
import { QboAlldata } from '../../../Redux/Applicant/Qbo/Qbo';
import { useToast } from '@chakra-ui/react';
import { RootState } from '../../../Redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function QuickbookAlldata() {
  const dispatch = useDispatch();
  const Toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const HandelLogin = async () => {
    setIsLoading(true);
    let res = '';
    try {
      const actionResult = await dispatch(QboAlldata() as any);
      const response = actionResult?.payload;
      res = response?.msg;
      if (actionResult?.payload?.status === 401) {
        navigate('/login');
      } else {
        Toast({
          title: response.msg,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      }
    } catch (error: any) {
      if (res) {
        Toast({
          title: res,
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      } else {
        Toast({
          title: '404',
          status: 'error',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        marginBottom="auto"
        p="22px"
        borderRadius="20px"
      >
        <Box>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={30}
            pt="20px"
          >
            Quick Book Alldata
          </Text>
        </Box>

        <Box mt="20px" mb={10}>
          <Button
            type="submit"
            bg="#114684"
            color="white"
            w="100%"
            _hover={{ bg: '#114684', color: 'white' }}
            onClick={HandelLogin}
            disabled={isLoading}
          >
            {isLoading ? 'Loading...' : 'Quick Book Alldata'}
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default QuickbookAlldata;
