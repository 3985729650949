import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { BASEURL } from 'src/App';
import { tokenExipry } from 'src/Redux/tokenExpiry';
import callAPI from 'src/utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Documents/slice';
import initialState from './state';

export const getAccessRequest = createAsyncThunk(
  'accessRequest/getAccessRequest',
  async () => {
    try {
      try {
        const response = await callAPI(
          `${BASEURL}/application/check-access-request`,
          'GET'
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const resendConsentRequest = createAsyncThunk(
  'accessRequest/resendConsentRequest',
  async (payload: { applicationId: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI(
        `permission/resend-consent-email`,
        'POST',
        payload
      );

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const permissionAction = createAsyncThunk(
  'accessRequest/permission',
  async (
    payload: {
      id: number | null;
      token: string | null;
      userType: string | null;
      permission: boolean | null;
      deniedReason: string | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const res = await callAPI(`permission/assign`, 'POST', payload);

      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const accessRequest = createSlice({
  name: 'accessRequest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get access requests
    builder
      .addCase(getAccessRequest.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_ACCESS_REQUESTS';
      })
      .addCase(getAccessRequest.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_ACCESS_REQUESTS';
        state.requests = action?.payload?.data;
      })
      .addCase(getAccessRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_ACCESS_REQUESTS';
        state.error = action?.payload as null;
      })
      .addCase(resendConsentRequest.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'RESEND_CONSENT_REQUEST';
      })
      .addCase(resendConsentRequest.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'RESEND_CONSENT_REQUEST';
        state.user = action?.payload?.data;
      })
      .addCase(resendConsentRequest.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'RESEND_CONSENT_REQUEST';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(permissionAction.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'PERMISSION_APPROVED_OR_DENIED';
      })
      .addCase(permissionAction.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'PERMISSION_APPROVED_OR_DENIED';
        state.user = action?.payload;
      })
      .addCase(permissionAction.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'PERMISSION_APPROVED_OR_DENIED';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const accessRequestSlice = accessRequest.reducer;

export default accessRequestSlice;
