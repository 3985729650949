import { APIStatus } from 'src/types/unions';

export interface ApplicantDashboard {
  error: any;
  status: APIStatus | null;
  type?: 'GET_APPLICANT_APPLICATIONS_LIST';
  applications: IApplication[];
}

const initialState: ApplicantDashboard = {
  error: null,
  status: null,
  applications: [],
};

export default initialState;
