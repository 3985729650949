import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  ModalContent,
  ModalHeader,
  ModalBody,
  Tooltip,
  Container,
  Input,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AccountantAllData } from '../../../Redux/Applicant/Accountant/Accountant';
import { AccountantDelete } from '../../../Redux/Applicant/Accountant/Accountant';
import { RootState } from '../../../Redux/Store';
import { FaUserEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { useToast } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';
import { AddAccountant } from '../../../Redux/Applicant/Accountant/Accountant';
import {} from '../../../Redux/Applicant/Accountant/Accountant';
import { Updateaccountant } from '../../../Redux/Applicant/Accountant/Accountant';
import { AccountantSchema } from '../../../ValidationSchema/Pages/Index';
import useObjectComparator from '../../../hooks/useObjectComparator';
import { useNavigate } from 'react-router-dom';

function AccountantTabel() {
  const Toast = useToast();
  const { areDifferent, setInitialValues, setNewValues } =
    useObjectComparator();
  const [isAccountUpdate, setIsAccountUpdate] = useState(false);
  const [isInitializePage, setIsInitializePage] = useState(false);
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const navigate = useNavigate();
  const userData: any = useSelector(
    (state: RootState) => state.accountant.user
  );

  let userGetidData = userData && userData.data;
  const [selectedUser, setSelectedUser] = useState<FormValues | null>(null);

  const handleEdit = (accountantId: any) => {
    const user = userGetidData?.find(
      (user: any) => user.accountantId === accountantId
    );
    setSelectedUser(user);
    setId(accountantId);

    setIsOpen(true);
  };

  useEffect(() => {
    fetchAccountAllData();
  }, []);

  const fetchAccountAllData = async () => {
    if (!!localStorage.getItem('Token')) {
      setIsInitializePage(true);
      let actionResult = await dispatch(AccountantAllData() as any);
      if (actionResult?.payload?.status === 401) {
        navigate('/login');
      }
      setIsInitializePage(false);
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    if (selectedUser) {
      formik.setValues({
        name: selectedUser.name || '',
        email: selectedUser.email || '',
        nameOfAccountingFirm: selectedUser.nameOfAccountingFirm || '',
        phoneNumber: selectedUser.phoneNumber || null,
      });
      setInitialValues({
        name: selectedUser.name || '',
        email: selectedUser.email || '',
        nameOfAccountingFirm: selectedUser.nameOfAccountingFirm || '',
        phoneNumber: selectedUser.phoneNumber || null,
      });
    } else {
      formik.setValues({
        name: '',
        email: '',
        phoneNumber: null,
        nameOfAccountingFirm: '',
      });
    }
  }, [selectedUser]);

  interface FormValues {
    name: string;
    email: string;
    phoneNumber: number | null;
    nameOfAccountingFirm: string;
  }

  const initialValues: FormValues = {
    name: '',
    email: '',
    phoneNumber: null,
    nameOfAccountingFirm: '',
  };

  const formik = useFormik({
    initialValues,

    validationSchema: AccountantSchema,

    onSubmit: async (values: FormValues, { resetForm }) => {
      setIsAccountUpdate(true);
      let res = '';
      try {
        if (id) {
          if (!areDifferent) {
            setIsAccountUpdate(false);
            return;
          }

          const actionResult = await dispatch(
            Updateaccountant({ accountId: id, ...values }) as any
          );
          const response = actionResult?.payload;
          res = response?.msg;
          const resstatus = response?.status;

          if (resstatus === 200) {
            Toast({
              title: response?.data,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            setIsOpen(false);
            fetchAccountAllData();
            resetForm();
          } else {
            if (resstatus === 401) {
              navigate('/login');
            } else {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            }
          }
        } else {
          const actionResult = await dispatch(AddAccountant(values) as any);
          const response = actionResult?.payload;
          res = response?.msg;
          const resstatus = response?.status;

          if (resstatus === 200) {
            Toast({
              title: response?.data,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            setIsOpen(false);
            fetchAccountAllData();
            resetForm();
          } else {
            if (resstatus === 401) {
              navigate('/login');
            } else {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            }
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      setIsAccountUpdate(false);
    },
  });
  const handleOpenPopup = () => {
    setIsOpen(true);
    setId(null);
  };

  const handleClosePopup = () => {
    setIsOpen(false);
    setSelectedUser(null);
    setId(null);
    formik.resetForm();
  };
  useEffect(() => {
    setNewValues(formik.values);
  }, [formik.values]);

  const handleDelete = (accountantId: any) => {
    Swal.fire({
      title: 'Do you want to Delete this Record?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#DC3741',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(AccountantDelete(accountantId) as any)
          .then((res: any) => {
            if (res.meta.requestStatus === 'fulfilled') {
              fetchAccountAllData();
              Swal.fire({
                title: 'Deleted Successfully.',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((err: any) => {});
      }
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleClosePopup}
        closeOnOverlayClick={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color={'#114684'}>
            {' '}
            {id ? 'Edit Accountant' : ' Add Accountant'}{' '}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Container textAlign="center" h="auto" p={0} borderRadius="20px">
              <Box>
                <Container
                  textAlign="center"
                  h="auto"
                  margin="auto"
                  display={'flex'}
                  flexDir={'column'}
                  p={0}
                  gap={'0.1rem'}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Box display={'flex'} flexDirection={'column'} gap={2}>
                      <Box display="flex" flexDirection={'column'}>
                        <Tooltip label="Name" placement="right" hasArrow>
                          <Input
                            fontSize={14}
                            w="100%"
                            type="text"
                            placeholder="Name"
                            name="name"
                            borderRadius="5px"
                            onChange={formik.handleChange}
                            value={formik.values.name}
                            onBlur={formik.handleBlur}
                            focusBorderColor="transparent"
                            bg={'#f0f5f9'}
                            border="none"
                          />
                        </Tooltip>
                        {formik.touched.name && formik.errors.name && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors.name}
                          </div>
                        )}
                      </Box>

                      <Box display="flex" flexDirection={'column'}>
                        <Tooltip
                          label="Email Address"
                          placement="right"
                          hasArrow
                        >
                          <Input
                            fontSize={14}
                            w="100%"
                            type="Email"
                            placeholder="Email Address"
                            name="email"
                            borderRadius="5px"
                            onChange={formik.handleChange}
                            value={formik.values.email}
                            onBlur={formik.handleBlur}
                            focusBorderColor="transparent"
                            bg={'#f0f5f9'}
                            border="none"
                          />
                        </Tooltip>
                        {formik.touched.email && formik.errors.email && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors.email}
                          </div>
                        )}
                      </Box>
                      <Box display="flex" flexDirection={'column'}>
                        <Tooltip
                          label="Name of accounting firm"
                          placement="right"
                          hasArrow
                        >
                          <Input
                            fontSize={14}
                            w="100%"
                            type="text"
                            placeholder="Name of accounting firm"
                            name="nameOfAccountingFirm"
                            borderRadius="5px"
                            onChange={formik.handleChange}
                            value={formik.values.nameOfAccountingFirm}
                            onBlur={formik.handleBlur}
                            focusBorderColor="transparent"
                            bg={'#f0f5f9'}
                            border="none"
                          />
                        </Tooltip>
                        {formik.touched.nameOfAccountingFirm &&
                          formik.errors.nameOfAccountingFirm && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {formik.errors.nameOfAccountingFirm}
                            </div>
                          )}
                      </Box>
                      <Box display="flex" flexDirection={'column'}>
                        <Tooltip
                          label="Phone number"
                          placement="right"
                          hasArrow
                        >
                          <Input
                            fontSize={14}
                            w="100%"
                            type="number"
                            placeholder="Phone number"
                            name="phoneNumber"
                            borderRadius="5px"
                            onChange={formik.handleChange}
                            value={formik.values.phoneNumber ?? ''}
                            onBlur={formik.handleBlur}
                            focusBorderColor="transparent"
                            bg={'#f0f5f9'}
                            border="none"
                          />
                        </Tooltip>
                        {formik.touched.phoneNumber &&
                          formik.errors.phoneNumber && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {formik.errors.phoneNumber}
                            </div>
                          )}
                      </Box>
                    </Box>

                    <Box mt={8} display={'flex'} gap={2}>
                      <Button
                        bg="#114684"
                        color="white"
                        w="100%"
                        _hover={{ bg: '#114684' }}
                        onClick={handleClosePopup}
                      >
                        Close
                      </Button>
                      <Button
                        isLoading={isAccountUpdate}
                        loadingText={id ? 'Update' : 'Submit'}
                        type="submit"
                        bg="#114684"
                        color="white"
                        w="100%"
                        _hover={{
                          bg: '#114684',
                          color: 'white',
                          cursor: areDifferent ? 'pointer' : 'not-allowed',
                        }}
                        isActive={!areDifferent}
                      >
                        {id ? 'Update' : 'Submit'}
                      </Button>
                    </Box>
                  </form>
                </Container>
              </Box>
            </Container>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Box display={'flex'} justifyContent={'end'} p={5}>
        <Button
          bg="#114684"
          color={'white'}
          _hover={{ bg: '#114684' }}
          onClick={handleOpenPopup}
        >
          Add Accountant
        </Button>
      </Box>

      <TableContainer
        border="0px solid black"
        p={5}
        whiteSpace={'break-spaces'}
      >
        <Table size="sm">
          <Thead border="1px solid black" bg="#114684">
            <Tr>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Name
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Email
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Name of accounting firm
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Phone number
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Edit
              </Th>{' '}
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Delete
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array?.isArray(userGetidData) && userGetidData?.length > 0
              ? userGetidData?.map((user?: any, index?: number) => (
                  <Tr key={index}>
                    <Td border="1px solid #0000002e">{user?.name} </Td>
                    <Td border="1px solid #0000002e">{user?.email}</Td>
                    <Td border="1px solid #0000002e">
                      {user?.nameOfAccountingFirm}
                    </Td>
                    <Td border="1px solid #0000002e">{user?.phoneNumber}</Td>

                    <Tooltip label="Edit" placement="right" hasArrow>
                      <Td
                        border="1px solid #0000002e"
                        cursor={'pointer'}
                        fontSize={'larger'}
                        onClick={() => handleEdit(user?.accountantId)}
                      >
                        <FaUserEdit />
                      </Td>
                    </Tooltip>
                    <Tooltip label="Delete" placement="right" hasArrow>
                      <Td
                        border="1px solid #0000002e"
                        cursor={'pointer'}
                        fontSize={'larger'}
                        onClick={() => handleDelete(user?.accountantId)}
                      >
                        <MdDelete />
                      </Td>
                    </Tooltip>
                  </Tr>
                ))
              : !isInitializePage && (
                  <Tr>
                    <Td colSpan={10} textAlign="center">
                      No data available
                    </Td>
                  </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AccountantTabel;
