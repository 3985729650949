import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callAPI from 'src/utils/callAPI';
import initialState, {
  TFilterAccountPayable,
  updateARRecordType,
} from './state';
import { REPORT_SHORTFORM } from 'src/types/unions';

export function isRejectedActionWithPayload(
  action: any
): action is { payload: { errorMessage: string } } {
  return action.payload && typeof action.payload.msg === 'string'
    ? action.payload.msg
    : '';
}

export const getAccountPayableData = createAsyncThunk(
  'accountPayableSlice/getAccountPayableData',
  async (
    {
      applicantId,
      filter,
    }: {
      applicantId: number;
      filter: TFilterAccountPayable;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `quickbook/report/account-payable-aging?applicantId=${applicantId}&filter=${filter}`,
        'GET',
        {},
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);
export const refreshAPReport = createAsyncThunk(
  'accountPayableSlice/refreshAPReport',
  async (
    payload: { applicantId: string; filter: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `quickbook/report/apa/refresh`,
        'POST',
        payload,
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const getAccountReceivableData = createAsyncThunk(
  'accountPayableSlice/getAccountReceivableData',
  async (
    {
      applicantId,
      filter,
    }: {
      applicantId: number;
      filter: TFilterAccountPayable;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `quickbook/report/account-receivable-aging?applicantId=${applicantId}&filter=${filter}`,
        'GET',
        {},
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const updateARRecord = createAsyncThunk(
  'accountPayableSlice/updateARRecord',
  async ({ payload }: { payload: updateARRecordType }, { rejectWithValue }) => {
    try {
      const response = await callAPI(
        `quickbook/report/ara/update`,
        'PUT',
        payload,
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);
export const refreshARReport = createAsyncThunk(
  'accountPayableSlice/refreshARReport',
  async (
    payload: { applicantId: string; filter: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `quickbook/report/ara/refresh`,
        'POST',
        payload,
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const getProfitLossData = createAsyncThunk(
  'accountPayableSlice/getProfitLossData',
  async (
    {
      applicantId,
      filter,
    }: {
      applicantId: number;
      filter: TFilterAccountPayable;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `quickbook/report/profit-loss?applicantId=${applicantId}&filter=${filter}`,
        'GET',
        {},
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const getBalanceSheetData = createAsyncThunk(
  'accountPayableSlice/getBalanceSheetData',
  async (
    {
      applicantId,
      filter,
    }: {
      applicantId: number;
      filter: TFilterAccountPayable;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `quickbook/report/balance-sheet?applicantId=${applicantId}&filter=${filter}`,
        'GET',
        {},
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const refreshBSPLReport = createAsyncThunk(
  'accountPayableSlice/refreshBSPLReport',
  async (
    payload: {
      applicantId: string;
      filter: string;
      reportName: 'Balance Sheet' | 'Profit and Loss';
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `quickbook/report/bspl/refresh`,
        'POST',
        payload,
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const getAllReports = createAsyncThunk(
  'accountPayableSlice/getAllReports',
  async (
    payload: {
      applicantId: number;
      applicationId: number;
      reportNames: REPORT_SHORTFORM[];
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        `quickbook/report/for-pdf`,
        'POST',
        payload,
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const getReportsStatus = createAsyncThunk(
  'accountPayableSlice/getReportsStatus',
  async (payload: { applicantId: number }, { rejectWithValue }) => {
    try {
      const res = await callAPI('quickbook/report/check', 'POST', payload);
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

const AccountPayableSlice = createSlice({
  name: 'AccountPayableSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAccountPayableData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'GET_ACCOUNT_PAYABLE_DATA';
      })
      .addCase(getAccountPayableData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_ACCOUNT_PAYABLE_DATA';
        state.data = action.payload.data;
      })
      .addCase(getAccountPayableData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_ACCOUNT_PAYABLE_DATA';
        state.error = isRejectedActionWithPayload(action);
        state.data = initialState.data;
      })

      //Refresh Account Payable Report
      .addCase(refreshAPReport.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'REFRESH_AP_REPORT';
      })
      .addCase(refreshAPReport.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'REFRESH_AP_REPORT';
        // state.data = action.payload.data;
      })
      .addCase(refreshAPReport.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'REFRESH_AP_REPORT';
        state.error = isRejectedActionWithPayload(action);
        state.data = initialState.data;
      })

      //Get Account Receivable data
      .addCase(getAccountReceivableData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'GET_ACCOUNT_RECEIVABLE_DATA';
      })
      .addCase(getAccountReceivableData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_ACCOUNT_RECEIVABLE_DATA';
        state.data = action.payload.data;
      })
      .addCase(getAccountReceivableData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_ACCOUNT_RECEIVABLE_DATA';
        state.error = isRejectedActionWithPayload(action);
        state.data = initialState.data;
      })

      //Get Profit Loss Data
      .addCase(getProfitLossData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'GET_PROFIT_LOSS_DATA';
      })
      .addCase(getProfitLossData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_PROFIT_LOSS_DATA';
        state.data = action.payload.data;
      })
      .addCase(getProfitLossData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_PROFIT_LOSS_DATA';
        state.error = isRejectedActionWithPayload(action);
        state.data = initialState.data;
      })

      //Get Balance Sheet Data
      .addCase(getBalanceSheetData.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'GET_BALANCE_SHEET_DATA';
      })
      .addCase(getBalanceSheetData.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_BALANCE_SHEET_DATA';
        state.data = action.payload.data;
      })
      .addCase(getBalanceSheetData.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_BALANCE_SHEET_DATA';
        state.error = isRejectedActionWithPayload(action);
        state.data = initialState.data;
      })

      //Update Account Receivable Record
      .addCase(updateARRecord.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'UPDATE_ACCOUNT_RECEIVABLE_RECORD';
      })
      .addCase(updateARRecord.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPDATE_ACCOUNT_RECEIVABLE_RECORD';
        state.data = action.payload.data;
      })
      .addCase(updateARRecord.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPDATE_ACCOUNT_RECEIVABLE_RECORD';
        state.error = isRejectedActionWithPayload(action);
        state.data = initialState.data;
      })

      //Refresh Account Receivable Report
      .addCase(refreshARReport.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'REFRESH_AR_REPORT';
      })
      .addCase(refreshARReport.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'REFRESH_AR_REPORT';
        // state.data = action.payload.data;
      })
      .addCase(refreshARReport.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'REFRESH_AR_REPORT';
        state.error = isRejectedActionWithPayload(action);
        state.data = initialState.data;
      })

      //Refresh BS/PL Report
      .addCase(refreshBSPLReport.pending, (state, action) => {
        state.status = 'loading';
        state.type =
          action?.meta?.arg?.reportName === 'Balance Sheet'
            ? 'REFRESH_BS_REPORT'
            : 'REFRESH_PL_REPORT';
      })
      .addCase(refreshBSPLReport.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type =
          action?.meta?.arg?.reportName === 'Balance Sheet'
            ? 'REFRESH_BS_REPORT'
            : 'REFRESH_PL_REPORT';
        // state.data = action.payload.data;
      })
      .addCase(refreshBSPLReport.rejected, (state, action) => {
        state.status = 'failed';
        state.type =
          action?.meta?.arg?.reportName === 'Balance Sheet'
            ? 'REFRESH_BS_REPORT'
            : 'REFRESH_PL_REPORT';
        state.error = isRejectedActionWithPayload(action);
        state.data = initialState.data;
      })
      //Refresh BS/PL Report
      .addCase(getAllReports.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'GET_ALL_REPORTS';
      })
      .addCase(getAllReports.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_ALL_REPORTS';
        state.allReportsData = action.payload.data;
      })
      .addCase(getAllReports.rejected, (state, action) => {
        state.type = 'GET_ALL_REPORTS';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      })
      // Report status
      .addCase(getReportsStatus.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'GET_REPORTS_STATUS';
      })
      .addCase(getReportsStatus.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_REPORTS_STATUS';
        state.reportsStatus = action.payload.data;
      })
      .addCase(getReportsStatus.rejected, (state, action) => {
        state.type = 'GET_REPORTS_STATUS';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const brokerAccountPayableSlice = AccountPayableSlice.reducer;

export default brokerAccountPayableSlice;
