import {
  Document,
  G,
  Image,
  Link,
  Page,
  Path,
  StyleSheet,
  Svg,
  Text,
  View,
} from '@react-pdf/renderer';
import moment from 'moment';
import { broketDetails } from '../../Pages/Reports/components/PdfModal';

type stepperFormLabel = {
  generalBanking: string;
  legal: string;
  personalFinancial: string;
  about: string;
  surety: string;
  insurance: string;
};

export type allFormData = {
  accountingSystem: any;
  creditBureau: any;
  financialInfo: any;
  cms: any;
  surety: any;
  insurance: any;
};

interface Props {
  title?: string;
  heading?: string;
  summary?: string;
  logo?: string;
  companyName?: string;
  contactInfo?: boolean | null;
  brokerInfo?: broketDetails;
  stepperFormLabel?: stepperFormLabel;
  allFormData?: any;
  qboCompanyName: string;
}

const ApplicationPDF = (props: Props) => {
  const {
    heading,
    logo,
    summary,
    title,
    companyName,
    contactInfo,
    brokerInfo,
    stepperFormLabel,
    allFormData,
    qboCompanyName,
  } = props || {};
  const {
    accountingSystem,
    creditBureau,
    insurance,
    surety,
    financialInfo,
    cms,
  } = allFormData;

  const isDisplayCompany = !title && !heading && !summary;

  const reciept_data = {
    id: '642be0b4bbe5d71a5341dfb1',
    invoice_no: '20200669',
    address: '739 Porter Avenue, Cade, Missouri, 1134',
    date: '24-09-2019',
    items: [
      {
        id: 1,
        desc: 'do ex anim quis velit excepteur non',
        qty: 8,
        price: 179.25,
      },
      {
        id: 2,
        desc: 'incididunt cillum fugiat aliqua Lorem sit Lorem',
        qty: 9,
        price: 107.78,
      },
      {
        id: 3,
        desc: 'quis Lorem ad laboris proident aliqua laborum',
        qty: 4,
        price: 181.62,
      },
      {
        id: 4,
        desc: 'exercitation non do eu ea ullamco cillum',
        qty: 4,
        price: 604.55,
      },
      {
        id: 5,
        desc: 'ea nisi non excepteur irure Lorem voluptate',
        qty: 6,
        price: 687.08,
      },
    ],
  };

  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 60,
      paddingBottom: 50,
      paddingLeft: 40,
      paddingRight: 40,
      // lineHeight: 1.5,
      flexDirection: 'column',
    },

    spaceBetween: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      color: '#3E3E3E',
    },

    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'row',
      // marginTop: 24,
    },

    logo: { width: 90 },

    reportTitle: { fontSize: 20, textAlign: 'center', fontWeight: 'bold' },

    addressTitle: { fontSize: 11, fontStyle: 'bold' },

    invoice: { fontWeight: 'bold', fontSize: 18, marginLeft: 10 },

    invoiceNumber: { fontSize: 11, fontWeight: 'bold', marginTop: 10 },

    address: { fontWeight: 400, fontSize: 10 },

    theader: {
      marginTop: 20,
      fontSize: 10,
      fontStyle: 'bold',
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      height: 20,
      backgroundColor: '#DEDEDE',
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    theader2: { flex: 2, borderRightWidth: 0, borderBottomWidth: 1 },

    tbody: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1,
      borderColor: 'whitesmoke',
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    total: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      flex: 1.5,
      borderColor: 'whitesmoke',
      borderBottomWidth: 1,
    },

    tbody2: { flex: 2, borderRightWidth: 1 },

    pageNumber: {
      position: 'absolute',
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: 'center',
      color: 'grey',
    },

    pageHeader: {
      backgroundColor: 'lightgray',
      color: '#121212',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      padding: '0 40px',
      display: 'flex',
      alignItems: 'flex-end',
    },
    headerLogo: {
      maxWidth: '100px',
    },

    companyLogo: {
      width: '100px',
      height: '100px',
    },

    companyName: { margin: 'auto 0' },

    logoTitle: {
      display: 'flex',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexDirection: 'row',
    },

    brokerName: {
      fontSize: 12,
      fontWeight: 600,
    },
    brokerContainer: {
      position: 'absolute',
      left: 40,
      right: 0,
      bottom: 60,
    },

    subTitle: { fontWeight: 'bold', fontSize: 18, marginTop: 10 },

    brokerData: {
      display: 'flex',
      // justifyContent: 'flex-start',
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
    companyDetails: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginTop: '10px',
    },

    brokerEmail: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    formHeading: {
      fontSize: 20,
      textAlign: 'center',
      fontWeight: 'bold',
      // marginTop: 34,
      marginBottom: 5,
    },
    AccountingFormLabel: {
      fontSize: 11,
      fontWeight: 'bold',
    },

    flexRowWrap: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
    flexRowNoWrap: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
    },

    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },

    dot: {
      height: '4px',
      width: '4px',
      backgroundColor: '#114684',
      borderRadius: '50%',
      marginTop: '3px',
      marginRight: '7px',
    },

    tableContainer: {
      width: '100%',
      borderStyle: 'solid',
      borderWidth: 1,
      borderColor: 'grey',
      marginBottom: 10,
    },
    tableRow: {
      flexDirection: 'row',
    },
    tableCell: {
      flex: 1,
      borderRightWidth: 1,
      borderRightColor: 'grey',
      borderRightStyle: 'solid',
      borderBottomWidth: 1,
      borderBottomColor: 'grey',
      borderBottomStyle: 'solid',
      padding: 5,
      fontSize: 11,
      textAlign: 'center',
    },
    headerCell: {
      backgroundColor: '#E4E4E4',
      fontWeight: 'bold',
    },
    lastCell: {
      borderRightWidth: 0,
    },
  });

  const InvoiceItemsTable = ({ invoice }: { invoice: any[] }) => {
    return (
      <View style={styles.tableContainer}>
        <InvoiceTableHeader />
        <InvoiceTableRow items={invoice} />
      </View>
    );
  };

  const InvoiceTableHeader = () => (
    <View style={styles.tableRow}>
      <Text style={[styles.tableCell, styles.headerCell]}>
        Type of construction
      </Text>
      <Text style={[styles.tableCell, styles.headerCell]}>
        Largest fixed price contract in the last 3 years for this type of
        construction
      </Text>
      <Text style={[styles.tableCell, styles.headerCell]}>
        Location of largest fixed price contract in the last 3 years for this
        type of construction
      </Text>
      <Text style={[styles.tableCell, styles.headerCell, styles.lastCell]}>
        % of annual sales related to this type of construction
      </Text>
    </View>
  );

  const InvoiceTableRow = ({ items }: { items: any[] }) => {
    return (
      <>
        {items.map((item: any, i: number) => (
          <View style={styles.tableRow} key={i}>
            <Text style={styles.tableCell}>{item.contractorType}</Text>
            <Text style={styles.tableCell}>
              {item?.contractPrice
                ? `$${item?.contractPrice?.toLocaleString('en-US', {
                    maximumFractionDigits: 2,
                  })}`
                : ''}
            </Text>

            <Text style={styles.tableCell}>{item.location}</Text>
            <Text style={[styles.tableCell, styles.lastCell]}>
              {item?.annualSales?.toLocaleString('en-US', {
                maximumFractionDigits: 2,
              })}
              %
            </Text>
          </View>
        ))}
      </>
    );
  };

  const PDFHeading = () => (
    <View style={{ ...styles.titleContainer }}>
      <View style={{ textAlign: 'center' }}>
        <Text style={styles.reportTitle}>{heading}</Text>
      </View>
    </View>
  );

  const Address = () => (
    <View>
      <Text style={styles.invoiceNumber}>{summary}</Text>
    </View>
  );

  const UserAddress = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View style={{ maxWidth: 200 }}>
          <Text style={styles.addressTitle}>Bill to </Text>
          <Text style={styles.address}>{reciept_data.address}</Text>
        </View>
        <Text style={styles.addressTitle}>{reciept_data.date}</Text>
      </View>
    </View>
  );

  const CompanyName = () => (
    <View style={styles.companyName}>
      <View style={{ textAlign: 'center' }}>
        <Text style={styles.reportTitle}>{companyName}</Text>
      </View>
    </View>
  );

  const PDFHeader = () => (
    <View style={styles.pageHeader} fixed>
      <Svg
        style={styles.headerLogo}
        // xmlns="http://www.w3.org/2000/svg"
        width="264.976"
        height="42.708"
        viewBox="0 0 264.976 42.708"
      >
        <G transform="translate(-433.734 -1043.091)">
          <G data-name="Group 16891">
            <G data-name="Group 16890">
              <Path
                data-name="Path 4196"
                d="M521.288,1073.012l.873-1.715a10.349,10.349,0,0,0,6.954,2.559c3.945,0,5.661-1.656,5.661-3.734,0-5.78-12.976-2.227-12.976-10.025,0-3.1,2.408-5.78,7.768-5.78a11.905,11.905,0,0,1,6.532,1.866l-.752,1.777a10.853,10.853,0,0,0-5.78-1.746c-3.854,0-5.571,1.715-5.571,3.823,0,5.78,12.976,2.288,12.976,9.965,0,3.1-2.469,5.75-7.858,5.75C525.954,1075.752,522.854,1074.638,521.288,1073.012Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4197"
                d="M544.647,1066.57V1054.5h2.228v11.982c0,5,2.348,7.285,6.443,7.285,4.124,0,6.472-2.288,6.472-7.285V1054.5h2.168v12.072c0,6.051-3.252,9.182-8.64,9.182S544.647,1072.621,544.647,1066.57Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4198"
                d="M589.29,1069.942c0,3.582-2.649,5.63-7.918,5.63h-9.694V1054.5h9.093c4.7,0,7.405,1.987,7.405,5.419a4.891,4.891,0,0,1-3.221,4.786A5.032,5.032,0,0,1,589.29,1069.942Zm-15.384-13.608v7.647h6.713c3.372,0,5.329-1.295,5.329-3.824s-1.957-3.823-5.329-3.823Zm13.157,13.427c0-2.739-2.018-3.944-5.721-3.944h-7.436v7.918h7.436C585.045,1073.735,587.063,1072.531,587.063,1069.761Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4199"
                d="M601.179,1056.425h-7.406V1054.5h17.04v1.927h-7.407v19.147h-2.227Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4200"
                d="M631.763,1075.572l-4.817-6.774a15.044,15.044,0,0,1-1.686.09H619.6v6.684h-2.229V1054.5h7.888c5.359,0,8.61,2.709,8.61,7.225a6.532,6.532,0,0,1-4.816,6.623l5.147,7.226Zm-.12-13.849c0-3.372-2.228-5.3-6.443-5.3h-5.6v10.566h5.6C629.415,1066.991,631.643,1065.034,631.643,1061.723Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4201"
                d="M655.363,1069.942H643.622l-2.529,5.63h-2.317l9.633-21.074h2.2l9.633,21.074h-2.347Zm-.812-1.807-5.059-11.319-5.057,11.319Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4202"
                d="M666.739,1054.5h2.229v21.074h-2.229Z"
                fill="#114684"
              />
              <Path
                data-name="Path 4203"
                d="M678.84,1054.5h8.58c6.744,0,11.29,4.335,11.29,10.536s-4.546,10.538-11.29,10.538h-8.58Zm8.46,19.147c5.6,0,9.213-3.552,9.213-8.611s-3.613-8.609-9.213-8.609h-6.232v17.22Z"
                fill="#114684"
              />
            </G>
          </G>
          <G data-name="Group 16894">
            <G data-name="Group 16892">
              <Path
                data-name="Path 4204"
                d="M502.013,1085.8H433.734v-42.708h68.279Zm-63.779-4.5h59.279v-33.708H438.234Z"
                fill="#114684"
              />
            </G>
            <G data-name="Group 16893">
              <Path
                data-name="Path 4205"
                d="M471.313,1076.159H443.4v-4.4h23.509v-5.082H443.546v-13.891h48.8v4.4H480.71v18.967h-4.4v-18.967H447.946v5.09h23.366Z"
                fill="#114684"
              />
            </G>
          </G>
        </G>
      </Svg>
    </View>
  );

  const PDFFooter = () => (
    <Text
      style={styles.pageNumber}
      render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
      fixed
    />
  );

  const CompanyDetails = () => (
    <View style={styles.companyDetails}>
      <View style={styles.logoTitle}>
        {!!logo && <Image style={styles.companyLogo} src={logo} />}
      </View>
      {contactInfo && <BrokerDetails />}
    </View>
  );

  const SubTitle = () => (
    <View>
      <Text style={styles.subTitle}>{title}</Text>
    </View>
  );

  const BrokerDetails = () => (
    // <View style={styles.brokerContainer}>
    <View style={styles.brokerData}>
      <Text style={{ ...styles.brokerName, marginBottom: '4px' }}>
        {brokerInfo?.brokerageName}
      </Text>
      <Text style={{ ...styles.brokerName, marginBottom: '4px' }}>
        {brokerInfo?.firstName + ' ' + brokerInfo?.lastName}
      </Text>
      <View style={{ ...styles.brokerEmail, marginBottom: '4px' }}>
        <View style={{ marginRight: 5 }}>
          <EmailSVG />
        </View>
        <View>
          <Text style={styles.brokerName}>{brokerInfo?.email}</Text>
        </View>
      </View>
      <View style={{ ...styles.brokerEmail, marginBottom: '4px' }}>
        <View style={{ marginRight: 5 }}>
          <ContactSVG />
        </View>
        <View>
          <Text style={styles.brokerName}>{brokerInfo?.contactNumber}</Text>
        </View>
      </View>
    </View>
    // </View>
  );

  const ContactSVG = () => (
    <Svg fill="currentColor" viewBox="0 0 512 512" height="10" width="10">
      <Path d="M497.39 361.8l-112-48a24 24 0 0 0-28 6.9l-49.6 60.6A370.66 370.66 0 0 1 130.6 204.11l60.6-49.6a23.94 23.94 0 0 0 6.9-28l-48-112A24.16 24.16 0 0 0 122.6.61l-104 24A24 24 0 0 0 0 48c0 256.5 207.9 464 464 464a24 24 0 0 0 23.4-18.6l24-104a24.29 24.29 0 0 0-14.01-27.6z"></Path>
    </Svg>
  );

  const EmailSVG = () => (
    <Svg fill="currentColor" viewBox="0 0 24 24" height="12" width="12">
      <Path fill="none" d="M0 0h24v24H0V0z"></Path>
      <Path d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2zm0 14H4V8l8 5 8-5v10zm-8-7L4 6h16l-8 5z"></Path>
    </Svg>
  );

  const AccountingSystemBankInfo = ({ bankInfo }: any) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          border: '1px solid black',
          borderTop: 'none',
        }}
      >
        <View
          style={{
            ...styles?.flexRowWrap,
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text>Name of bank : </Text>
            <Text style={{ flexGrow: 1 }}>{bankInfo?.name ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text>Address : </Text>
            <Text>{bankInfo?.address ?? 'NA'}</Text>
          </View>
        </View>
        <View
          style={{
            ...styles?.flexRowWrap,
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Account manager name :{' '}
            </Text>
            <Text>{bankInfo?.accountManagerName ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              With bank since what year :{' '}
            </Text>{' '}
            <Text> {bankInfo?.withBankSinceYear ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Phone number : </Text>
            <Text>{bankInfo?.bankPhoneNumber ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Authorized line of credit :{' '}
            </Text>
            <Text>{bankInfo?.authorizedLineOfCredit ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowNoWrap,
              padding: 2,
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              How much of authorized line of credit is currently used? :{' '}
            </Text>
            <Text>{bankInfo?.usedLineOfCredit ?? 'NA'}</Text>
          </View>
          <View
            style={{
              ...styles?.flexRowWrap,
              justifyContent: 'space-between',
              padding: 2,
            }}
          >
            <View
              style={{
                ...styles?.flexRowNoWrap,
                padding: 2,
              }}
            >
              <Text style={styles?.AccountingFormLabel}>
                How is the line of credit secured? :{' '}
              </Text>
              <Text>
                {bankInfo?.creditSecured !== null
                  ? bankInfo?.creditSecured
                    ? 'Secured'
                    : 'Unsecured'
                  : 'NA'}
              </Text>
            </View>
            <View
              style={{
                ...styles?.flexRowNoWrap,
                padding: 2,
              }}
            >
              <Text style={styles?.AccountingFormLabel}>
                Credit secured value :{' '}
              </Text>
              <Text>{bankInfo?.securedVal ?? 'NA'}</Text>
            </View>
            {bankInfo?.securedVal === 'other' && (
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  padding: 2,
                }}
              >
                <Text style={styles?.AccountingFormLabel}>
                  If Other(Please describe) :{' '}
                </Text>
                <Text>{bankInfo?.otherSecuredVal ?? 'NA'}</Text>
              </View>
            )}
          </View>
        </View>
      </View>
    );
  };

  const AccountingSystem = () => (
    <View break>
      <View style={styles.formHeading}>
        <Text>
          {stepperFormLabel && stepperFormLabel?.generalBanking?.trim() !== ''
            ? stepperFormLabel?.generalBanking
            : 'General Banking'}
        </Text>
      </View>
      <View
        style={{
          border: '1px solid black',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <View
          style={{
            width: '50%',
            flex: '1',
            borderRight: '1px solid black',
          }}
        >
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Legal name of company :{' '}
            </Text>
            <Text>{accountingSystem?.legalName ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Date business established :{' '}
            </Text>
            <Text>
              {accountingSystem?.businessEstablishedDate
                ? moment(accountingSystem?.businessEstablishedDate).format(
                    'MM/DD/yyyy'
                  )
                : 'NA'}
            </Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Street : </Text>
            <Text>{accountingSystem?.street ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Country : </Text>
            <Text>{accountingSystem?.country ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>City : </Text>
            <Text>{accountingSystem?.city ?? 'NA'}</Text>
          </View>
          <View
            style={{
              // borderBottom: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Date of Fiscal Year End :{' '}
            </Text>
            <Text>
              {accountingSystem?.dateCompleted
                ? moment(accountingSystem?.dateCompleted).format('MM/DD/yyyy')
                : 'NA'}
            </Text>
          </View>
        </View>
        <View
          style={{
            width: '50%',
            flex: '1',
          }}
        >
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Website : </Text>
            <Text>{accountingSystem?.website ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Email address : </Text>
            <Text>{accountingSystem?.email ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              {`${accountingSystem?.country === 'United States' ? 'State' : 'Province'}`}{' '}
              :{' '}
            </Text>
            <Text>{accountingSystem?.province ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              {`${accountingSystem?.country === 'United States' ? 'Zip code' : 'Postal code'}`}{' '}
              :{' '}
            </Text>
            <Text>{accountingSystem?.postalCode ?? 'NA'}</Text>
          </View>
          <View
            style={{
              borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Telephone : </Text>
            <Text>{accountingSystem?.telephone ?? 'NA'}</Text>
          </View>
          <View
            style={{
              // borderBottom: '1px solid black',
              // borderRight: '1px solid black',
              padding: 2,
            }}
          ></View>
        </View>
      </View>

      <View
        style={{
          border: '1px solid black',
          borderBottom: accountingSystem?.mailingAddressSameAsOffice
            ? '1px solid black'
            : 'none',
          marginTop: 5,
        }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            padding: 3,
          }}
        >
          <Text style={styles?.AccountingFormLabel}>
            Use the Office address as the mailing address? :{' '}
          </Text>
          <Text>
            {accountingSystem?.mailingAddressSameAsOffice ? 'Yes' : 'No'}
          </Text>
        </View>
      </View>
      {!accountingSystem?.mailingAddressSameAsOffice && (
        <View
          style={{
            border: '1px solid black',
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
          }}
        >
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Street : </Text>
            <Text>{accountingSystem?.mailingStreet ?? 'NA'}</Text>
          </View>
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>City : </Text>{' '}
            <Text> {accountingSystem?.mailingCity ?? 'NA'}</Text>
          </View>
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Country : </Text>
            <Text>{accountingSystem?.mailingCountry ?? 'NA'}</Text>
          </View>
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              {`${accountingSystem?.country === 'United States' ? 'State' : 'Province'}`}{' '}
              :{' '}
            </Text>
            <Text>{accountingSystem?.mailingProvince ?? 'NA'}</Text>
          </View>
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              {`${accountingSystem?.country === 'United States' ? 'Zip code' : 'Postal code'}`}{' '}
              :{' '}
            </Text>
            <Text>{accountingSystem?.mailingPostalCode ?? 'NA'}</Text>
          </View>
        </View>
      )}

      <View style={{ border: '1px solid black', borderTop: 'none' }}>
        <View
          style={{
            padding: 2,
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <Text style={styles?.AccountingFormLabel}>
            Name of Accounting Firm :{' '}
          </Text>
          <Text>{accountingSystem?.accountingFirmName ?? 'NA'}</Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>
              Contact Name at Accounting Firm :{' '}
            </Text>
            <Text>{accountingSystem?.contactName ?? 'NA'}</Text>
          </View>

          <View
            style={{
              padding: 2,
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <Text style={styles?.AccountingFormLabel}>Phone number : </Text>
            <Text>{accountingSystem?.phoneNumber ?? 'NA'}</Text>
          </View>
        </View>
      </View>

      <View style={{ border: '1px solid black', borderTop: 'none' }}>
        <View style={{ padding: 2, display: 'flex', flexDirection: 'row' }}>
          <Text style={styles?.AccountingFormLabel}>
            What intervals are interim statements prepared? :{' '}
          </Text>
          <Text style={{ textTransform: 'capitalize' }}>
            {accountingSystem?.interimStatementInterval ?? 'NA'}
          </Text>
        </View>
      </View>

      <View>
        {accountingSystem?.bankInfo &&
          accountingSystem?.bankInfo?.map((item: any, index: number) => (
            <AccountingSystemBankInfo bankInfo={item} key={index} />
          ))}
      </View>
    </View>
  );

  const getcheckedValue = (queKey: boolean | null) => {
    return queKey !== null ? (queKey === true ? 'Yes' : 'No') : 'No';
  };

  const CreditBureauCompanies = ({ company }: any) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          padding: 2,
          marginBottom: 5,
        }}
      >
        <View style={{ ...styles?.flexRowWrap }}>
          <Text>Name : {company?.name ?? 'NA'}</Text>
          <Text style={{ marginLeft: 30 }}>
            Nature of operations of related company :{' '}
            {company?.natureOfOperations ?? 'NA'}
          </Text>
        </View>
        <View>
          <Text>
            What is the ownership structure of this related company :{' '}
            {company?.ownershipStructure ?? 'NA'}
          </Text>
        </View>
      </View>
    );
  };
  const LifeInsurence = ({ company }: any) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          padding: 2,
          marginBottom: 5,
        }}
      >
        <View style={{ ...styles?.flexRowWrap }}>
          <Text>Name : {company?.name ?? 'NA'}</Text>
          <Text style={{ marginLeft: 30 }}>
            Nature of operations of related company :{' '}
            {company?.natureOfOperations ?? 'NA'}
          </Text>
        </View>
        <View>
          <Text>
            What is the ownership structure of this related company :{' '}
            {company?.ownershipStructure ?? 'NA'}
          </Text>
        </View>
      </View>
    );
  };

  const getByLabelText = (key: string) => {
    return (
      <Text
        style={{
          fontWeight: 600,
          color: '#114684',
          fontSize: 10,
          marginRight: '10px',
        }}
        wrap={false}
      >
        {key}
      </Text>
    );
  };

  const formatValue = (value: any) => {
    if ((typeof value === 'number' || Number(value)) && value < 100) {
      return `${value}%`;
    }
    return value;
  };
  const formatNumber = (number: number): string => {
    if (number !== 0 && !number) return '';
    const formattedNumber = number.toLocaleString('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `$ ${formattedNumber}`;
  };
  const getFieldInput = (key: string, value: any) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          // justifyContent: 'space-between',
          gap: '2px',
          width: '32%',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <View style={{ ...styles?.dot }}></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            borderRadius: '5px',
            border: '1px solid #7f7f7f',
            padding: '5px 10px',
            flexGrow: 1,
          }}
        >
          {key.includes('%')
            ? formatValue(value)
            : key.includes('$')
              ? formatNumber(value)
              : value}
        </Text>
      </View>
    );
  };

  const getFieldInputCommon = (
    key: string,
    value: any,
    maxWidth?: string,
    width?: string
  ) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '4px',
          width: `${width ?? '32%'}`,
          flexGrow: 1,
          maxWidth: `${maxWidth ?? '32%'}`,
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
          }}
        >
          <View style={{ ...styles?.dot }}></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            borderRadius: '5px',
            border: '1px solid #7f7f7f',
            padding: '5px 10px',
            flexGrow: 1,
          }}
        >
          {key.includes('%')
            ? formatValue(value)
            : key.includes('$')
              ? formatNumber(value)
              : value}
        </Text>
      </View>
    );
  };
  const getInputCapitalized = (key: string, value: any) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '2px',
          width: '32%',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <View style={{ ...styles?.dot }}></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            borderRadius: '5px',
            border: '1px solid #7f7f7f',
            padding: '5px 10px',
            textTransform: 'capitalize',
            flexGrow: 1,
          }}
        >
          {value}
        </Text>
      </View>
    );
  };
  const getPFInput = (key: string, value: any) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '2px',
          width: '48.5%',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <View style={{ ...styles?.dot }}></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            borderRadius: '5px',
            border: '1px solid #7f7f7f',
            padding: '5px 10px',
            flexGrow: 1,
          }}
        >
          {value}
        </Text>
      </View>
    );
  };
  const getAssetPriceInput = (
    key: string,
    value: any,
    isOtherType: boolean
  ) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '2px',
          width: isOtherType ? '31.5%' : '48.5%',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <View style={{ ...styles?.dot }}></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            borderRadius: '5px',
            border: '1px solid #7f7f7f',
            padding: '5px 10px',
            flexGrow: 1,
          }}
        >
          {value}
        </Text>
      </View>
    );
  };

  const getFieldCheckbox = (key: string, value: boolean) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '2px',
          // justifyContent: 'space-between',
          // gridColumnEnd: 'span 3',
          width: '32%',
        }}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <View style={{ ...styles?.dot }}></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            borderRadius: '5px',
            border: '1px solid #7f7f7f',
            padding: '5px 10px',
            maxWidth: '280px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {value ? (value === true ? 'Yes' : 'No') : 'No'}
        </Text>
      </View>
    );
  };

  const getFieldCheckboxCredit = (key: string, value: boolean) => {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          gap: '2px',
          width: '32%',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexRowNoWrap,
            justifyContent: 'flex-start',
            alignItems: 'flex-start',
          }}
        >
          <View style={{ ...styles?.dot }}></View>
          {getByLabelText(key)}
        </View>
        <Text
          style={{
            fontSize: 10,
            borderRadius: '5px',
            border: '1px solid #7f7f7f',
            padding: '5px 10px',
            maxWidth: '275px',
            flexGrow: 1,
          }}
        >
          {' '}
          {value ? (value === true ? 'Secured' : 'Unsecured') : 'Unsecured'}
        </Text>
      </View>
    );
  };

  const isShowPFForm = () => {
    const ownerData =
      financialInfo?.ownersFinancialInfo &&
      financialInfo?.ownersFinancialInfo?.length > 0 &&
      financialInfo?.ownersFinancialInfo?.map((owner: any, i: number) => {
        return owner?.ownerInfo?.nameHoldco ||
          owner?.ownerInfo?.dateOfBirth ||
          owner?.ownerInfo?.position ||
          owner?.ownerInfo?.inPositionSince ||
          owner?.ownerInfo?.ownershipPercentage ||
          isPFSection(owner)
          ? true
          : false;
      });

    return ownerData?.includes(true);
  };

  const isShowSurety = () => {
    return surety?.suretyName ||
      surety?.totalWorkOnHand ||
      surety?.singleJob ||
      surety?.reasonChangingSurety ||
      typeof surety?.refusedBond === 'boolean' ||
      !isEveryObjKeyNull(surety?.whoSignSeal)
      ? true
      : false;
  };

  const isShowInsurance = () => {
    return typeof insurance?.keyManLifeInsurance?.hasKeyManLifeInsurance ===
      'boolean' ||
      typeof insurance?.commercialGeneralLiabilityInsurance
        ?.hasCommercialGeneralLiabilityInsurance === 'boolean' ||
      typeof insurance?.environmentalInsurance?.hasEnvironmentalInsurance ===
        'boolean' ||
      typeof insurance?.errorsAndOmissionsInsurance
        ?.hasErrorsAndOmissionsInsurance === 'boolean' ||
      typeof insurance?.fidelityInsurance?.hasFidelityInsurance === 'boolean' ||
      typeof insurance?.equipmentInsurance?.hasEquipmentInsurance ===
        'boolean' ||
      typeof insurance?.otherInsurance?.hasOtherInsurance === 'boolean'
      ? true
      : false;
  };

  const isEveryObjKeyNull = (arr: { [key: string]: any }) => {
    return arr?.every((info: any) =>
      Object.values(info).every(
        (value) => value === null || value === '' || value === 0
      )
    );
  };

  const isEveryObjKeyNullRemoveId = (arr: { [key: string]: any }) => {
    return arr?.every((info: any) => {
      const entries = Object.entries(info).filter(([key]) => key !== 'id');
      return entries.every(
        ([, value]) => value === null || value === '' || value === 0
      );
    });
  };
  const isEveryObjKeyNullAssets = (arr: { [key: string]: any }) => {
    return arr?.every((info: any) => {
      const entries = Object.entries(info).filter(
        ([key]) => key !== 'id' && key !== 'type'
      );
      return entries.every(
        ([, value]) => value === null || value === '' || value === 0
      );
    });
  };

  const getFilteredArray = (arr: { [key: string]: any }) => {
    return arr.filter(
      (info: any) =>
        !Object.values(info).every(
          (value) => value === null || value === '' || value === 0
        )
    );
  };
  const getFilteredArrayAssets = (arr: { [key: string]: any }) => {
    return arr.filter(
      (info: any) =>
        info?.value &&
        !Object.values(info).every(
          (value) => value === null || value === '' || value === 0
        )
    );
  };

  const isPFSection = (owner: any) => {
    return (
      owner?.assetsResidential?.address ||
      owner?.assetsResidential?.value ||
      !isEveryObjKeyNullRemoveId(owner?.assetsRealEstate) ||
      !isEveryObjKeyNullAssets(owner?.assetsPrice) ||
      owner?.liabilitiesResidence?.mortgage ||
      owner?.liabilitiesResidence?.value ||
      !isEveryObjKeyNullRemoveId(owner?.liabilitiesRealEstate) ||
      !isEveryObjKeyNullAssets(owner?.liabilitiesPrice) ||
      !isEveryObjKeyNullRemoveId(owner?.businessInterests) ||
      owner?.totalAssets ||
      owner?.totalLiabilities ||
      owner?.personalNetWorth
    );
  };

  const AccountingSystemNew = () => (
    <View break>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <View style={{ width: '100%' }} id="generalBankingPage">
          <Text style={{ ...styles?.formHeading, color: '#114684' }}>
            {stepperFormLabel && stepperFormLabel?.generalBanking?.trim() !== ''
              ? stepperFormLabel?.generalBanking
              : 'General & Banking'}
          </Text>
        </View>
        <View
          style={{
            ...styles?.flexColumn,
            gap: '15px',
            border: '1px solid #7f7f7f',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            padding: '10px 5px',
          }}
        >
          <View style={{ ...styles?.flexRowWrap, gap: '10px' }}>
            {accountingSystem?.legalName &&
              getFieldInput(
                'Legal name of company',
                accountingSystem?.legalName
              )}
            {accountingSystem?.businessEstablishedDate &&
              getFieldInput(
                'Date business established',
                moment(accountingSystem?.businessEstablishedDate).format(
                  'MM/DD/YYYY'
                )
              )}
            {accountingSystem?.email &&
              getFieldInput('Email address', accountingSystem?.email)}
            {accountingSystem?.website &&
              getFieldInput('Website', accountingSystem?.website)}
            {accountingSystem?.street &&
              getFieldInput('Street', accountingSystem?.street)}
            {accountingSystem?.city &&
              getFieldInput('City', accountingSystem?.city)}
            {accountingSystem?.province &&
              getFieldInput(
                `${accountingSystem?.country === 'United States' ? 'State' : 'Province'}`,
                accountingSystem?.province
              )}
            {accountingSystem?.postalCode &&
              getFieldInput(
                `${accountingSystem?.country === 'United States' ? 'Zip code' : 'Postal code'}`,
                accountingSystem?.postalCode
              )}
            {accountingSystem?.country &&
              getFieldInput('Country', accountingSystem?.country)}
          </View>
          <View style={{ ...styles?.flexRowWrap, gap: '10px' }} wrap={false}>
            {getFieldCheckbox(
              'Use the Office address as the mailing address?',
              accountingSystem?.mailingAddressSameAsOffice
            )}
          </View>
        </View>
        <View
          style={{
            ...styles?.flexColumn,
            gap: '15px',
            border: '1px solid #7f7f7f',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            padding: '10px 5px',
          }}
        >
          {/* <View style={{ ...styles?.flexRowWrap, gap: '10px' }}> */}
          {accountingSystem?.mailingAddressSameAsOffice !== null &&
            !accountingSystem?.mailingAddressSameAsOffice && (
              <View style={{ ...styles?.flexRowWrap, gap: '10px' }}>
                {accountingSystem?.mailingStreet &&
                  getFieldInput('Street', accountingSystem?.mailingStreet)}
                {accountingSystem?.mailingCity &&
                  getFieldInput('City', accountingSystem?.mailingCity)}
                {accountingSystem?.mailingProvince &&
                  getFieldInput(
                    `${accountingSystem?.mailingCountry === 'United States' ? 'State' : 'Province'}`,
                    accountingSystem?.mailingProvince
                  )}
                {accountingSystem?.mailingPostalCode &&
                  getFieldInput(
                    `${accountingSystem?.mailingCountry === 'United States' ? 'Zip code' : 'Postal code'}`,
                    accountingSystem?.mailingPostalCode
                  )}
                {accountingSystem?.mailingCountry &&
                  getFieldInput('Country', accountingSystem?.mailingCountry)}
              </View>
            )}
          {/* </View> */}
          <View style={{ ...styles?.flexRowWrap, gap: '10px' }}>
            {accountingSystem?.telephone &&
              getFieldInput('Telephone ', accountingSystem?.telephone)}
            {accountingSystem?.accountingFirmName &&
              getFieldInput(
                'Name of Accounting Firm',
                accountingSystem?.accountingFirmName
              )}
            {accountingSystem?.contactName &&
              getFieldInput(
                'Contact Name at Accounting Firm',
                accountingSystem?.contactName
              )}
            {!!accountingSystem?.phoneNumber &&
              getFieldInput('Phone number', accountingSystem?.phoneNumber)}
            {accountingSystem?.interimStatementInterval &&
              getInputCapitalized(
                'What intervals are interim statements prepared?',
                accountingSystem?.interimStatementInterval
              )}
            {accountingSystem?.dateCompleted &&
              getFieldInput(
                'Date of Fiscal Year End',
                moment(accountingSystem?.dateCompleted).format('MM/DD/YYYY')
              )}
          </View>
        </View>
        {accountingSystem?.bankInfo &&
          !isEveryObjKeyNull(accountingSystem?.bankInfo) && (
            <View
              style={{
                ...styles?.flexColumn,
                gap: '15px',
                border: '1px solid #7f7f7f',
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
                padding: '10px 5px',
              }}
            >
              {accountingSystem?.bankInfo &&
                getFilteredArray(accountingSystem?.bankInfo)?.map(
                  (item: any, index: number) => (
                    <View
                      style={{
                        ...styles?.flexRowWrap,
                        gap: '10px',
                        borderBottom:
                          getFilteredArray(accountingSystem?.bankInfo).length -
                            1 ===
                          index
                            ? ''
                            : '1px solid #7f7f7f',
                        paddingBottom:
                          getFilteredArray(accountingSystem?.bankInfo).length -
                            1 ===
                          index
                            ? ''
                            : '8px',
                      }}
                      wrap={false}
                    >
                      {item?.bankName &&
                        getFieldInput('Name of bank ', item?.bankName)}
                      {item?.address && getFieldInput('Address', item?.address)}
                      {!!item?.authorizedLineOfCredit &&
                        getFieldInput(
                          '$ Authorized line of credit',
                          item?.authorizedLineOfCredit
                        )}
                      {!!item?.bankPhoneNumber &&
                        getFieldInput('Phone number', item?.bankPhoneNumber)}
                      {!!item?.withBankSinceYear &&
                        getFieldInput(
                          'With bank since what year',
                          item?.withBankSinceYear
                        )}
                      {item?.accountManagerName &&
                        getFieldInput(
                          'Account manager name',
                          item?.accountManagerName
                        )}
                      {item?.usedLineOfCredit &&
                        getFieldInput(
                          '$ How much of authorized line of credit is currently used?',
                          item?.usedLineOfCredit
                        )}
                      {item?.creditSecured !== null &&
                        getFieldCheckboxCredit(
                          'How is the line of credit secured?',
                          item?.creditSecured
                        )}
                      {item?.creditSecured &&
                        item?.securedVal &&
                        getInputCapitalized(
                          'Credit Secured Value',
                          item?.securedVal
                        )}
                      {item?.otherSecuredVal &&
                        getFieldInput(
                          'Other(Please describe)',
                          item?.otherSecuredVal
                        )}
                    </View>
                  )
                )}
            </View>
          )}
      </View>
    </View>
  );

  const getQueLabel = (que: string) => {
    return (
      <Text
        style={{
          fontWeight: 600,
          color: '#114684',
          fontSize: 10,
          marginRight: '10px',
        }}
      >
        {que}{' '}
      </Text>
    );
  };

  const creditBureauQue = (
    que: string,
    key: boolean | null,
    detailsQue: string,
    detailsKey: string | null
  ) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          padding: '8px',
          gap: '4px',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexColumn,
            gap: '2px',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <View style={{ ...styles?.dot }}></View>
            {getQueLabel(que)}
          </View>
          <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}>
            {key ? (key === true ? 'Yes' : 'No') : 'No'}
          </Text>
        </View>
        {key && (
          <View style={{ ...styles?.flexColumn, gap: '2px' }}>
            <View
              style={{
                ...styles?.flexRowNoWrap,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <View style={{ ...styles?.dot }}></View>
              <Text style={{ fontWeight: 600, color: '#114684', fontSize: 10 }}>
                {detailsQue}{' '}
              </Text>
            </View>
            <View>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 10,
                  borderRadius: '5px',
                  border: '1px solid #7f7f7f',
                  padding: '5px 10px',
                  flexGrow: 1,
                }}
              >
                {detailsKey}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  };

  const suretyQue = (
    que: string,
    key: boolean | null,
    detailsQue: string,
    detailsKey: string | null
  ) => {
    return (
      typeof key === 'boolean' && (
        <View
          style={{
            ...styles?.flexColumn,
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            padding: '8px',
            gap: '4px',
          }}
          wrap={false}
        >
          <View
            style={{
              ...styles?.flexColumn,
              gap: '2px',
            }}
          >
            <View
              style={{
                ...styles?.flexRowNoWrap,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <View style={{ ...styles?.dot }}></View>
              {getQueLabel(que)}
            </View>
            <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}>
              {key ? (key === true ? 'Yes' : 'No') : 'No'}
            </Text>
          </View>
          {key && (
            <View style={{ ...styles?.flexColumn, gap: '2px' }}>
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                <Text
                  style={{ fontWeight: 600, color: '#114684', fontSize: 10 }}
                >
                  {detailsQue}{' '}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 10,
                    borderRadius: '5px',
                    border: '1px solid #7f7f7f',
                    padding: '5px 10px',
                    flexGrow: 1,
                  }}
                >
                  {detailsKey}
                </Text>
              </View>
            </View>
          )}
        </View>
      )
    );
  };

  const creditBureaudisputesWithSuppliers = (
    que: string,
    key: boolean | null
  ) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          padding: '8px',
          gap: '4px',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexColumn,
            gap: '2px',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <View style={{ ...styles?.dot }}></View>
            {getQueLabel(que)}
          </View>
          <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}>
            {key ? (key === true ? 'Yes' : 'No') : 'No'}
          </Text>
        </View>
        {key && (
          <View style={{ ...styles?.flexRowNoWrap, gap: '10px' }}>
            <View style={{ ...styles?.flexColumn, gap: '2px', width: '50%' }}>
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                <Text
                  style={{
                    fontWeight: 600,
                    color: '#114684',
                    fontSize: 10,
                    marginRight: '10px',
                  }}
                >
                  Provide details{' '}
                </Text>
              </View>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 10,
                  borderRadius: '5px',
                  border: '1px solid #7f7f7f',
                  padding: '5px 10px',
                  flexGrow: 1,
                }}
              >
                {creditBureau?.disputesWithSuppliers?.details}
              </Text>
            </View>
            <View style={{ ...styles?.flexColumn, gap: '2px', width: '50%' }}>
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                <Text
                  style={{
                    fontWeight: 600,
                    color: '#114684',
                    fontSize: 10,
                    marginRight: '10px',
                  }}
                >
                  By what means is it funded? Provide a copy of the agreement.{' '}
                </Text>
              </View>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 10,
                  borderRadius: '5px',
                  border: '1px solid #7f7f7f',
                  padding: '5px 10px',
                  flexGrow: 1,
                }}
              >
                {creditBureau?.disputesWithSuppliers?.fundingMeans}
              </Text>
            </View>
          </View>
        )}
      </View>
    );
  };

  const creditBureauQueChain = (
    que: string,
    key: boolean | null,
    queSecond: string,
    KeySecond: boolean | null
  ) => {
    return (
      <View
        style={{
          ...styles?.flexColumn,
          backgroundColor: '#f5f5f5',
          borderRadius: '8px',
          padding: '8px',
          gap: '4px',
        }}
        wrap={false}
      >
        <View
          style={{
            ...styles?.flexColumn,
            gap: '2px',
          }}
        >
          <View
            style={{
              ...styles?.flexRowNoWrap,
              justifyContent: 'flex-start',
              alignItems: 'flex-start',
            }}
          >
            <View style={{ ...styles?.dot }}></View>
            {getQueLabel(que)}
          </View>
          <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}>
            {key ? (key === true ? 'Yes' : 'No') : 'No'}
          </Text>
        </View>
        {key && (
          <View
            style={{
              ...styles?.flexColumn,
              gap: '2px',
            }}
          >
            <View
              style={{
                ...styles?.flexRowNoWrap,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <View style={{ ...styles?.dot }}></View>
              {getQueLabel(queSecond)}
            </View>
            <Text style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}>
              {KeySecond ? (KeySecond === true ? 'Yes' : 'No') : 'No'}
            </Text>
          </View>
        )}
      </View>
    );
  };

  const CreditBureauNew = () => {
    const showRelatedCompanies =
      creditBureau?.relatedCompanies?.hasRelatedCompanies &&
      creditBureau?.relatedCompanies?.companies?.length > 0 &&
      creditBureau?.relatedCompanies?.companies?.some(
        (company: any) =>
          company?.name ||
          company?.natureOfOperations ||
          company?.ownershipStructure
      );
    return (
      <View break>
        <View
          style={{
            ...styles?.flexColumn,
            gap: '15px',
          }}
        >
          <View style={{ width: '100%' }} id="legalPage">
            <Text style={{ ...styles?.formHeading, color: '#114684' }}>
              {stepperFormLabel && stepperFormLabel?.legal?.trim() !== ''
                ? stepperFormLabel?.legal
                : 'Legal'}
            </Text>
          </View>
          <View
            style={{
              ...styles?.flexColumn,
              border: '1px solid #7f7f7f',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              padding: '10px 0',
            }}
          >
            {/* <View
          style={{
            ...styles?.flexColumn,
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            padding: '8px',
            gap: '4px',
          }}
        >
          <View
            style={{
              // ...styles?.flexRowWrap,
              // justifyContent: 'space-between',
              ...styles?.flexColumn,
              gap: '2px',
            }}
          >
            <View
              style={{
                ...styles?.flexRowNoWrap,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <View style={{ ...styles?.dot }}></View>
              <Text
                style={{
                  fontWeight: 600,
                  color: '#114684',
                  fontSize: 10,
                  marginRight: '10px',
                }}
              >
                Has the company, any prior company or any of the owners or
                their spouses or a company owned by any of them ever been
                placed in receivership or bankruptcy?{' '}
              </Text>
            </View>
            <Text
              style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}
            >
              {creditBureau?.receivershipOrBankruptcyHistory?.hasHistory
                ? creditBureau?.receivershipOrBankruptcyHistory
                    ?.hasHistory === true
                  ? 'Yes'
                  : 'No'
                : 'No'}
            </Text>
          </View>
          {creditBureau?.receivershipOrBankruptcyHistory?.hasHistory && (
            <View style={{ ...styles?.flexColumn, gap: '2px' }}>
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                <Text
                  style={{ fontWeight: 600, color: '#114684', fontSize: 10 }}
                >
                  If Yes, provide details{' '}
                </Text>
              </View>
              <View>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 10,
                    borderRadius: '5px',
                    border: '1px solid #7f7f7f',
                    padding: '5px 10px',
                  }}
                >
                  {creditBureau?.receivershipOrBankruptcyHistory?.details}
                </Text>
              </View>
            </View>
          )}
        </View> */}
            {creditBureauQue(
              `Has the company, any prior company or any of the owners or their spouses or a company owned by any of them ever been placed in receivership or bankruptcy?`,
              creditBureau?.receivershipOrBankruptcyHistory?.hasHistory,
              `Provide details`,
              creditBureau?.receivershipOrBankruptcyHistory?.details
            )}
            {creditBureauQue(
              `Has the company, its owners or any of the related companies provided any guarantees for the indebtedness of any other party, outside Corporation or individuals?`,
              creditBureau?.guaranteesForOtherParties?.hasGuarantees,
              `Provide details`,
              creditBureau?.guaranteesForOtherParties?.details
            )}

            {showRelatedCompanies && (
              <View
                style={{
                  ...styles?.flexColumn,
                  backgroundColor: '#f5f5f5',
                  borderRadius: '8px',
                  padding: '8px',
                  gap: '4px',
                }}
                wrap={false}
              >
                <View
                  style={{
                    ...styles?.flexColumn,
                    gap: '2px',
                  }}
                >
                  <View
                    style={{
                      ...styles?.flexRowNoWrap,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <View style={{ ...styles?.dot }}></View>
                    {getQueLabel(
                      'Does the company have one or more related companies?'
                    )}
                  </View>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 10,
                      marginLeft: '10px',
                    }}
                  >
                    {creditBureau?.relatedCompanies?.hasRelatedCompanies ===
                    true
                      ? 'Yes'
                      : 'No'}
                  </Text>
                </View>
                {creditBureau?.relatedCompanies?.hasRelatedCompanies && (
                  <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                    {creditBureau?.relatedCompanies?.companies?.map(
                      (item: any, i: number) => (
                        <View
                          key={i}
                          style={{
                            ...styles?.flexRowWrap,
                            gap: '8px',
                          }}
                        >
                          {item?.name &&
                            getFieldInputCommon('Name', item?.name)}
                          {item?.natureOfOperations &&
                            getFieldInputCommon(
                              'Nature of operations of related company',
                              item?.natureOfOperations
                            )}
                          {item?.ownershipStructure &&
                            getFieldInputCommon(
                              'What % is the ownership structure of this related company',
                              item?.ownershipStructure
                            )}
                        </View>
                      )
                    )}
                  </View>
                )}
              </View>
            )}

            {creditBureauQue(
              `Have there been any changes in the control, ownership, or management of the company in the past 3 years or are any such changes impending?`,
              creditBureau?.changesInManagement?.hasChanges,
              `Provide details`,
              creditBureau?.changesInManagement?.explanation
            )}
            {creditBureauQue(
              `Are there any liens for labour or material filed against you by anyone?`,
              creditBureau?.liensFiledAgainstCompany?.hasLiens,
              `Provide details`,
              creditBureau?.liensFiledAgainstCompany?.details
            )}
            {accountingSystem?.country === 'United States' &&
              creditBureauQueChain(
                `Are there any state or federal tax liens that are open?`,
                creditBureau?.stateFederalTax,
                `Is there a payment plan in place? Provide a copy of the agreement or documentation from the taxing entity.`,
                creditBureau?.paymentPlan
              )}
            {creditBureauQue(
              `Are there any judgments, suits or claims outstanding against your company its officers or any company of either?`,
              creditBureau?.outstandingJudgments?.hasOutstandingJudgments,
              `Provide details`,
              creditBureau?.outstandingJudgments?.details
            )}

            <View
              style={{
                ...styles?.flexColumn,
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
                padding: '8px',
                gap: '4px',
              }}
              wrap={false}
            >
              <View
                style={{
                  ...styles?.flexColumn,
                  gap: '2px',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel(
                    'Are there any claims made against a surety bond?'
                  )}
                </View>
                <Text
                  style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}
                >
                  {creditBureau?.bondClaims
                    ? creditBureau?.bondClaims === true
                      ? 'Yes'
                      : 'No'
                    : 'No'}
                </Text>
              </View>
              {creditBureau?.bondClaims && (
                <View
                  style={{
                    ...styles?.flexColumn,
                    gap: '2px',
                  }}
                >
                  <View
                    style={{
                      ...styles?.flexRowNoWrap,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <View style={{ ...styles?.dot }}></View>
                    {getQueLabel('Were the claims paid?')}
                  </View>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 10,
                      marginLeft: '10px',
                    }}
                  >
                    {creditBureau?.claimsPaid
                      ? creditBureau?.claimsPaid === true
                        ? 'Yes'
                        : 'No'
                      : 'No'}
                  </Text>
                </View>
              )}
              {creditBureau?.bondClaims && creditBureau?.claimsPaid && (
                <View
                  style={{
                    ...styles?.flexColumn,
                    gap: '2px',
                  }}
                >
                  <View
                    style={{
                      ...styles?.flexRowNoWrap,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <View style={{ ...styles?.dot }}></View>
                    {getQueLabel('Was the surety made whole?')}
                  </View>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 10,
                      marginLeft: '10px',
                    }}
                  >
                    {creditBureau?.suretyMade
                      ? creditBureau?.suretyMade === true
                        ? 'Yes'
                        : 'No'
                      : 'No'}
                  </Text>
                </View>
              )}
              {creditBureau?.bondClaims &&
                creditBureau?.claimsPaid &&
                creditBureau?.suretyMade === false && (
                  <View style={{ ...styles?.flexColumn, gap: '2px' }}>
                    <View
                      style={{
                        ...styles?.flexRowNoWrap,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}
                    >
                      <View style={{ ...styles?.dot }}></View>
                      <Text
                        style={{
                          fontWeight: 600,
                          color: '#114684',
                          fontSize: 10,
                        }}
                      >
                        Provide details{' '}
                      </Text>
                    </View>
                    <View>
                      <Text
                        style={{
                          fontWeight: 600,
                          fontSize: 10,
                          borderRadius: '5px',
                          border: '1px solid #7f7f7f',
                          padding: '5px 10px',
                        }}
                      >
                        {creditBureau?.whyNotMade}
                      </Text>
                    </View>
                  </View>
                )}
            </View>

            {creditBureauQue(
              `Are others disputing any work which you did or failed to do or any account which you presented to them?`,
              creditBureau?.disputesWithOthers?.hasDisputes,
              `Provide details`,
              creditBureau?.disputesWithOthers?.details
            )}

            {creditBureauQue(
              `Are there any liens for labour or material filed by you against a third party?`,
              creditBureau?.liensFiledByCompany?.hasLiens,
              `Provide details`,
              creditBureau?.liensFiledByCompany?.details
            )}

            {creditBureaudisputesWithSuppliers(
              `Is the company presently engaged in any dispute with suppliers or sub trades relative to their work or materials?`,
              creditBureau?.disputesWithSuppliers?.hasDisputes
            )}

            {creditBureauQue(
              `Is there a formal Buy/Sell agreement.`,
              creditBureau?.buySellAgreement,
              `Provide further details regarding the agreement`,
              creditBureau?.agreementDetails
            )}

            {/* <View
              style={{
                ...styles?.flexColumn,
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
                padding: '8px',
                gap: '4px',
              }}
              wrap={false}
            >
              <View
                style={{
                  ...styles?.flexColumn,
                  gap: '2px',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('Is there a formal Buy/Sell agreement.')}
                </View>
                <Text
                  style={{ fontWeight: 600, fontSize: 10, marginLeft: '10px' }}
                >
                  {creditBureau?.buySellAgreement
                    ? creditBureau?.buySellAgreement === true
                      ? 'Yes'
                      : 'No'
                    : 'No'}
                </Text>
              </View>
            </View> */}
          </View>
        </View>
      </View>
    );
  };

  const PresonalFinancial = () => (
    <View break>
      {financialInfo?.ownersFinancialInfo &&
        financialInfo?.ownersFinancialInfo?.length > 0 &&
        financialInfo?.ownersFinancialInfo?.map((owner: any, i: number) => {
          return (
            <View
              style={{
                ...styles?.flexColumn,
                gap: '15px',
                marginBottom:
                  i < financialInfo?.ownersFinancialInfo?.length - 1
                    ? '15px'
                    : '0px',
              }}
              key={i}
            >
              {(owner?.ownerInfo?.nameHoldco ||
                owner?.ownerInfo?.dateOfBirth ||
                owner?.ownerInfo?.position ||
                owner?.ownerInfo?.inPositionSince ||
                owner?.ownerInfo?.ownershipPercentage ||
                isPFSection(owner)) && (
                <View
                  style={{
                    width: '100%',
                    ...styles?.flexColumn,
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                  wrap={false}
                >
                  <Text
                    style={{ ...styles?.formHeading, color: '#114684' }}
                    id={`personalFinancialPage-${i + 1}`}
                  >
                    {stepperFormLabel &&
                    stepperFormLabel?.personalFinancial?.trim() !== ''
                      ? stepperFormLabel?.personalFinancial
                      : 'Personal Financial'}
                  </Text>
                  {owner?.ownerInfo?.nameHoldco && (
                    <Text
                      style={{
                        fontSize: 14,
                        textAlign: 'center',
                        fontWeight: 'bold',
                        marginBottom: 5,
                        color: '#114684',
                      }}
                    >
                      {owner?.ownerInfo?.nameHoldco}
                    </Text>
                  )}
                </View>
              )}
              {(owner?.ownerInfo?.nameHoldco ||
                owner?.ownerInfo?.dateOfBirth ||
                owner?.ownerInfo?.position ||
                owner?.ownerInfo?.inPositionSince ||
                owner?.ownerInfo?.ownershipPercentage) && (
                <View
                  style={{
                    ...styles?.flexRowWrap,
                    gap: '8px',

                    border: '1px solid #7f7f7f',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    padding: '10px 10px',
                  }}
                >
                  {owner?.ownerInfo?.nameHoldco &&
                    getFieldInput('Name/Holdco', owner?.ownerInfo?.nameHoldco)}
                  {owner?.ownerInfo?.dateOfBirth &&
                    getFieldInput(
                      'Date of birth',
                      moment(owner?.ownerInfo?.dateOfBirth).format('MM/DD/YYYY')
                    )}
                  {owner?.ownerInfo?.position &&
                    getFieldInput(
                      `Position at ${qboCompanyName}`,
                      owner?.ownerInfo?.position
                    )}
                  {owner?.ownerInfo?.inPositionSince &&
                    getFieldInput(
                      'In position since',
                      moment(owner?.ownerInfo?.inPositionSince).format(
                        'MM/DD/YYYY'
                      )
                    )}
                  {owner?.ownerInfo?.ownershipPercentage &&
                    getFieldInput(
                      `% ownership of ${qboCompanyName}`,
                      owner?.ownerInfo?.ownershipPercentage
                    )}
                </View>
              )}
              {isPFSection(owner) && (
                <View
                  style={{
                    ...styles?.flexColumn,
                    gap: '15px',
                    border: '1px solid #7f7f7f',
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    padding: '10px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      ...styles?.flexColumn,
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                    wrap={false}
                  >
                    <Text
                      style={{
                        fontWeight: 600,
                        color: '#114684',
                        fontSize: 10,
                        textAlign: 'center',
                      }}
                    >
                      FINANCIAL INFORMATION
                    </Text>
                    <Text
                      style={{
                        fontWeight: 600,
                        color: '#114684',
                        fontSize: 9,
                        textAlign: 'left',
                      }}
                    >
                      Supporting documents such as RRSP statements, tax
                      assessments, company financial statements, bank statements
                      etc. should accompany the information, otherwise they will
                      be discounted from the total assets
                    </Text>
                  </View>
                  {(owner?.assetsResidential?.address ||
                    owner?.assetsResidential?.value ||
                    !isEveryObjKeyNullRemoveId(owner?.assetsRealEstate) ||
                    !isEveryObjKeyNullAssets(owner?.assetsPrice)) && (
                    <View style={{ ...styles?.flexColumn, gap: '10px' }}>
                      <Text
                        style={{
                          fontWeight: 600,
                          color: '#114684',
                          fontSize: 10,
                        }}
                      >
                        ASSETS
                      </Text>

                      <View style={{ ...styles?.flexColumn, gap: '5px' }}>
                        {(owner?.assetsResidential?.address ||
                          owner?.assetsResidential?.value) && (
                          <View style={{ ...styles?.flexRowWrap, gap: '10px' }}>
                            {owner?.assetsResidential?.address &&
                              getPFInput(
                                'Personal residential address',
                                owner?.assetsResidential?.address
                              )}
                            {owner?.assetsResidential?.value &&
                              getFieldInputCommon(
                                '$ Market Value',
                                owner?.assetsResidential?.value
                              )}
                          </View>
                        )}
                        {!isEveryObjKeyNullRemoveId(
                          owner?.assetsRealEstate
                        ) && (
                          <View>
                            {getFilteredArray(owner?.assetsRealEstate)?.map(
                              (assets: any) => {
                                return (
                                  <View
                                    style={{
                                      ...styles?.flexRowWrap,
                                      gap: '10px',
                                    }}
                                  >
                                    {assets?.address &&
                                      getPFInput(
                                        'Other real estate addresses',
                                        assets?.address
                                      )}
                                    {assets?.value &&
                                      getFieldInputCommon(
                                        '$ Market value',
                                        assets?.value
                                      )}
                                  </View>
                                );
                              }
                            )}
                          </View>
                        )}
                        {!isEveryObjKeyNullRemoveId(owner?.assetsPrice) && (
                          <View>
                            {getFilteredArrayAssets(owner?.assetsPrice)?.map(
                              (assets: any) => {
                                return (
                                  <View
                                    style={{
                                      ...styles?.flexRowWrap,
                                      gap: '10px',
                                    }}
                                  >
                                    {assets?.type &&
                                      getAssetPriceInput(
                                        'Select Dropdown',
                                        assets?.type,
                                        assets?.type === 'Others' &&
                                          assets?.otherAssetType
                                      )}
                                    {assets?.type === 'Others' &&
                                      assets?.otherAssetType &&
                                      getAssetPriceInput(
                                        'Type',
                                        assets?.otherAssetType,
                                        assets?.type === 'Others' &&
                                          assets?.otherAssetType
                                      )}
                                    {assets?.value &&
                                      getAssetPriceInput(
                                        '$ Value',
                                        formatNumber(assets?.value),
                                        assets?.type === 'Others' &&
                                          assets?.otherAssetType
                                      )}
                                  </View>
                                );
                              }
                            )}
                          </View>
                        )}
                      </View>
                    </View>
                  )}

                  {(owner?.liabilitiesResidence?.mortgage ||
                    owner?.liabilitiesResidence?.value ||
                    !isEveryObjKeyNullRemoveId(owner?.liabilitiesRealEstate) ||
                    !isEveryObjKeyNullAssets(owner?.liabilitiesPrice)) && (
                    <View style={{ ...styles?.flexColumn, gap: '10px' }}>
                      <Text
                        style={{
                          fontWeight: 600,
                          color: '#114684',
                          fontSize: 10,
                        }}
                      >
                        LIABILITIES
                      </Text>
                      <View style={{ ...styles?.flexColumn, gap: '5px' }}>
                        {(owner?.liabilitiesResidence?.mortgage ||
                          owner?.liabilitiesResidence?.value) && (
                          <View style={{ ...styles?.flexRowWrap, gap: '10px' }}>
                            {owner?.liabilitiesResidence?.mortgage &&
                              getPFInput(
                                'Personal residence mortgage',
                                owner?.liabilitiesResidence?.mortgage
                              )}
                            {owner?.liabilitiesResidence?.value &&
                              getFieldInputCommon(
                                '$ Mortgage Value',
                                owner?.liabilitiesResidence?.value
                              )}
                          </View>
                        )}
                        {!isEveryObjKeyNullRemoveId(
                          owner?.liabilitiesRealEstate
                        ) && (
                          <View>
                            {getFilteredArray(
                              owner?.liabilitiesRealEstate
                            )?.map((assets: any) => {
                              return (
                                <View
                                  style={{
                                    ...styles?.flexRowWrap,
                                    gap: '10px',
                                  }}
                                >
                                  {assets?.mortgage &&
                                    getPFInput(
                                      'Other real estate mortgage',
                                      assets?.mortgage
                                    )}
                                  {assets?.value &&
                                    getFieldInputCommon(
                                      '$ Mortgage Value',
                                      assets?.value
                                    )}
                                </View>
                              );
                            })}
                          </View>
                        )}
                        {!isEveryObjKeyNullRemoveId(
                          owner?.liabilitiesPrice
                        ) && (
                          <View>
                            {getFilteredArrayAssets(
                              owner?.liabilitiesPrice
                            )?.map((assets: any) => {
                              return (
                                <View
                                  style={{
                                    ...styles?.flexRowWrap,
                                    gap: '10px',
                                  }}
                                >
                                  {assets?.type &&
                                    getAssetPriceInput(
                                      'Select Dropdown',
                                      assets?.type,
                                      assets?.type === 'Others' &&
                                        assets?.otherLiabilityType
                                    )}
                                  {assets?.type === 'Others' &&
                                    assets?.otherLiabilityType &&
                                    getAssetPriceInput(
                                      'Type',
                                      assets?.otherLiabilityType,
                                      assets?.type === 'Others' &&
                                        assets?.otherLiabilityType
                                    )}
                                  {assets?.value &&
                                    getAssetPriceInput(
                                      '$ Value',
                                      formatNumber(assets?.value),
                                      assets?.type === 'Others' &&
                                        assets?.otherLiabilityType
                                    )}
                                </View>
                              );
                            })}
                          </View>
                        )}
                      </View>
                    </View>
                  )}

                  {!isEveryObjKeyNullRemoveId(owner?.businessInterests) && (
                    <View style={{ ...styles?.flexColumn, gap: '10px' }}>
                      {getFilteredArray(owner?.businessInterests)?.map(
                        (item: any) => {
                          return (
                            <View
                              style={{ ...styles?.flexRowWrap, gap: '8px' }}
                            >
                              {item?.legalName &&
                                getFieldInputCommon(
                                  'Business interests (Legal business name)',
                                  item?.legalName
                                )}
                              {item?.value &&
                                getFieldInputCommon(
                                  '$ Total Value of the Business',
                                  item?.value
                                )}
                              {item?.ownershipPercentage &&
                                getFieldInputCommon(
                                  'Your Ownership %',
                                  item?.ownershipPercentage
                                )}
                            </View>
                          );
                        }
                      )}
                    </View>
                  )}

                  <View style={{ ...styles?.flexColumn, gap: '10px' }}>
                    <View style={{ ...styles?.flexRowWrap, gap: '10px' }}>
                      {owner?.totalAssets &&
                        getFieldInputCommon(
                          '$ Total assets',
                          owner?.totalAssets
                        )}
                      {owner?.totalLiabilities &&
                        getFieldInputCommon(
                          '$ Total liabilities',
                          owner?.totalLiabilities
                        )}
                    </View>
                    {owner?.personalNetWorth && (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          gap: '2px',
                        }}
                        wrap={false}
                      >
                        {getFieldInputCommon(
                          '$ Personal net worth',
                          owner?.personalNetWorth
                        )}
                      </View>
                    )}
                  </View>
                </View>
              )}
            </View>
          );
        })}
    </View>
  );

  const InsurenceLimitProvider = ({
    mainQuestion,
    mainAnswer,
    subAnswer,
  }: {
    mainQuestion: string;
    mainAnswer: boolean | null;
    subAnswer: {
      limit: any;
      provider: any;
      repBrokerage: any;
    };
  }) => {
    return (
      <>
        {typeof mainAnswer === 'boolean' && (
          <View
            style={{
              ...styles?.flexColumn,
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              padding: '8px',
              gap: '4px',
            }}
            wrap={false}
          >
            <View
              style={{
                ...styles?.flexColumn,
                gap: '2px',
              }}
            >
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                {getQueLabel(mainQuestion)}
              </View>
              <Text
                style={{
                  fontWeight: 600,
                  fontSize: 10,
                  marginLeft: '10px',
                }}
              >
                {mainAnswer === true ? 'Yes' : 'No'}
              </Text>
            </View>
            {mainAnswer && (
              <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                <View
                  style={{
                    ...styles?.flexRowWrap,
                    gap: '8px',
                  }}
                >
                  {subAnswer?.limit &&
                    getFieldInputCommon(
                      'What are the limit(s)',
                      subAnswer?.limit ?? <> &nbsp;</>
                    )}
                  {subAnswer.provider &&
                    getFieldInputCommon(
                      'Who is the insurer?',
                      subAnswer.provider ?? <> &nbsp;</>
                    )}
                  {subAnswer.repBrokerage &&
                    getFieldInputCommon(
                      'Who is your representing brokerage?',
                      subAnswer.repBrokerage ?? <> &nbsp;</>
                    )}
                </View>
              </View>
            )}
          </View>
        )}
      </>
    );
  };

  const InsuranceNew = () => {
    return (
      <View break>
        <View
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '15px',
          }}
        >
          <View style={{ width: '100%', ...styles?.flexColumn, gap: '10px' }}>
            <View style={{ width: '100%' }} id="insurancePage">
              <Text style={{ ...styles?.formHeading, color: '#114684' }}>
                {stepperFormLabel && stepperFormLabel?.insurance?.trim() !== ''
                  ? stepperFormLabel?.insurance
                  : 'Insurance'}
              </Text>
            </View>
            <View
              style={{
                ...styles?.flexColumn,
                border: '1px solid #7f7f7f',
                backgroundColor: '#f5f5f5',
                borderRadius: '8px',
                padding: '10px 0',
              }}
            >
              {typeof insurance?.keyManLifeInsurance?.hasKeyManLifeInsurance ===
                'boolean' && (
                <View
                  style={{
                    ...styles?.flexColumn,
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    padding: '8px',
                    gap: '4px',
                  }}
                  wrap={false}
                >
                  <View
                    style={{
                      ...styles?.flexColumn,
                      gap: '2px',
                    }}
                  >
                    <View
                      style={{
                        ...styles?.flexRowNoWrap,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}
                    >
                      <View style={{ ...styles?.dot }}></View>
                      {getQueLabel('Is there Key man life insurance?')}
                    </View>
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: 10,
                        marginLeft: '10px',
                      }}
                    >
                      {insurance?.keyManLifeInsurance
                        ?.hasKeyManLifeInsurance === true
                        ? 'Yes'
                        : 'No'}
                    </Text>
                  </View>
                  {insurance?.keyManLifeInsurance?.hasKeyManLifeInsurance &&
                    !isEveryObjKeyNull(
                      insurance?.keyManLifeInsurance?.details
                    ) && (
                      <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                        {insurance?.keyManLifeInsurance?.details?.length > 0 &&
                          getFilteredArray(
                            insurance?.keyManLifeInsurance?.details
                          )?.map((item: any, i: number) => (
                            <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                              <View
                                style={{
                                  ...styles?.flexRowWrap,
                                  gap: '8px',
                                }}
                              >
                                {item?.keyPerson &&
                                  getFieldInputCommon(
                                    'Key person',
                                    item?.keyPerson ?? <> &nbsp;</>
                                  )}
                                {item?.amount &&
                                  getFieldInputCommon(
                                    '$ Amount',
                                    item.amount ?? <> &nbsp;</>
                                  )}

                                {item?.beneficiary &&
                                  getFieldInputCommon(
                                    'Beneficiary',
                                    item.beneficiary ?? <> &nbsp;</>
                                  )}
                              </View>
                            </View>
                          ))}
                      </View>
                    )}
                </View>
              )}
              <InsurenceLimitProvider
                mainQuestion={
                  'Do you have commercial general liability insurance?'
                }
                mainAnswer={
                  insurance?.commercialGeneralLiabilityInsurance
                    ?.hasCommercialGeneralLiabilityInsurance
                }
                subAnswer={{
                  limit: insurance?.commercialGeneralLiabilityInsurance?.limit,
                  provider:
                    insurance?.commercialGeneralLiabilityInsurance?.provider,
                  repBrokerage:
                    insurance?.commercialGeneralLiabilityInsurance
                      ?.repBrokerage,
                }}
              />
              <InsurenceLimitProvider
                mainQuestion={`Do you have environmental (pollution) Insurance?`}
                mainAnswer={
                  insurance?.environmentalInsurance?.hasEnvironmentalInsurance
                }
                subAnswer={{
                  limit: insurance?.environmentalInsurance?.limit,
                  provider: insurance?.environmentalInsurance?.provider,
                  repBrokerage: insurance?.environmentalInsurance?.repBrokerage,
                }}
              />
              <InsurenceLimitProvider
                mainQuestion={'Do you have Errors & Omissions Insurance?'}
                mainAnswer={
                  insurance?.errorsAndOmissionsInsurance
                    ?.hasErrorsAndOmissionsInsurance
                }
                subAnswer={{
                  limit: insurance?.errorsAndOmissionsInsurance?.limit,
                  provider: insurance?.errorsAndOmissionsInsurance?.provider,
                  repBrokerage:
                    insurance?.errorsAndOmissionsInsurance?.repBrokerage,
                }}
              />
              <InsurenceLimitProvider
                mainQuestion={`Do you have crime (fidelity) insurance?`}
                mainAnswer={insurance?.fidelityInsurance?.hasFidelityInsurance}
                subAnswer={{
                  limit: insurance?.fidelityInsurance?.limit,
                  provider: insurance?.fidelityInsurance?.provider,
                  repBrokerage: insurance?.fidelityInsurance?.repBrokerage,
                }}
              />
              <InsurenceLimitProvider
                mainQuestion={'Do you have equipment insurance?'}
                mainAnswer={
                  insurance?.equipmentInsurance?.hasEquipmentInsurance
                }
                subAnswer={{
                  limit: insurance?.equipmentInsurance?.limit,
                  provider: insurance?.equipmentInsurance?.provider,
                  repBrokerage: insurance?.equipmentInsurance?.repBrokerage,
                }}
              />
              <InsurenceLimitProvider
                mainQuestion={'Do you have cyber Insurance?'}
                mainAnswer={insurance?.cyberInsurance?.hasCyberInsurance}
                subAnswer={{
                  limit: insurance?.cyberInsurance?.limit,
                  provider: insurance?.cyberInsurance?.provider,
                  repBrokerage: insurance?.cyberInsurance?.repBrokerage,
                }}
              />

              {typeof insurance?.otherInsurance?.hasOtherInsurance ===
                'boolean' && (
                <View
                  style={{
                    ...styles?.flexColumn,
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    padding: '8px',
                    gap: '4px',
                  }}
                  wrap={false}
                >
                  <View
                    style={{
                      ...styles?.flexColumn,
                      gap: '2px',
                    }}
                  >
                    <View
                      style={{
                        ...styles?.flexRowNoWrap,
                        justifyContent: 'flex-start',
                        alignItems: 'flex-start',
                      }}
                    >
                      <View style={{ ...styles?.dot }}></View>
                      {getQueLabel('Do you have any other type of insurance?')}
                    </View>
                    <Text
                      style={{
                        fontWeight: 600,
                        fontSize: 10,
                        marginLeft: '10px',
                      }}
                    >
                      {insurance?.otherInsurance?.hasOtherInsurance === true
                        ? 'Yes'
                        : 'No'}
                    </Text>
                  </View>
                  {insurance?.otherInsurance?.hasOtherInsurance &&
                    !isEveryObjKeyNull(insurance?.otherInsurance?.details) && (
                      <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                        {insurance?.otherInsurance?.details?.length > 0 &&
                          getFilteredArray(
                            insurance?.otherInsurance?.details
                          )?.map((item: any, i: number) => (
                            <View
                              key={i}
                              style={{
                                ...styles?.flexRowWrap,
                                gap: '8px',
                              }}
                            >
                              {item?.type &&
                                getFieldInputCommon(
                                  'Please list what type',
                                  item?.type,
                                  'auto',
                                  '23%'
                                )}
                              {item.limit &&
                                getFieldInputCommon(
                                  'What are the limit(s)',
                                  item.limit,
                                  'auto',
                                  '23%'
                                )}
                              {item.provider &&
                                getFieldInputCommon(
                                  'Who is the insurer?',
                                  item.provider,
                                  'auto',
                                  '23%'
                                )}
                              {item.brokerage &&
                                getFieldInputCommon(
                                  'Add your brokerage',
                                  item.brokerage,
                                  'auto',
                                  '23%'
                                )}
                            </View>
                          ))}
                      </View>
                    )}
                </View>
              )}
            </View>
          </View>
        </View>
      </View>
    );
  };

  const SuretyNew = () => (
    <View break>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <View style={{ width: '100%' }} id="suretyPage">
          <Text style={{ ...styles?.formHeading, color: '#114684' }}>
            {stepperFormLabel && stepperFormLabel?.surety?.trim() !== ''
              ? stepperFormLabel?.surety
              : 'Surety'}
          </Text>
        </View>
        <View
          style={{
            ...styles?.flexColumn,
            gap: '5px',
            border: '1px solid #7f7f7f',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            padding: '10px 0',
          }}
        >
          {(surety?.suretyName ||
            surety?.totalWorkOnHand ||
            surety?.singleJob) && (
            <View
              style={{
                ...styles?.flexRowNoWrap,
                justifyContent: 'flex-start',
                alignItems: 'flex-start',
              }}
            >
              <View
                style={{
                  ...styles?.flexRowWrap,
                  gap: '8px',
                  padding: '0 10px',
                }}
              >
                {surety?.suretyName &&
                  getFieldInputCommon(
                    'Name of your current or most recent Surety',
                    surety?.suretyName,
                    'auto'
                  )}

                {surety?.totalWorkOnHand &&
                  getFieldInputCommon(
                    '$ Total work on hand limit',
                    surety?.totalWorkOnHand,
                    'auto'
                  )}

                {surety?.singleJob &&
                  getFieldInputCommon(
                    '$ Single job limit',
                    surety?.singleJob,
                    'auto'
                  )}
              </View>
            </View>
          )}
          {surety?.reasonChangingSurety && (
            <View
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: '1px',
                padding: '0 8px',
              }}
            >
              <View
                style={{
                  ...styles?.flexRowNoWrap,
                  justifyContent: 'flex-start',
                  alignItems: 'flex-start',
                }}
              >
                <View style={{ ...styles?.dot }}></View>
                {getQueLabel('Reason for changing Surety')}
              </View>
              <Text
                style={{
                  fontSize: 11,
                  borderRadius: '5px',
                  border: '1px solid #7f7f7f',
                  padding: '5px 10px',
                }}
              >
                {surety?.reasonChangingSurety}
              </Text>
            </View>
          )}

          {suretyQue(
            `Have you ever been refused a bond?`,
            surety?.refusedBond,
            `Provide details`,
            surety?.refusedBondExplanation
          )}
          {surety?.whoSignSeal?.length > 0 &&
            !isEveryObjKeyNull(surety?.whoSignSeal) && (
              <View
                style={{ ...styles?.flexColumn, gap: '10px', padding: '0 8px' }}
                wrap={false}
              >
                <View
                  style={{
                    ...styles?.flexColumn,
                    backgroundColor: '#f5f5f5',
                    borderRadius: '8px',
                    gap: '4px',
                  }}
                  wrap={false}
                >
                  <View
                    style={{
                      ...styles?.flexRowNoWrap,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <View style={{ ...styles?.dot }}></View>
                    {getQueLabel(
                      `Who is authorized to sign and seal documents on behalf of the company?`
                    )}
                  </View>
                </View>
                {surety?.whoSignSeal && (
                  <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                    {surety?.whoSignSeal?.length > 0 &&
                      getFilteredArray(surety?.whoSignSeal)?.map(
                        (item: any, i: number) => (
                          <View
                            style={{
                              ...styles?.flexRowWrap,
                              gap: '8px',
                            }}
                          >
                            {item?.firstName && (
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  gap: '2px',
                                  width: '32%',
                                }}
                              >
                                <View
                                  style={{
                                    ...styles?.flexRowNoWrap,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <View style={{ ...styles?.dot }}></View>
                                  {getQueLabel('First Name')}
                                </View>
                                <Text
                                  style={{
                                    fontSize: 11,
                                    borderRadius: '5px',
                                    border: '1px solid #7f7f7f',
                                    padding: '5px 10px',
                                  }}
                                >
                                  {item?.firstName}
                                </Text>
                              </View>
                            )}
                            {item?.lastName && (
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  gap: '2px',
                                  width: '32%',
                                }}
                              >
                                <View
                                  style={{
                                    ...styles?.flexRowNoWrap,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <View style={{ ...styles?.dot }}></View>
                                  {getQueLabel('Last Name')}
                                </View>
                                <Text
                                  style={{
                                    fontSize: 11,
                                    borderRadius: '5px',
                                    border: '1px solid #7f7f7f',
                                    padding: '5px 10px',
                                  }}
                                >
                                  {item?.lastName}
                                </Text>
                              </View>
                            )}
                            {item?.position && (
                              <View
                                style={{
                                  display: 'flex',
                                  flexDirection: 'column',
                                  justifyContent: 'space-between',
                                  gap: '2px',
                                  width: '32%',
                                }}
                              >
                                <View
                                  style={{
                                    ...styles?.flexRowNoWrap,
                                    justifyContent: 'flex-start',
                                    alignItems: 'flex-start',
                                  }}
                                >
                                  <View style={{ ...styles?.dot }}></View>
                                  {getQueLabel('Position')}
                                </View>
                                <Text
                                  style={{
                                    fontSize: 11,
                                    borderRadius: '5px',
                                    border: '1px solid #7f7f7f',
                                    padding: '5px 10px',
                                  }}
                                >
                                  {item?.position}
                                </Text>
                              </View>
                            )}
                          </View>
                        )
                      )}
                  </View>
                )}
                {/* {surety?.whoSignSeal?.map((data: any) => {
                return (
                  <>
                    <View style={{ ...styles?.dot }}></View>

                    {getFieldInput('First Name', data?.firstName)}
                    <View style={{ ...styles?.dot }}></View>

                    {getFieldInput('Last Name', data?.lastName)}
                    <View style={{ ...styles?.dot }}></View>

                    {getFieldInput('Position', data?.position)}
                  </>
                );
              })} */}
              </View>
            )}
        </View>
      </View>
    </View>
  );

  const AboutNew = () => (
    <View break>
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '15px',
        }}
      >
        <View style={{ width: '100%' }} id="aboutPage">
          <Text style={{ ...styles?.formHeading, color: '#114684' }}>
            {stepperFormLabel && stepperFormLabel?.about?.trim() !== ''
              ? stepperFormLabel?.about
              : 'About'}
          </Text>
        </View>
        <View
          style={{
            ...styles?.flexColumn,
            gap: '15px',
            border: '1px solid #7f7f7f',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            padding: '10px 5px',
          }}
        >
          <View style={{ ...styles?.flexRowWrap, gap: '10px' }}>
            {/* {cms?.name && (
             <View style={{ ...styles?.dot }}></View>
            getFieldInput('Name', cms?.name))} */}
            {cms?.name && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '2px',
                  width: '32%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('Name')}
                </View>
                <Text
                  style={{
                    fontSize: 11,
                    borderRadius: '5px',
                    border: '1px solid #7f7f7f',
                    padding: '5px 10px',
                  }}
                >
                  {cms?.name}
                </Text>
              </View>
            )}
            {cms?.position && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '2px',
                  width: '32%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('Position')}
                </View>
                <Text
                  style={{
                    fontSize: 11,
                    borderRadius: '5px',
                    border: '1px solid #7f7f7f',
                    padding: '5px 10px',
                  }}
                >
                  {cms?.position}
                </Text>
              </View>
            )}
            {cms?.hiringDate && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '2px',
                  width: '32%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('Hiring Date')}
                </View>
                <Text
                  style={{
                    fontSize: 11,
                    borderRadius: '5px',
                    border: '1px solid #7f7f7f',
                    padding: '5px 10px',
                  }}
                >
                  {moment(cms?.hiringDate).format('MM/DD/YYYY')}
                </Text>
              </View>
            )}

            {cms?.geoArea && (
              <View
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  gap: '2px',
                  width: '48%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('In which geographic area do you work?')}
                </View>
                <Text
                  style={{
                    fontSize: 11,
                    borderRadius: '5px',
                    border: '1px solid #7f7f7f',
                    padding: '5px 10px',
                  }}
                >
                  {cms?.geoArea}
                </Text>
              </View>
            )}
            {cms?.workOutsideDetails && cms?.workOutsideDetails !== null && (
              <View
                style={{
                  ...styles?.flexColumn,
                  gap: '2px',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('Do you ever work outside it?')}
                </View>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 11,
                    marginLeft: '10px',
                  }}
                >
                  {cms?.workOutsideDetails?.workOutside
                    ? cms?.workOutsideDetails?.workOutside === true
                      ? 'Yes'
                      : 'No'
                    : 'No'}
                </Text>
              </View>
            )}
            {cms?.workOutsideDetails?.workOutside && (
              <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                {cms?.workOutsideDetails?.workOutside && (
                  <View
                    style={{
                      ...styles?.flexRowWrap,
                      gap: '8px',
                    }}
                  >
                    {cms?.workOutsideDetails?.where && (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          gap: '2px',
                          width: '49%',
                        }}
                      >
                        <View
                          style={{
                            ...styles?.flexRowNoWrap,
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                          }}
                        >
                          <View style={{ ...styles?.dot }}></View>
                          {getQueLabel('Where?')}
                        </View>
                        <Text
                          style={{
                            fontSize: 11,
                            borderRadius: '5px',
                            border: '1px solid #7f7f7f',
                            padding: '5px 10px',
                          }}
                        >
                          {cms?.workOutsideDetails?.where}
                        </Text>
                      </View>
                    )}
                    {cms?.workOutsideDetails?.howOften && (
                      <View
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          justifyContent: 'space-between',
                          gap: '2px',
                          width: '49%',
                        }}
                      >
                        <View
                          style={{
                            ...styles?.flexRowNoWrap,
                            justifyContent: 'flex-start',
                            alignItems: 'flex-start',
                          }}
                        >
                          <View style={{ ...styles?.dot }}></View>
                          {getQueLabel('How often?')}
                        </View>
                        <Text
                          style={{
                            fontSize: 11,
                            borderRadius: '5px',
                            border: '1px solid #7f7f7f',
                            padding: '5px 10px',
                          }}
                        >
                          {cms?.workOutsideDetails?.howOften}
                        </Text>
                      </View>
                    )}
                  </View>
                )}
              </View>
            )}
            {cms?.fixedContract && cms?.fixedContract?.length > 0 && (
              <View
                style={{
                  ...styles?.flexColumn,
                  gap: '2px',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('What type of contractor are you?')}
                </View>

                {cms.contractors?.some(
                  (item: any) => item.type === 'Others'
                ) && (
                  <View
                    style={{
                      ...styles?.flexRowWrap,
                      gap: '8px',
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '2px',
                        width: '100%',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 11,
                          borderRadius: '5px',
                          border: '1px solid #7f7f7f',
                          padding: '5px 10px',
                        }}
                      >
                        other
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            )}
            {cms?.fixedContract?.length > 0 && (
              <InvoiceItemsTable invoice={cms?.fixedContract} />
            )}
            {cms?.largestWorkAmount &&
              getFieldInputCommon(
                '$ What was the largest $ amount of work on hand at any one time in the past 4 years? ',
                cms?.largestWorkAmount,
                'auto'
              )}
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: '2px',
              }}
              wrap={false}
            >
              {getFieldInputCommon(
                'How many contracts did it consist of?',
                cms?.numberOfContracts,
                'auto'
              )}

              {getFieldInputCommon(
                'What % of work is usually sublet to others?',
                cms?.workSubletPercentage,
                'auto'
              )}
            </View>
            {getFieldInputCommon(
              'What type of work is usually undertaken with own forces?',
              cms?.workUndertakenOwnForces,
              'auto'
            )}
            {cms?.managementControl && cms?.managementControl?.length > 0 && (
              <View
                style={{
                  ...styles?.flexColumn,
                  gap: '2px',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel(
                    'To what extent does management control and supervise individual jobs?'
                  )}
                </View>

                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 11,
                    marginLeft: '10px',
                  }}
                >
                  {/* {cms.managementControl
                    .filter((item: any) =>
                      ['Daily', 'Weekly', 'Monthly'].includes(item.jobType)
                    )
                    .map((item: any, i: number) => `a. ${item.jobType} `)}

                  {cms.managementControl
                    .filter(
                      (item: any) =>
                        !['Daily', 'Weekly', 'Monthly'].includes(item.jobType)
                    )
                    .map((item: any, i: number) => `b. ${item.jobType} `)} */}
                  {cms?.managementControl?.map((item: any, index: number) => {
                    if (cms?.managementControl?.length - 1 === index) {
                      return item.jobType;
                    } else {
                      return item.jobType + ', ';
                    }
                  })}
                </Text>
              </View>
            )}
            {cms.managementControl?.some(
              (item: any) => item.jobType === 'Other'
            ) && (
              <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                {
                  <View
                    style={{
                      ...styles?.flexRowWrap,
                      gap: '8px',
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '2px',
                        width: '100%',
                      }}
                    >
                      {cms?.managementControl.map((item: any, i: number) =>
                        item.otherJobType ? (
                          <Text
                            key={i}
                            style={{
                              fontSize: 11,
                              borderRadius: '5px',
                              border: '1px solid #7f7f7f',
                              padding: '5px 10px',
                            }}
                          >
                            {item.otherJobType}
                          </Text>
                        ) : null
                      )}
                    </View>
                  </View>
                }
              </View>
            )}
            {cms?.workSublet && cms?.workSublet?.length > 0 && (
              <View
                style={{
                  ...styles?.flexColumn,
                  gap: '2px',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('What type of work is usually sublet?')}
                </View>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 11,
                    marginLeft: '10px',
                  }}
                >
                  {cms?.workSublet?.length > 0 &&
                    cms?.workSublet?.map((item: any, i: number) => {
                      if (cms?.workSublet?.length - 1 === i) {
                        return `${item.type}`;
                      } else {
                        return `${item.type}, `;
                      }
                    })}
                </Text>
              </View>
            )}
            {cms.workSublet?.some((item: any) => item.type === 'Others') && (
              <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                {
                  <View
                    style={{
                      ...styles?.flexRowWrap,
                      gap: '8px',
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '2px',
                        width: '100%',
                      }}
                    >
                      {cms?.workSublet.map((item: any, i: number) =>
                        item.otherSublet ? (
                          <Text
                            key={i}
                            style={{
                              fontSize: 11,
                              borderRadius: '5px',
                              border: '1px solid #7f7f7f',
                              padding: '5px 10px',
                            }}
                          >
                            {item.otherSublet}
                          </Text>
                        ) : null
                      )}
                    </View>
                  </View>
                }
              </View>
            )}
            {cms?.totalWorkProgramOneTime &&
              getFieldInputCommon(
                `$ What is the total value of projects ($) that your organization can manage simultaneously?`,
                cms?.totalWorkProgramOneTime,
                'auto'
              )}
            {cms?.totalWorkProgram12Months &&
              getFieldInputCommon(
                `$ What is the total value of projects ($) that your organization can manage over the next 12 months?`,
                cms?.totalWorkProgram12Months,
                'auto'
              )}
            {cms?.obtainBondsFromSubcontractors && (
              <View
                style={{
                  ...styles?.flexColumn,
                  backgroundColor: '#f5f5f5',
                  borderRadius: '8px',
                  gap: '4px',
                  width: '100%',
                }}
                wrap={false}
              >
                <View
                  style={{
                    ...styles?.flexColumn,
                    gap: '2px',
                    paddingBottom: '5px',
                  }}
                >
                  <View
                    style={{
                      ...styles?.flexRowNoWrap,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <View style={{ ...styles?.dot }}></View>
                    {getQueLabel(
                      'Do you obtain bonds from some of your subcontractors?'
                    )}
                  </View>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 11,
                      marginLeft: '10px',
                    }}
                  >
                    {cms?.obtainBondsFromSubcontractors?.hasBonds
                      ? cms?.obtainBondsFromSubcontractors?.hasBonds === true
                        ? 'Yes'
                        : 'No'
                      : 'No'}
                  </Text>
                </View>
                {cms?.obtainBondsFromSubcontractors?.hasBonds && (
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '4px',
                    }}
                    wrap={false}
                  >
                    <View
                      style={{
                        ...styles?.flexRowNoWrap,
                      }}
                    >
                      <View style={{ ...styles?.dot }}></View>
                      {getByLabelText(
                        'What is the minimum contract $ value that you require a bond from a subcontractor?'
                      )}
                    </View>
                    <Text
                      style={{
                        fontSize: 10,
                        borderRadius: '5px',
                        border: '1px solid #7f7f7f',
                        padding: '5px 10px',
                      }}
                    >
                      {/* {'What is the minimum contract $ value that you require a bond from a subcontractor?'.includes(
                        '%'
                      )
                        ? formatValue(
                            cms?.obtainBondsFromSubcontractors?.minimumContract
                          )
                        : 'What is the minimum contract $ value that you require a bond from a subcontractor?'.includes(
                              '$'
                            )
                          ? formatNumber(
                              cms?.obtainBondsFromSubcontractors
                                ?.minimumContract
                            )
                          : cms?.obtainBondsFromSubcontractors?.minimumContract} */}
                      {cms?.obtainBondsFromSubcontractors?.minimumContract}
                    </Text>
                  </View>
                )}
              </View>
            )}
            {cms?.salesPercentageByOwnerType &&
              cms?.salesPercentageByOwnerType?.length > 0 && (
                <View
                  style={{
                    ...styles?.flexColumn,
                    gap: '2px',
                    width: '100%',
                  }}
                >
                  <View
                    style={{
                      ...styles?.flexRowNoWrap,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <View style={{ ...styles?.dot }}></View>
                    {getQueLabel(
                      'List the approximate % of your annual sales for each type of owner for whom you perform work for'
                    )}
                  </View>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 10,
                      marginLeft: '10px',
                    }}
                  >
                    {cms?.salesPercentageByOwnerType?.length > 0 &&
                      cms?.salesPercentageByOwnerType?.map(
                        (item: any, i: number) => {
                          if (item?.otherOwnerType) {
                            return `${item.otherOwnerType}(${item.salesPercentage}%)`;
                          } else {
                            return `${item.ownerType}(${item.salesPercentage}%), `;
                          }
                        }
                      )}
                  </Text>
                </View>
              )}
            {/* {cms.salesPercentageByOwnerType?.some(
              (item: any) => item.ownerType === 'Others'
            ) && (
              <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                {
                  <View
                    style={{
                      ...styles?.flexRowWrap,
                      gap: '8px',
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '2px',
                        width: '100%',
                      }}
                    >
                      {cms?.salesPercentageByOwnerType.map(
                        (item: any, i: number) =>
                          item.otherOwnerType ? (
                            <Text
                              key={i}
                              style={{
                                fontSize: 11,
                                borderRadius: '5px',
                                border: '1px solid #7f7f7f',
                                padding: '5px 10px',
                              }}
                            >
                              {formatNumber(item.otherOwnerType)}
                            </Text>
                          ) : null
                      )}
                    </View>
                  </View>
                }
              </View>
            )} */}

            {cms?.workOutsideCanada && (
              <View
                style={{
                  ...styles?.flexColumn,
                  backgroundColor: '#f5f5f5',
                  borderRadius: '8px',

                  gap: '4px',
                }}
                wrap={false}
              >
                <View
                  style={{
                    ...styles?.flexColumn,
                    gap: '2px',
                    paddingBottom: '5px',
                  }}
                >
                  <View
                    style={{
                      ...styles?.flexRowNoWrap,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <View style={{ ...styles?.dot }}></View>
                    {getQueLabel('Do you perform any work outside of Canada?')}
                  </View>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 11,
                      marginLeft: '10px',
                    }}
                  >
                    {cms?.workOutsideCanada?.performWorkOutside
                      ? cms?.workOutsideCanada?.performWorkOutside === true
                        ? 'Yes'
                        : 'No'
                      : 'No'}
                  </Text>
                </View>
                {cms?.workOutsideCanada?.performWorkOutside && (
                  <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                    {cms?.workOutsideCanada?.annualSalesPercentage?.length >
                      0 &&
                      cms?.workOutsideCanada?.annualSalesPercentage?.map(
                        (item: any, i: number) => (
                          <View
                            style={{
                              ...styles?.flexRowWrap,
                              gap: '8px',
                            }}
                          >
                            <View
                              style={{
                                display: 'flex',
                                flexDirection: 'row',
                                width: '100%',
                                gap: '2px',
                              }}
                              wrap={false}
                            >
                              {getFieldInputCommon(
                                'List the country(s) you perform work in',
                                item?.country,
                                'auto'
                              )}

                              {getFieldInputCommon(
                                'What % of your annual sales account for each country (exclude Canada)?',
                                item?.percentage,
                                'auto'
                              )}
                            </View>
                          </View>
                        )
                      )}
                  </View>
                )}
              </View>
            )}
            {cms.employeesUnionized !== null && (
              <View
                style={{
                  ...styles?.flexColumn,
                  gap: '2px',
                  width: '49%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('Are your employees unionized?')}
                </View>
                <Text
                  style={{ fontWeight: 600, fontSize: 11, marginLeft: '10px' }}
                >
                  {cms?.employeesUnionized
                    ? cms?.employeesUnionized === true
                      ? 'Yes'
                      : 'No'
                    : 'No'}
                </Text>
              </View>
            )}
            {cms.payUnionScaleWages !== null && (
              <View
                style={{
                  ...styles?.flexColumn,
                  gap: '2px',
                  width: '49%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('Do you pay union scale wages?')}
                </View>
                <Text
                  style={{ fontWeight: 600, fontSize: 11, marginLeft: '10px' }}
                >
                  {cms?.payUnionScaleWages
                    ? cms?.payUnionScaleWages === true
                      ? 'Yes'
                      : 'No'
                    : 'No'}
                </Text>
              </View>
            )}
          </View>
        </View>
        <View
          break
          style={{
            ...styles?.flexColumn,
            gap: '15px',
            border: '1px solid #7f7f7f',
            backgroundColor: '#f5f5f5',
            borderRadius: '8px',
            padding: '10px 5px',
          }}
        >
          <View style={{ width: '100%' }}>
            <Text style={{ ...styles?.formHeading, color: '#114684' }}>
              Bond Request - Project Details
            </Text>
          </View>
          <View style={{ ...styles?.flexRowWrap, gap: '10px' }}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                gap: '8px',
              }}
              wrap={false}
            >
              {getFieldInputCommon('Name of owner', cms?.ownerName, 'auto')}

              {getFieldInputCommon(
                '$ Contract price ',
                Number(cms?.contractPrice) ?? cms?.contractPrice,
                'auto'
              )}
            </View>
            {cms?.typeOfProjectOwner && (
              <View
                style={{
                  ...styles?.flexColumn,
                  gap: '2px',
                  width: '100%',
                }}
              >
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    justifyContent: 'flex-start',
                    alignItems: 'flex-start',
                  }}
                >
                  <View style={{ ...styles?.dot }}></View>
                  {getQueLabel('Type of owner for this project')}
                </View>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 11,
                    marginLeft: '10px',
                  }}
                >
                  {cms?.typeOfProjectOwner?.type}
                </Text>
              </View>
            )}
            {cms?.typeOfProjectOwner?.otherType !== null && (
              <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                {cms?.typeOfProjectOwner?.otherType && (
                  <View
                    style={{
                      ...styles?.flexRowWrap,
                      gap: '8px',
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '2px',
                        width: '100%',
                      }}
                    >
                      <Text
                        style={{
                          fontSize: 11,
                          borderRadius: '5px',
                          border: '1px solid #7f7f7f',
                          padding: '5px 10px',
                        }}
                      >
                        {cms?.typeOfProjectOwner?.otherType}
                      </Text>
                    </View>
                  </View>
                )}
              </View>
            )}
            {cms?.typeOfWork &&
              cms?.typeOfWork !== null &&
              cms?.typeOfWork?.length > 0 && (
                <View
                  style={{
                    ...styles?.flexColumn,
                    gap: '2px',
                    width: '100%',
                  }}
                >
                  <View
                    style={{
                      ...styles?.flexRowNoWrap,
                      justifyContent: 'flex-start',
                      alignItems: 'flex-start',
                    }}
                  >
                    <View style={{ ...styles?.dot }}></View>
                    {getQueLabel('Type of work')}
                  </View>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 11,
                      marginLeft: '10px',
                    }}
                  >
                    {cms?.typeOfWork?.length > 0 &&
                      cms?.typeOfWork.map((item: any, i: number) => {
                        if (cms?.typeOfWork?.length - 1 === i) {
                          return `${item.type}`;
                        } else {
                          return `${item.type}, `;
                        }
                      })}
                  </Text>
                </View>
              )}
            {cms?.typeOfWork?.some((item: any) => item.type === 'Others') && (
              <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                {
                  <View
                    style={{
                      ...styles?.flexRowWrap,
                      gap: '8px',
                    }}
                  >
                    <View
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        gap: '2px',
                        width: '100%',
                      }}
                    >
                      {cms?.typeOfWork.map((item: any, i: number) =>
                        item.otherType && item.otherType !== null ? (
                          <Text
                            key={i}
                            style={{
                              fontSize: 11,
                              borderRadius: '5px',
                              border: '1px solid #7f7f7f',
                              padding: '5px 10px',
                            }}
                          >
                            {item.otherType}
                          </Text>
                        ) : null
                      )}
                    </View>
                  </View>
                }
              </View>
            )}
            {cms?.estimatedSubletBudget &&
              getFieldInputCommon(
                '$ What is your estimated sublet budget for this project? ',
                Number(cms?.estimatedSubletBudget) ??
                  cms?.estimatedSubletBudget,
                'auto'
              )}
          </View>
        </View>

        {cms?.keyPersonnel &&
        cms.keyPersonnel.length > 0 &&
        cms.keyPersonnel.filter(
          (person: any) => person.name || person.position || person.hiringDate
        ).length > 0 ? (
          <View
            break
            style={{
              ...styles?.flexColumn,
              gap: '15px',
              border: '1px solid #7f7f7f',
              backgroundColor: '#f5f5f5',
              borderRadius: '8px',
              padding: '10px 5px',
            }}
          >
            <View style={{ width: '100%' }}>
              <Text style={{ ...styles?.formHeading, color: '#114684' }}>
                Key personnel
              </Text>
              <View style={{ ...styles?.flexColumn, gap: '4px' }}>
                {cms?.keyPersonnel?.map((item: any, i: number) => (
                  <View
                    key={i}
                    style={{
                      ...styles?.flexRowWrap,
                      gap: '8px',
                    }}
                  >
                    {item?.name && getFieldInputCommon('Name', item?.name)}
                    {item?.position &&
                      getFieldInputCommon('Position', item?.position)}
                    {item?.hiringDate &&
                      getFieldInputCommon(
                        'Hiring date',
                        moment(item?.hiringDate).format('MM/DD/YYYY')
                      )}
                  </View>
                ))}
              </View>
            </View>
          </View>
        ) : null}
      </View>
    </View>
  );

  const getIndex = () => {
    return isShowPFForm() ? 4 : 3;
  };

  const toRoman = (num: number): string => {
    const romanNumeralMap: [string, number][] = [
      ['M', 1000],
      ['CM', 900],
      ['D', 500],
      ['CD', 400],
      ['C', 100],
      ['XC', 90],
      ['L', 50],
      ['XL', 40],
      ['X', 10],
      ['IX', 9],
      ['V', 5],
      ['IV', 4],
      ['I', 1],
    ];
    let result = '';
    for (const [roman, n] of romanNumeralMap) {
      while (num >= n) {
        result += roman;
        num -= n;
      }
    }
    return result;
  };

  const TOC = () => (
    <View break>
      <Text style={{ ...styles?.formHeading }}>Table of Contents</Text>
      <View
        style={{
          ...styles?.flexColumn,
          gap: '8px',
          marginTop: 40,
          marginLeft: 10,
        }}
      >
        <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
          <Text>1.</Text>
          <Link
            src="#generalBankingPage"
            style={{ textDecoration: 'none', fontSize: 12, color: '#114684' }}
          >
            {stepperFormLabel && stepperFormLabel?.generalBanking?.trim() !== ''
              ? stepperFormLabel?.generalBanking
              : 'General & Banking'}
          </Link>
        </View>
        <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
          <Text>2.</Text>
          <Link
            src="#legalPage"
            style={{ textDecoration: 'none', fontSize: 12, color: '#114684' }}
          >
            {stepperFormLabel && stepperFormLabel?.legal?.trim() !== ''
              ? stepperFormLabel?.legal
              : 'Legal'}
          </Link>
        </View>
        {isShowPFForm() && (
          <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
            <Text>3.</Text>
            <Link
              src={`#personalFinancialPage-${1}`}
              style={{ textDecoration: 'none', fontSize: 12, color: '#114684' }}
            >
              {stepperFormLabel &&
              stepperFormLabel?.personalFinancial?.trim() !== ''
                ? stepperFormLabel?.personalFinancial
                : 'Personal Financial'}
            </Link>
          </View>
        )}
        {isShowPFForm() &&
          financialInfo?.ownersFinancialInfo?.map((owner: any, i: number) => {
            if (
              owner?.ownerInfo?.nameHoldco ||
              owner?.ownerInfo?.dateOfBirth ||
              owner?.ownerInfo?.position ||
              owner?.ownerInfo?.inPositionSince ||
              owner?.ownerInfo?.ownershipPercentage ||
              isPFSection(owner)
            ) {
              return (
                <View
                  style={{
                    ...styles?.flexRowNoWrap,
                    gap: '2px',
                    paddingLeft: 10,
                  }}
                  key={i}
                >
                  {/* <Text>{`${i + 1})`}</Text> */}
                  <Text>{`${toRoman(i + 1)})`}</Text>
                  <Link
                    src={`#personalFinancialPage-${i + 1}`}
                    style={{
                      textDecoration: 'none',
                      fontSize: 11,
                      color: '#114684',
                    }}
                  >
                    {owner?.ownerInfo?.nameHoldco
                      ? owner?.ownerInfo?.nameHoldco
                      : `Owner ${i + 1}`}
                  </Link>
                </View>
              );
            }
          })}
        <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
          <Text>{getIndex()}.</Text>
          <Link
            src="#aboutPage"
            style={{ textDecoration: 'none', fontSize: 12, color: '#114684' }}
          >
            {stepperFormLabel && stepperFormLabel?.about?.trim() !== ''
              ? stepperFormLabel?.about
              : 'About'}
          </Link>
        </View>
        {isShowSurety() && (
          <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
            <Text>{getIndex() + 1}.</Text>
            <Link
              src="#suretyPage"
              style={{ textDecoration: 'none', fontSize: 12, color: '#114684' }}
            >
              {stepperFormLabel && stepperFormLabel?.surety?.trim() !== ''
                ? stepperFormLabel?.surety
                : 'Surety'}
            </Link>
          </View>
        )}
        {isShowInsurance() && (
          <View style={{ ...styles?.flexRowNoWrap, gap: '2px' }}>
            <Text>{getIndex() + (isShowSurety() ? 2 : 1)}.</Text>
            <Link
              src="#insurancePage"
              style={{ textDecoration: 'none', fontSize: 12, color: '#114684' }}
            >
              {stepperFormLabel && stepperFormLabel?.insurance?.trim() !== ''
                ? stepperFormLabel?.insurance
                : 'Insurance'}
            </Link>
          </View>
        )}
      </View>
    </View>
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PDFHeader />
        {isDisplayCompany ? (
          <CompanyName />
        ) : (
          <View>
            <PDFHeading />
            <CompanyDetails />
            <SubTitle />
            <Address />
          </View>
        )}
        <TOC />
        <AccountingSystemNew />
        <CreditBureauNew />
        {isShowPFForm() && <PresonalFinancial />}
        <AboutNew />
        {(surety?.suretyName ||
          surety?.totalWorkOnHand ||
          surety?.singleJob ||
          surety?.reasonChangingSurety ||
          typeof surety?.refusedBond === 'boolean' ||
          !isEveryObjKeyNull(surety?.whoSignSeal)) && <SuretyNew />}
        {(typeof insurance?.keyManLifeInsurance?.hasKeyManLifeInsurance ===
          'boolean' ||
          typeof insurance?.commercialGeneralLiabilityInsurance
            ?.hasCommercialGeneralLiabilityInsurance === 'boolean' ||
          typeof insurance?.environmentalInsurance
            ?.hasEnvironmentalInsurance === 'boolean' ||
          typeof insurance?.errorsAndOmissionsInsurance
            ?.hasErrorsAndOmissionsInsurance === 'boolean' ||
          typeof insurance?.fidelityInsurance?.hasFidelityInsurance ===
            'boolean' ||
          typeof insurance?.equipmentInsurance?.hasEquipmentInsurance ===
            'boolean' ||
          typeof insurance?.otherInsurance?.hasOtherInsurance ===
            'boolean') && <InsuranceNew />}

        {/* <UserAddress /> */}
        {/* <TableHead />
        <TableBody />
        <TableTotal /> */}
        <PDFFooter />
      </Page>
    </Document>
  );
};

export default ApplicationPDF;
