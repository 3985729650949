import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  Flex,
  Input,
  Spinner,
  Stack,
  Text,
  Textarea,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import {
  Accordion,
  ActionIcon,
  Tooltip as MantineTooltip,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useFormik } from 'formik';
import { debounce } from 'lodash';
import {
  MRT_Cell,
  MRT_Column,
  MRT_ColumnDef,
  MRT_Row,
  MRT_TableInstance,
} from 'mantine-react-table';
import { DatePickerInput } from 'rc-datepicker';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { HiMiniInformationCircle } from 'react-icons/hi2';
import { LuRefreshCcw } from 'react-icons/lu';
import { MdUploadFile } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  getApplicationById,
  refreshDuns,
  removeType,
} from 'src/Redux/Applications/slice';
import {
  hasBankruptcyHistoryTableFields,
  liensFiledAgainstCompanyTableFields,
  outstandingJudgementsTableFields,
  outstandingSuitsTableFields,
  stateFederalTaxTableFields,
} from 'src/data/LegalForm/tableColumns';
import { useAppToast, useS3FileUpload } from 'src/hooks';
import {
  Creditbureau,
  creditBureauUpdate,
  RescindFromApplicant,
} from '../../../Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { getApplicantHistory } from '../../../Redux/Broker/Dashboard/History';
import { RootState, useAppSelector } from '../../../Redux/Store';
import { CreditBureauSchema } from '../../../ValidationSchema/Pages/Index';
import {
  deepObjectComparison,
  formatDate,
  getS3FileInfo,
  getValue,
  handleMinMaxNumberInput,
  removeFields,
} from '../../../utils/helpers';
import AccordianTable from './Component/LegalForm/AccordianTable';
import LegalFormAccordian from './Component/LegalForm/LegalFormAccordian';
import LegalFormCourtFilingUpload from './Component/LegalForm/LegalFormCourtFilingUpload';
import LegalFormEditableTable from './Component/LegalForm/LegalFormEditableTable';
import { IsSubmited } from './Stepper';

const LegalForm = forwardRef<any, any>(
  (
    {
      isSubmited,
      setIsSubmited,
      formData,
      accountingSystem,
      setActiveStep,
    }: {
      formData: TLegalAPIData;
      isSubmited: IsSubmited;
      setIsSubmited: (pros: IsSubmited) => void;
      accountingSystem: TAccountingSystem;
      setActiveStep: React.Dispatch<React.SetStateAction<number>>;
    },
    ref
  ) => {
    console.log('LegalForm-formData', formData);
    console.log('render>>>');

    // const [accField, setAccField] = useState<string[]>([
    //   'Bankruptcy',
    //   'Insolvency',
    //   'Liquidation',
    // ]);

    const [activeAcc, setActiveAcc] = useState<{
      outstandingJudgments: string | null;
      hasBankruptcyHistory: string | null;
    }>({
      outstandingJudgments: null,
      hasBankruptcyHistory: null,
    });

    const Toast = useToast();
    const dispatch = useDispatch();
    const {
      S3UploadError,
      S3UploadLoading,
      S3UploadResponse,
      uploadbulkFilesInS3,
    } = useS3FileUpload();
    const [isCourtFilingUpload, { open, close }] = useDisclosure(false);
    const guaranteesForOtherPartiesRef = useRef<DatePickerInput>(null);
    const changeInControlRef = useRef<DatePickerInput>(null);
    const changeInOwnershipRef = useRef<DatePickerInput>(null);
    const changeInManagementRef = useRef<DatePickerInput>(null);
    // const liensFiledAgainstCompanyDateRef = useRef<DatePickerInput>(null);
    // const outstandingJudgmentsRef = useRef<DatePickerInput>(null);
    const bondClaimsRef = useRef<DatePickerInput>(null);
    // const liensFiledByCompanyRef = useRef<DatePickerInput>(null);
    const buySellAgreementRef = useRef<DatePickerInput>(null);
    const [selectedEditRow, setSelectedEditRow] = useState<{
      name: string | null;
      value:
        | {
            linkId: number;
            link: string | null;
            urlKey: string | null;
          }[]
        | null;
      uploadState: string | null;
    }>({ name: null, value: null, uploadState: null });

    // const [showTextarea, setShowTextarea] = useState({
    //   hasHistory: false,
    //   hasGuarantees: false,
    //   hasRelatedCompanies: false,
    //   hasChanges: false,
    //   hasLiens: false,
    //   hasLiensFiledByCompany: false,
    //   hasOutstandingJudgments: false,
    //   hasDisputes: false,
    //   hasDisputesWithSuppliers: false,
    // });

    const location = useLocation();
    const { showBox, showHistory, applicationStatus, applicantId } =
      location.state || {};
    const [historyIndex, setHistoryIndex] = useState(0);

    const [isReturnToSender, setIsReturnToSender] = useState(false);
    const [loader, setLoader] = useState<{
      btnLoader: boolean;
      refreshDunsLoader: boolean;
    }>({
      btnLoader: false,
      refreshDunsLoader: false,
    });
    const [rescindFromApplicant, setRescindFromApplicant] =
      useState<boolean>(false);
    const navigate = useNavigate();
    const paramsData = useParams<{ id: string }>();
    const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;
    ///user
    // const userData = useSelector(
    //   (state: RootState) => state.createApplicationFormSlice.user
    // );
    const HistoryData: any = useSelector(
      (state: RootState) => state.history.user
    );
    const createApplicationForm = useAppSelector(
      (state: RootState) => state.createApplicationFormSlice
    );
    const appliactionsSlice = useAppSelector(
      (state) => state.appliactionsSlice
    );
    const toast = useAppToast();
    const currentHistoryData = HistoryData?.data[historyIndex]?.details;

    // let userGetidData = userData && userData.data;
    // let brokerid = userGetidData && userGetidData.brokerId;
    // let applicationId = userGetidData && userGetidData.applicationId;

    //ongoing
    // const data: any = useSelector(
    //   (state: RootState) => state.brokerageDashboard.user
    // );

    // let creditBureauFormData = data && data?.data?.creditBureau;
    const AccoutingFormData = accountingSystem;
    // let ongoingapplicationId =
    //   data && data?.data?.accountingSystem?.applicationId;
    // let ongoingbrokerId = data && data?.data?.accountingSystem?.brokerId;

    //applicant fetch
    // const applicantFetch = useSelector(
    //   (state: RootState) => state.notification.user
    // );
    // const applicantFetchData =
    //   applicantFetch && applicantFetch?.data?.applications;
    // const applicantObject = applicantFetchData?.find(
    //   (applicant: any) => applicant.applicationId === ongoingID
    // );

    // const qboState = useAppSelector((state: RootState) => state.qbo);

    const userType = localStorage.getItem('userType');
    const isApplicantUser = userType === 'applicant';
    const applicationId = formData?.applicationId;
    const brokerId = formData?.brokerId;

    // useEffect(() => {
    //   if (ongoingID) {
    //     fetchOngoingApplicant();
    //   }
    //   if (!showHistory) {
    //     fetchDashboardData();
    //   }
    // }, [dispatch, ongoingID]);

    // const fetchOngoingApplicant = async () => {
    //   if (!!localStorage.getItem('Token')) {
    //     let id = !showHistory && ongoingID ? ongoingID : 0;
    //     id = showHistory && applicantId ? applicantId : id;
    //     let actionResult = await dispatch(OngoingApplicant({ id: id }) as any);
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    // const fetchDashboardData = async () => {
    //   if (!!localStorage.getItem('Token')) {
    //     let actionResult = await dispatch(
    //       fetchDashboardDetails({ applicationFilter: '' }) as any
    //     );
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    useEffect(() => {
      const fetchData = async () => {
        if (!!localStorage.getItem('Token')) {
          if (ongoingID && showHistory) {
            let actionResult = await dispatch(
              getApplicantHistory({
                applicationId: ongoingID,
                modelName: 'Credit Bureau',
              }) as any
            );
            if (actionResult?.payload?.status === 401) {
              navigate('/login');
            }
          }
        } else {
          navigate('/login');
        }
      };
      fetchData();
    }, []);

    useEffect(() => {
      const { status, type, dunsData, error } = appliactionsSlice;
      console.log('type', type);

      switch (status) {
        case 'loading':
          if (type === 'APPLICATION_REFRESH_DUNS') {
            setLoader({ ...loader, refreshDunsLoader: true });
          }
          break;
        case 'succeed':
          if (type === 'APPLICATION_REFRESH_DUNS') {
            setLoader({ ...loader, refreshDunsLoader: false });
            fetchOngoingApplication();
            toast({
              status: 'success',
              title: dunsData?.msg,
            });
          }
          break;
        case 'failed':
          if (type === 'APPLICATION_REFRESH_DUNS') {
            setLoader({ ...loader, refreshDunsLoader: false });
            toast({
              status: 'error',
              title: error,
            });
          }
          break;

        default:
          break;
      }

      return () => {
        dispatch(removeType({}) as any);
      };
    }, [appliactionsSlice.status]);

    useEffect(() => {
      const { status, type, error, user } = createApplicationForm;

      switch (status) {
        case 'loading': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: true });
          }
          break;
        }
        case 'succeed': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: false });
            setRescindFromApplicant(false);
            toast({
              status: 'success',
              title: user?.data,
            });
            fetchOngoingApplication();
          }
          break;
        }
        case 'failed': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: false });
            setRescindFromApplicant(false);
            toast({
              status: 'error',
              title: error,
            });
          }
          break;
        }

        default:
          break;
      }
    }, [createApplicationForm.status]);

    // Function to ensure each object in the array has the required keys (compare with comparison object)
    // const ensureKeys = (array: any, comparison: any) => {
    //   return array.map((item: any) => {
    //     // Create a new object that copies all properties from the existing object
    //     let newItem = { ...item };

    //     // Iterate over each key in the comparison object
    //     for (const key in comparison) {
    //       // If the key doesn't exist in the newItem, add it with the value from comparison
    //       if (!(key in newItem)) {
    //         newItem[key] = comparison[key];
    //       } else if (
    //         typeof newItem[key] === 'object' &&
    //         newItem[key] !== null &&
    //         typeof comparison[key] === 'object' &&
    //         comparison[key] !== null
    //       ) {
    //         // If the key exists and both the newItem and comparison key values are objects, recurse
    //         newItem[key] = ensureKeys([newItem[key]], comparison[key])[0];
    //       }
    //     }
    //     return newItem;
    //   });
    // };

    // const hasLiensFiledAgainstCompanyComparisonObj = {
    //   lienDetails: null,
    //   dateOfFiling: null,
    //   jurisdiction: null,
    //   caseNumber: null,
    //   courtFilings: null,
    //   lienStatus: null,
    //   nameOfLienHolder: null,
    //   nameOfDebtor: null,
    //   filingAmount: {
    //     value: null,
    //     currency: null,
    //   },
    //   projectInvolved: null,
    //   scopeOfWork: null,
    //   completionStatus: null,
    //   reasonForLien: null,
    // };

    useEffect(() => {
      if (formData) {
        console.log('useEffect-setInitialData>>>');

        const historyRelatedCompanies =
          currentHistoryData?.relatedCompanies?.companies;
        const companies = getValue(
          showHistory,
          currentHistoryData?.relatedCompanies?.companies,
          formData?.relatedCompanies?.companies
        );
        const relatedCompanies = companies
          ? companies?.map((company: any, i: number) => ({
              name: getValue(
                showHistory,
                historyRelatedCompanies?.[i]?.name,
                company?.name
              ),
              natureOfOperations: getValue(
                showHistory,
                historyRelatedCompanies?.[i]?.natureOfOperations,
                company?.natureOfOperations
              ),
              ownershipStructure: getValue(
                showHistory,
                historyRelatedCompanies?.[i]?.ownershipStructure,
                company?.ownershipStructure
              ),
            }))
          : initialValues?.relatedCompanies?.companies;

        const hostoryOfLegalDoc =
          currentHistoryData?.hasGuarantees?.legalAndRegulatoryCompliance
            ?.guaranteeAgreementDocumentation?.legalDocuments;

        const legalDocumentsValue = getValue(
          showHistory,
          currentHistoryData?.hasGuarantees?.legalAndRegulatoryCompliance
            ?.guaranteeAgreementDocumentation?.legalDocuments,
          formData?.hasGuarantees?.legalAndRegulatoryCompliance
            ?.guaranteeAgreementDocumentation?.legalDocuments
        );

        const legalDocuments = legalDocumentsValue?.map(
          (item: any, i: number) => ({
            linkId: getValue(
              showHistory,
              hostoryOfLegalDoc?.[i]?.linkId,
              item?.linkId
            ),
            link: getValue(
              showHistory,
              hostoryOfLegalDoc?.[i]?.link,
              item?.link
            ),
            urlKey: getValue(
              showHistory,
              hostoryOfLegalDoc?.[i]?.urlKey,
              item?.urlKey
            ),
          })
        );

        const historyOfLegalDocumentation =
          currentHistoryData?.hasChangesInManagement?.documentation
            ?.legalDocumentation;

        const legalDocumentation =
          formData?.hasChangesInManagement?.documentation?.legalDocumentation?.map(
            (item: any, i: number) => ({
              linkId: getValue(
                showHistory,
                historyOfLegalDocumentation?.[i]?.linkId,
                item?.linkId
              ),
              link: getValue(
                showHistory,
                historyOfLegalDocumentation?.[i]?.link,
                item?.link
              ),
              urlKey: getValue(
                showHistory,
                historyOfLegalDocumentation?.[i]?.urlKey,
                item?.urlKey
              ),
            })
          );

        const historyOfShareholderAgreements =
          currentHistoryData?.hasChangesInManagement?.documentation
            ?.shareholderAgreements;
        const shareholderAgreements =
          formData?.hasChangesInManagement?.documentation?.shareholderAgreements?.map(
            (item: any, i: number) => ({
              linkId: getValue(
                showHistory,
                historyOfShareholderAgreements?.[i]?.linkId,
                item?.linkId
              ),
              link: getValue(
                showHistory,
                historyOfShareholderAgreements?.[i]?.link,
                item?.link
              ),
              urlKey: getValue(
                showHistory,
                historyOfShareholderAgreements?.[i]?.urlKey,
                item?.urlKey
              ),
            })
          );

        const historyOfRegulatoryFilings =
          currentHistoryData?.hasChangesInManagement?.documentation
            ?.regulatoryFilings;
        const regulatoryFilings =
          formData?.hasChangesInManagement?.documentation?.regulatoryFilings?.map(
            (item: any, i: number) => ({
              linkId: getValue(
                showHistory,
                historyOfRegulatoryFilings?.[i]?.linkId,
                item?.linkId
              ),
              link: getValue(
                showHistory,
                historyOfRegulatoryFilings?.[i]?.link,
                item?.link
              ),
              urlKey: getValue(
                showHistory,
                historyOfRegulatoryFilings?.[i]?.urlKey,
                item?.urlKey
              ),
            })
          );

        // const historyOfguaranteesForOtherPartiesFS =
        //   currentHistoryData?.hasGuarantees?.impactOnFinancialHealth
        //     ?.impactOnFinancialStatements?.financialStatements;
        // const guaranteesForOtherPartiesFinancialStatements =
        //   formData?.hasGuarantees?.impactOnFinancialHealth?.impactOnFinancialStatements?.financialStatements?.map(
        //     (item: any, i: number) => ({
        //       linkId: getValue(
        //         showHistory,
        //         historyOfguaranteesForOtherPartiesFS?.[i]?.linkId,
        //         item?.linkId
        //       ),
        //       link: getValue(
        //         showHistory,
        //         historyOfguaranteesForOtherPartiesFS?.[i]?.link,
        //         item?.link
        //       ),
        //       urlKey: getValue(
        //         showHistory,
        //         historyOfguaranteesForOtherPartiesFS?.[i]?.urlKey,
        //         item?.urlKey
        //       ),
        //     })
        //   );

        // const historyOfrelatedCompaniesaFS =
        //   currentHistoryData?.hasRelatedCompanies?.financialInformation
        //     ?.financialStatements;
        // const relatedCompaniesFinancialStatements =
        //   formData?.hasRelatedCompanies?.financialInformation?.financialStatements?.map(
        //     (item: any, i: number) => ({
        //       linkId: getValue(
        //         showHistory,
        //         historyOfrelatedCompaniesaFS?.[i]?.linkId,
        //         item?.linkId
        //       ),
        //       link: getValue(
        //         showHistory,
        //         historyOfrelatedCompaniesaFS?.[i]?.link,
        //         item?.link
        //       ),
        //       urlKey: getValue(
        //         showHistory,
        //         historyOfrelatedCompaniesaFS?.[i]?.urlKey,
        //         item?.urlKey
        //       ),
        //     })
        //   );

        const historyOfTermsOfIndebtedness =
          currentHistoryData?.hasGuarantees?.guaranteeDetails?.financialDetails
            ?.termsOfIndebtedness;

        const termsOfIndebtednessValue = getValue(
          showHistory,
          currentHistoryData?.hasGuarantees?.guaranteeDetails?.financialDetails
            ?.termsOfIndebtedness,
          formData?.hasGuarantees?.guaranteeDetails?.financialDetails
            ?.termsOfIndebtedness
        );
        const termsOfIndebtedness = termsOfIndebtednessValue?.map(
          (item: any, i: number) => ({
            linkId: getValue(
              showHistory,
              historyOfTermsOfIndebtedness?.[i]?.linkId,
              item?.linkId
            ),
            link: getValue(
              showHistory,
              historyOfTermsOfIndebtedness?.[i]?.link,
              item?.link
            ),
            urlKey: getValue(
              showHistory,
              historyOfTermsOfIndebtedness?.[i]?.urlKey,
              item?.urlKey
            ),
          })
        );

        // const historyOfCourtFilings =
        //   currentHistoryData?.hasLiensFiledAgainstCompany?.generalInfo
        //     ?.courtFilings;
        // const courtFilings =
        //   formData?.hasLiensFiledAgainstCompany?.generalInfo?.courtFilings?.map(
        //     (item: any, i: number) => ({
        //       linkId: getValue(
        //         showHistory,
        //         historyOfCourtFilings?.[i]?.linkId,
        //         item?.linkId
        //       ),
        //       link: getValue(
        //         showHistory,
        //         historyOfCourtFilings?.[i]?.link,
        //         item?.link
        //       ),
        //       urlKey: getValue(
        //         showHistory,
        //         historyOfCourtFilings?.[i]?.urlKey,
        //         item?.urlKey
        //       ),
        //     })
        //   );

        const historyOfAgreementCopy =
          currentHistoryData?.hasBuySellAgreement?.agreementCopy;

        const agreementCopyValue = getValue(
          showHistory,
          currentHistoryData?.hasBuySellAgreement?.agreementCopy,
          formData?.hasBuySellAgreement?.agreementCopy
        );

        const agreementCopy = agreementCopyValue?.map(
          (item: any, i: number) => ({
            linkId: getValue(
              showHistory,
              historyOfAgreementCopy?.[i]?.linkId,
              item?.linkId
            ),
            link: getValue(
              showHistory,
              historyOfAgreementCopy?.[i]?.link,
              item?.link
            ),
            urlKey: getValue(
              showHistory,
              historyOfAgreementCopy?.[i]?.urlKey,
              item?.urlKey
            ),
          })
        );

        let receivershipOrBankruptcyAcc: {
          name: string;
          tableData?: Record<string, any>[] | null;
          fieldArray: Record<string, any>[] | null;
          columns?: MRT_ColumnDef<LegalFormAccordianTable>[] | [];
        }[] = [];

        if (formData?.hasBankruptcyHistory) {
          if (
            formData?.hasBankruptcyHistory &&
            formData?.hasBankruptcyHistory?.bankruptcyDetails &&
            formData?.hasBankruptcyHistory?.bankruptcyDetails?.length
          ) {
            receivershipOrBankruptcyAcc = [
              {
                name: 'Bankruptcy',
                // tableData: bankruptcyTableData,
                fieldArray: formData?.hasBankruptcyHistory?.bankruptcyDetails,
                // columns: bankruptcyColumns,
              },
            ];
          }
          if (
            formData?.hasBankruptcyHistory &&
            formData?.hasBankruptcyHistory?.insolvencyDetails &&
            formData?.hasBankruptcyHistory?.insolvencyDetails?.length
          ) {
            receivershipOrBankruptcyAcc = [
              ...receivershipOrBankruptcyAcc,
              {
                name: 'Insolvency',
                // tableData: insolvencyTableData,
                fieldArray: formData?.hasBankruptcyHistory?.insolvencyDetails,
                // columns: insolvencyColumns,
                // tableData: [
                //   {
                //     name: 'Date of Filing',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.dateOfFiling,
                //   },
                //   {
                //     name: 'Jurisdiction / Court',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.jurisdictionCourt,
                //   },
                //   {
                //     name: 'Case Number',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.caseNumber,
                //   },
                //   {
                //     name: 'Bankruptcy Chapter',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.bankruptcyChapter,
                //   },
                //   {
                //     name: 'Total Debt Amount',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.totalDebtAmount,
                //   },
                //   {
                //     name: 'List of Major Creditors',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.listOfMajorCreditors,
                //   },
                //   {
                //     name: 'Assets at Time of Filing',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.assetsAtTimeOfFiling,
                //   },
                //   {
                //     name: 'Outcome/Disposition',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.outcome,
                //   },
                //   {
                //     name: 'Reason for Bankruptcy',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.reasonForBankruptcy,
                //   },
                //   {
                //     name: 'Duration of Proceedings',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.durationOfProceedings,
                //   },
                //   {
                //     name: 'Impact on Business Operations',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.impactOnBusinessOperations,
                //   },
                //   {
                //     name: 'Date of Discharge',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.dateOfDischarge,
                //   },
                //   {
                //     name: 'Discharge Conditions',
                //     value:
                //       formData?.hasBankruptcyHistory?.insolvencyDetails?.[0]
                //         ?.dischargeConditions,
                //   },
                // ],
              },
            ];
          }
          if (
            formData?.hasBankruptcyHistory &&
            formData?.hasBankruptcyHistory?.liquidationDetails &&
            formData?.hasBankruptcyHistory?.liquidationDetails?.length
          ) {
            receivershipOrBankruptcyAcc = [
              ...receivershipOrBankruptcyAcc,
              {
                name: 'Liquidation',
                // tableData: liquidationTableData,
                fieldArray: formData?.hasBankruptcyHistory?.liquidationDetails,
                // columns: liquidationColumns,
              },
            ];
          }
        }

        let outstandingJudgmentsAcc: {
          name: string;
          fieldArray: Record<string, any>[] | null;
        }[] = [];

        if (
          formData?.hasOutstandingJudgments &&
          formData?.hasOutstandingJudgments?.length > 0
        ) {
          outstandingJudgmentsAcc = [
            ...outstandingJudgmentsAcc,
            {
              name: 'Judgements',
              fieldArray: formData?.hasOutstandingJudgments,
            },
          ];
        }
        if (
          formData?.hasOutstandingSuits &&
          formData?.hasOutstandingSuits?.length > 0
        ) {
          outstandingJudgmentsAcc = [
            ...outstandingJudgmentsAcc,
            {
              name: 'Suits',
              fieldArray: formData?.hasOutstandingSuits,
            },
          ];
        }
        if (
          formData?.hasOutstandingClaims &&
          formData?.hasOutstandingClaims?.length > 0
        ) {
          outstandingJudgmentsAcc = [
            ...outstandingJudgmentsAcc,
            {
              name: 'Claims',
              fieldArray: formData?.hasOutstandingClaims,
            },
          ];
        }

        let relatedCompaniesAcc: hasAccordianWithSubSection[] = [];

        // if (
        //   formData?.hasRelatedCompanies &&
        //   formData?.hasRelatedCompanies?.globalUltimate
        // ) {
        //   relatedCompaniesAcc = [
        //     ...relatedCompaniesAcc,
        //     {
        //       parentAccName: 'Global Ultimate',
        //       parentAccContent: [
        //         {
        //           name: 'General Information',
        //           fields: [
        //             {
        //               label: 'Names of Related Companies',
        //               name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.namesOfRelatedCompanies',
        //             },
        //             {
        //               label: 'Type of Relationship',
        //               name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.typeOfRelationship',
        //             },
        //             {
        //               label: 'Ownership Structure',
        //               name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.ownershipStructure',
        //             },
        //           ],
        //         },
        //         {
        //           name: 'Company Details',
        //           fields: [
        //             {
        //               label: 'Business Activities',
        //               name: 'hasRelatedCompanies.globalUltimate.companyDetails.businessActivities',
        //             },
        //             {
        //               label: 'Locations',
        //               name: 'hasRelatedCompanies.globalUltimate.companyDetails.locations',
        //             },
        //           ],
        //         },
        //         // {
        //         //   name: 'Financial Information',
        //         //   fields: [
        //         //     {
        //         //       label: 'Financial Statements',
        //         //       name: 'hasRelatedCompanies.financialInformation.financialStatements',
        //         //       type: 'file',
        //         //       uploadState: 'relatedCompaniesFinancialStatements',
        //         //     },
        //         //     {
        //         //       label: 'Intercompany Transactions',
        //         //       name: 'hasRelatedCompanies.financialInformation.intercompanyTransactions',
        //         //     },
        //         //     {
        //         //       label: 'Debt and Liabilities',
        //         //       name: 'hasRelatedCompanies.financialInformation.debtAndLiabilities',
        //         //     },
        //         //   ],
        //         // },
        //         {
        //           name: 'Operational and Management Details',
        //           fields: [
        //             // {
        //             //   label: 'Management Structure',
        //             //   name: 'hasRelatedCompanies.operationalAndManagementDetails.managementStructure',
        //             // },
        //             {
        //               label: 'Operational Dependencies',
        //               name: 'hasRelatedCompanies.globalUltimate.operationalAndManagementDetails.operationalDependencies',
        //             },
        //           ],
        //         },
        //         // {
        //         //   name: 'Legal and Regulatory Information',
        //         //   fields: [
        //         //     {
        //         //       label: 'Legal Structure',
        //         //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.legalStructure',
        //         //     },
        //         //     {
        //         //       label: 'Compliance Status',
        //         //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.complianceStatus',
        //         //     },
        //         //   ],
        //         // },
        //         // {
        //         //   name: 'Risk Assessment',
        //         //   fields: [
        //         //     {
        //         //       label: 'Risk Exposure',
        //         //       name: 'hasRelatedCompanies.riskAssessment.riskExposure',
        //         //     },
        //         //   ],
        //         // },
        //         // {
        //         //   name: 'Strategic Information',
        //         //   fields: [
        //         //     {
        //         //       label: 'Strategic Importance',
        //         //       name: 'hasRelatedCompanies.strategicInformation.strategicImportance',
        //         //     },
        //         //   ],
        //         // },
        //       ],
        //     },
        //   ];
        // }
        // if (
        //   formData?.hasRelatedCompanies &&
        //   formData?.hasRelatedCompanies?.domesticUltimate
        // ) {
        //   relatedCompaniesAcc = [
        //     ...relatedCompaniesAcc,
        //     {
        //       parentAccName: 'Domestic Ultimate',
        //       parentAccContent: [
        //         {
        //           name: 'General Information',
        //           fields: [
        //             {
        //               label: 'Names of Related Companies',
        //               name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.namesOfRelatedCompanies',
        //             },
        //             {
        //               label: 'Type of Relationship',
        //               name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.typeOfRelationship',
        //             },
        //             {
        //               label: 'Ownership Structure',
        //               name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.ownershipStructure',
        //             },
        //           ],
        //         },
        //         {
        //           name: 'Company Details',
        //           fields: [
        //             {
        //               label: 'Business Activities',
        //               name: 'hasRelatedCompanies.domesticUltimate.companyDetails.businessActivities',
        //             },
        //             {
        //               label: 'Locations',
        //               name: 'hasRelatedCompanies.domesticUltimate.companyDetails.locations',
        //             },
        //           ],
        //         },
        //         // {
        //         //   name: 'Financial Information',
        //         //   fields: [
        //         //     {
        //         //       label: 'Financial Statements',
        //         //       name: 'hasRelatedCompanies.financialInformation.financialStatements',
        //         //       type: 'file',
        //         //       uploadState: 'relatedCompaniesFinancialStatements',
        //         //     },
        //         //     {
        //         //       label: 'Intercompany Transactions',
        //         //       name: 'hasRelatedCompanies.financialInformation.intercompanyTransactions',
        //         //     },
        //         //     {
        //         //       label: 'Debt and Liabilities',
        //         //       name: 'hasRelatedCompanies.financialInformation.debtAndLiabilities',
        //         //     },
        //         //   ],
        //         // },
        //         {
        //           name: 'Operational and Management Details',
        //           fields: [
        //             // {
        //             //   label: 'Management Structure',
        //             //   name: 'hasRelatedCompanies.operationalAndManagementDetails.managementStructure',
        //             // },
        //             {
        //               label: 'Operational Dependencies',
        //               name: 'hasRelatedCompanies.domesticUltimate.operationalAndManagementDetails.operationalDependencies',
        //             },
        //           ],
        //         },
        //         // {
        //         //   name: 'Legal and Regulatory Information',
        //         //   fields: [
        //         //     {
        //         //       label: 'Legal Structure',
        //         //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.legalStructure',
        //         //     },
        //         //     {
        //         //       label: 'Compliance Status',
        //         //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.complianceStatus',
        //         //     },
        //         //   ],
        //         // },
        //         // {
        //         //   name: 'Risk Assessment',
        //         //   fields: [
        //         //     {
        //         //       label: 'Risk Exposure',
        //         //       name: 'hasRelatedCompanies.riskAssessment.riskExposure',
        //         //     },
        //         //   ],
        //         // },
        //         // {
        //         //   name: 'Strategic Information',
        //         //   fields: [
        //         //     {
        //         //       label: 'Strategic Importance',
        //         //       name: 'hasRelatedCompanies.strategicInformation.strategicImportance',
        //         //     },
        //         //   ],
        //         // },
        //       ],
        //     },
        //   ];
        // }
        // if (
        //   formData?.hasRelatedCompanies &&
        //   formData?.hasRelatedCompanies?.parent
        // ) {
        //   relatedCompaniesAcc = [
        //     ...relatedCompaniesAcc,
        //     {
        //       parentAccName: 'Parent',
        //       parentAccContent: [
        //         {
        //           name: 'General Information',
        //           fields: [
        //             {
        //               label: 'Names of Related Companies',
        //               name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.namesOfRelatedCompanies',
        //             },
        //             {
        //               label: 'Type of Relationship',
        //               name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.typeOfRelationship',
        //             },
        //             {
        //               label: 'Ownership Structure',
        //               name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.ownershipStructure',
        //             },
        //           ],
        //         },
        //         {
        //           name: 'Company Details',
        //           fields: [
        //             {
        //               label: 'Business Activities',
        //               name: 'hasRelatedCompanies.parent.companyDetails.businessActivities',
        //             },
        //             {
        //               label: 'Locations',
        //               name: 'hasRelatedCompanies.parent.companyDetails.locations',
        //             },
        //           ],
        //         },
        //         // {
        //         //   name: 'Financial Information',
        //         //   fields: [
        //         //     {
        //         //       label: 'Financial Statements',
        //         //       name: 'hasRelatedCompanies.financialInformation.financialStatements',
        //         //       type: 'file',
        //         //       uploadState: 'relatedCompaniesFinancialStatements',
        //         //     },
        //         //     {
        //         //       label: 'Intercompany Transactions',
        //         //       name: 'hasRelatedCompanies.financialInformation.intercompanyTransactions',
        //         //     },
        //         //     {
        //         //       label: 'Debt and Liabilities',
        //         //       name: 'hasRelatedCompanies.financialInformation.debtAndLiabilities',
        //         //     },
        //         //   ],
        //         // },
        //         {
        //           name: 'Operational and Management Details',
        //           fields: [
        //             // {
        //             //   label: 'Management Structure',
        //             //   name: 'hasRelatedCompanies.operationalAndManagementDetails.managementStructure',
        //             // },
        //             {
        //               label: 'Operational Dependencies',
        //               name: 'hasRelatedCompanies.parent.operationalAndManagementDetails.operationalDependencies',
        //             },
        //           ],
        //         },
        //         // {
        //         //   name: 'Legal and Regulatory Information',
        //         //   fields: [
        //         //     {
        //         //       label: 'Legal Structure',
        //         //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.legalStructure',
        //         //     },
        //         //     {
        //         //       label: 'Compliance Status',
        //         //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.complianceStatus',
        //         //     },
        //         //   ],
        //         // },
        //         // {
        //         //   name: 'Risk Assessment',
        //         //   fields: [
        //         //     {
        //         //       label: 'Risk Exposure',
        //         //       name: 'hasRelatedCompanies.riskAssessment.riskExposure',
        //         //     },
        //         //   ],
        //         // },
        //         // {
        //         //   name: 'Strategic Information',
        //         //   fields: [
        //         //     {
        //         //       label: 'Strategic Importance',
        //         //       name: 'hasRelatedCompanies.strategicInformation.strategicImportance',
        //         //     },
        //         //   ],
        //         // },
        //       ],
        //     },
        //   ];
        // }

        if (
          formData?.hasRelatedCompanies &&
          formData?.hasRelatedCompanies?.globalUltimate
        ) {
          relatedCompaniesAcc = [
            ...relatedCompaniesAcc,
            {
              name: 'globalUltimate',
              subSection: [
                {
                  name: 'General Information',
                  fields: [
                    {
                      label: 'Names of Related Companies',
                      name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.namesOfRelatedCompanies',
                    },
                    {
                      label: 'Type of Relationship',
                      name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.typeOfRelationship',
                    },
                    {
                      label: 'Ownership Structure',
                      name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.ownershipStructure',
                    },
                  ],
                  sectionNo: 1,
                },
                {
                  name: 'Company Details',
                  fields: [
                    {
                      label: 'Business Activities',
                      name: 'hasRelatedCompanies.globalUltimate.companyDetails.businessActivities',
                    },
                    {
                      label: 'Locations',
                      name: 'hasRelatedCompanies.globalUltimate.companyDetails.locations',
                    },
                  ],
                  sectionNo: 2,
                },
                {
                  name: 'Operational and Management Details',
                  fields: [
                    // {
                    //   label: 'Management Structure',
                    //   name: 'hasRelatedCompanies.operationalAndManagementDetails.managementStructure',
                    // },
                    {
                      label: 'Operational Dependencies',
                      name: 'hasRelatedCompanies.globalUltimate.operationalAndManagementDetails.operationalDependencies',
                    },
                  ],
                  sectionNo: 3,
                },
              ],
            },
          ];
        }
        if (
          formData?.hasRelatedCompanies &&
          formData?.hasRelatedCompanies?.domesticUltimate
        ) {
          relatedCompaniesAcc = [
            ...relatedCompaniesAcc,
            {
              name: 'domesticUltimate',
              subSection: [
                {
                  name: 'General Information',
                  fields: [
                    {
                      label: 'Names of Related Companies',
                      name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.namesOfRelatedCompanies',
                    },
                    {
                      label: 'Type of Relationship',
                      name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.typeOfRelationship',
                    },
                    {
                      label: 'Ownership Structure',
                      name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.ownershipStructure',
                    },
                  ],
                  sectionNo: 1,
                },
                {
                  name: 'Company Details',
                  fields: [
                    {
                      label: 'Business Activities',
                      name: 'hasRelatedCompanies.domesticUltimate.companyDetails.businessActivities',
                    },
                    {
                      label: 'Locations',
                      name: 'hasRelatedCompanies.domesticUltimate.companyDetails.locations',
                    },
                  ],
                  sectionNo: 2,
                },
                {
                  name: 'Operational and Management Details',
                  fields: [
                    {
                      label: 'Operational Dependencies',
                      name: 'hasRelatedCompanies.domesticUltimate.operationalAndManagementDetails.operationalDependencies',
                    },
                  ],
                  sectionNo: 3,
                },
              ],
            },
          ];
        }
        if (
          formData?.hasRelatedCompanies &&
          formData?.hasRelatedCompanies?.parent
        ) {
          relatedCompaniesAcc = [
            ...relatedCompaniesAcc,
            {
              name: 'parent',
              subSection: [
                {
                  name: 'General Information',
                  fields: [
                    {
                      label: 'Names of Related Companies',
                      name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.namesOfRelatedCompanies',
                    },
                    {
                      label: 'Type of Relationship',
                      name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.typeOfRelationship',
                    },
                    {
                      label: 'Ownership Structure',
                      name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.ownershipStructure',
                    },
                  ],
                  sectionNo: 1,
                },
                {
                  name: 'Company Details',
                  fields: [
                    {
                      label: 'Business Activities',
                      name: 'hasRelatedCompanies.parent.companyDetails.businessActivities',
                    },
                    {
                      label: 'Locations',
                      name: 'hasRelatedCompanies.parent.companyDetails.locations',
                    },
                  ],
                  sectionNo: 2,
                },
                {
                  name: 'Operational and Management Details',
                  fields: [
                    {
                      label: 'Operational Dependencies',
                      name: 'hasRelatedCompanies.parent.operationalAndManagementDetails.operationalDependencies',
                    },
                  ],
                  sectionNo: 3,
                },
              ],
            },
          ];
        }

        const apiValues = {
          ...initialValues,
          applicationId: formData?.applicationId,
          brokerId: formData?.brokerId,
          applicantId: formData?.applicantId,
          receivershipOrBankruptcyHistory: {
            hasHistory: getValue(
              showHistory,
              currentHistoryData?.receivershipOrBankruptcyHistory?.hasHistory,
              receivershipOrBankruptcyAcc?.length > 0
                ? formData?.receivershipOrBankruptcyHistory?.hasHistory ?? true
                : formData?.receivershipOrBankruptcyHistory?.hasHistory
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.receivershipOrBankruptcyHistory?.details,
              formData?.receivershipOrBankruptcyHistory?.details
            ),
          },
          guaranteesForOtherParties: {
            hasGuarantees: getValue(
              showHistory,
              currentHistoryData?.guaranteesForOtherParties?.hasGuarantees,
              formData?.guaranteesForOtherParties?.hasGuarantees
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.guaranteesForOtherParties?.details,
              formData?.guaranteesForOtherParties?.details
            ),
          },
          relatedCompanies: {
            hasRelatedCompanies: getValue(
              showHistory,
              currentHistoryData?.relatedCompanies?.hasRelatedCompanies,
              formData?.hasRelatedCompanies
                ? formData?.relatedCompanies?.hasRelatedCompanies ?? true
                : formData?.relatedCompanies?.hasRelatedCompanies
            ),
            companies: relatedCompanies,
          },
          changesInManagement: {
            hasChanges: getValue(
              showHistory,
              currentHistoryData?.changesInManagement?.hasChanges,
              formData?.hasChangesInManagement
                ? formData?.changesInManagement?.hasChanges ?? true
                : formData?.changesInManagement?.hasChanges
              // formData?.changesInManagement?.hasChanges
            ),
            explanation: getValue(
              showHistory,
              currentHistoryData?.changesInManagement?.explanation,
              formData?.changesInManagement?.explanation
            ),
          },
          liensFiledAgainstCompany: {
            hasLiens: getValue(
              showHistory,
              currentHistoryData?.liensFiledAgainstCompany?.hasLiens,
              formData?.hasLiensFiledAgainstCompany &&
                formData?.hasLiensFiledAgainstCompany?.length > 0
                ? formData?.liensFiledAgainstCompany?.hasLiens ?? true
                : formData?.liensFiledAgainstCompany?.hasLiens
            ),
            // amount: getValue(
            //   showHistory,
            //   currentHistoryData?.liensFiledAgainstCompany?.amount,
            //   formData?.liensFiledAgainstCompany?.amount
            // ),
            details: getValue(
              showHistory,
              currentHistoryData?.liensFiledAgainstCompany?.details,
              formData?.liensFiledAgainstCompany?.details
            ),
          },
          stateFederalTax: getValue(
            showHistory,
            currentHistoryData?.stateFederalTax,
            formData?.hasStateFederalTax &&
              formData?.hasStateFederalTax?.length > 0
              ? formData?.stateFederalTax ?? true
              : formData?.stateFederalTax
          ),
          paymentPlan: getValue(
            showHistory,
            currentHistoryData?.paymentPlan,
            formData?.paymentPlan
          ),
          // taxingEntityDoc: getValue(
          //   showHistory,
          //   currentHistoryData?.taxingEntityDoc,
          //   formData?.taxingEntityDoc
          // ),
          outstandingJudgments: {
            hasOutstandingJudgments: getValue(
              showHistory,
              currentHistoryData?.outstandingJudgments?.hasOutstandingJudgments,
              outstandingJudgmentsAcc?.length > 0
                ? formData?.outstandingJudgments?.hasOutstandingJudgments ??
                    true
                : formData?.outstandingJudgments?.hasOutstandingJudgments
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.outstandingJudgments?.details,
              formData?.outstandingJudgments?.details
            ),
          },
          bondClaims: getValue(
            showHistory,
            currentHistoryData?.bondClaims,
            formData?.bondClaims
          ),
          claimsPaid: getValue(
            showHistory,
            currentHistoryData?.claimsPaid,
            formData?.claimsPaid
          ),
          suretyMade: getValue(
            showHistory,
            currentHistoryData?.suretyMade,
            formData?.suretyMade
          ),
          whyNotMade: getValue(
            showHistory,
            currentHistoryData?.whyNotMade,
            formData?.whyNotMade
          ),
          disputesWithOthers: {
            hasDisputes: getValue(
              showHistory,
              currentHistoryData?.disputesWithOthers?.hasDisputes,
              formData?.disputesWithOthers?.hasDisputes
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.disputesWithOthers?.details,
              formData?.disputesWithOthers?.details
            ),
          },
          liensFiledByCompany: {
            hasLiens: getValue(
              showHistory,
              currentHistoryData?.liensFiledByCompany?.hasLiens,
              formData?.hasLiensFiledByCompany &&
                formData?.hasLiensFiledByCompany?.length > 0
                ? formData?.liensFiledByCompany?.hasLiens ?? true
                : formData?.liensFiledByCompany?.hasLiens
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.liensFiledByCompany?.details,
              formData?.liensFiledByCompany?.details
            ),
          },
          disputesWithSuppliers: {
            hasDisputes: getValue(
              showHistory,
              currentHistoryData?.disputesWithSuppliers?.hasDisputes,
              formData?.disputesWithSuppliers?.hasDisputes
            ),
            fundingMeans: getValue(
              showHistory,
              currentHistoryData?.disputesWithSuppliers?.fundingMeans,
              formData?.disputesWithSuppliers?.fundingMeans
            ),
            details: getValue(
              showHistory,
              currentHistoryData?.disputesWithSuppliers?.details,
              formData?.disputesWithSuppliers?.details
            ),
          },
          buySellAgreement: getValue(
            showHistory,
            currentHistoryData?.buySellAgreement,
            formData?.buySellAgreement
          ),
          agreementDetails: getValue(
            showHistory,
            currentHistoryData?.agreementDetails,
            formData?.agreementDetails
          ),
          returnToSender: userType === 'broker' ? false : null,
          applicationName: getValue(
            showHistory,
            currentHistoryData?.applicationName,
            formData?.applicationName || ''
          ),

          receivershipOrBankruptcyAcc: receivershipOrBankruptcyAcc,
          guaranteesForOtherPartiesAcc:
            formik?.initialValues?.guaranteesForOtherPartiesAcc,

          hasGuarantees: {
            guaranteeDetails: {
              guarantorInformation: {
                nameOfGuarantors: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.guarantorInformation?.nameOfGuarantors,
                  formData?.hasGuarantees?.guaranteeDetails
                    ?.guarantorInformation?.nameOfGuarantors
                ),
                position: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.guarantorInformation?.position,
                  formData?.hasGuarantees?.guaranteeDetails
                    ?.guarantorInformation?.position
                ),
              },
              beneficiaryInformation: {
                nameOfBeneficiary: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.beneficiaryInformation?.nameOfBeneficiary,
                  formData?.hasGuarantees?.guaranteeDetails
                    ?.beneficiaryInformation?.nameOfBeneficiary
                ),
                relationshipToGuarantor: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.beneficiaryInformation?.relationshipToGuarantor,
                  formData?.hasGuarantees?.guaranteeDetails
                    ?.beneficiaryInformation?.relationshipToGuarantor
                ),
              },
              guaranteeAgreementDetails: {
                typeOfGuarantee: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.guaranteeAgreementDetails?.typeOfGuarantee,
                  formData?.hasGuarantees?.guaranteeDetails
                    ?.guaranteeAgreementDetails?.typeOfGuarantee
                ),
                dateOfAgreement: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.guaranteeAgreementDetails?.dateOfAgreement,
                  formData?.hasGuarantees?.guaranteeDetails
                    ?.guaranteeAgreementDetails?.dateOfAgreement
                ),
                durationOfGuarantee: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.guaranteeAgreementDetails?.durationOfGuarantee,
                  formData?.hasGuarantees?.guaranteeDetails
                    ?.guaranteeAgreementDetails?.durationOfGuarantee
                ),
              },
              financialDetails: {
                amountGuaranteed: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.financialDetails?.amountGuaranteed,
                  formData?.hasGuarantees?.guaranteeDetails?.financialDetails
                    ?.amountGuaranteed
                ),
                currency: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.financialDetails?.currency,
                  formData?.hasGuarantees?.guaranteeDetails?.financialDetails
                    ?.currency
                ),
                termsOfIndebtedness: termsOfIndebtedness,
              },
              collateralAndSecurity: {
                collateralProvided: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.collateralAndSecurity?.collateralProvided,
                  formData?.hasGuarantees?.guaranteeDetails
                    ?.collateralAndSecurity?.collateralProvided
                ),
                valuationOfCollateral: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.guaranteeDetails
                    ?.collateralAndSecurity?.valuationOfCollateral,
                  formData?.hasGuarantees?.guaranteeDetails
                    ?.collateralAndSecurity?.valuationOfCollateral
                ),
              },
            },
            impactOnFinancialHealth: {
              currentStatusOfIndebtedness: {
                outstandingBalance: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.impactOnFinancialHealth
                    ?.currentStatusOfIndebtedness?.outstandingBalance,
                  formData?.hasGuarantees?.impactOnFinancialHealth
                    ?.currentStatusOfIndebtedness?.outstandingBalance
                ),
                paymentStatus: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees?.impactOnFinancialHealth
                    ?.currentStatusOfIndebtedness?.paymentStatus,
                  formData?.hasGuarantees?.impactOnFinancialHealth
                    ?.currentStatusOfIndebtedness?.paymentStatus
                ),
              },
              // impactOnFinancialStatements: {
              //   financialStatements:
              //     guaranteesForOtherPartiesFinancialStatements,
              //   contingentLiabilities: getValue(
              //     showHistory,
              //     currentHistoryData?.hasGuarantees?.impactOnFinancialHealth
              //       ?.impactOnFinancialStatements?.contingentLiabilities,
              //     formData?.hasGuarantees?.impactOnFinancialHealth
              //       ?.impactOnFinancialStatements?.contingentLiabilities
              //   ),
              // },
              // creditReports: {
              //   creditReportsOfGuarantors: getValue(
              //     showHistory,
              //     currentHistoryData?.hasGuarantees?.impactOnFinancialHealth
              //       ?.creditReports?.creditReportsOfGuarantors,
              //     formData?.hasGuarantees?.impactOnFinancialHealth
              //       ?.creditReports?.creditReportsOfGuarantors
              //   ),
              //   creditReportsOfBeneficiary: getValue(
              //     showHistory,
              //     currentHistoryData?.hasGuarantees?.impactOnFinancialHealth
              //       ?.creditReports?.creditReportsOfBeneficiary,
              //     formData?.hasGuarantees?.impactOnFinancialHealth
              //       ?.creditReports?.creditReportsOfBeneficiary
              //   ),
              // },
            },
            legalAndRegulatoryCompliance: {
              guaranteeAgreementDocumentation: {
                legalDocuments: legalDocuments,
                courtFilings: getValue(
                  showHistory,
                  currentHistoryData?.hasGuarantees
                    ?.legalAndRegulatoryCompliance
                    ?.guaranteeAgreementDocumentation?.courtFilings,
                  formData?.hasGuarantees?.legalAndRegulatoryCompliance
                    ?.guaranteeAgreementDocumentation?.courtFilings
                ),
              },
              // regulatoryCompliance: {
              //   complianceStatus: getValue(
              //     showHistory,
              //     currentHistoryData?.hasGuarantees
              //       ?.legalAndRegulatoryCompliance?.regulatoryCompliance
              //       ?.complianceStatus,
              //     formData?.hasGuarantees?.legalAndRegulatoryCompliance
              //       ?.regulatoryCompliance?.complianceStatus
              //   ),
              // },
            },
            // riskMitigationAndRecovery: {
            //   riskMitigationMeasures: {
            //     riskManagementPlans: getValue(
            //       showHistory,
            //       currentHistoryData?.hasGuarantees?.riskMitigationAndRecovery
            //         ?.riskMitigationMeasures?.riskManagementPlans,
            //       formData?.hasGuarantees?.riskMitigationAndRecovery
            //         ?.riskMitigationMeasures?.riskManagementPlans
            //     ),
            //     insuranceCoverage: getValue(
            //       showHistory,
            //       currentHistoryData?.hasGuarantees?.riskMitigationAndRecovery
            //         ?.riskMitigationMeasures?.insuranceCoverage,
            //       formData?.hasGuarantees?.riskMitigationAndRecovery
            //         ?.riskMitigationMeasures?.insuranceCoverage
            //     ),
            //     recoveryStrategy: getValue(
            //       showHistory,
            //       currentHistoryData?.hasGuarantees?.riskMitigationAndRecovery
            //         ?.riskMitigationMeasures?.recoveryStrategy,
            //       formData?.hasGuarantees?.riskMitigationAndRecovery
            //         ?.riskMitigationMeasures?.recoveryStrategy
            //     ),
            //   },
            // },
          },
          uploadLegalDoc: initialValues?.uploadLegalDoc,

          relatedCompaniesAcc: relatedCompaniesAcc,
          // hasRelatedCompanies: {
          //   generalInfoRelatedCompanies: {
          //     namesOfRelatedCompanies: getValue(
          //       showHistory,
          //       currentHistoryData?.hasRelatedCompanies
          //         ?.generalInfoRelatedCompanies?.namesOfRelatedCompanies,
          //       formData?.hasRelatedCompanies?.generalInfoRelatedCompanies
          //         ?.namesOfRelatedCompanies
          //     ),
          //     typeOfRelationship: getValue(
          //       showHistory,
          //       currentHistoryData?.hasRelatedCompanies
          //         ?.generalInfoRelatedCompanies?.typeOfRelationship,
          //       formData?.hasRelatedCompanies?.generalInfoRelatedCompanies
          //         ?.typeOfRelationship
          //     ),
          //     ownershipStructure: getValue(
          //       showHistory,
          //       currentHistoryData?.hasRelatedCompanies
          //         ?.generalInfoRelatedCompanies?.ownershipStructure,
          //       formData?.hasRelatedCompanies?.generalInfoRelatedCompanies
          //         ?.ownershipStructure
          //     ),
          //   },
          //   companyDetails: {
          //     businessActivities: getValue(
          //       showHistory,
          //       currentHistoryData?.hasRelatedCompanies?.companyDetails
          //         ?.businessActivities,
          //       formData?.hasRelatedCompanies?.companyDetails
          //         ?.businessActivities
          //     ),
          //     locations: getValue(
          //       showHistory,
          //       currentHistoryData?.hasRelatedCompanies?.companyDetails
          //         ?.locations,
          //       formData?.hasRelatedCompanies?.companyDetails?.locations
          //     ),
          //   },
          //   // financialInformation: {
          //   //   financialStatements: relatedCompaniesFinancialStatements,
          //   //   intercompanyTransactions: getValue(
          //   //     showHistory,
          //   //     currentHistoryData?.hasRelatedCompanies?.financialInformation
          //   //       ?.intercompanyTransactions,
          //   //     formData?.hasRelatedCompanies?.financialInformation
          //   //       ?.intercompanyTransactions
          //   //   ),
          //   //   debtAndLiabilities: getValue(
          //   //     showHistory,
          //   //     currentHistoryData?.hasRelatedCompanies?.financialInformation
          //   //       ?.debtAndLiabilities,
          //   //     formData?.hasRelatedCompanies?.financialInformation
          //   //       ?.debtAndLiabilities
          //   //   ),
          //   // },
          //   operationalAndManagementDetails: {
          //     // managementStructure: getValue(
          //     //   showHistory,
          //     //   currentHistoryData?.hasRelatedCompanies
          //     //     ?.operationalAndManagementDetails?.managementStructure,
          //     //   formData?.hasRelatedCompanies?.operationalAndManagementDetails
          //     //     ?.managementStructure
          //     // ),
          //     operationalDependencies: getValue(
          //       showHistory,
          //       currentHistoryData?.hasRelatedCompanies
          //         ?.operationalAndManagementDetails?.operationalDependencies,
          //       formData?.hasRelatedCompanies?.operationalAndManagementDetails
          //         ?.operationalDependencies
          //     ),
          //   },
          //   // legalAndRegulatoryInformation: {
          //   //   legalStructure: getValue(
          //   //     showHistory,
          //   //     currentHistoryData?.hasRelatedCompanies
          //   //       ?.legalAndRegulatoryInformation?.legalStructure,
          //   //     formData?.hasRelatedCompanies?.legalAndRegulatoryInformation
          //   //       ?.legalStructure
          //   //   ),
          //   //   complianceStatus: getValue(
          //   //     showHistory,
          //   //     currentHistoryData?.hasRelatedCompanies
          //   //       ?.legalAndRegulatoryInformation?.complianceStatus,
          //   //     formData?.hasRelatedCompanies?.legalAndRegulatoryInformation
          //   //       ?.complianceStatus
          //   //   ),
          //   // },
          //   // riskAssessment: {
          //   //   riskExposure: getValue(
          //   //     showHistory,
          //   //     currentHistoryData?.hasRelatedCompanies?.riskAssessment
          //   //       ?.riskExposure,
          //   //     formData?.hasRelatedCompanies?.riskAssessment?.riskExposure
          //   //   ),
          //   // },
          //   // strategicInformation: {
          //   //   strategicImportance: getValue(
          //   //     showHistory,
          //   //     currentHistoryData?.hasRelatedCompanies?.strategicInformation
          //   //       ?.strategicImportance,
          //   //     formData?.hasRelatedCompanies?.strategicInformation
          //   //       ?.strategicImportance
          //   //   ),
          //   // },
          // },

          hasRelatedCompanies: formData?.hasRelatedCompanies
            ? {
                globalUltimate: {
                  generalInfoRelatedCompanies: {
                    namesOfRelatedCompanies: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.globalUltimate
                        ?.generalInfoRelatedCompanies?.namesOfRelatedCompanies,
                      formData?.hasRelatedCompanies?.globalUltimate
                        ?.generalInfoRelatedCompanies?.namesOfRelatedCompanies
                    ),
                    typeOfRelationship: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.globalUltimate
                        ?.generalInfoRelatedCompanies?.typeOfRelationship,
                      formData?.hasRelatedCompanies?.globalUltimate
                        ?.generalInfoRelatedCompanies?.typeOfRelationship
                    ),
                    ownershipStructure: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.globalUltimate
                        ?.generalInfoRelatedCompanies?.ownershipStructure,
                      formData?.hasRelatedCompanies?.globalUltimate
                        ?.generalInfoRelatedCompanies?.ownershipStructure
                    ),
                  },
                  companyDetails: {
                    businessActivities: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.globalUltimate
                        ?.companyDetails?.businessActivities,
                      formData?.hasRelatedCompanies?.globalUltimate
                        ?.companyDetails?.businessActivities
                    ),
                    locations: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.globalUltimate
                        ?.companyDetails?.locations,
                      formData?.hasRelatedCompanies?.globalUltimate
                        ?.companyDetails?.locations
                    ),
                  },
                  operationalAndManagementDetails: {
                    // managementStructure: getValue(
                    //   showHistory,
                    //   currentHistoryData?.hasRelatedCompanies
                    //     ?.operationalAndManagementDetails?.managementStructure,
                    //   formData?.hasRelatedCompanies?.operationalAndManagementDetails
                    //     ?.managementStructure
                    // ),
                    operationalDependencies: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.globalUltimate
                        ?.operationalAndManagementDetails
                        ?.operationalDependencies,
                      formData?.hasRelatedCompanies?.globalUltimate
                        ?.operationalAndManagementDetails
                        ?.operationalDependencies
                    ),
                  },
                },
                domesticUltimate: {
                  generalInfoRelatedCompanies: {
                    namesOfRelatedCompanies: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.domesticUltimate
                        ?.generalInfoRelatedCompanies?.namesOfRelatedCompanies,
                      formData?.hasRelatedCompanies?.domesticUltimate
                        ?.generalInfoRelatedCompanies?.namesOfRelatedCompanies
                    ),
                    typeOfRelationship: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.domesticUltimate
                        ?.generalInfoRelatedCompanies?.typeOfRelationship,
                      formData?.hasRelatedCompanies?.domesticUltimate
                        ?.generalInfoRelatedCompanies?.typeOfRelationship
                    ),
                    ownershipStructure: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.domesticUltimate
                        ?.generalInfoRelatedCompanies?.ownershipStructure,
                      formData?.hasRelatedCompanies?.domesticUltimate
                        ?.generalInfoRelatedCompanies?.ownershipStructure
                    ),
                  },
                  companyDetails: {
                    businessActivities: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.domesticUltimate
                        ?.companyDetails?.businessActivities,
                      formData?.hasRelatedCompanies?.domesticUltimate
                        ?.companyDetails?.businessActivities
                    ),
                    locations: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.domesticUltimate
                        ?.companyDetails?.locations,
                      formData?.hasRelatedCompanies?.domesticUltimate
                        ?.companyDetails?.locations
                    ),
                  },
                  operationalAndManagementDetails: {
                    // managementStructure: getValue(
                    //   showHistory,
                    //   currentHistoryData?.hasRelatedCompanies
                    //     ?.operationalAndManagementDetails?.managementStructure,
                    //   formData?.hasRelatedCompanies?.operationalAndManagementDetails
                    //     ?.managementStructure
                    // ),
                    operationalDependencies: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.domesticUltimate
                        ?.operationalAndManagementDetails
                        ?.operationalDependencies,
                      formData?.hasRelatedCompanies?.domesticUltimate
                        ?.operationalAndManagementDetails
                        ?.operationalDependencies
                    ),
                  },
                },
                parent: {
                  generalInfoRelatedCompanies: {
                    namesOfRelatedCompanies: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.parent
                        ?.generalInfoRelatedCompanies?.namesOfRelatedCompanies,
                      formData?.hasRelatedCompanies?.parent
                        ?.generalInfoRelatedCompanies?.namesOfRelatedCompanies
                    ),
                    typeOfRelationship: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.parent
                        ?.generalInfoRelatedCompanies?.typeOfRelationship,
                      formData?.hasRelatedCompanies?.parent
                        ?.generalInfoRelatedCompanies?.typeOfRelationship
                    ),
                    ownershipStructure: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.parent
                        ?.generalInfoRelatedCompanies?.ownershipStructure,
                      formData?.hasRelatedCompanies?.parent
                        ?.generalInfoRelatedCompanies?.ownershipStructure
                    ),
                  },
                  companyDetails: {
                    businessActivities: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.parent
                        ?.companyDetails?.businessActivities,
                      formData?.hasRelatedCompanies?.parent?.companyDetails
                        ?.businessActivities
                    ),
                    locations: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.parent
                        ?.companyDetails?.locations,
                      formData?.hasRelatedCompanies?.parent?.companyDetails
                        ?.locations
                    ),
                  },
                  operationalAndManagementDetails: {
                    // managementStructure: getValue(
                    //   showHistory,
                    //   currentHistoryData?.hasRelatedCompanies
                    //     ?.operationalAndManagementDetails?.managementStructure,
                    //   formData?.hasRelatedCompanies?.operationalAndManagementDetails
                    //     ?.managementStructure
                    // ),
                    operationalDependencies: getValue(
                      showHistory,
                      currentHistoryData?.hasRelatedCompanies?.parent
                        ?.operationalAndManagementDetails
                        ?.operationalDependencies,
                      formData?.hasRelatedCompanies?.parent
                        ?.operationalAndManagementDetails
                        ?.operationalDependencies
                    ),
                  },
                },
              }
            : initialValues?.hasRelatedCompanies,
          changesInManagementAcc: formik?.initialValues?.changesInManagementAcc,
          hasChangesInManagement: {
            changeInControl: {
              natureOfChangeInControl: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInControl
                  ?.natureOfChangeInControl,
                formData?.hasChangesInManagement?.changeInControl
                  ?.natureOfChangeInControl
              ),
              dateOfChange: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInControl
                  ?.dateOfChange,
                formData?.hasChangesInManagement?.changeInControl?.dateOfChange
              ),
              entitiesInvolved: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInControl
                  ?.entitiesInvolved,
                formData?.hasChangesInManagement?.changeInControl
                  ?.entitiesInvolved
              ),
              detailsOfControlTransfer: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInControl
                  ?.detailsOfControlTransfer,
                formData?.hasChangesInManagement?.changeInControl
                  ?.detailsOfControlTransfer
              ),
            },
            changeInOwnership: {
              natureOfOwnershipChange: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInOwnership
                  ?.natureOfOwnershipChange,
                formData?.hasChangesInManagement?.changeInOwnership
                  ?.natureOfOwnershipChange
              ),
              dateOfChange: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInOwnership
                  ?.dateOfChange,
                formData?.hasChangesInManagement?.changeInOwnership
                  ?.dateOfChange
              ),
              previousOwners: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInOwnership
                  ?.previousOwners,
                formData?.hasChangesInManagement?.changeInOwnership
                  ?.previousOwners
              ),
              newOwners: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInOwnership
                  ?.newOwners,
                formData?.hasChangesInManagement?.changeInOwnership?.newOwners
              ),
              reasonForChange: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInOwnership
                  ?.reasonForChange,
                formData?.hasChangesInManagement?.changeInOwnership
                  ?.reasonForChange
              ),
            },
            changeInManagement: {
              natureOfManagementChange: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInManagement
                  ?.natureOfManagementChange,
                formData?.hasChangesInManagement?.changeInManagement
                  ?.natureOfManagementChange
              ),
              dateOfChange: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInManagement
                  ?.dateOfChange,
                formData?.hasChangesInManagement?.changeInManagement
                  ?.dateOfChange
              ),
              previousManagement: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInManagement
                  ?.previousManagement,
                formData?.hasChangesInManagement?.changeInManagement
                  ?.previousManagement
              ),
              newManagement: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInManagement
                  ?.newManagement,
                formData?.hasChangesInManagement?.changeInManagement
                  ?.newManagement
              ),
              reasonForChange: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.changeInManagement
                  ?.reasonForChange,
                formData?.hasChangesInManagement?.changeInManagement
                  ?.reasonForChange
              ),
            },
            // impactOnCompany: {
            //   strategicImpact: getValue(
            //     showHistory,
            //     currentHistoryData?.hasChangesInManagement?.impactOnCompany
            //       ?.strategicImpact,
            //     formData?.hasChangesInManagement?.impactOnCompany
            //       ?.strategicImpact
            //   ),
            //   financialImpact: getValue(
            //     showHistory,
            //     currentHistoryData?.hasChangesInManagement?.impactOnCompany
            //       ?.financialImpact,
            //     formData?.hasChangesInManagement?.impactOnCompany
            //       ?.financialImpact
            //   ),
            //   operationalImpact: getValue(
            //     showHistory,
            //     currentHistoryData?.hasChangesInManagement?.impactOnCompany
            //       ?.operationalImpact,
            //     formData?.hasChangesInManagement?.impactOnCompany
            //       ?.operationalImpact
            //   ),
            //   employeeImpact: getValue(
            //     showHistory,
            //     currentHistoryData?.hasChangesInManagement?.impactOnCompany
            //       ?.employeeImpact,
            //     formData?.hasChangesInManagement?.impactOnCompany
            //       ?.employeeImpact
            //   ),
            // },
            documentation: {
              legalDocumentation: legalDocumentation,
              shareholderAgreements: shareholderAgreements,
              regulatoryFilings: regulatoryFilings,
            },
            futurePlans: {
              impendingChanges: getValue(
                showHistory,
                currentHistoryData?.hasChangesInManagement?.futurePlans
                  ?.impendingChanges,
                formData?.hasChangesInManagement?.futurePlans?.impendingChanges
              ),
            },
          },

          legalDocument: initialValues?.legalDocument,
          shareholderAgreements: initialValues?.shareholderAgreements,
          regulatoryFilings: initialValues?.regulatoryFilings,

          // liensFiledAgainstCompanyAcc:
          //   formik?.initialValues?.liensFiledAgainstCompanyAcc,
          // hasLiensFiledAgainstCompany: {
          //   generalInfo: {
          //     lienDetails: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.generalInfo
          //         ?.lienDetails,
          //       formData?.hasLiensFiledAgainstCompany?.generalInfo?.lienDetails
          //     ),
          //     dateOfFiling: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.generalInfo
          //         ?.dateOfFiling,
          //       formData?.hasLiensFiledAgainstCompany?.generalInfo?.dateOfFiling
          //     ),
          //     jurisdiction: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.generalInfo
          //         ?.jurisdiction,
          //       formData?.hasLiensFiledAgainstCompany?.generalInfo?.jurisdiction
          //     ),
          //     caseNumber: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.generalInfo
          //         ?.caseNumber,
          //       formData?.hasLiensFiledAgainstCompany?.generalInfo?.caseNumber
          //     ),
          //     courtFilings: courtFilings,
          //     lienStatus: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.generalInfo
          //         ?.lienStatus,
          //       formData?.hasLiensFiledAgainstCompany?.generalInfo?.lienStatus
          //     ),
          //   },
          //   claimantInfo: {
          //     nameOfClaimant: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.claimantInfo
          //         ?.nameOfClaimant,
          //       formData?.hasLiensFiledAgainstCompany?.claimantInfo
          //         ?.nameOfClaimant
          //     ),
          //     relationshipToCompany: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.claimantInfo
          //         ?.relationshipToCompany,
          //       formData?.hasLiensFiledAgainstCompany?.claimantInfo
          //         ?.relationshipToCompany
          //     ),
          //   },
          //   lienAmount: {
          //     filingAmount: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.lienAmount
          //         ?.filingAmount,
          //       formData?.hasLiensFiledAgainstCompany?.lienAmount?.filingAmount
          //     ),
          //     // breakdownOfAmount: string | null;
          //   },
          //   projectDetails: {
          //     projectInvolved: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.projectDetails
          //         ?.projectInvolved,
          //       formData?.hasLiensFiledAgainstCompany?.projectDetails
          //         ?.projectInvolved
          //     ),
          //     scopeOfWork: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.projectDetails
          //         ?.scopeOfWork,
          //       formData?.hasLiensFiledAgainstCompany?.projectDetails
          //         ?.scopeOfWork
          //     ),
          //     completionStatus: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.projectDetails
          //         ?.completionStatus,
          //       formData?.hasLiensFiledAgainstCompany?.projectDetails
          //         ?.completionStatus
          //     ),
          //   },
          //   disputeInfo: {
          //     reasonForLien: getValue(
          //       showHistory,
          //       currentHistoryData?.hasLiensFiledAgainstCompany?.disputeInfo
          //         ?.reasonForLien,
          //       formData?.hasLiensFiledAgainstCompany?.disputeInfo
          //         ?.reasonForLien
          //     ),
          //     // companyPosition: null,
          //   },
          //   // financialImpact: {
          //   //     impactOnCashFlow: null,
          //   //     contingentLiabilities: null,
          //   // },
          //   // legalAndResolutionDetails: {
          //   //     legalRepresentation:  null,
          //   //     courtFilings:  null,
          //   //     currentStatus:  null,
          //   //     resolutionEfforts:  null,
          //   //     outcomeOrSettlement:  null,
          //   // },
          // },
          courtFilings: initialValues?.courtFilings,

          // outstandingJudgmentsAcc: initialValues?.outstandingJudgmentsAcc,

          bondClaimsAcc: initialValues?.bondClaimsAcc,
          hasClaimsSuretybond: {
            typeofSuretyBond: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.typeofSuretyBond,
              formData?.hasClaimsSuretybond?.typeofSuretyBond
            ),
            bondAmount: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.bondAmount,
              formData?.hasClaimsSuretybond?.bondAmount
            ),
            bondNumber: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.bondNumber,
              formData?.hasClaimsSuretybond?.bondNumber
            ),
            issuingSuretyCompany: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.issuingSuretyCompany,
              formData?.hasClaimsSuretybond?.issuingSuretyCompany
            ),
            natureOftheClaim: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.natureOftheClaim,
              formData?.hasClaimsSuretybond?.natureOftheClaim
            ),
            dateOfClaim: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.dateOfClaim,
              formData?.hasClaimsSuretybond?.dateOfClaim
            ),
            claimantInformation: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.claimantInformation,
              formData?.hasClaimsSuretybond?.claimantInformation
            ),
            projectInvolved: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.projectInvolved,
              formData?.hasClaimsSuretybond?.projectInvolved
            ),
            scopeOfWork: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.scopeOfWork,
              formData?.hasClaimsSuretybond?.scopeOfWork
            ),
            contractAmount: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.contractAmount,
              formData?.hasClaimsSuretybond?.contractAmount
            ),
            claimAmount: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.claimAmount,
              formData?.hasClaimsSuretybond?.claimAmount
            ),
            breakdownOfClaim: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.breakdownOfClaim,
              formData?.hasClaimsSuretybond?.breakdownOfClaim
            ),
            currentStatusOfClaim: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.currentStatusOfClaim,
              formData?.hasClaimsSuretybond?.currentStatusOfClaim
            ),
            resolutionEfforts: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.resolutionEfforts,
              formData?.hasClaimsSuretybond?.resolutionEfforts
            ),
            outcomeSettlement: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.outcomeSettlement,
              formData?.hasClaimsSuretybond?.outcomeSettlement
            ),
            financialImpact: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.financialImpact,
              formData?.hasClaimsSuretybond?.financialImpact
            ),
            legalRepresentation: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.legalRepresentation,
              formData?.hasClaimsSuretybond?.legalRepresentation
            ),
            courtFilings: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.courtFilings,
              formData?.hasClaimsSuretybond?.courtFilings
            ),
            mitigationStrategies: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.mitigationStrategies,
              formData?.hasClaimsSuretybond?.mitigationStrategies
            ),
            regulatoryCompliance: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.regulatoryCompliance,
              formData?.hasClaimsSuretybond?.regulatoryCompliance
            ),
            potentialFutureClaims: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.potentialFutureClaims,
              formData?.hasClaimsSuretybond?.potentialFutureClaims
            ),
            strategicPlans: getValue(
              showHistory,
              currentHistoryData?.hasClaimsSuretybond?.strategicPlans,
              formData?.hasClaimsSuretybond?.strategicPlans
            ),
          },
          // liensFiledByCompanyAcc: initialValues?.liensFiledByCompanyAcc,

          buySellAgreementAcc: initialValues?.buySellAgreementAcc,
          hasBuySellAgreement: {
            natureOfAgreement: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.natureOfAgreement,
              formData?.hasBuySellAgreement?.natureOfAgreement
            ),
            dateOfAgreement: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.dateOfAgreement,
              formData?.hasBuySellAgreement?.dateOfAgreement
            ),
            partiesInvolved: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.partiesInvolved,
              formData?.hasBuySellAgreement?.partiesInvolved
            ),
            triggeringEvents: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.triggeringEvents,
              formData?.hasBuySellAgreement?.triggeringEvents
            ),
            valuationMethod: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.valuationMethod,
              formData?.hasBuySellAgreement?.valuationMethod
            ),
            fundingMechanism: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.fundingMechanism,
              formData?.hasBuySellAgreement?.fundingMechanism
            ),
            paymentTerms: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.paymentTerms,
              formData?.hasBuySellAgreement?.paymentTerms
            ),
            ownershipStructure: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.ownershipStructure,
              formData?.hasBuySellAgreement?.ownershipStructure
            ),
            shareholderOrPartnerInterests: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement
                ?.shareholderOrPartnerInterests,
              formData?.hasBuySellAgreement?.shareholderOrPartnerInterests
            ),
            insurancePolicies: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.insurancePolicies,
              formData?.hasBuySellAgreement?.insurancePolicies
            ),
            agreementCopy: agreementCopy,
            legalRepresentation: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.legalRepresentation,
              formData?.hasBuySellAgreement?.legalRepresentation
            ),
            operationalImpact: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.operationalImpact,
              formData?.hasBuySellAgreement?.operationalImpact
            ),
            managementSuccession: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.managementSuccession,
              formData?.hasBuySellAgreement?.managementSuccession
            ),
            regulatoryCompliance: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.regulatoryCompliance,
              formData?.hasBuySellAgreement?.regulatoryCompliance
            ),
            reviewAndUpdates: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.reviewAndUpdates,
              formData?.hasBuySellAgreement?.reviewAndUpdates
            ),
            mitigationStrategies: getValue(
              showHistory,
              currentHistoryData?.hasBuySellAgreement?.mitigationStrategies,
              formData?.hasBuySellAgreement?.mitigationStrategies
            ),
          },
          agreementCopy: initialValues?.agreementCopy,
          // relatedCompaniesFinancialStatements:
          //   initialValues?.relatedCompaniesFinancialStatements,
          // guaranteesForOtherPartiesFinancialStatements:
          //   initialValues?.guaranteesForOtherPartiesFinancialStatements,
          termsOfIndebtedness: initialValues?.termsOfIndebtedness,

          hasLiensFiledAgainstCompany:
            formData?.hasLiensFiledAgainstCompany &&
            formData?.hasLiensFiledAgainstCompany?.length > 0
              ? formData?.hasLiensFiledAgainstCompany
              : initialValues?.hasLiensFiledAgainstCompany,
          hasLiensFiledByCompany:
            formData?.hasLiensFiledByCompany &&
            formData?.hasLiensFiledByCompany?.length > 0
              ? formData?.hasLiensFiledByCompany
              : initialValues?.hasLiensFiledByCompany,
          hasStateFederalTax:
            formData?.hasStateFederalTax &&
            formData?.hasStateFederalTax?.length > 0
              ? formData?.hasStateFederalTax
              : initialValues?.hasStateFederalTax,

          outstandingJudgmentsAcc: outstandingJudgmentsAcc,
          hasOutstandingJudgments:
            formData?.hasOutstandingJudgments &&
            formData?.hasOutstandingJudgments?.length > 0
              ? formData?.hasOutstandingJudgments
              : initialValues?.hasOutstandingJudgments,
          hasOutstandingSuits:
            formData?.hasOutstandingSuits &&
            formData?.hasOutstandingSuits?.length > 0
              ? formData?.hasOutstandingSuits
              : initialValues?.hasOutstandingSuits,
          hasOutstandingClaims:
            formData?.hasOutstandingClaims &&
            formData?.hasOutstandingClaims?.length > 0
              ? formData?.hasOutstandingClaims
              : initialValues?.hasOutstandingClaims,

          paymentPlanUpload: initialValues?.paymentPlanUpload,

          hasBankruptcyHistory: formData?.hasBankruptcyHistory,
        };
        // setShowTextarea({
        //   hasHistory: getValue(
        //     showHistory,
        //     currentHistoryData?.receivershipOrBankruptcyHistory?.hasHistory,
        //     formData?.receivershipOrBankruptcyHistory?.hasHistory
        //   ),
        //   hasGuarantees: getValue(
        //     showHistory,
        //     currentHistoryData?.guaranteesForOtherParties?.hasGuarantees,
        //     formData?.guaranteesForOtherParties?.hasGuarantees
        //   ),
        //   hasRelatedCompanies: getValue(
        //     showHistory,
        //     currentHistoryData?.relatedCompanies?.hasRelatedCompanies,
        //     formData?.relatedCompanies?.hasRelatedCompanies
        //   ),
        //   hasChanges: getValue(
        //     showHistory,
        //     currentHistoryData?.changesInManagement?.hasChanges,
        //     formData?.changesInManagement?.hasChanges
        //   ),
        //   hasLiens: getValue(
        //     showHistory,
        //     currentHistoryData?.liensFiledAgainstCompany?.hasLiens,
        //     formData?.liensFiledAgainstCompany?.hasLiens
        //   ),
        //   hasLiensFiledByCompany: getValue(
        //     showHistory,
        //     currentHistoryData?.liensFiledByCompany?.hasLiens,
        //     formData?.liensFiledByCompany?.hasLiens
        //   ),
        //   hasOutstandingJudgments: getValue(
        //     showHistory,
        //     currentHistoryData?.outstandingJudgments?.hasOutstandingJudgments,
        //     formData?.outstandingJudgments?.hasOutstandingJudgments
        //   ),
        //   hasDisputes: getValue(
        //     showHistory,
        //     currentHistoryData?.disputesWithOthers?.hasDisputes,
        //     formData?.disputesWithOthers?.hasDisputes
        //   ),
        //   hasDisputesWithSuppliers: getValue(
        //     showHistory,
        //     currentHistoryData?.disputesWithSuppliers?.hasDisputes,
        //     formData?.disputesWithSuppliers?.hasDisputes
        //   ),
        // });
        setInitialValues(apiValues);
      }
    }, [formData, currentHistoryData, AccoutingFormData]);

    const [initialValues, setInitialValues] = useState<TLegal>({
      applicationId: applicationId,

      brokerId: brokerId,
      applicantId: formData?.applicantId,
      receivershipOrBankruptcyHistory: {
        hasHistory: null,
        details: null,
      },
      guaranteesForOtherParties: {
        hasGuarantees: null,
        details: null,
      },

      relatedCompanies: {
        hasRelatedCompanies: null,
        companies: [
          {
            name: null,
            natureOfOperations: null,
            ownershipStructure: null,
          },
        ],
      },
      changesInManagement: {
        hasChanges: null,
        explanation: null,
      },
      liensFiledAgainstCompany: {
        hasLiens: null,
        // amount: null,
        details: null,
      },
      stateFederalTax: null,
      paymentPlan: null,
      // taxingEntityDoc: null,
      outstandingJudgments: {
        hasOutstandingJudgments: null,
        details: null,
      },
      bondClaims: null,
      claimsPaid: null,
      suretyMade: null,
      whyNotMade: null,
      disputesWithOthers: {
        hasDisputes: null,
        details: null,
      },
      liensFiledByCompany: {
        hasLiens: null,
        details: null,
      },
      disputesWithSuppliers: {
        hasDisputes: null,
        details: null,
        fundingMeans: null,
      },
      buySellAgreement: null,
      agreementDetails: null,
      returnToSender: userType === 'broker' ? false : null,
      applicationName: '',

      // receivershipOrBankruptcyAcc: [
      //   {
      //     name: 'Bankruptcy',
      //     // tableData: null,
      //     fieldArray: null,
      //     // columns: [],
      //   },
      //   {
      //     name: 'Insolvency',
      //     // tableData: null,
      //     fieldArray: null,
      //     // columns: [],
      //   },
      //   {
      //     name: 'Liquidation',
      //     // tableData: null,
      //     fieldArray: null,
      //     // columns: [],
      //   },
      // ],
      receivershipOrBankruptcyAcc: null,
      guaranteesForOtherPartiesAcc: [
        {
          name: 'guaranteeDetails',
          subSection: [
            {
              name: 'Guarantor Information',
              fields: [
                {
                  label: 'Name of the Guarantor(s)',
                  name: 'hasGuarantees.guaranteeDetails.guarantorInformation.nameOfGuarantors',
                },
                {
                  label: 'Position(s)',
                  name: 'hasGuarantees.guaranteeDetails.guarantorInformation.position',
                },
              ],
              sectionNo: 1,
            },
            {
              name: 'Beneficiary Information',
              fields: [
                {
                  label: 'Name of the Beneficiary',
                  name: 'hasGuarantees.guaranteeDetails.beneficiaryInformation.nameOfBeneficiary',
                },
                {
                  label: 'Relationship to Guarantor',
                  name: 'hasGuarantees.guaranteeDetails.beneficiaryInformation.relationshipToGuarantor',
                },
              ],
              sectionNo: 2,
            },
            {
              name: 'Guarantee Agreement Details',
              fields: [
                {
                  label: 'Type of Guarantee',
                  name: 'hasGuarantees.guaranteeDetails.guaranteeAgreementDetails.typeOfGuarantee',
                },
                {
                  label: 'Date of Agreement',
                  name: 'hasGuarantees.guaranteeDetails.guaranteeAgreementDetails.dateOfAgreement',
                  type: 'date',
                  refState: guaranteesForOtherPartiesRef,
                },
                {
                  label: 'Duration of Guarantee',
                  name: 'hasGuarantees.guaranteeDetails.guaranteeAgreementDetails.durationOfGuarantee',
                },
              ],
              sectionNo: 3,
            },
            {
              name: 'Financial Details',
              fields: [
                {
                  label: 'Amount Guaranteed',
                  name: 'hasGuarantees.guaranteeDetails.financialDetails.amountGuaranteed',
                },
                {
                  label: 'Currency',
                  name: 'hasGuarantees.guaranteeDetails.financialDetails.currency',
                },
                {
                  label: 'Terms of Indebtedness',
                  name: 'hasGuarantees.guaranteeDetails.financialDetails.termsOfIndebtedness',
                  type: 'file',
                  uploadState: 'termsOfIndebtedness',
                },
              ],
              sectionNo: 4,
            },
            {
              name: 'Collateral and Security',
              fields: [
                {
                  label: 'Collateral Provided',
                  name: 'hasGuarantees.guaranteeDetails.collateralAndSecurity.collateralProvided',
                },
                {
                  label: 'Valuation of Collateral',
                  name: 'hasGuarantees.guaranteeDetails.collateralAndSecurity.valuationOfCollateral',
                },
              ],
              sectionNo: 5,
            },
          ],
        },
        {
          name: 'impactOnFinancialHealth',
          subSection: [
            {
              name: 'Current Status of Indebtedness',
              fields: [
                {
                  label: 'Outstanding Balance',
                  name: 'hasGuarantees.impactOnFinancialHealth.currentStatusOfIndebtedness.outstandingBalance',
                },
                {
                  label: 'Payment Status',
                  name: 'hasGuarantees.impactOnFinancialHealth.currentStatusOfIndebtedness.paymentStatus',
                },
              ],
              sectionNo: 6,
            },
            // {
            //   name: 'Impact on Financial Statements',
            //   fields: [
            //     {
            //       label: 'Financial Statements',
            //       name: 'hasGuarantees.impactOnFinancialHealth.impactOnFinancialStatements.financialStatements',
            //       type: 'file',
            //       uploadState: 'guaranteesForOtherPartiesFinancialStatements',
            //     },
            //     {
            //       label: 'Contingent Liabilities',
            //       name: 'hasGuarantees.impactOnFinancialHealth.impactOnFinancialStatements.contingentLiabilities',
            //     },
            //   ],
            //   sectionNo: 7,
            // },
            // {
            //   name: 'Credit Reports',
            //   fields: [
            //     {
            //       label: 'Credit Reports of Guarantor(s)',
            //       name: 'hasGuarantees.impactOnFinancialHealth.creditReports.creditReportsOfGuarantors',
            //     },
            //     {
            //       label: 'Credit Reports of Beneficiary',
            //       name: 'hasGuarantees.impactOnFinancialHealth.creditReports.creditReportsOfBeneficiary',
            //     },
            //   ],
            //   sectionNo: 8,
            // },
          ],
        },
        {
          name: 'legalAndRegulatoryCompliance',
          subSection: [
            {
              name: 'Guarantee Agreement Documentation',
              fields: [
                {
                  label: 'Legal Documents',
                  name: 'hasGuarantees.legalAndRegulatoryCompliance.guaranteeAgreementDocumentation.legalDocuments',
                  type: 'file',
                  uploadState: 'uploadLegalDoc',
                },
                {
                  label: 'Court Filings',
                  name: 'hasGuarantees.legalAndRegulatoryCompliance.guaranteeAgreementDocumentation.courtFilings',
                },
              ],
              sectionNo: 7,
              // sectionNo: 9,
            },
            // {
            //   name: 'Regulatory Compliance',
            //   fields: [
            //     {
            //       label: 'Compliance Status',
            //       name: 'hasGuarantees.legalAndRegulatoryCompliance.regulatoryCompliance.complianceStatus',
            //     },
            //   ],
            //   sectionNo: 10,
            // },
          ],
        },
        // {
        //   name: 'Risk Mitigation and Recovery',
        //   subSection: [
        //     {
        //       name: 'Risk Mitigation Measures',
        //       fields: [
        //         {
        //           label: 'Risk Management Plans',
        //           name: 'hasGuarantees.riskMitigationAndRecovery.riskMitigationMeasures.riskManagementPlans',
        //         },
        //         {
        //           label: 'Insurance Coverage',
        //           name: 'hasGuarantees.riskMitigationAndRecovery.riskMitigationMeasures.insuranceCoverage',
        //         },
        //         {
        //           label: 'Recovery Strategy',
        //           name: 'hasGuarantees.riskMitigationAndRecovery.riskMitigationMeasures.recoveryStrategy',
        //         },
        //       ],
        //       sectionNo: 11,
        //     },
        //   ],
        // },
      ],

      hasGuarantees: {
        guaranteeDetails: {
          guarantorInformation: {
            nameOfGuarantors: null,
            position: null,
          },
          beneficiaryInformation: {
            nameOfBeneficiary: null,
            relationshipToGuarantor: null,
          },
          guaranteeAgreementDetails: {
            typeOfGuarantee: null,
            dateOfAgreement: null,
            durationOfGuarantee: null,
          },
          financialDetails: {
            amountGuaranteed: null,
            currency: null,
            termsOfIndebtedness: [
              {
                linkId: null,
                link: null,
                urlKey: null,
              },
            ],
          },
          collateralAndSecurity: {
            collateralProvided: null,
            valuationOfCollateral: null,
          },
        },

        impactOnFinancialHealth: {
          currentStatusOfIndebtedness: {
            outstandingBalance: null,
            paymentStatus: null,
          },
          // impactOnFinancialStatements: {
          //   financialStatements: [
          //     {
          //       linkId: null,
          //       link: null,
          //       urlKey: null,
          //     },
          //   ],
          //   contingentLiabilities: null,
          // },
          // creditReports: {
          //   creditReportsOfGuarantors: null,
          //   creditReportsOfBeneficiary: null,
          // },
        },

        legalAndRegulatoryCompliance: {
          guaranteeAgreementDocumentation: {
            legalDocuments: [
              {
                linkId: null,
                link: null,
                urlKey: null,
              },
            ],
            courtFilings: null,
          },
          // regulatoryCompliance: {
          //   complianceStatus: null,
          // },
        },

        // riskMitigationAndRecovery: {
        //   riskMitigationMeasures: {
        //     riskManagementPlans: null,
        //     insuranceCoverage: null,
        //     recoveryStrategy: null,
        //   },
        // },
      },
      uploadLegalDoc: null,

      // relatedCompaniesAcc: [
      //   {
      //     parentAccName: 'Global Ultimate',
      //     parentAccContent: [
      //       {
      //         name: 'General Information',
      //         fields: [
      //           {
      //             label: 'Names of Related Companies',
      //             name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.namesOfRelatedCompanies',
      //           },
      //           {
      //             label: 'Type of Relationship',
      //             name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.typeOfRelationship',
      //           },
      //           {
      //             label: 'Ownership Structure',
      //             name: 'hasRelatedCompanies.globalUltimate.generalInfoRelatedCompanies.ownershipStructure',
      //           },
      //         ],
      //       },
      //       {
      //         name: 'Company Details',
      //         fields: [
      //           {
      //             label: 'Business Activities',
      //             name: 'hasRelatedCompanies.globalUltimate.companyDetails.businessActivities',
      //           },
      //           {
      //             label: 'Locations',
      //             name: 'hasRelatedCompanies.globalUltimate.companyDetails.locations',
      //           },
      //         ],
      //       },
      //       // {
      //       //   name: 'Financial Information',
      //       //   fields: [
      //       //     {
      //       //       label: 'Financial Statements',
      //       //       name: 'hasRelatedCompanies.financialInformation.financialStatements',
      //       //       type: 'file',
      //       //       uploadState: 'relatedCompaniesFinancialStatements',
      //       //     },
      //       //     {
      //       //       label: 'Intercompany Transactions',
      //       //       name: 'hasRelatedCompanies.financialInformation.intercompanyTransactions',
      //       //     },
      //       //     {
      //       //       label: 'Debt and Liabilities',
      //       //       name: 'hasRelatedCompanies.financialInformation.debtAndLiabilities',
      //       //     },
      //       //   ],
      //       // },
      //       {
      //         name: 'Operational and Management Details',
      //         fields: [
      //           // {
      //           //   label: 'Management Structure',
      //           //   name: 'hasRelatedCompanies.operationalAndManagementDetails.managementStructure',
      //           // },
      //           {
      //             label: 'Operational Dependencies',
      //             name: 'hasRelatedCompanies.globalUltimate.operationalAndManagementDetails.operationalDependencies',
      //           },
      //         ],
      //       },
      //       // {
      //       //   name: 'Legal and Regulatory Information',
      //       //   fields: [
      //       //     {
      //       //       label: 'Legal Structure',
      //       //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.legalStructure',
      //       //     },
      //       //     {
      //       //       label: 'Compliance Status',
      //       //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.complianceStatus',
      //       //     },
      //       //   ],
      //       // },
      //       // {
      //       //   name: 'Risk Assessment',
      //       //   fields: [
      //       //     {
      //       //       label: 'Risk Exposure',
      //       //       name: 'hasRelatedCompanies.riskAssessment.riskExposure',
      //       //     },
      //       //   ],
      //       // },
      //       // {
      //       //   name: 'Strategic Information',
      //       //   fields: [
      //       //     {
      //       //       label: 'Strategic Importance',
      //       //       name: 'hasRelatedCompanies.strategicInformation.strategicImportance',
      //       //     },
      //       //   ],
      //       // },
      //     ],
      //   },
      //   {
      //     parentAccName: 'Domestic Ultimate',
      //     parentAccContent: [
      //       {
      //         name: 'General Information',
      //         fields: [
      //           {
      //             label: 'Names of Related Companies',
      //             name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.namesOfRelatedCompanies',
      //           },
      //           {
      //             label: 'Type of Relationship',
      //             name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.typeOfRelationship',
      //           },
      //           {
      //             label: 'Ownership Structure',
      //             name: 'hasRelatedCompanies.domesticUltimate.generalInfoRelatedCompanies.ownershipStructure',
      //           },
      //         ],
      //       },
      //       {
      //         name: 'Company Details',
      //         fields: [
      //           {
      //             label: 'Business Activities',
      //             name: 'hasRelatedCompanies.domesticUltimate.companyDetails.businessActivities',
      //           },
      //           {
      //             label: 'Locations',
      //             name: 'hasRelatedCompanies.domesticUltimate.companyDetails.locations',
      //           },
      //         ],
      //       },
      //       // {
      //       //   name: 'Financial Information',
      //       //   fields: [
      //       //     {
      //       //       label: 'Financial Statements',
      //       //       name: 'hasRelatedCompanies.financialInformation.financialStatements',
      //       //       type: 'file',
      //       //       uploadState: 'relatedCompaniesFinancialStatements',
      //       //     },
      //       //     {
      //       //       label: 'Intercompany Transactions',
      //       //       name: 'hasRelatedCompanies.financialInformation.intercompanyTransactions',
      //       //     },
      //       //     {
      //       //       label: 'Debt and Liabilities',
      //       //       name: 'hasRelatedCompanies.financialInformation.debtAndLiabilities',
      //       //     },
      //       //   ],
      //       // },
      //       {
      //         name: 'Operational and Management Details',
      //         fields: [
      //           // {
      //           //   label: 'Management Structure',
      //           //   name: 'hasRelatedCompanies.operationalAndManagementDetails.managementStructure',
      //           // },
      //           {
      //             label: 'Operational Dependencies',
      //             name: 'hasRelatedCompanies.domesticUltimate.operationalAndManagementDetails.operationalDependencies',
      //           },
      //         ],
      //       },
      //       // {
      //       //   name: 'Legal and Regulatory Information',
      //       //   fields: [
      //       //     {
      //       //       label: 'Legal Structure',
      //       //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.legalStructure',
      //       //     },
      //       //     {
      //       //       label: 'Compliance Status',
      //       //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.complianceStatus',
      //       //     },
      //       //   ],
      //       // },
      //       // {
      //       //   name: 'Risk Assessment',
      //       //   fields: [
      //       //     {
      //       //       label: 'Risk Exposure',
      //       //       name: 'hasRelatedCompanies.riskAssessment.riskExposure',
      //       //     },
      //       //   ],
      //       // },
      //       // {
      //       //   name: 'Strategic Information',
      //       //   fields: [
      //       //     {
      //       //       label: 'Strategic Importance',
      //       //       name: 'hasRelatedCompanies.strategicInformation.strategicImportance',
      //       //     },
      //       //   ],
      //       // },
      //     ],
      //   },
      //   {
      //     parentAccName: 'Parent',
      //     parentAccContent: [
      //       {
      //         name: 'General Information',
      //         fields: [
      //           {
      //             label: 'Names of Related Companies',
      //             name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.namesOfRelatedCompanies',
      //           },
      //           {
      //             label: 'Type of Relationship',
      //             name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.typeOfRelationship',
      //           },
      //           {
      //             label: 'Ownership Structure',
      //             name: 'hasRelatedCompanies.parent.generalInfoRelatedCompanies.ownershipStructure',
      //           },
      //         ],
      //       },
      //       {
      //         name: 'Company Details',
      //         fields: [
      //           {
      //             label: 'Business Activities',
      //             name: 'hasRelatedCompanies.parent.companyDetails.businessActivities',
      //           },
      //           {
      //             label: 'Locations',
      //             name: 'hasRelatedCompanies.parent.companyDetails.locations',
      //           },
      //         ],
      //       },
      //       // {
      //       //   name: 'Financial Information',
      //       //   fields: [
      //       //     {
      //       //       label: 'Financial Statements',
      //       //       name: 'hasRelatedCompanies.financialInformation.financialStatements',
      //       //       type: 'file',
      //       //       uploadState: 'relatedCompaniesFinancialStatements',
      //       //     },
      //       //     {
      //       //       label: 'Intercompany Transactions',
      //       //       name: 'hasRelatedCompanies.financialInformation.intercompanyTransactions',
      //       //     },
      //       //     {
      //       //       label: 'Debt and Liabilities',
      //       //       name: 'hasRelatedCompanies.financialInformation.debtAndLiabilities',
      //       //     },
      //       //   ],
      //       // },
      //       {
      //         name: 'Operational and Management Details',
      //         fields: [
      //           // {
      //           //   label: 'Management Structure',
      //           //   name: 'hasRelatedCompanies.operationalAndManagementDetails.managementStructure',
      //           // },
      //           {
      //             label: 'Operational Dependencies',
      //             name: 'hasRelatedCompanies.parent.operationalAndManagementDetails.operationalDependencies',
      //           },
      //         ],
      //       },
      //       // {
      //       //   name: 'Legal and Regulatory Information',
      //       //   fields: [
      //       //     {
      //       //       label: 'Legal Structure',
      //       //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.legalStructure',
      //       //     },
      //       //     {
      //       //       label: 'Compliance Status',
      //       //       name: 'hasRelatedCompanies.legalAndRegulatoryInformation.complianceStatus',
      //       //     },
      //       //   ],
      //       // },
      //       // {
      //       //   name: 'Risk Assessment',
      //       //   fields: [
      //       //     {
      //       //       label: 'Risk Exposure',
      //       //       name: 'hasRelatedCompanies.riskAssessment.riskExposure',
      //       //     },
      //       //   ],
      //       // },
      //       // {
      //       //   name: 'Strategic Information',
      //       //   fields: [
      //       //     {
      //       //       label: 'Strategic Importance',
      //       //       name: 'hasRelatedCompanies.strategicInformation.strategicImportance',
      //       //     },
      //       //   ],
      //       // },
      //     ],
      //   },
      // ],
      relatedCompaniesAcc: null,
      hasRelatedCompanies: null,

      changesInManagementAcc: [
        {
          name: 'Change in Control',
          fields: [
            {
              label: 'Nature of Change in Control',
              name: 'hasChangesInManagement.changeInControl.natureOfChangeInControl',
            },
            {
              label: 'Date of Change',
              name: 'hasChangesInManagement.changeInControl.dateOfChange',
              type: 'date',
              refState: changeInControlRef,
            },
            {
              label: 'Entities Involved',
              name: 'hasChangesInManagement.changeInControl.entitiesInvolved',
            },
            {
              label: 'Details of Control Transfer',
              name: 'hasChangesInManagement.changeInControl.detailsOfControlTransfer',
            },
          ],
        },
        {
          name: 'Change in Ownership',
          fields: [
            {
              label: 'Nature of Ownership Change',
              name: 'hasChangesInManagement.changeInOwnership.natureOfOwnershipChange',
            },
            {
              label: 'Date of Change',
              name: 'hasChangesInManagement.changeInOwnership.dateOfChange',
              type: 'date',
              refState: changeInOwnershipRef,
            },
            {
              label: 'Previous Owners',
              name: 'hasChangesInManagement.changeInOwnership.previousOwners',
            },
            {
              label: 'New Owners',
              name: 'hasChangesInManagement.changeInOwnership.newOwners',
            },
            {
              label: 'Reason for Change',
              name: 'hasChangesInManagement.changeInOwnership.reasonForChange',
            },
          ],
        },
        {
          name: 'Change in Management',
          fields: [
            {
              label: 'Nature of Management Change',
              name: 'hasChangesInManagement.changeInManagement.natureOfManagementChange',
            },
            {
              label: 'Date of Change',
              name: 'hasChangesInManagement.changeInManagement.dateOfChange',
              type: 'date',
              refState: changeInManagementRef,
            },
            {
              label: 'Previous Management',
              name: 'hasChangesInManagement.changeInManagement.previousManagement',
            },
            {
              label: 'New Management',
              name: 'hasChangesInManagement.changeInManagement.newManagement',
            },
            {
              label: 'Reason for Change',
              name: 'hasChangesInManagement.changeInManagement.reasonForChange',
            },
          ],
        },
        // {
        //   name: 'Impact on Company',
        //   fields: [
        //     {
        //       label: 'Strategic Impact',
        //       name: 'hasChangesInManagement.impactOnCompany.strategicImpact',
        //     },
        //     {
        //       label: 'Financial Impact',
        //       name: 'hasChangesInManagement.impactOnCompany.financialImpact',
        //     },
        //     {
        //       label: 'Operational Impact',
        //       name: 'hasChangesInManagement.impactOnCompany.operationalImpact',
        //     },
        //     {
        //       label: 'Employee Impact',
        //       name: 'hasChangesInManagement.impactOnCompany.employeeImpact',
        //     },
        //   ],
        // },
        {
          name: 'Documentation',
          fields: [
            {
              label: 'Legal Documentation',
              name: 'hasChangesInManagement.documentation.legalDocumentation',
              type: 'file',
              uploadState: 'legalDocument',
            },
            {
              label: 'Shareholder Agreements',
              name: 'hasChangesInManagement.documentation.shareholderAgreements',
              type: 'file',
              uploadState: 'shareholderAgreements',
            },
            {
              label: 'Regulatory Filings',
              name: 'hasChangesInManagement.documentation.regulatoryFilings',
              type: 'file',
              uploadState: 'regulatoryFilings',
            },
          ],
        },
        {
          name: 'Future Plans',
          fields: [
            {
              label: 'Impending Changes',
              name: 'hasChangesInManagement.futurePlans.impendingChanges',
            },
          ],
        },
      ],

      hasChangesInManagement: {
        changeInControl: {
          natureOfChangeInControl: null,
          dateOfChange: null,
          entitiesInvolved: null,
          detailsOfControlTransfer: null,
        },

        changeInOwnership: {
          natureOfOwnershipChange: null,
          dateOfChange: null,
          previousOwners: null,
          newOwners: null,
          reasonForChange: null,
        },

        changeInManagement: {
          natureOfManagementChange: null,
          dateOfChange: null,
          previousManagement: null,
          newManagement: null,
          reasonForChange: null,
        },

        // impactOnCompany: {
        //   strategicImpact: null,
        //   financialImpact: null,
        //   operationalImpact: null,
        //   employeeImpact: null,
        // },

        documentation: {
          legalDocumentation: [
            {
              linkId: null,
              link: null,
              urlKey: null,
            },
          ],
          shareholderAgreements: [
            {
              linkId: null,
              link: null,
              urlKey: null,
            },
          ],
          regulatoryFilings: [
            {
              linkId: null,
              link: null,
              urlKey: null,
            },
          ],
        },

        futurePlans: {
          impendingChanges: null,
        },
      },
      legalDocument: null,
      shareholderAgreements: null,
      regulatoryFilings: null,

      // liensFiledAgainstCompanyAcc: [
      //   {
      //     name: 'General Information',
      //     fields: [
      //       {
      //         label: 'Lien Details',
      //         name: '',
      //       },
      //       {
      //         label: 'Date of Filing',
      //         name: '',
      //         type: 'date',
      //         refState: liensFiledAgainstCompanyDateRef,
      //       },
      //       {
      //         label: 'Jurisdiction',
      //         name: '',
      //       },
      //       {
      //         label: 'Case Number',
      //         name: '',
      //       },
      //       {
      //         label: 'Court Filings',
      //         name: '',
      //         type: 'file',
      //         uploadState: 'courtFilings',
      //       },
      //       {
      //         label: 'Lien Status',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Claimant Information',
      //     fields: [
      //       {
      //         label: 'Name of Claimant',
      //         name: '',
      //       },
      //       {
      //         label: 'Relationship to Company',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Lien Amount',
      //     fields: [
      //       {
      //         label: 'Filing Amount',
      //         name: '',
      //       },
      //       // {
      //       //   label: 'Breakdown of Amount',
      //       //   name: '',
      //       // },
      //     ],
      //   },
      //   {
      //     name: 'Project Details',
      //     fields: [
      //       {
      //         label: 'Project Involved',
      //         name: '',
      //       },
      //       {
      //         label: 'Scope of Work',
      //         name: '',
      //       },
      //       {
      //         label: 'Completion Status',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Dispute Information',
      //     fields: [
      //       {
      //         label: 'Reason for Lien',
      //         name: '',
      //       },
      //       // {
      //       //   label: 'Company’s Position',
      //       //   name: '',
      //       // },
      //     ],
      //   },
      //   // {
      //   //   name: 'Financial Impact',
      //   //   fields: [
      //   //     {
      //   //       label: 'Impact on Cash Flow',
      //   //       name: '',
      //   //     },
      //   //     {
      //   //       label: 'Contingent Liabilities',
      //   //       name: '',
      //   //     },
      //   //   ],
      //   // },
      //   // {
      //   //   name: 'Legal and Resolution Details',
      //   //   fields: [
      //   //     {
      //   //       label: 'Legal Representation',
      //   //       name: '',
      //   //     },
      //   //     {
      //   //       label: 'Court Filings',
      //   //       name: '',
      //   //       type: 'file',
      //   //       uploadState: 'courtFilings',
      //   //     },
      //   //     {
      //   //       label: 'Lien Status',
      //   //       name: '',
      //   //     },
      //   //     {
      //   //       label: 'Resolution Efforts',
      //   //       name: '',
      //   //     },
      //   //     {
      //   //       label: 'Outcome or Settlement',
      //   //       name: '',
      //   //     },
      //   //   ],
      //   // },
      // ],
      // hasLiensFiledAgainstCompany: {
      //   generalInfo: {
      //     lienDetails: null,
      //     dateOfFiling: null,
      //     jurisdiction: null,
      //     caseNumber: null,
      //     courtFilings: [
      //       {
      //         linkId: null,
      //         link: null,
      //         urlKey: null,
      //       },
      //     ],
      //     lienStatus: null,
      //   },
      //   claimantInfo: {
      //     nameOfClaimant: null,
      //     relationshipToCompany: null,
      //   },
      //   lienAmount: {
      //     filingAmount: null,
      //     // breakdownOfAmount: string | null;
      //   },
      //   projectDetails: {
      //     projectInvolved: null,
      //     scopeOfWork: null,
      //     completionStatus: null,
      //   },
      //   disputeInfo: {
      //     reasonForLien: null,
      //     // companyPosition: null,
      //   },
      //   // financialImpact: {
      //   //     impactOnCashFlow: null,
      //   //     contingentLiabilities: null,
      //   // },
      //   // legalAndResolutionDetails: {
      //   //     legalRepresentation:  null,
      //   //     courtFilings:  null,
      //   //     currentStatus:  null,
      //   //     resolutionEfforts:  null,
      //   //     outcomeOrSettlement:  null,
      //   // },
      // },
      courtFilings: null,

      // outstandingJudgmentsAcc: [
      //   {
      //     name: 'General Information',
      //     fields: [
      //       {
      //         label: 'Type of Legal Action',
      //         name: '',
      //       },
      //       {
      //         label: 'Date of Filing',
      //         name: '',
      //         type: 'date',
      //         refState: outstandingJudgmentsRef,
      //       },
      //       {
      //         label: 'Jurisdiction',
      //         name: '',
      //       },
      //       {
      //         label: 'Case Number',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Claimant Information',
      //     fields: [
      //       {
      //         label: 'Name of Claimant',
      //         name: '',
      //       },
      //       {
      //         label: 'Relationship to Company',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Details of the Legal Action',
      //     fields: [
      //       {
      //         label: 'Nature of Claim',
      //         name: '',
      //       },
      //       {
      //         label: 'Amount Claimed',
      //         name: '',
      //       },
      //       {
      //         label: 'Parties Involved',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Status of Legal Action',
      //     fields: [
      //       {
      //         label: 'Current Status',
      //         name: '',
      //       },
      //       {
      //         label: 'Court Filings',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Financial Impact',
      //     fields: [
      //       {
      //         label: 'Potential Financial Exposure',
      //         name: '',
      //       },
      //       {
      //         label: 'Impact on Financial Statements',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Resolution and Settlement Efforts',
      //     fields: [
      //       {
      //         label: 'Settlement Attempts',
      //         name: '',
      //       },
      //       {
      //         label: 'Outcome or Resolution',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Risk Assessment and Mitigation',
      //     fields: [
      //       {
      //         label: 'Mitigation Strategies',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Operational Impact',
      //     fields: [
      //       {
      //         label: 'Impact on Operations',
      //         name: '',
      //       },
      //       {
      //         label: 'Employee Impact',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Communication',
      //     fields: [
      //       {
      //         label: 'Regulatory Compliance',
      //         name: '',
      //       },
      //     ],
      //   },
      // ],

      bondClaimsAcc: [
        {
          name: 'General Information',
          fields: [
            {
              label: 'Type of Surety Bond',
              name: 'hasClaimsSuretybond.typeofSuretyBond',
            },
            {
              label: 'Bond Amount',
              name: 'hasClaimsSuretybond.bondAmount',
            },
            {
              label: 'Bond Number',
              name: 'hasClaimsSuretybond.bondNumber',
            },
            {
              label: 'Issuing Surety Company',
              name: 'hasClaimsSuretybond.issuingSuretyCompany',
            },
          ],
        },
        {
          name: 'Claim Details',
          fields: [
            {
              label: 'Nature of the Claim',
              name: 'hasClaimsSuretybond.natureOftheClaim',
            },
            {
              label: 'Date of Claim',
              name: 'hasClaimsSuretybond.dateOfClaim',
              type: 'date',
              refState: bondClaimsRef,
            },
            {
              label: 'Claimant Information',
              name: 'hasClaimsSuretybond.claimantInformation',
            },
          ],
        },
        {
          name: 'Project Details',
          fields: [
            {
              label: 'Project Involved',
              name: 'hasClaimsSuretybond.projectInvolved',
            },
            {
              label: 'Scope of Work',
              name: 'hasClaimsSuretybond.scopeOfWork',
            },
            {
              label: 'Contract Amount',
              name: 'hasClaimsSuretybond.contractAmount',
            },
          ],
        },
        {
          name: 'Financial Details',
          fields: [
            {
              label: 'Claim Amount',
              name: 'hasClaimsSuretybond.claimAmount',
            },
            {
              label: 'Breakdown of Claim',
              name: 'hasClaimsSuretybond.breakdownOfClaim',
            },
          ],
        },
        {
          name: 'Resolution and Settlement',
          fields: [
            {
              label: 'Current Status of Claim',
              name: 'hasClaimsSuretybond.currentStatusOfClaim',
            },
            {
              label: 'Resolution Efforts',
              name: 'hasClaimsSuretybond.resolutionEfforts',
            },
            {
              label: 'Outcome or Settlement',
              name: 'hasClaimsSuretybond.outcomeSettlement',
            },
          ],
        },
        {
          name: 'Impact on Company',
          fields: [
            {
              label: 'Financial Impact',
              name: 'hasClaimsSuretybond.financialImpact',
            },
          ],
        },
        {
          name: 'Legal and Documentation',
          fields: [
            {
              label: 'Legal Representation',
              name: 'hasClaimsSuretybond.legalRepresentation',
            },
            {
              label: 'Court Filings',
              name: 'hasClaimsSuretybond.courtFilings',
            },
          ],
        },
        {
          name: 'Risk Assessment and Mitigation',
          fields: [
            {
              label: 'Mitigation Strategies',
              name: 'hasClaimsSuretybond.mitigationStrategies',
            },
          ],
        },
        {
          name: 'Communication',
          fields: [
            {
              label: 'Regulatory Compliance',
              name: 'hasClaimsSuretybond.regulatoryCompliance',
            },
          ],
        },
        {
          name: 'Future Risks',
          fields: [
            {
              label: 'Potential Future Claims',
              name: 'hasClaimsSuretybond.potentialFutureClaims',
            },
            {
              label: 'Strategic Plans',
              name: 'hasClaimsSuretybond.strategicPlans',
            },
          ],
        },
      ],
      hasClaimsSuretybond: {
        typeofSuretyBond: null,
        bondAmount: null,
        bondNumber: null,
        issuingSuretyCompany: null,
        natureOftheClaim: null,
        dateOfClaim: null,
        claimantInformation: null,
        projectInvolved: null,
        scopeOfWork: null,
        contractAmount: null,
        claimAmount: null,
        breakdownOfClaim: null,
        currentStatusOfClaim: null,
        resolutionEfforts: null,
        outcomeSettlement: null,
        financialImpact: null,
        legalRepresentation: null,
        courtFilings: null,
        mitigationStrategies: null,
        regulatoryCompliance: null,
        potentialFutureClaims: null,
        strategicPlans: null,
      },

      // liensFiledByCompanyAcc: [
      //   {
      //     name: 'General Information',
      //     fields: [
      //       {
      //         label: 'Type of Lien',
      //         name: '',
      //       },
      //       {
      //         label: 'Date of Filing',
      //         name: '',
      //         type: 'date',
      //         refState: liensFiledByCompanyRef,
      //       },
      //       {
      //         label: 'Jurisdiction',
      //         name: '',
      //       },
      //       {
      //         label: 'Case Number',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Claimant Information',
      //     fields: [
      //       {
      //         label: 'Name of Claimant',
      //         name: '',
      //       },
      //       {
      //         label: 'Relationship to Third Party',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Third Party Information',
      //     fields: [
      //       {
      //         label: 'Name of Third Party',
      //         name: '',
      //       },
      //       {
      //         label: 'Third Party’s Role',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Lien Amount',
      //     fields: [
      //       {
      //         label: 'Total Amount Claimed',
      //         name: '',
      //       },
      //       {
      //         label: 'Breakdown of Amount',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Project Details',
      //     fields: [
      //       {
      //         label: 'Project Involved',
      //         name: '',
      //       },
      //       {
      //         label: 'Scope of Work',
      //         name: '',
      //       },
      //       {
      //         label: 'Completion Status',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Reason for Lien',
      //     fields: [
      //       {
      //         label: 'Reason for Filing',
      //         name: '',
      //       },
      //       {
      //         label: 'Attempts to Resolve',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Legal and Documentation',
      //     fields: [
      //       {
      //         label: 'Legal Representation',
      //         name: '',
      //       },
      //       {
      //         label: 'Court Filings',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Current Status',
      //     fields: [
      //       {
      //         label: 'Current Status of Lien',
      //         name: '',
      //       },
      //       {
      //         label: 'Resolution Efforts',
      //         name: '',
      //       },
      //       {
      //         label: 'Outcome or Settlement',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Financial Impact',
      //     fields: [
      //       {
      //         label: 'Contingent Liabilities',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Risk Assessment and Mitigation',
      //     fields: [
      //       {
      //         label: 'Risk Analysis',
      //         name: '',
      //       },
      //       {
      //         label: 'Mitigation Strategies',
      //         name: '',
      //       },
      //     ],
      //   },
      //   {
      //     name: 'Communication',
      //     fields: [
      //       {
      //         label: 'Regulatory Compliance',
      //         name: '',
      //       },
      //     ],
      //   },
      // ],

      buySellAgreementAcc: [
        {
          name: 'General Information',
          fields: [
            {
              label: 'Nature of Agreement',
              name: 'hasBuySellAgreement.natureOfAgreement',
            },
            {
              label: 'Date of Agreement',
              name: 'hasBuySellAgreement.dateOfAgreement',
              type: 'date',
              refState: buySellAgreementRef,
            },
            {
              label: 'Parties Involved',
              name: 'hasBuySellAgreement.partiesInvolved',
            },
          ],
        },
        {
          name: 'Agreement Terms',
          fields: [
            {
              label: 'Triggering Events',
              name: 'hasBuySellAgreement.triggeringEvents',
            },
            {
              label: 'Valuation Method',
              name: 'hasBuySellAgreement.valuationMethod',
            },
            {
              label: 'Funding Mechanism',
              name: 'hasBuySellAgreement.fundingMechanism',
            },
            {
              label: 'Payment Terms',
              name: 'hasBuySellAgreement.paymentTerms',
            },
          ],
        },
        {
          name: 'Ownership Information',
          fields: [
            {
              label: 'Ownership Structure',
              name: 'hasBuySellAgreement.ownershipStructure',
            },
            {
              label: 'Shareholder/Partner Interests',
              name: 'hasBuySellAgreement.shareholderOrPartnerInterests',
            },
          ],
        },
        {
          name: 'Financial Impact',
          fields: [
            {
              label: 'Insurance Policies',
              name: 'hasBuySellAgreement.insurancePolicies',
            },
          ],
        },
        {
          name: 'Legal and Documentation',
          fields: [
            {
              label: 'Agreement Copy',
              name: 'hasBuySellAgreement.agreementCopy',
              type: 'file',
              uploadState: 'agreementCopy',
            },
            {
              label: 'Legal Representation',
              name: 'hasBuySellAgreement.legalRepresentation',
            },
          ],
        },
        {
          name: 'Impact on Business Operations',
          fields: [
            {
              label: 'Operational Impact',
              name: 'hasBuySellAgreement.operationalImpact',
            },
            {
              label: 'Management Succession',
              name: 'hasBuySellAgreement.managementSuccession',
            },
          ],
        },
        {
          name: 'Compliance and Communication',
          fields: [
            {
              label: 'Regulatory Compliance',
              name: 'hasBuySellAgreement.regulatoryCompliance',
            },
          ],
        },
        {
          name: 'Future Planning',
          fields: [
            {
              label: 'Review and Updates',
              name: 'hasBuySellAgreement.reviewAndUpdates',
            },
          ],
        },
        {
          name: 'Risk Assessment and Mitigation',
          fields: [
            {
              label: 'Mitigation Strategies',
              name: 'hasBuySellAgreement.mitigationStrategies',
            },
          ],
        },
      ],
      hasBuySellAgreement: {
        natureOfAgreement: null,
        dateOfAgreement: null,
        partiesInvolved: null,
        triggeringEvents: null,
        valuationMethod: null,
        fundingMechanism: null,
        paymentTerms: null,
        ownershipStructure: null,
        shareholderOrPartnerInterests: null,
        insurancePolicies: null,
        agreementCopy: [
          {
            linkId: null,
            link: null,
            urlKey: null,
          },
        ],
        legalRepresentation: null,
        operationalImpact: null,
        managementSuccession: null,
        regulatoryCompliance: null,
        reviewAndUpdates: null,
        mitigationStrategies: null,
      },

      agreementCopy: null,
      // relatedCompaniesFinancialStatements: null,
      // guaranteesForOtherPartiesFinancialStatements: null,
      termsOfIndebtedness: null,

      hasLiensFiledAgainstCompany: null,
      hasLiensFiledByCompany: null,
      hasStateFederalTax: null,
      outstandingJudgmentsAcc: null,
      hasOutstandingJudgments: null,
      hasOutstandingSuits: null,
      hasOutstandingClaims: null,
      paymentPlanUpload: null,
      hasBankruptcyHistory: null,
    });

    const OnHandelReturnToSender = () => {
      setIsReturnToSender(true);
    };

    const OnHandleReturnToBroker = () => {
      setRescindFromApplicant(true);
      dispatch(
        RescindFromApplicant({
          applicationId: formData?.applicationId,
          formName: 'creditBureau',
        }) as any
      );
    };

    // const fetchId = bureauId ? bureauId : ongoingbureauId;
    // const fetchId = true;

    useEffect(() => {
      if (S3UploadResponse) {
        console.log('S3UploadResponse', S3UploadResponse, formik?.values);
        let values = formik?.values;
        const s3resData: Record<string, any> = S3UploadResponse;
        const {
          hasGuaranteesLegalDocuments,
          hasChangesInManagementLegalDocumentation,
          hasChangesInManagementShareholderAgreements,
          hasChangesInManagementRegulatoryFilings,
          hasGuaranteesTermsOfIndebtedness,
          hasBuySellAgreementCopy,
        } = s3resData?.reduce(
          (acc: any, item: any) => {
            switch (item.fieldname) {
              case 'files[hasGuaranteesLegalDocuments]':
                acc.hasGuaranteesLegalDocuments.push(item);
                break;
              // case 'files[hasGuaranteesFinancialStatements]':
              //   acc.hasGuaranteesFinancialStatements.push(item);
              //   break;
              case 'files[hasChangesInManagementLegalDocumentation]':
                acc.hasChangesInManagementLegalDocumentation.push(item);
                break;
              case 'files[hasChangesInManagementShareholderAgreements]':
                acc.hasChangesInManagementShareholderAgreements.push(item);
                break;
              case 'files[hasChangesInManagementRegulatoryFilings]':
                acc.hasChangesInManagementRegulatoryFilings.push(item);
                break;
              case 'files[hasGuaranteesTermsOfIndebtedness]':
                acc.hasGuaranteesTermsOfIndebtedness.push(item);
                break;
              case 'files[hasBuySellAgreementCopy]':
                acc.hasBuySellAgreementCopy.push(item);
                break;
              default:
                // Optionally handle unknown fieldnames
                break;
            }
            return acc;
          },
          {
            hasGuaranteesLegalDocuments: [] as any[],
            // hasGuaranteesFinancialStatements: [] as any[],
            // hasRelatedCompaniesFinancialStatements: [] as any[],
            hasChangesInManagementLegalDocumentation: [] as any[],
            hasChangesInManagementShareholderAgreements: [] as any[],
            hasChangesInManagementRegulatoryFilings: [] as any[],
            hasGuaranteesTermsOfIndebtedness: [] as any[],
            hasBuySellAgreementCopy: [] as any[],
          }
        );
        // const hasGuaranteesLegalDocuments = s3resData?.filter(
        //   (item: any) =>
        //     item.fieldname === 'files[hasGuaranteesLegalDocuments]'
        // );
        // const hasGuaranteesFinancialStatements = s3resData?.filter(
        //   (item: any) =>
        //     item.fieldname === 'files[hasGuaranteesFinancialStatements]'
        // );
        // const hasRelatedCompaniesFinancialStatements = s3resData?.filter(
        //   (item: any) =>
        //     item.fieldname === 'files[hasRelatedCompaniesFinancialStatements]'
        // );

        if (hasGuaranteesLegalDocuments?.length) {
          values = {
            ...values,
            hasGuarantees: {
              ...values?.hasGuarantees,
              legalAndRegulatoryCompliance: {
                ...values?.hasGuarantees?.legalAndRegulatoryCompliance,
                guaranteeAgreementDocumentation: {
                  ...values?.hasGuarantees?.legalAndRegulatoryCompliance
                    ?.guaranteeAgreementDocumentation,
                  legalDocuments: getS3FileInfo(hasGuaranteesLegalDocuments),
                },
              },
            },
          };
        }
        // if (hasGuaranteesFinancialStatements?.length) {
        //   values = {
        //     ...values,
        //     hasGuarantees: {
        //       ...values?.hasGuarantees,
        //       impactOnFinancialHealth: {
        //         ...values?.hasGuarantees?.impactOnFinancialHealth,
        //         impactOnFinancialStatements: {
        //           ...values?.hasGuarantees?.impactOnFinancialHealth
        //             ?.impactOnFinancialStatements,
        //           financialStatements: getS3FileInfo(
        //             hasGuaranteesFinancialStatements
        //           ),
        //         },
        //       },
        //     },
        //   };
        // }
        // if (hasRelatedCompaniesFinancialStatements?.length) {
        //   values = {
        //     ...values,
        //     hasRelatedCompanies: {
        //       ...values?.hasRelatedCompanies,
        //       financialInformation: {
        //         ...values?.hasRelatedCompanies?.financialInformation,
        //         financialStatements: getS3FileInfo(
        //           hasRelatedCompaniesFinancialStatements
        //         ),
        //       },
        //     },
        //   };
        // }
        if (hasChangesInManagementLegalDocumentation?.length) {
          values = {
            ...values,
            hasChangesInManagement: {
              ...values?.hasChangesInManagement,
              documentation: {
                ...values?.hasChangesInManagement?.documentation,
                legalDocumentation: getS3FileInfo(
                  hasChangesInManagementLegalDocumentation
                ),
              },
            },
          };
        }
        if (hasChangesInManagementShareholderAgreements?.length) {
          values = {
            ...values,
            hasChangesInManagement: {
              ...values?.hasChangesInManagement,
              documentation: {
                ...values?.hasChangesInManagement?.documentation,
                shareholderAgreements: getS3FileInfo(
                  hasChangesInManagementShareholderAgreements
                ),
              },
            },
          };
        }
        if (hasChangesInManagementRegulatoryFilings?.length) {
          values = {
            ...values,
            hasChangesInManagement: {
              ...values?.hasChangesInManagement,
              documentation: {
                ...values?.hasChangesInManagement?.documentation,
                regulatoryFilings: getS3FileInfo(
                  hasChangesInManagementRegulatoryFilings
                ),
              },
            },
          };
        }
        if (hasGuaranteesTermsOfIndebtedness?.length) {
          values = {
            ...values,
            hasGuarantees: {
              ...values?.hasGuarantees,
              guaranteeDetails: {
                ...values?.hasGuarantees?.guaranteeDetails,
                financialDetails: {
                  ...values?.hasGuarantees?.guaranteeDetails?.financialDetails,
                  termsOfIndebtedness: getS3FileInfo(
                    hasGuaranteesTermsOfIndebtedness
                  ),
                },
              },
            },
          };
        }
        // if (hasLiensFiledAgainstCompanyCourtFilings?.length) {
        //   values = {
        //     ...values,
        //     hasLiensFiledAgainstCompany: {
        //       ...values?.hasLiensFiledAgainstCompany,
        //       generalInfo: {
        //         ...values?.hasLiensFiledAgainstCompany?.generalInfo,
        //         courtFilings: getS3FileInfo(
        //           hasLiensFiledAgainstCompanyCourtFilings
        //         ),
        //       },
        //     },
        //   };
        // }
        if (hasBuySellAgreementCopy?.length) {
          values = {
            ...values,
            hasBuySellAgreement: {
              ...values?.hasBuySellAgreement,
              agreementCopy: getS3FileInfo(hasBuySellAgreementCopy),
            },
          };
        }

        console.log('values', values);

        submitHandler(values);
      }
      return () => {};
    }, [S3UploadResponse]);

    const submitHandler = async (values: TLegal) => {
      setIsSubmited({ ...isSubmited, 'Credit Bureau': true });
      let res = '';
      let removeLegalFormFields = [
        'receivershipOrBankruptcyAcc',
        'guaranteesForOtherPartiesAcc',
        'relatedCompaniesAcc',
        'changesInManagementAcc',
        // 'liensFiledAgainstCompanyAcc',
        // 'outstandingJudgmentsAcc',
        'bondClaimsAcc',
        // 'liensFiledByCompanyAcc',
        'buySellAgreementAcc',
        'uploadLegalDoc',
        // 'guaranteesForOtherPartiesFinancialStatements',
        // 'relatedCompaniesFinancialStatements',
        'regulatoryFilings',
        'shareholderAgreements',
        'legalDocument',
        'agreementCopy',
        'courtFilings',
        'termsOfIndebtedness',
        'courtFilings',
        'paymentPlanUpload',
        'outstandingJudgmentsAcc',
      ];
      try {
        console.log('submit legal form', values);

        if (!isReturnToSender) {
          let editedValues: any = {};
          const updatedData: Partial<TLegal> = deepObjectComparison(
            values,
            formData
          );
          // const keys: (keyof TLegal)[] = Object.keys(
          //   values
          // ) as (keyof TLegal)[];

          // for (const key of keys) {
          //   if (
          //     !deepObjectComparison(
          //       (values as any)[key],
          //       creditBureauFormData[key]
          //     )
          //   ) {
          //     (editedValues as any)[key] = values[key];
          //   }
          // }

          editedValues = {
            ...values,
            updatedData: removeFields(updatedData, removeLegalFormFields),
            // returnToSender: isApplicantUser,
            // returnToSender: false,
            applicationId: formData?.applicationId,
            applicantId: formData?.applicantId,
            brokerId: formData?.brokerId,
            applicationName: formData?.applicationName,
          };
          const trimedValues = removeFields(
            editedValues,
            removeLegalFormFields
          );
          console.log('applicationId', applicationId);

          const actionResult = await dispatch(
            creditBureauUpdate({
              updatedData: trimedValues,
              applicationId: applicationId,
            }) as any
          );
          const response = actionResult.payload;
          res = response.msg;
          const resStatus = response.status;

          if (resStatus === 200) {
            Toast({
              title: response.msg,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            fetchOngoingApplication();
          } else {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        } else if (isReturnToSender) {
          let editedValues: any = {};
          const updatedData: Partial<TLegal> = deepObjectComparison(
            values,
            formData
          );
          // const keys: (keyof TLegal)[] = Object.keys(
          //   values
          // ) as (keyof TLegal)[];

          // for (const key of keys) {
          //   if (
          //     !deepObjectComparison(
          //       (values as any)[key],
          //       creditBureauFormData[key]
          //     )
          //   ) {
          //     (editedValues as any)[key] = values[key];
          //   }
          // }

          editedValues = {
            ...values,
            updatedData: removeFields(updatedData, removeLegalFormFields),
            // returnToSender: isApplicantUser,
            returnToSender: userType === 'applicant' ? false : true,
            applicationId: formData?.applicationId,
            applicantId: formData?.applicantId,
            brokerId: formData?.brokerId,
            applicationName: formData?.applicationName,
          };
          const trimedValues = removeFields(
            editedValues,
            removeLegalFormFields
          );
          setLoader({ ...loader, btnLoader: true });
          const actionResult = await dispatch(
            creditBureauUpdate({
              updatedData: trimedValues,
              applicationId: applicationId,
            }) as any
          );
          if (userType === 'broker') {
            const response = actionResult.payload;
            res = response.msg;
            const resStatus = response.status;

            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            }
          } else {
            navigate('/');
          }
          setIsReturnToSender(false);
          setLoader({ ...loader, btnLoader: false });
        } else {
          const actionResult = await dispatch(Creditbureau(values) as any);
          const response = actionResult?.payload;
          res = response?.msg;
          const resStatus = response?.status;

          if (resStatus === 200) {
            Toast({
              title: response.msg,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            fetchOngoingApplication();
          } else {
            if (resStatus !== 401) {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            } else {
              navigate('/login');
            }
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
    };

    const formik = useFormik({
      enableReinitialize: true,
      initialValues,

      validationSchema: CreditBureauSchema(),

      onSubmit: async (values: TLegal) => {
        try {
          console.log('submit', values, formik?.initialValues);
          const isLegalFormDocUpload =
            (values?.uploadLegalDoc && values?.uploadLegalDoc?.length) ||
            // (values?.guaranteesForOtherPartiesFinancialStatements &&
            //   values?.guaranteesForOtherPartiesFinancialStatements?.length) ||
            // (values?.relatedCompaniesFinancialStatements &&
            //   values?.relatedCompaniesFinancialStatements?.length) ||
            (values?.legalDocument && values?.legalDocument?.length) ||
            (values?.shareholderAgreements &&
              values?.shareholderAgreements?.length) ||
            (values?.regulatoryFilings && values?.regulatoryFilings?.length) ||
            (values?.termsOfIndebtedness &&
              values?.termsOfIndebtedness?.length) ||
            (values?.agreementCopy && values?.agreementCopy?.length);

          const formData = new FormData();

          if (values?.uploadLegalDoc && values?.uploadLegalDoc?.length) {
            values?.uploadLegalDoc?.forEach((file: File) => {
              formData.append(`files[hasGuaranteesLegalDocuments]`, file);
            });
          }
          // if (
          //   values?.guaranteesForOtherPartiesFinancialStatements &&
          //   values?.guaranteesForOtherPartiesFinancialStatements?.length
          // ) {
          //   values?.guaranteesForOtherPartiesFinancialStatements?.forEach(
          //     (file: File) => {
          //       formData.append(
          //         `files[hasGuaranteesFinancialStatements]`,
          //         file
          //       );
          //     }
          //   );
          // }
          // if (
          //   values?.relatedCompaniesFinancialStatements &&
          //   values?.relatedCompaniesFinancialStatements?.length
          // ) {
          //   values?.relatedCompaniesFinancialStatements?.forEach(
          //     (file: File) => {
          //       formData.append(
          //         `files[hasRelatedCompaniesFinancialStatements]`,
          //         file
          //       );
          //     }
          //   );
          // }
          if (values?.legalDocument && values?.legalDocument?.length) {
            values?.legalDocument?.forEach((file: File) => {
              formData.append(
                `files[hasChangesInManagementLegalDocumentation]`,
                file
              );
            });
          }
          if (
            values?.shareholderAgreements &&
            values?.shareholderAgreements?.length
          ) {
            values?.shareholderAgreements?.forEach((file: File) => {
              formData.append(
                `files[hasChangesInManagementShareholderAgreements]`,
                file
              );
            });
          }
          if (values?.regulatoryFilings && values?.regulatoryFilings?.length) {
            values?.regulatoryFilings?.forEach((file: File) => {
              formData.append(
                `files[hasChangesInManagementRegulatoryFilings]`,
                file
              );
            });
          }
          if (
            values?.termsOfIndebtedness &&
            values?.termsOfIndebtedness?.length
          ) {
            values?.termsOfIndebtedness?.forEach((file: File) => {
              formData.append(`files[hasGuaranteesTermsOfIndebtedness]`, file);
            });
          }
          if (values?.agreementCopy && values?.agreementCopy?.length) {
            values?.agreementCopy?.forEach((file: File) => {
              formData.append(`files[hasBuySellAgreementCopy]`, file);
            });
          }

          if (isLegalFormDocUpload) {
            console.log('FormData contents:', formData);
            formData.forEach((value, key) => {
              console.log(`${key}:`, value);
            });

            await uploadbulkFilesInS3(formData);

            if (S3UploadError) {
              toast({
                title: 'Erro in file uploading!!',
                status: 'error',
              });
              return;
            }
          } else {
            console.log('submit!!!');

            submitHandler(values);
          }
        } catch (error: any) {
          console.log('error', error);
        }
      },
    });

    const { values, setFieldValue, errors, touched } = formik;

    const getReceivershipOrBankruptcyAccColumns = (name: string) => {
      switch (name) {
        case 'Bankruptcy': {
          console.log('Bankruptcy Columns>>>');
          return bankruptcyColumns;
        }
        case 'Insolvency': {
          console.log('Insolvency Columns>>>');
          return insolvencyColumns;
        }
        case 'Liquidation': {
          console.log('Liquidation Columns>>>');
          return liquidationColumns;
        }
        default:
          break;
      }
    };
    const getReceivershipOrBankruptcyAccTableData = (name: string) => {
      switch (name) {
        case 'Bankruptcy': {
          console.log('Bankruptcy TableData>>>');
          return bankruptcyTableData;
        }
        case 'Insolvency': {
          console.log('Insolvency TableData>>>');
          return insolvencyTableData;
        }
        case 'Liquidation': {
          console.log('Liquidation TableData>>>');
          return liquidationTableData;
        }
        default:
          break;
      }
    };

    const bankruptcyTableData = hasBankruptcyHistoryTableFields.reduce(
      (acc: any[], field) => {
        const dynamicFieldObject =
          values?.hasBankruptcyHistory?.bankruptcyDetails?.reduce(
            (fieldAcc: any, item: any, index: number) => {
              fieldAcc[`value[${index}]`] = item?.[field.key];
              return fieldAcc;
            },
            { name: field.name, id: `${field.key}` }
          );
        // acc.push(dynamicFieldObject);
        if (
          dynamicFieldObject &&
          (field.key === 'listOfMajorCreditors' ||
            field.key === 'assetsAtTimeOfFiling' ||
            field.key === 'outcome' ||
            field.key === 'durationOfProceedings' ||
            field.key === 'impactOnBusinessOperations' ||
            field.key === 'dischargeConditions')
        ) {
          const hasNonNullValues = Object.keys(dynamicFieldObject).some(
            (key) => !!dynamicFieldObject[key] && key.startsWith('value[')
          );
          if (hasNonNullValues) {
            acc.push(dynamicFieldObject);
          }
        } else {
          acc.push(dynamicFieldObject);
        }
        return acc;
      },
      []
    );

    const bankruptcyColumns = useMemo<
      MRT_ColumnDef<LegalFormAccordianTable>[]
    >(() => {
      const baseColumns = [
        {
          accessorKey: 'name',
          size: 200,
          maxSize: 200,
          header: 'Name',
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          // mantineTableHeadCellProps: {
          //   width: '10px !important',
          //   className: 'px-10',
          // },
          mantineTableBodyCellProps: {
            className: 'px-10',
            sx: {
              outline: 'none !important',
            },
          },
        },
      ];

      const dynamicColumns =
        values?.hasBankruptcyHistory?.bankruptcyDetails?.map(
          (_item: any, index: number) => ({
            accessorKey: `value[${index}]`,
            size: 200,
            maxSize: 200,
            header: `Bankruptcy details ${index + 1}`,
            enableColumnActions: false,
            enableSorting: false,
            enableEditing: (row: any) => {
              return (row?.original?.id === 'totalDebtAmount' &&
                !formData?.hasBankruptcyHistory?.bankruptcyDetails?.[index]
                  ?.totalDebtAmount) ||
                (row?.original?.id === 'dateOfDischarge' &&
                  !formData?.hasBankruptcyHistory?.bankruptcyDetails?.[index]
                    ?.dateOfDischarge)
                ? true
                : false;
            },
            mantineTableBodyCellProps: (props: any) => {
              // console.log('props', props);
              const isNameHolderRow =
                props?.row?.original?.id === 'jurisdictionCourt';

              return {
                className: isNameHolderRow ? 'liens-details' : '',
                sx: {
                  outline: 'none !important',
                  // fontWeight: isParentRow ? 'bold' : 'normal',
                },
              };
            },
            // mantineEditTextInputProps: {
            //   placeholder: '',
            // },
            mantineEditTextInputProps: ({
              cell,
              column,
              row,
              table,
            }: {
              cell: MRT_Cell<LegalFormAccordianTable>;
              column: MRT_Column<LegalFormAccordianTable>;
              row: MRT_Row<LegalFormAccordianTable>;
              table: MRT_TableInstance<LegalFormAccordianTable>;
            }) => ({
              onChange: (event: any) => {
                console.log('cell', cell, event?.target?.value, column, row);
                let bankruptcyDetails: hasBankruptcyHistoryTable[] | null =
                  values?.hasBankruptcyHistory?.bankruptcyDetails ?? null;

                if (bankruptcyDetails && bankruptcyDetails?.length > 0) {
                  const updatedBankruptcyDetails = bankruptcyDetails?.map(
                    (lien: any, i: number) => {
                      if (i === index) {
                        return {
                          ...lien,
                          [row?.original?.id as any]: event?.target?.value,
                        };
                      } else {
                        return lien;
                      }
                    }
                  );

                  console.log(
                    'bankruptcyDetails',
                    values?.hasBankruptcyHistory?.bankruptcyDetails,
                    updatedBankruptcyDetails
                  );

                  handleChange(
                    'hasBankruptcyHistory.bankruptcyDetails',
                    updatedBankruptcyDetails
                  );
                }
              },
              // type: row?.original?.id === 'dateOfFiling' ? 'date' : 'text',
              type: 'text',
              variant: 'unstyled',
              placeholder: '',
            }),

            Cell: ({ cell, row }: { cell: any; row: any }) => {
              if (row?.original?.id === 'jurisdictionCourt') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={formik.handleChange}
                    value={
                      values?.hasBankruptcyHistory?.bankruptcyDetails?.[index]
                        ?.jurisdictionCourt ?? ''
                    }
                    name={`hasBankruptcyHistory.bankruptcyDetails[${index}].jurisdictionCourt`}
                    readOnly={true}
                  />
                );
              } else {
                return cell?.getValue();
              }
            },
          })
        ) ?? [];

      return [...baseColumns, ...dynamicColumns];
    }, [values?.hasBankruptcyHistory?.bankruptcyDetails]);

    const insolvencyTableData = hasBankruptcyHistoryTableFields.reduce(
      (acc: any[], field) => {
        const dynamicFieldObject =
          values?.hasBankruptcyHistory?.insolvencyDetails?.reduce(
            (fieldAcc: any, item: any, index: number) => {
              fieldAcc[`value[${index}]`] = item?.[field.key];
              return fieldAcc;
            },
            { name: field.name, id: `${field.key}` }
          );

        if (
          dynamicFieldObject &&
          (field.key === 'listOfMajorCreditors' ||
            field.key === 'assetsAtTimeOfFiling' ||
            field.key === 'outcome' ||
            field.key === 'durationOfProceedings' ||
            field.key === 'impactOnBusinessOperations' ||
            field.key === 'dischargeConditions')
        ) {
          const hasNonNullValues = Object.keys(dynamicFieldObject).some(
            (key) => !!dynamicFieldObject[key] && key.startsWith('value[')
          );
          if (hasNonNullValues) {
            acc.push(dynamicFieldObject);
          }
        } else {
          acc.push(dynamicFieldObject);
        }

        return acc;
      },
      []
    );

    const insolvencyColumns = useMemo<
      MRT_ColumnDef<LegalFormAccordianTable>[]
    >(() => {
      const baseColumns = [
        {
          accessorKey: 'name',
          size: 200,
          maxSize: 200,
          header: 'Name',
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          // mantineTableHeadCellProps: {
          //   width: '10px !important',
          //   className: 'px-10',
          // },
          mantineTableBodyCellProps: {
            className: 'px-10',
            sx: {
              outline: 'none !important',
            },
          },
        },
      ];

      const dynamicColumns =
        values?.hasBankruptcyHistory?.insolvencyDetails?.map(
          (_item: any, index: number) => ({
            accessorKey: `value[${index}]`,
            size: 200,
            maxSize: 200,
            header: `Insolvency details ${index + 1}`,
            enableColumnActions: false,
            enableSorting: false,
            enableEditing: (row: any) => {
              return (row?.original?.id === 'totalDebtAmount' &&
                !formData?.hasBankruptcyHistory?.insolvencyDetails?.[index]
                  ?.totalDebtAmount) ||
                (row?.original?.id === 'dateOfDischarge' &&
                  !formData?.hasBankruptcyHistory?.insolvencyDetails?.[index]
                    ?.dateOfDischarge)
                ? true
                : false;
            },
            mantineTableBodyCellProps: (props: any) => {
              // console.log('props', props);
              const isNameHolderRow =
                props?.row?.original?.id === 'jurisdictionCourt';

              return {
                className: isNameHolderRow ? 'liens-details' : '',
                sx: {
                  outline: 'none !important',
                  // fontWeight: isParentRow ? 'bold' : 'normal',
                },
              };
            },
            // mantineEditTextInputProps: {
            //   placeholder: '',
            // },
            mantineEditTextInputProps: ({
              cell,
              column,
              row,
              table,
            }: {
              cell: MRT_Cell<LegalFormAccordianTable>;
              column: MRT_Column<LegalFormAccordianTable>;
              row: MRT_Row<LegalFormAccordianTable>;
              table: MRT_TableInstance<LegalFormAccordianTable>;
            }) => ({
              onChange: (event: any) => {
                console.log('cell', cell, event?.target?.value, column, row);
                let insolvencyDetails: hasBankruptcyHistoryTable[] | null =
                  values?.hasBankruptcyHistory?.insolvencyDetails ?? null;

                if (insolvencyDetails && insolvencyDetails?.length > 0) {
                  const updatedInsolvencyDetails = insolvencyDetails?.map(
                    (lien: any, i: number) => {
                      if (i === index) {
                        return {
                          ...lien,
                          [row?.original?.id as any]: event?.target?.value,
                        };
                      } else {
                        return lien;
                      }
                    }
                  );

                  console.log(
                    'insolvencyDetails',
                    values?.hasBankruptcyHistory?.insolvencyDetails,
                    updatedInsolvencyDetails
                  );

                  handleChange(
                    'hasBankruptcyHistory.insolvencyDetails',
                    updatedInsolvencyDetails
                  );
                }
              },
              // type: row?.original?.id === 'dateOfFiling' ? 'date' : 'text',
              type: 'text',
              variant: 'unstyled',
              placeholder: '',
            }),

            Cell: ({ cell, row }: { cell: any; row: any }) => {
              if (row?.original?.id === 'jurisdictionCourt') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={formik.handleChange}
                    value={
                      values?.hasBankruptcyHistory?.insolvencyDetails?.[index]
                        ?.jurisdictionCourt ?? ''
                    }
                    name={`hasBankruptcyHistory.insolvencyDetails[${index}].jurisdictionCourt`}
                    readOnly={true}
                  />
                );
              } else {
                return cell?.getValue();
              }
            },
          })
        ) ?? [];

      return [...baseColumns, ...dynamicColumns];
    }, [values?.hasBankruptcyHistory?.insolvencyDetails]);

    const liquidationTableData = hasBankruptcyHistoryTableFields.reduce(
      (acc: any[], field) => {
        const dynamicFieldObject =
          values?.hasBankruptcyHistory?.liquidationDetails?.reduce(
            (fieldAcc: any, item: any, index: number) => {
              fieldAcc[`value[${index}]`] = item?.[field.key];
              return fieldAcc;
            },
            { name: field.name, id: `${field.key}` }
          );

        if (
          dynamicFieldObject &&
          (field.key === 'listOfMajorCreditors' ||
            field.key === 'assetsAtTimeOfFiling' ||
            field.key === 'outcome' ||
            field.key === 'durationOfProceedings' ||
            field.key === 'impactOnBusinessOperations' ||
            field.key === 'dischargeConditions')
        ) {
          const hasNonNullValues = Object.keys(dynamicFieldObject).some(
            (key) => !!dynamicFieldObject[key] && key.startsWith('value[')
          );
          if (hasNonNullValues) {
            acc.push(dynamicFieldObject);
          }
        } else {
          acc.push(dynamicFieldObject);
        }

        return acc;
      },
      []
    );

    const liquidationColumns = useMemo<
      MRT_ColumnDef<LegalFormAccordianTable>[]
    >(
      () => {
        const baseColumns = [
          {
            accessorKey: 'name',
            size: 200,
            maxSize: 200,
            header: 'Name',
            enableColumnActions: false,
            enableSorting: false,
            enableEditing: false,
            // mantineTableHeadCellProps: {
            //   width: '10px !important',
            //   className: 'px-10',
            // },
            mantineTableBodyCellProps: {
              className: 'px-10',
              sx: {
                outline: 'none !important',
              },
            },
          },
        ];

        const dynamicColumns =
          values?.hasBankruptcyHistory?.liquidationDetails?.map(
            (_item: any, index: number) => ({
              accessorKey: `value[${index}]`,
              size: 200,
              maxSize: 200,
              header: `Liquidation details ${index + 1}`,
              enableColumnActions: false,
              enableSorting: false,
              enableEditing: (row: any) => {
                return (row?.original?.id === 'totalDebtAmount' &&
                  !formData?.hasBankruptcyHistory?.liquidationDetails?.[index]
                    ?.totalDebtAmount) ||
                  (row?.original?.id === 'dateOfDischarge' &&
                    !formData?.hasBankruptcyHistory?.liquidationDetails?.[index]
                      ?.dateOfDischarge)
                  ? true
                  : false;
              },
              mantineTableBodyCellProps: (props: any) => {
                // console.log('props', props);
                const isNameHolderRow =
                  props?.row?.original?.id === 'jurisdictionCourt';

                return {
                  className: isNameHolderRow ? 'liens-details' : '',
                  sx: {
                    outline: 'none !important',
                    // fontWeight: isParentRow ? 'bold' : 'normal',
                  },
                };
              },
              // mantineEditTextInputProps: {
              //   placeholder: '',
              // },
              mantineEditTextInputProps: ({
                cell,
                column,
                row,
                table,
              }: {
                cell: MRT_Cell<LegalFormAccordianTable>;
                column: MRT_Column<LegalFormAccordianTable>;
                row: MRT_Row<LegalFormAccordianTable>;
                table: MRT_TableInstance<LegalFormAccordianTable>;
              }) => ({
                onChange: (event: any) => {
                  console.log('cell', cell, event?.target?.value, column, row);
                  let liquidationDetails: hasBankruptcyHistoryTable[] | null =
                    values?.hasBankruptcyHistory?.liquidationDetails ?? null;

                  if (liquidationDetails && liquidationDetails?.length > 0) {
                    const updatedLiquidationDetails = liquidationDetails?.map(
                      (lien: any, i: number) => {
                        if (i === index) {
                          return {
                            ...lien,
                            [row?.original?.id as any]: event?.target?.value,
                          };
                        } else {
                          return lien;
                        }
                      }
                    );

                    console.log(
                      'liquidationDetails',
                      values?.hasBankruptcyHistory?.liquidationDetails,
                      updatedLiquidationDetails
                    );

                    handleChange(
                      'hasBankruptcyHistory.liquidationDetails',
                      updatedLiquidationDetails
                    );
                  }
                },
                // type: row?.original?.id === 'dateOfFiling' ? 'date' : 'text',
                type: 'text',
                variant: 'unstyled',
                placeholder: '',
              }),

              Cell: ({ cell, row }: { cell: any; row: any }) => {
                if (row?.original?.id === 'jurisdictionCourt') {
                  return (
                    <Textarea
                      fontSize={'13px'}
                      w="100%"
                      placeholder=""
                      // borderRadius="5px"
                      focusBorderColor="transparent"
                      borderColor={'transparent'}
                      _hover={{ borderColor: 'transparent' }}
                      onChange={formik.handleChange}
                      value={
                        values?.hasBankruptcyHistory?.liquidationDetails?.[
                          index
                        ]?.jurisdictionCourt ?? ''
                      }
                      name={`hasBankruptcyHistory.liquidationDetails[${index}].jurisdictionCourt`}
                      readOnly={true}
                    />
                  );
                } else {
                  return cell?.getValue();
                }
              },
            })
          ) ?? [];

        return [...baseColumns, ...dynamicColumns];
      },
      [values?.hasBankruptcyHistory?.liquidationDetails] // dependency array to update columns if data changes
    );

    const liensFiledAgainstCompanyTableData =
      liensFiledAgainstCompanyTableFields.reduce(
        (acc: any[], field, i: number) => {
          const dynamicFieldObject =
            formik?.values?.hasLiensFiledAgainstCompany?.reduce(
              (fieldAcc: any, item: any, index: number) => {
                fieldAcc[`value[${index}]`] =
                  field.key === 'filingAmount'
                    ? item?.[field.key]?.value
                    : item?.[field.key];
                return fieldAcc;
              },
              {
                name: field.name,
                parentKey: field.parentKey,
                id: `${field.key}`,
              }
              // { parentKey: field.parentKey, name: field.name }
              // { parentKey: field.parentKey }
              // {id: `${field.key}[${index}]`},
            );
          // console.log('dynamicFieldObject', dynamicFieldObject);

          acc.push(dynamicFieldObject);
          return acc;
        },
        []
      );

    const liensFiledAgainstCompanyColumns = useMemo<
      MRT_ColumnDef<LegalFormLiensFiledAgainstCompanyTable>[]
    >(() => {
      const baseColumns = [
        {
          accessorKey: 'name',
          size: 200,
          maxSize: 200,
          header: `Liens (${formik?.values?.hasLiensFiledAgainstCompany?.length})`,
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          // mantineTableHeadCellProps: {
          //   width: '10px !important',
          //   className: 'px-10',
          // },
          mantineTableBodyCellProps: (props: any) => {
            // console.log('props', props);
            const isParentRow = props?.row?.original?.parentKey;
            return {
              className: isParentRow
                ? 'px-10 fw-bold liens-table-parent-row'
                : 'pl-50 fw-normal',
              sx: {
                outline: 'none !important',
                // fontWeight: isParentRow ? 'bold' : 'normal',
              },
            };
          },
        },
      ];

      const dynamicColumns =
        formik?.values?.hasLiensFiledAgainstCompany?.map(
          (_item: any, index: number) => ({
            accessorKey: `value[${index}]`,
            size: 200,
            maxSize: 200,
            header: `Lien ${index + 1}`,
            enableColumnActions: false,
            enableSorting: false,
            // enableEditing: true,
            mantineTableBodyCellProps: (props: any) => {
              // console.log('props', props);
              const isNameHolderRow =
                props?.row?.original?.id === 'nameOfLienHolder' ||
                props?.row?.original?.id === 'nameOfDebtor' ||
                props?.row?.original?.id === 'jurisdiction';
              const isParentRow = props?.row?.original?.parentKey;
              return {
                className: isNameHolderRow
                  ? 'liens-details'
                  : isParentRow
                    ? 'liens-table-parent-row'
                    : '',
                sx: {
                  outline: 'none !important',
                  // fontWeight: isParentRow ? 'bold' : 'normal',
                },
              };
            },
            mantineEditTextInputProps: ({
              cell,
              column,
              row,
              table,
            }: {
              cell: MRT_Cell<LegalFormLiensFiledAgainstCompanyTable>;
              column: MRT_Column<LegalFormLiensFiledAgainstCompanyTable>;
              row: MRT_Row<LegalFormLiensFiledAgainstCompanyTable>;
              table: MRT_TableInstance<LegalFormLiensFiledAgainstCompanyTable>;
            }) => ({
              onChange: (event: any) => {
                console.log('cell', cell, event?.target?.value, column, row);
                let hasLiensFiledAgainstCompany: liensDetails[] | null =
                  formik?.values?.hasLiensFiledAgainstCompany;
                // const matches = cell?.id?.match(/(.+)_value\[(\d+)\]/);
                // const key = matches[1]; // "caseNumber"
                // const objIndex = parseInt(matches[2], 10); // 1

                // if (
                //   objIndex >= 0 &&
                //   objIndex < hasLiensFiledAgainstCompany?.length
                // ) {
                //   hasLiensFiledAgainstCompany[objIndex][key] =
                //     event?.target?.value; // Modify the value of the key
                // }

                if (
                  hasLiensFiledAgainstCompany &&
                  hasLiensFiledAgainstCompany?.length > 0
                ) {
                  // if (row?.original?.id === 'filingAmount') {
                  //   hasLiensFiledAgainstCompany[index][row?.original?.id] = {
                  //     ...hasLiensFiledAgainstCompany[index][row?.original?.id],
                  //     value: Number(event?.target?.value),
                  //   };
                  // } else {
                  //   hasLiensFiledAgainstCompany[index][row?.original?.id] =
                  //     event?.target?.value; // Modify the value of the key
                  // }

                  const updatedHasLiensFiledAgainstCompany =
                    hasLiensFiledAgainstCompany?.map((lien: any, i: number) => {
                      if (i === index) {
                        if (row?.original?.id === 'filingAmount') {
                          return {
                            ...lien,
                            [row?.original?.id]: {
                              ...lien[row?.original?.id],
                              value: Number(event?.target?.value),
                            },
                          };
                        } else {
                          return {
                            ...lien,
                            [row?.original?.id]: event?.target?.value,
                          };
                        }
                      } else {
                        return lien;
                      }
                    });

                  console.log(
                    'hasLiensFiledAgainstCompany',
                    hasLiensFiledAgainstCompany,
                    updatedHasLiensFiledAgainstCompany
                  );

                  handleChange(
                    'hasLiensFiledAgainstCompany',
                    updatedHasLiensFiledAgainstCompany
                  );
                }
              },
              // type: row?.original?.id === 'dateOfFiling' ? 'date' : 'text',
              type: row?.original?.id === 'filingAmount' ? 'number' : 'text',
              variant: 'unstyled',
              placeholder: '',
            }),
            // Cell: ({ cell, row }: { cell: any; row: any }) => {
            //   const cellValue = cell.getValue() as any;
            //   console.log('cell-value', cellValue, row);

            //   if (row?.original?.id === 'courtFilings') {
            //     return (
            //       <Flex gap={3} justifyContent={'left'}>
            //         <ActionIcon
            //           onClick={() => {
            //             // table.setEditingRow(row);
            //             console.log('row', row);

            //             // setSelectedEditRow({
            //             //   row: row,
            //             //   table: table,
            //             // });
            //             // open();
            //           }}
            //           size={'sm'}
            //         >
            //           <MantineTooltip label="Edit" position="right">
            //             <IconEdit />
            //           </MantineTooltip>
            //         </ActionIcon>
            //       </Flex>
            //     );
            //   }
            // },

            Cell: ({ cell, row }: { cell: any; row: any }) => {
              if (row?.original?.id === 'nameOfLienHolder') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasLiensFiledAgainstCompany[${index}].nameOfLienHolder`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasLiensFiledAgainstCompany?.[index]
                        ?.nameOfLienHolder
                        ? values?.hasLiensFiledAgainstCompany?.[
                            index
                          ]?.nameOfLienHolder?.join(', ')
                        : ''
                    }
                    name={`hasLiensFiledAgainstCompany[${index}].nameOfLienHolder`}
                  />
                );
              } else if (row?.original?.id === 'nameOfDebtor') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasLiensFiledAgainstCompany[${index}].nameOfDebtor`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasLiensFiledAgainstCompany?.[index]?.nameOfDebtor
                        ? values?.hasLiensFiledAgainstCompany?.[
                            index
                          ]?.nameOfDebtor?.join(', ')
                        : ''
                    }
                    name={`hasLiensFiledAgainstCompany[${index}].nameOfDebtor`}
                    // rows={2}
                  />
                );
              } else if (row?.original?.id === 'courtFilings') {
                return (
                  <Flex gap={3} justifyContent={'left'}>
                    <MantineTooltip label="Upload Documents" position="right">
                      <ActionIcon
                        onClick={() => {
                          // table.setEditingRow(row);
                          const uploadName = `hasLiensFiledAgainstCompany[${index}].courtFilings`;
                          const formikvalue =
                            formik?.values?.hasLiensFiledAgainstCompany?.find(
                              (file: any, i: number) => index === i
                            )?.courtFilings;
                          console.log(
                            'row-courtFiling-hasLiensFiledAgainstCompany',
                            row,
                            formikvalue
                          );

                          setSelectedEditRow({
                            name: uploadName,
                            value: formikvalue ?? null,
                            uploadState: 'courtFilings',
                          });
                          open();
                        }}
                        size={'lg'}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent !important',
                          },
                        }}
                      >
                        <MdUploadFile size={'20px'} />
                      </ActionIcon>
                    </MantineTooltip>
                  </Flex>
                );
              } else if (row?.original?.id === 'jurisdiction') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={formik.handleChange}
                    value={
                      values?.hasLiensFiledAgainstCompany?.[index]
                        ?.jurisdiction ?? ''
                    }
                    name={`hasLiensFiledAgainstCompany[${index}].jurisdiction`}
                    // rows={2}
                  />
                );
              }
            },
          })
        ) ?? [];

      return [...baseColumns, ...dynamicColumns];
    }, [formik?.values?.hasLiensFiledAgainstCompany]);

    console.log(
      'liensFiledAgainstCompanyColumns',
      liensFiledAgainstCompanyColumns,
      liensFiledAgainstCompanyTableData
    );

    const liensFiledByCompanyTableData =
      liensFiledAgainstCompanyTableFields.reduce(
        (acc: any[], field, i: number) => {
          const dynamicFieldObject =
            formik?.values?.hasLiensFiledByCompany?.reduce(
              (fieldAcc: any, item: any, index: number) => {
                fieldAcc[`value[${index}]`] =
                  field.key === 'filingAmount'
                    ? item?.[field.key]?.value
                    : item?.[field.key];
                return fieldAcc;
              },
              {
                name: field.name,
                parentKey: field.parentKey,
                id: `${field.key}`,
              }
              // { parentKey: field.parentKey, name: field.name }
              // { parentKey: field.parentKey }
              // {id: `${field.key}[${index}]`},
            );
          // console.log('dynamicFieldObject', dynamicFieldObject);

          acc.push(dynamicFieldObject);
          return acc;
        },
        []
      );

    const liensFiledByCompanyColumns = useMemo<
      MRT_ColumnDef<LegalFormLiensFiledAgainstCompanyTable>[]
    >(() => {
      const baseColumns = [
        {
          accessorKey: 'name',
          size: 200,
          maxSize: 200,
          header: `Liens (${formik?.values?.hasLiensFiledByCompany?.length})`,
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          // mantineTableHeadCellProps: {
          //   width: '10px !important',
          //   className: 'px-10',
          // },
          mantineTableBodyCellProps: (props: any) => {
            // console.log('props', props);
            const isParentRow = props?.row?.original?.parentKey;
            return {
              className: isParentRow
                ? 'px-10 fw-bold liens-table-parent-row'
                : 'pl-50 fw-normal',
              sx: {
                outline: 'none !important',
                // fontWeight: isParentRow ? 'bold' : 'normal',
              },
            };
          },
        },
      ];

      const dynamicColumns =
        formik?.values?.hasLiensFiledByCompany?.map(
          (_item: any, index: number) => ({
            accessorKey: `value[${index}]`,
            size: 200,
            maxSize: 200,
            header: `Lien ${index + 1}`,
            enableColumnActions: false,
            enableSorting: false,
            // enableEditing: true,
            mantineTableBodyCellProps: (props: any) => {
              // console.log('props', props);
              const isNameHolderRow =
                props?.row?.original?.id === 'nameOfLienHolder' ||
                props?.row?.original?.id === 'nameOfDebtor' ||
                props?.row?.original?.id === 'jurisdiction';
              const isParentRow = props?.row?.original?.parentKey;
              return {
                className: isNameHolderRow
                  ? 'liens-details'
                  : isParentRow
                    ? 'liens-table-parent-row'
                    : '',
                sx: {
                  outline: 'none !important',
                  // fontWeight: isParentRow ? 'bold' : 'normal',
                },
              };
            },
            mantineEditTextInputProps: ({
              cell,
              column,
              row,
              table,
            }: {
              cell: MRT_Cell<LegalFormLiensFiledAgainstCompanyTable>;
              column: MRT_Column<LegalFormLiensFiledAgainstCompanyTable>;
              row: MRT_Row<LegalFormLiensFiledAgainstCompanyTable>;
              table: MRT_TableInstance<LegalFormLiensFiledAgainstCompanyTable>;
            }) => ({
              onChange: (event: any) => {
                console.log('cell', cell, event?.target?.value, column);
                let hasLiensFiledByCompany: liensDetails[] | null =
                  formik?.values?.hasLiensFiledByCompany;

                if (
                  hasLiensFiledByCompany &&
                  hasLiensFiledByCompany?.length > 0
                ) {
                  // if (row?.original?.id === 'filingAmount') {
                  //   hasLiensFiledByCompany[index][row?.original?.id] = {
                  //     ...hasLiensFiledByCompany[index][row?.original?.id],
                  //     value: Number(event?.target?.value),
                  //   };
                  // } else {
                  //   hasLiensFiledByCompany[index][row?.original?.id] =
                  //     event?.target?.value; // Modify the value of the key
                  // }

                  const updatedHasLiensFiledByCompany =
                    hasLiensFiledByCompany?.map((lien: any, i: number) => {
                      if (i === index) {
                        if (row?.original?.id === 'filingAmount') {
                          return {
                            ...lien,
                            [row?.original?.id]: {
                              ...lien[row?.original?.id],
                              value: Number(event?.target?.value),
                            },
                          };
                        } else {
                          return {
                            ...lien,
                            [row?.original?.id]: event?.target?.value,
                          };
                        }
                      } else {
                        return lien;
                      }
                    });

                  console.log(
                    'hasLiensFiledByCompany',
                    hasLiensFiledByCompany,
                    updatedHasLiensFiledByCompany
                  );

                  handleChange(
                    'hasLiensFiledByCompany',
                    updatedHasLiensFiledByCompany
                  );
                }
              },
              // type: row?.original?.id === 'dateOfFiling' ? 'date' : 'text',
              type: row?.original?.id === 'filingAmount' ? 'number' : 'text',
              variant: 'unstyled',
              placeholder: '',
            }),
            // Cell: ({ cell, row }: { cell: any; row: any }) => {
            //   const cellValue = cell.getValue() as any;
            //   console.log('cell-value', cellValue, row);

            //   if (row?.original?.id === 'courtFilings') {
            //     return (
            //       <Flex gap={3} justifyContent={'left'}>
            //         <ActionIcon
            //           onClick={() => {
            //             // table.setEditingRow(row);
            //             console.log('row', row);

            //             // setSelectedEditRow({
            //             //   row: row,
            //             //   table: table,
            //             // });
            //             // open();
            //           }}
            //           size={'sm'}
            //         >
            //           <MantineTooltip label="Edit" position="right">
            //             <IconEdit />
            //           </MantineTooltip>
            //         </ActionIcon>
            //       </Flex>
            //     );
            //   }
            // },

            Cell: ({ cell, row }: { cell: any; row: any }) => {
              if (row?.original?.id === 'nameOfLienHolder') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasLiensFiledByCompany[${index}].nameOfLienHolder`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasLiensFiledByCompany?.[index]?.nameOfLienHolder
                        ? values?.hasLiensFiledByCompany?.[
                            index
                          ]?.nameOfLienHolder?.join(', ')
                        : ''
                    }
                    name={`hasLiensFiledByCompany[${index}].nameOfLienHolder`}
                  />
                );
              } else if (row?.original?.id === 'nameOfDebtor') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasLiensFiledByCompany[${index}].nameOfDebtor`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasLiensFiledByCompany?.[index]?.nameOfDebtor
                        ? values?.hasLiensFiledByCompany?.[
                            index
                          ]?.nameOfDebtor?.join(', ')
                        : ''
                    }
                    name={`hasLiensFiledByCompany[${index}].nameOfDebtor`}
                    // rows={2}
                  />
                );
              } else if (row?.original?.id === 'courtFilings') {
                return (
                  <Flex gap={3} justifyContent={'left'}>
                    <MantineTooltip label="Upload Documents" position="right">
                      <ActionIcon
                        onClick={() => {
                          // table.setEditingRow(row);
                          const uploadName = `hasLiensFiledByCompany[${index}].courtFilings`;
                          const formikvalue =
                            formik?.values?.hasLiensFiledByCompany?.find(
                              (file: any, i: number) => index === i
                            )?.courtFilings;
                          console.log(
                            'row-courtFiling-hasLiensFiledByCompany',
                            row,
                            formikvalue
                          );

                          setSelectedEditRow({
                            name: uploadName,
                            value: formikvalue ?? null,
                            uploadState: 'courtFilings',
                          });
                          open();
                        }}
                        size={'lg'}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent !important',
                          },
                        }}
                      >
                        <MdUploadFile size={'20px'} />
                      </ActionIcon>
                    </MantineTooltip>
                  </Flex>
                );
              } else if (row?.original?.id === 'jurisdiction') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={formik.handleChange}
                    value={
                      values?.hasLiensFiledByCompany?.[index]?.jurisdiction ??
                      ''
                    }
                    name={`hasLiensFiledByCompany[${index}].jurisdiction`}
                    // rows={2}
                  />
                );
              }
            },
          })
        ) ?? [];

      return [...baseColumns, ...dynamicColumns];
    }, [formik?.values?.hasLiensFiledByCompany]);

    const stateFederalTaxTableData = stateFederalTaxTableFields.reduce(
      (acc: any[], field, i: number) => {
        const dynamicFieldObject = formik?.values?.hasStateFederalTax?.reduce(
          (fieldAcc: any, item: any, index: number) => {
            fieldAcc[`value[${index}]`] =
              field.key === 'filingAmount'
                ? item?.[field.key]?.value
                : item?.[field.key];
            return fieldAcc;
          },
          {
            name: field.name,
            parentKey: field.parentKey,
            id: `${field.key}`,
          }
          // { parentKey: field.parentKey, name: field.name }
          // { parentKey: field.parentKey }
          // {id: `${field.key}[${index}]`},
        );
        // console.log('dynamicFieldObject', dynamicFieldObject);

        acc.push(dynamicFieldObject);
        return acc;
      },
      []
    );

    const stateFederalTaxColumns = useMemo<
      MRT_ColumnDef<LegalFormLiensFiledAgainstCompanyTable>[]
    >(() => {
      const baseColumns = [
        {
          accessorKey: 'name',
          size: 200,
          maxSize: 200,
          header: `State / Federal Tax (${formik?.values?.hasStateFederalTax?.length})`,
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          // mantineTableHeadCellProps: {
          //   width: '10px !important',
          //   className: 'px-10',
          // },
          mantineTableBodyCellProps: (props: any) => {
            // console.log('props', props);
            const isParentRow = props?.row?.original?.parentKey;
            return {
              className: isParentRow
                ? 'px-10 fw-bold liens-table-parent-row'
                : 'pl-50 fw-normal',
              sx: {
                outline: 'none !important',
                // fontWeight: isParentRow ? 'bold' : 'normal',
              },
            };
          },
        },
      ];

      const dynamicColumns =
        formik?.values?.hasStateFederalTax?.map(
          (_item: any, index: number) => ({
            accessorKey: `value[${index}]`,
            size: 200,
            maxSize: 200,
            header: `Lien ${index + 1}`,
            enableColumnActions: false,
            enableSorting: false,
            // enableEditing: true,
            mantineTableBodyCellProps: (props: any) => {
              // console.log('props', props);
              const isNameHolderRow =
                props?.row?.original?.id === 'nameOfLienHolder' ||
                props?.row?.original?.id === 'nameOfDebtor' ||
                props?.row?.original?.id === 'jurisdiction';
              const isParentRow = props?.row?.original?.parentKey;
              return {
                className: isNameHolderRow
                  ? 'liens-details'
                  : isParentRow
                    ? 'liens-table-parent-row'
                    : '',
                sx: {
                  outline: 'none !important',
                  // fontWeight: isParentRow ? 'bold' : 'normal',
                },
              };
            },
            mantineEditTextInputProps: ({
              cell,
              column,
              row,
              table,
            }: {
              cell: MRT_Cell<LegalFormLiensFiledAgainstCompanyTable>;
              column: MRT_Column<LegalFormLiensFiledAgainstCompanyTable>;
              row: MRT_Row<LegalFormLiensFiledAgainstCompanyTable>;
              table: MRT_TableInstance<LegalFormLiensFiledAgainstCompanyTable>;
            }) => ({
              onChange: (event: any) => {
                console.log('cell', cell, event?.target?.value, column, row);
                let hasStateFederalTax: stateFederalTaxDetails[] | null =
                  formik?.values?.hasStateFederalTax;

                if (hasStateFederalTax && hasStateFederalTax?.length > 0) {
                  const updatedHasStateFederalTax = hasStateFederalTax?.map(
                    (lien: any, i: number) => {
                      if (i === index) {
                        if (row?.original?.id === 'filingAmount') {
                          return {
                            ...lien,
                            [row?.original?.id]: {
                              ...lien[row?.original?.id],
                              value: Number(event?.target?.value),
                            },
                          };
                        } else {
                          return {
                            ...lien,
                            [row?.original?.id]: event?.target?.value,
                          };
                        }
                      } else {
                        return lien;
                      }
                    }
                  );

                  console.log(
                    'hasStateFederalTax',
                    hasStateFederalTax,
                    updatedHasStateFederalTax
                  );

                  handleChange('hasStateFederalTax', updatedHasStateFederalTax);
                }
              },
              // type: row?.original?.id === 'dateOfFiling' ? 'date' : 'text',
              type: row?.original?.id === 'filingAmount' ? 'number' : 'text',
              variant: 'unstyled',
              placeholder: '',
            }),
            // Cell: ({ cell, row }: { cell: any; row: any }) => {
            //   const cellValue = cell.getValue() as any;
            //   console.log('cell-value', cellValue, row);

            //   if (row?.original?.id === 'courtFilings') {
            //     return (
            //       <Flex gap={3} justifyContent={'left'}>
            //         <ActionIcon
            //           onClick={() => {
            //             // table.setEditingRow(row);
            //             console.log('row', row);

            //             // setSelectedEditRow({
            //             //   row: row,
            //             //   table: table,
            //             // });
            //             // open();
            //           }}
            //           size={'sm'}
            //         >
            //           <MantineTooltip label="Edit" position="right">
            //             <IconEdit />
            //           </MantineTooltip>
            //         </ActionIcon>
            //       </Flex>
            //     );
            //   }
            // },

            Cell: ({ cell, row }: { cell: any; row: any }) => {
              if (row?.original?.id === 'nameOfLienHolder') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasStateFederalTax[${index}].nameOfLienHolder`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasStateFederalTax?.[index]?.nameOfLienHolder
                        ? values?.hasStateFederalTax?.[
                            index
                          ]?.nameOfLienHolder?.join(', ')
                        : ''
                    }
                    name={`hasStateFederalTax[${index}].nameOfLienHolder`}
                  />
                );
              } else if (row?.original?.id === 'nameOfDebtor') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasStateFederalTax[${index}].nameOfDebtor`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasStateFederalTax?.[index]?.nameOfDebtor
                        ? values?.hasStateFederalTax?.[
                            index
                          ]?.nameOfDebtor?.join(', ')
                        : ''
                    }
                    name={`hasStateFederalTax[${index}].nameOfDebtor`}
                    // rows={2}
                  />
                );
              } else if (row?.original?.id === 'courtFilings') {
                return (
                  <Flex gap={3} justifyContent={'left'}>
                    <MantineTooltip label="Upload Documents" position="right">
                      <ActionIcon
                        onClick={() => {
                          // table.setEditingRow(row);
                          const uploadName = `hasStateFederalTax[${index}].courtFilings`;
                          const formikvalue =
                            formik?.values?.hasStateFederalTax?.find(
                              (file: any, i: number) => index === i
                            )?.courtFilings;
                          console.log(
                            'row-courtFiling-hasStateFederalTax',
                            row,
                            formikvalue
                          );

                          setSelectedEditRow({
                            name: uploadName,
                            value: formikvalue ?? null,
                            uploadState: 'courtFilings',
                          });
                          open();
                        }}
                        size={'lg'}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent !important',
                          },
                        }}
                      >
                        <MdUploadFile size={'20px'} />
                      </ActionIcon>
                    </MantineTooltip>
                  </Flex>
                );
              } else if (row?.original?.id === 'hasPaymentPlan') {
                return (
                  <Stack spacing={5} direction="row" alignItems={'center'}>
                    <Flex
                      direction={'row'}
                      alignItems={'center'}
                      flexWrap={'nowrap'}
                      gap={3}
                    >
                      <Checkbox
                        colorScheme="green"
                        isChecked={
                          values?.hasStateFederalTax?.[index]
                            ?.hasPaymentPlan === true
                        }
                        onChange={(e) => {
                          handleChange(
                            `hasStateFederalTax[${index}].hasPaymentPlan`,
                            e.target.checked
                          );
                        }}
                        readOnly={showBox}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={
                          values?.hasStateFederalTax?.[index]
                            ?.hasPaymentPlan === false
                        }
                        onChange={(e) => {
                          handleChange(
                            `hasStateFederalTax[${index}].hasPaymentPlan`,
                            !e.target.checked
                          );
                        }}
                        readOnly={showBox}
                      >
                        No
                      </Checkbox>
                    </Flex>
                    <Flex
                      direction={'row'}
                      alignItems={'center'}
                      flexWrap={'nowrap'}
                      gap={3}
                    >
                      {values?.hasStateFederalTax?.[index]?.hasPaymentPlan && (
                        <MantineTooltip
                          label="Upload Documents"
                          position="right"
                        >
                          <ActionIcon
                            onClick={() => {
                              // table.setEditingRow(row);
                              const uploadName = `hasStateFederalTax[${index}].paymentPlanUpload`;
                              const formikvalue =
                                formik?.values?.hasStateFederalTax?.find(
                                  (file: any, i: number) => index === i
                                )?.paymentPlanUpload;
                              console.log(
                                'row-paymentPlan-hasStateFederalTax',
                                row,
                                formikvalue
                              );

                              setSelectedEditRow({
                                name: uploadName,
                                value: formikvalue ?? null,
                                uploadState: 'paymentPlanUpload',
                              });
                              open();
                            }}
                            size={'lg'}
                            sx={{
                              '&:hover': {
                                backgroundColor: 'transparent !important',
                              },
                            }}
                          >
                            <MdUploadFile size={'20px'} />
                          </ActionIcon>
                        </MantineTooltip>
                      )}
                    </Flex>
                  </Stack>
                );
              } else if (row?.original?.id === 'jurisdiction') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={formik.handleChange}
                    value={
                      values?.hasStateFederalTax?.[index]?.jurisdiction ?? ''
                    }
                    name={`hasStateFederalTax[${index}].jurisdiction`}
                    // rows={2}
                  />
                );
              }
            },
          })
        ) ?? [];

      return [...baseColumns, ...dynamicColumns];
    }, [formik?.values?.hasStateFederalTax]);

    // console.log(
    //   'stateFederalTaxColumns',
    //   stateFederalTaxColumns,
    //   stateFederalTaxTableData
    // );

    const outstandingJudgmentsTableData =
      outstandingJudgementsTableFields.reduce(
        (acc: any[], field, i: number) => {
          const dynamicFieldObject =
            formik?.values?.hasOutstandingJudgments?.reduce(
              (fieldAcc: any, item: any, index: number) => {
                fieldAcc[`value[${index}]`] =
                  field.key === 'filingAmount'
                    ? item?.[field.key]?.value
                    : item?.[field.key];
                return fieldAcc;
              },
              {
                name: field.name,
                parentKey: field.parentKey,
                id: `${field.key}`,
              }
              // { parentKey: field.parentKey, name: field.name }
              // { parentKey: field.parentKey }
              // {id: `${field.key}[${index}]`},
            );
          // console.log('dynamicFieldObject', dynamicFieldObject);

          acc.push(dynamicFieldObject);
          return acc;
        },
        []
      );

    const outstandingJudgmentsColumns = useMemo<
      MRT_ColumnDef<LegalFormLiensFiledAgainstCompanyTable>[]
    >(() => {
      const baseColumns = [
        {
          accessorKey: 'name',
          size: 200,
          maxSize: 200,
          header: `Judgements (${formik?.values?.hasOutstandingJudgments?.length})`,
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          // mantineTableHeadCellProps: {
          //   width: '10px !important',
          //   className: 'px-10',
          // },
          mantineTableBodyCellProps: (props: any) => {
            // console.log('props', props);
            const isParentRow = props?.row?.original?.parentKey;
            return {
              className: isParentRow
                ? 'px-10 fw-bold liens-table-parent-row'
                : 'pl-50 fw-normal',
              sx: {
                outline: 'none !important',
                // fontWeight: isParentRow ? 'bold' : 'normal',
              },
            };
          },
        },
      ];

      const dynamicColumns =
        formik?.values?.hasOutstandingJudgments?.map(
          (_item: any, index: number) => ({
            accessorKey: `value[${index}]`,
            size: 200,
            maxSize: 200,
            header: `Judgement ${index + 1}`,
            enableColumnActions: false,
            enableSorting: false,
            // enableEditing: true,
            mantineTableBodyCellProps: (props: any) => {
              // console.log('props', props);
              const isNameHolderRow =
                props?.row?.original?.id === 'nameOfPlaintiff' ||
                props?.row?.original?.id === 'nameOfDefendant' ||
                props?.row?.original?.id === 'jurisdiction';
              const isParentRow = props?.row?.original?.parentKey;
              return {
                className: isNameHolderRow
                  ? 'liens-details'
                  : isParentRow
                    ? 'liens-table-parent-row'
                    : '',
                sx: {
                  outline: 'none !important',
                  // fontWeight: isParentRow ? 'bold' : 'normal',
                },
              };
            },
            mantineEditTextInputProps: ({
              cell,
              column,
              row,
              table,
            }: {
              cell: MRT_Cell<LegalFormLiensFiledAgainstCompanyTable>;
              column: MRT_Column<LegalFormLiensFiledAgainstCompanyTable>;
              row: MRT_Row<LegalFormLiensFiledAgainstCompanyTable>;
              table: MRT_TableInstance<LegalFormLiensFiledAgainstCompanyTable>;
            }) => ({
              onChange: (event: any) => {
                console.log('cell', cell, event?.target?.value, column);
                let hasOutstandingJudgments:
                  | hasOutstandingJudgmentsDetails[]
                  | null = formik?.values?.hasOutstandingJudgments;

                if (
                  hasOutstandingJudgments &&
                  hasOutstandingJudgments?.length > 0
                ) {
                  const updatedHasOutstandingJudgments =
                    hasOutstandingJudgments?.map((lien: any, i: number) => {
                      if (i === index) {
                        if (row?.original?.id === 'filingAmount') {
                          return {
                            ...lien,
                            [row?.original?.id]: {
                              ...lien[row?.original?.id],
                              value: Number(event?.target?.value),
                            },
                          };
                        } else {
                          return {
                            ...lien,
                            [row?.original?.id]: event?.target?.value,
                          };
                        }
                      } else {
                        return lien;
                      }
                    });

                  console.log(
                    'hasOutstandingJudgments',
                    hasOutstandingJudgments,
                    updatedHasOutstandingJudgments
                  );

                  handleChange(
                    'hasOutstandingJudgments',
                    updatedHasOutstandingJudgments
                  );
                }
              },
              // type: row?.original?.id === 'dateOfFiling' ? 'date' : 'text',
              type: row?.original?.id === 'filingAmount' ? 'number' : 'text',
              variant: 'unstyled',
              placeholder: '',
            }),

            Cell: ({ cell, row }: { cell: any; row: any }) => {
              if (row?.original?.id === 'nameOfDebtors') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasOutstandingJudgments[${index}].nameOfDebtors`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasOutstandingJudgments?.[index]?.nameOfDebtors
                        ? values?.hasOutstandingJudgments?.[
                            index
                          ]?.nameOfDebtors?.join(', ')
                        : ''
                    }
                    name={`hasOutstandingJudgments[${index}].nameOfDebtors`}
                  />
                );
              } else if (row?.original?.id === 'nameOfCreditors') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasOutstandingJudgments[${index}].nameOfCreditors`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasOutstandingJudgments?.[index]?.nameOfCreditors
                        ? values?.hasOutstandingJudgments?.[
                            index
                          ]?.nameOfCreditors?.join(', ')
                        : ''
                    }
                    name={`hasOutstandingJudgments[${index}].nameOfCreditors`}
                    // rows={2}
                  />
                );
              } else if (row?.original?.id === 'courtFilings') {
                return (
                  <Flex gap={3} justifyContent={'left'}>
                    <MantineTooltip label="Upload Documents" position="right">
                      <ActionIcon
                        onClick={() => {
                          // table.setEditingRow(row);
                          const uploadName = `hasOutstandingJudgments[${index}].courtFilings`;
                          const formikvalue =
                            formik?.values?.hasOutstandingJudgments?.find(
                              (file: any, i: number) => index === i
                            )?.courtFilings;
                          console.log(
                            'row-courtFiling-hasOutstandingJudgments',
                            row,
                            formikvalue
                          );

                          setSelectedEditRow({
                            name: uploadName,
                            value: formikvalue ?? null,
                            uploadState: 'courtFilings',
                          });
                          open();
                        }}
                        size={'lg'}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent !important',
                          },
                        }}
                      >
                        <MdUploadFile size={'20px'} />
                      </ActionIcon>
                    </MantineTooltip>
                  </Flex>
                );
              } else if (row?.original?.id === 'jurisdiction') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={formik.handleChange}
                    value={
                      values?.hasOutstandingJudgments?.[index]?.jurisdiction ??
                      ''
                    }
                    name={`hasOutstandingJudgments[${index}].jurisdiction`}
                    // rows={2}
                  />
                );
              }
            },
          })
        ) ?? [];

      return [...baseColumns, ...dynamicColumns];
    }, [formik?.values?.hasOutstandingJudgments]);

    const outstandingSuitsTableData = outstandingSuitsTableFields.reduce(
      (acc: any[], field, i: number) => {
        const dynamicFieldObject = formik?.values?.hasOutstandingSuits?.reduce(
          (fieldAcc: any, item: any, index: number) => {
            fieldAcc[`value[${index}]`] =
              field.key === 'filingAmount'
                ? item?.[field.key]?.value
                : item?.[field.key];
            return fieldAcc;
          },
          {
            name: field.name,
            parentKey: field.parentKey,
            id: `${field.key}`,
          }
          // { parentKey: field.parentKey, name: field.name }
          // { parentKey: field.parentKey }
          // {id: `${field.key}[${index}]`},
        );
        // console.log('dynamicFieldObject', dynamicFieldObject);

        acc.push(dynamicFieldObject);
        return acc;
      },
      []
    );

    const outstandingSuitsColumns = useMemo<
      MRT_ColumnDef<LegalFormLiensFiledAgainstCompanyTable>[]
    >(() => {
      const baseColumns = [
        {
          accessorKey: 'name',
          size: 200,
          maxSize: 200,
          header: `Suits (${formik?.values?.hasOutstandingSuits?.length})`,
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          // mantineTableHeadCellProps: {
          //   width: '10px !important',
          //   className: 'px-10',
          // },
          mantineTableBodyCellProps: (props: any) => {
            // console.log('props', props);
            const isParentRow = props?.row?.original?.parentKey;
            return {
              className: isParentRow
                ? 'px-10 fw-bold liens-table-parent-row'
                : 'pl-50 fw-normal',
              sx: {
                outline: 'none !important',
                // fontWeight: isParentRow ? 'bold' : 'normal',
              },
            };
          },
        },
      ];

      const dynamicColumns =
        formik?.values?.hasOutstandingSuits?.map(
          (_item: any, index: number) => ({
            accessorKey: `value[${index}]`,
            size: 200,
            maxSize: 200,
            header: `Suit ${index + 1}`,
            enableColumnActions: false,
            enableSorting: false,
            // enableEditing: true,
            mantineTableBodyCellProps: (props: any) => {
              // console.log('props', props);
              const isNameHolderRow =
                props?.row?.original?.id === 'nameOfPlaintiff' ||
                props?.row?.original?.id === 'nameOfDefendant' ||
                props?.row?.original?.id === 'jurisdiction';
              const isParentRow = props?.row?.original?.parentKey;
              return {
                className: isNameHolderRow
                  ? 'liens-details'
                  : isParentRow
                    ? 'liens-table-parent-row'
                    : '',
                sx: {
                  outline: 'none !important',
                  // fontWeight: isParentRow ? 'bold' : 'normal',
                },
              };
            },
            mantineEditTextInputProps: ({
              cell,
              column,
              row,
              table,
            }: {
              cell: MRT_Cell<LegalFormLiensFiledAgainstCompanyTable>;
              column: MRT_Column<LegalFormLiensFiledAgainstCompanyTable>;
              row: MRT_Row<LegalFormLiensFiledAgainstCompanyTable>;
              table: MRT_TableInstance<LegalFormLiensFiledAgainstCompanyTable>;
            }) => ({
              onChange: (event: any) => {
                console.log('cell', cell, event?.target?.value, column);
                let hasOutstandingSuits: hasOutstandingSuitsDetails[] | null =
                  formik?.values?.hasOutstandingSuits;

                if (hasOutstandingSuits && hasOutstandingSuits?.length > 0) {
                  const updatedHasOutstandingSuits = hasOutstandingSuits?.map(
                    (lien: any, i: number) => {
                      if (i === index) {
                        if (row?.original?.id === 'filingAmount') {
                          return {
                            ...lien,
                            [row?.original?.id]: {
                              ...lien[row?.original?.id],
                              value: Number(event?.target?.value),
                            },
                          };
                        } else {
                          return {
                            ...lien,
                            [row?.original?.id]: event?.target?.value,
                          };
                        }
                      } else {
                        return lien;
                      }
                    }
                  );

                  console.log(
                    'hasOutstandingSuits',
                    hasOutstandingSuits,
                    updatedHasOutstandingSuits
                  );

                  handleChange(
                    'hasOutstandingSuits',
                    updatedHasOutstandingSuits
                  );
                }
              },
              // type: row?.original?.id === 'dateOfFiling' ? 'date' : 'text',
              type: row?.original?.id === 'filingAmount' ? 'number' : 'text',
              variant: 'unstyled',
              placeholder: '',
            }),

            Cell: ({ cell, row }: { cell: any; row: any }) => {
              if (row?.original?.id === 'nameOfPlaintiff') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasOutstandingSuits[${index}].nameOfPlaintiff`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasOutstandingSuits?.[index]?.nameOfPlaintiff
                        ? values?.hasOutstandingSuits?.[
                            index
                          ]?.nameOfPlaintiff?.join(', ')
                        : ''
                    }
                    name={`hasOutstandingSuits[${index}].nameOfPlaintiff`}
                  />
                );
              } else if (row?.original?.id === 'nameOfDefendant') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasOutstandingSuits[${index}].nameOfDefendant`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasOutstandingSuits?.[index]?.nameOfDefendant
                        ? values?.hasOutstandingSuits?.[
                            index
                          ]?.nameOfDefendant?.join(', ')
                        : ''
                    }
                    name={`hasOutstandingSuits[${index}].nameOfDefendant`}
                    // rows={2}
                  />
                );
              } else if (row?.original?.id === 'courtFilings') {
                return (
                  <Flex gap={3} justifyContent={'left'}>
                    <MantineTooltip label="Upload Documents" position="right">
                      <ActionIcon
                        onClick={() => {
                          // table.setEditingRow(row);
                          const uploadName = `hasOutstandingSuits[${index}].courtFilings`;
                          const formikvalue =
                            formik?.values?.hasOutstandingSuits?.find(
                              (file: any, i: number) => index === i
                            )?.courtFilings;
                          console.log(
                            'row-courtFiling-hasOutstandingSuits',
                            row,
                            formikvalue
                          );

                          setSelectedEditRow({
                            name: uploadName,
                            value: formikvalue ?? null,
                            uploadState: 'courtFilings',
                          });
                          open();
                        }}
                        size={'lg'}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent !important',
                          },
                        }}
                      >
                        <MdUploadFile size={'20px'} />
                      </ActionIcon>
                    </MantineTooltip>
                  </Flex>
                );
              } else if (row?.original?.id === 'jurisdiction') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={formik.handleChange}
                    value={
                      values?.hasOutstandingSuits?.[index]?.jurisdiction ?? ''
                    }
                    name={`hasOutstandingSuits[${index}].jurisdiction`}
                    // rows={2}
                  />
                );
              }
            },
          })
        ) ?? [];

      return [...baseColumns, ...dynamicColumns];
    }, [formik?.values?.hasOutstandingSuits]);

    const outstandingClaimsTableData = outstandingSuitsTableFields.reduce(
      (acc: any[], field, i: number) => {
        const dynamicFieldObject = formik?.values?.hasOutstandingClaims?.reduce(
          (fieldAcc: any, item: any, index: number) => {
            fieldAcc[`value[${index}]`] =
              field.key === 'filingAmount'
                ? item?.[field.key]?.value
                : item?.[field.key];
            return fieldAcc;
          },
          {
            name: field.name,
            parentKey: field.parentKey,
            id: `${field.key}`,
          }
          // { parentKey: field.parentKey, name: field.name }
          // { parentKey: field.parentKey }
          // {id: `${field.key}[${index}]`},
        );
        // console.log('dynamicFieldObject', dynamicFieldObject);

        acc.push(dynamicFieldObject);
        return acc;
      },
      []
    );

    const outstandingClaimsColumns = useMemo<
      MRT_ColumnDef<LegalFormLiensFiledAgainstCompanyTable>[]
    >(() => {
      const baseColumns = [
        {
          accessorKey: 'name',
          size: 200,
          maxSize: 200,
          header: `Claims (${formik?.values?.hasOutstandingClaims?.length})`,
          enableColumnActions: false,
          enableSorting: false,
          enableEditing: false,
          // mantineTableHeadCellProps: {
          //   width: '10px !important',
          //   className: 'px-10',
          // },
          mantineTableBodyCellProps: (props: any) => {
            // console.log('props', props);
            const isParentRow = props?.row?.original?.parentKey;
            return {
              className: isParentRow
                ? 'px-10 fw-bold liens-table-parent-row'
                : 'pl-50 fw-normal',
              sx: {
                outline: 'none !important',
                // fontWeight: isParentRow ? 'bold' : 'normal',
              },
            };
          },
        },
      ];

      const dynamicColumns =
        formik?.values?.hasOutstandingClaims?.map(
          (_item: any, index: number) => ({
            accessorKey: `value[${index}]`,
            size: 200,
            maxSize: 200,
            header: `Claim ${index + 1}`,
            enableColumnActions: false,
            enableSorting: false,
            // enableEditing: true,
            mantineTableBodyCellProps: (props: any) => {
              // console.log('props', props);
              const isNameHolderRow =
                props?.row?.original?.id === 'nameOfPlaintiff' ||
                props?.row?.original?.id === 'nameOfDefendant' ||
                props?.row?.original?.id === 'jurisdiction';
              const isParentRow = props?.row?.original?.parentKey;
              return {
                className: isNameHolderRow
                  ? 'liens-details'
                  : isParentRow
                    ? 'liens-table-parent-row'
                    : '',
                sx: {
                  outline: 'none !important',
                  // fontWeight: isParentRow ? 'bold' : 'normal',
                },
              };
            },
            mantineEditTextInputProps: ({
              cell,
              column,
              row,
              table,
            }: {
              cell: MRT_Cell<LegalFormLiensFiledAgainstCompanyTable>;
              column: MRT_Column<LegalFormLiensFiledAgainstCompanyTable>;
              row: MRT_Row<LegalFormLiensFiledAgainstCompanyTable>;
              table: MRT_TableInstance<LegalFormLiensFiledAgainstCompanyTable>;
            }) => ({
              onChange: (event: any) => {
                console.log('cell', cell, event?.target?.value, column);
                let hasOutstandingClaims: hasOutstandingSuitsDetails[] | null =
                  formik?.values?.hasOutstandingClaims;

                if (hasOutstandingClaims && hasOutstandingClaims?.length > 0) {
                  const updatedHasOutstandingClaims = hasOutstandingClaims?.map(
                    (lien: any, i: number) => {
                      if (i === index) {
                        if (row?.original?.id === 'filingAmount') {
                          return {
                            ...lien,
                            [row?.original?.id]: {
                              ...lien[row?.original?.id],
                              value: Number(event?.target?.value),
                            },
                          };
                        } else {
                          return {
                            ...lien,
                            [row?.original?.id]: event?.target?.value,
                          };
                        }
                      } else {
                        return lien;
                      }
                    }
                  );

                  console.log(
                    'hasOutstandingClaims',
                    hasOutstandingClaims,
                    updatedHasOutstandingClaims
                  );

                  handleChange(
                    'hasOutstandingClaims',
                    updatedHasOutstandingClaims
                  );
                }
              },
              // type: row?.original?.id === 'dateOfFiling' ? 'date' : 'text',
              type: row?.original?.id === 'filingAmount' ? 'number' : 'text',
              variant: 'unstyled',
              placeholder: '',
            }),

            Cell: ({ cell, row }: { cell: any; row: any }) => {
              if (row?.original?.id === 'nameOfPlaintiff') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasOutstandingClaims[${index}].nameOfPlaintiff`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasOutstandingClaims?.[index]?.nameOfPlaintiff
                        ? values?.hasOutstandingClaims?.[
                            index
                          ]?.nameOfPlaintiff?.join(', ')
                        : ''
                    }
                    name={`hasOutstandingClaims[${index}].nameOfPlaintiff`}
                  />
                );
              } else if (row?.original?.id === 'nameOfDefendant') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={(e) => {
                      const currentValue = e.currentTarget?.value
                        ?.split(', ')
                        .map((item) => item?.split(','))
                        .flat();
                      handleChange(
                        `hasOutstandingClaims[${index}].nameOfDefendant`,
                        currentValue
                      );
                    }}
                    value={
                      values?.hasOutstandingClaims?.[index]?.nameOfDefendant
                        ? values?.hasOutstandingClaims?.[
                            index
                          ]?.nameOfDefendant?.join(', ')
                        : ''
                    }
                    name={`hasOutstandingClaims[${index}].nameOfDefendant`}
                    // rows={2}
                  />
                );
              } else if (row?.original?.id === 'courtFilings') {
                return (
                  <Flex gap={3} justifyContent={'left'}>
                    <MantineTooltip label="Upload Documents" position="right">
                      <ActionIcon
                        onClick={() => {
                          // table.setEditingRow(row);
                          const uploadName = `hasOutstandingClaims[${index}].courtFilings`;
                          const formikvalue =
                            formik?.values?.hasOutstandingClaims?.find(
                              (file: any, i: number) => index === i
                            )?.courtFilings;
                          console.log(
                            'row-courtFiling-hasOutstandingClaims',
                            row,
                            formikvalue
                          );

                          setSelectedEditRow({
                            name: uploadName,
                            value: formikvalue ?? null,
                            uploadState: 'courtFilings',
                          });
                          open();
                        }}
                        size={'lg'}
                        sx={{
                          '&:hover': {
                            backgroundColor: 'transparent !important',
                          },
                        }}
                      >
                        <MdUploadFile size={'20px'} />
                      </ActionIcon>
                    </MantineTooltip>
                  </Flex>
                );
              } else if (row?.original?.id === 'jurisdiction') {
                return (
                  <Textarea
                    fontSize={'13px'}
                    w="100%"
                    placeholder=""
                    // borderRadius="5px"
                    focusBorderColor="transparent"
                    borderColor={'transparent'}
                    _hover={{ borderColor: 'transparent' }}
                    onChange={formik.handleChange}
                    value={
                      values?.hasOutstandingClaims?.[index]?.jurisdiction ?? ''
                    }
                    name={`hasOutstandingClaims[${index}].jurisdiction`}
                    // rows={2}
                  />
                );
              }
            },
          })
        ) ?? [];

      return [...baseColumns, ...dynamicColumns];
    }, [formik?.values?.hasOutstandingClaims]);

    const getOutstandingJudgmentsAccTable = (name: string) => {
      switch (name) {
        case 'Judgements': {
          console.log('Judgements>>>');

          return (
            <LegalFormEditableTable
              columns={outstandingJudgmentsColumns}
              tableData={outstandingJudgmentsTableData}
            />
          );
        }
        case 'Suits': {
          console.log('Suits>>>');
          return (
            <LegalFormEditableTable
              columns={outstandingSuitsColumns}
              tableData={outstandingSuitsTableData}
            />
          );
        }
        case 'Claims': {
          console.log('Claims>>>');
          return (
            <LegalFormEditableTable
              columns={outstandingClaimsColumns}
              tableData={outstandingClaimsTableData}
            />
          );
        }
        default:
          break;
      }
    };

    // useEffect(() => {
    //   if (
    //     values?.receivershipOrBankruptcyAcc &&
    //     values?.receivershipOrBankruptcyAcc?.length > 0
    //   ) {
    //     console.log('values?.receivershipOrBankruptcyAcc>>>');

    //     setFieldValue(
    //       'receivershipOrBankruptcyHistory.hasHistory',
    //       values?.receivershipOrBankruptcyHistory?.hasHistory ?? true
    //     );
    //   }
    //   if (values?.hasRelatedCompanies) {
    //     console.log('values?.hasRelatedCompanies>>>');
    //     setFieldValue(
    //       'relatedCompanies.hasRelatedCompanies',
    //       values?.relatedCompanies?.hasRelatedCompanies ?? true
    //     );
    //   }
    //   if (values?.hasLiensFiledAgainstCompany) {
    //     console.log('values?.hasLiensFiledAgainstCompany>>>');

    //     setFieldValue(
    //       'liensFiledAgainstCompany.hasLiens',
    //       values?.liensFiledAgainstCompany?.hasLiens ?? true
    //     );
    //   }
    //   if (values?.hasLiensFiledByCompany) {
    //     console.log('values?.hasLiensFiledByCompany>>>');

    //     setFieldValue(
    //       'liensFiledByCompany.hasLiens',
    //       values?.liensFiledByCompany?.hasLiens ?? true
    //     );
    //   }
    //   if (values?.hasStateFederalTax) {
    //     console.log('values?.hasStateFederalTax>>>');

    //     setFieldValue('stateFederalTax', values?.stateFederalTax ?? true);
    //   }
    //   if (
    //     values?.outstandingJudgmentsAcc &&
    //     values?.outstandingJudgmentsAcc?.length > 0
    //   ) {
    //     console.log('values?.outstandingJudgmentsAcc>>>');

    //     setFieldValue(
    //       'outstandingJudgments.hasOutstandingJudgments',
    //       values?.outstandingJudgments?.hasOutstandingJudgments ?? true
    //     );
    //   }
    // }, [values]);

    useImperativeHandle(ref, () => ({
      formik: formik,
    }));

    function capitalizeFirstLetter(str: any) {
      const dotIndex = str.indexOf('.');
      const modifiedStr = dotIndex !== -1 ? str.slice(0, dotIndex) : str;
      return (
        modifiedStr.charAt(0).toUpperCase() +
        modifiedStr
          .slice(1)
          .replace(/[A-Z]/g, (match: any) => ' ' + match.toLowerCase()) +
        (dotIndex !== -1 ? ' can not be null.' : '.')
      );
    }

    // Check QBO Status
    // useEffect(() => {
    //   const appId = applicationId;
    //   if (applicationId && !qboState.escReason) {
    //     fetchCheckQboStatus(appId);
    //   }
    // }, [applicationId]);

    // const fetchCheckQboStatus = async (appId: number) => {
    //   if (!!localStorage.getItem('Token')) {
    //     let actionResult = await dispatch(CheckQboStatus(appId) as any);
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    const fetchOngoingApplication = async () => {
      dispatch(
        getApplicationById({
          applicationId,
        }) as any
      );
    };

    const handleAddBox = (index: number) => {
      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        let relatedCompanies = newValues?.relatedCompanies || {};
        const companies = newValues?.relatedCompanies?.companies || [];

        companies?.push({
          name: null,
          natureOfOperations: null,
          ownershipStructure: null,
        });

        relatedCompanies = { ...relatedCompanies, companies: companies };

        return {
          ...newValues,
          relatedCompanies,
        };
      });
    };

    const handleRemoveBox = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        let relatedCompanies = newValues?.relatedCompanies || {};
        const companies = newValues?.relatedCompanies?.companies || [];

        if (companies?.length > 1) {
          const removeIndex = index;
          companies?.splice(removeIndex, 1);
          relatedCompanies = { ...relatedCompanies, companies: companies };

          return {
            ...newValues,
            relatedCompanies,
          };
        }

        return newValues;
      });
    };

    const onRefreshDuns = async () => {
      console.log(
        'formData',
        formData?.applicationId,
        AccoutingFormData?.applicantId
      );
      if (AccoutingFormData?.applicantId && formData?.applicationId) {
        await dispatch(
          refreshDuns({
            applicantId: AccoutingFormData?.applicantId,
            applicationId: formData?.applicationId,
          }) as any
        );
      }
    };

    const handleChange = debounce((name, value) => {
      setFieldValue(name, value);
    }, 1);

    console.log('formik', formik);
    console.log('isCourtFilingUpload', isCourtFilingUpload, selectedEditRow);

    return (
      <Box
        display="flex"
        gap={4}
        w="min(88rem,95%)"
        margin={'-236px auto 0'}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Container
          maxW={'container.lg'}
          boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
          h="auto"
          p="20px"
          borderRadius="20px"
          gap={5}
          bg={'white'}
          // overflow="auto" maxHeight="calc(100vh - 150px)" sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box display={'flex'} flexDirection="column" gap={5}>
              <Box display={'flex'} justifyContent={'space-between'}>
                <Box width={'100%'}>
                  <Text
                    textAlign="start"
                    fontSize={12}
                    color="red"
                    fontWeight="600"
                    pr={5}
                  >
                    {/* Pending Applicant Consent ! */}
                  </Text>
                </Box>
                <Box width={'100%'}>
                  <Text
                    fontWeight="600"
                    color="#114684"
                    textAlign="center"
                    fontSize={20}
                  >
                    Legal{' '}
                    {/* {applicantHeaderName && applicantHeaderName.length > 0
                        ? `: ${applicantHeaderName} `
                        : null} */}
                  </Text>
                </Box>
                <Box
                  width={'100%'}
                  display={'flex'}
                  justifyContent={'flex-end'}
                  marginRight={'10px'}
                >
                  {/* <AppOutlineButton
                    minW={'100px'}
                    onClick={onRefreshDuns}
                    isLoading={loader?.refreshDunsLoader}
                    loadingText={'Refresh Duns'}
                    _loading={{
                      '&:hover': {
                        bg: 'rgba(17, 70, 132,0.9)',
                        color: '#ffffff',
                        opacity: 0.4,
                        cursor: 'not-allowed',
                      },
                    }}
                    isDisabled={loader?.refreshDunsLoader}
                  >
                    Refresh Duns
                  </AppOutlineButton> */}
                  {loader?.refreshDunsLoader ? (
                    <Spinner size={'md'} />
                  ) : (
                    <Tooltip
                      fontSize="md"
                      label="Refresh Duns"
                      placement={'auto'}
                    >
                      <Box cursor={'pointer'} onClick={onRefreshDuns}>
                        <LuRefreshCcw size={22} />
                      </Box>
                    </Tooltip>
                  )}
                </Box>
              </Box>
              {/* <Box>
                  {!qboState.loading &&
                    applicationStatus !== 'Drafts' &&
                    !qboState?.escAccess && (
                      <AlertBox
                        variant={qboState?.escAccess ? 'success' : 'error'}
                        message={qboState?.escReason}
                      />
                    )}
                </Box> */}
              <Box display={'flex'} flexDirection={'column'} gap={3}>
                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={3}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Has the company, any prior company or any of the
                          owners or their spouses or a company owned by any of
                          them ever been placed in receivership or bankruptcy?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.receivershipOrBankruptcyHistory
                              .hasHistory === true
                          }
                          onChange={(e) => {
                            handleChange(
                              'receivershipOrBankruptcyHistory.hasHistory',
                              e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.receivershipOrBankruptcyHistory
                              .hasHistory === false
                          }
                          onChange={(e) => {
                            handleChange(
                              'receivershipOrBankruptcyHistory.hasHistory',
                              !e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.receivershipOrBankruptcyHistory?.hasHistory &&
                      errors.receivershipOrBankruptcyHistory?.hasHistory && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.receivershipOrBankruptcyHistory?.hasHistory
                          )}
                        </div>
                      )}
                  </Box>

                  {values.receivershipOrBankruptcyHistory?.hasHistory &&
                    formik?.values?.receivershipOrBankruptcyAcc &&
                    formik?.values?.receivershipOrBankruptcyAcc?.length > 0 && (
                      <Accordion
                        variant="filled"
                        className="help_accordian_section"
                        // multiple
                        value={activeAcc?.hasBankruptcyHistory}
                        onChange={(value) => {
                          setActiveAcc({
                            ...activeAcc,
                            hasBankruptcyHistory: value,
                          });
                        }}
                      >
                        {formik?.values?.receivershipOrBankruptcyAcc?.map(
                          (item: any) => {
                            return (
                              <Accordion.Item
                                key={item?.id}
                                value={item?.name}
                                className={'help_item'}
                              >
                                <Accordion.Control>
                                  {item?.name} ({item?.fieldArray?.length})
                                </Accordion.Control>
                                <Accordion.Panel className="accordion-content">
                                  <AccordianTable
                                    tableData={
                                      activeAcc?.hasBankruptcyHistory ===
                                      item?.name
                                        ? getReceivershipOrBankruptcyAccTableData(
                                            item?.name
                                          ) ?? []
                                        : []
                                    }
                                    formik={formik}
                                    title={item?.name}
                                    formikState={'receivershipOrBankruptcyAcc'}
                                    columns={
                                      activeAcc?.hasBankruptcyHistory ===
                                      item?.name
                                        ? getReceivershipOrBankruptcyAccColumns(
                                            item?.name
                                          ) ?? []
                                        : []
                                    }
                                  />
                                </Accordion.Panel>
                              </Accordion.Item>
                            );
                          }
                        )}
                      </Accordion>
                    )}
                  {values.receivershipOrBankruptcyHistory?.hasHistory && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            Additional Details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>
                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Additional Details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={(e) =>
                              handleChange(
                                'receivershipOrBankruptcyHistory.details',
                                e?.currentTarget?.value
                              )
                            }
                            value={
                              values.receivershipOrBankruptcyHistory.details ??
                              ''
                            }
                            name="receivershipOrBankruptcyHistory.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              currentHistoryData?.updatedData
                                ?.receivershipOrBankruptcyHistory &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.receivershipOrBankruptcyHistory &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              currentHistoryData?.updatedData
                                ?.receivershipOrBankruptcyHistory &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.receivershipOrBankruptcyHistory &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.receivershipOrBankruptcyHistory?.details &&
                          errors.receivershipOrBankruptcyHistory?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.receivershipOrBankruptcyHistory?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Has the company, its owners or any of the related
                          companies provided any guarantees for the indebtedness
                          of any other party, outside Corporation or
                          individuals? <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.guaranteesForOtherParties.hasGuarantees ===
                            true
                          }
                          onChange={(e) => {
                            handleChange(
                              'guaranteesForOtherParties.hasGuarantees',
                              e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.guaranteesForOtherParties.hasGuarantees ===
                            false
                          }
                          onChange={(e) => {
                            handleChange(
                              'guaranteesForOtherParties.hasGuarantees',
                              !e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.guaranteesForOtherParties?.hasGuarantees &&
                      errors.guaranteesForOtherParties?.hasGuarantees && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {errors.guaranteesForOtherParties?.hasGuarantees}
                        </div>
                      )}
                  </Box>

                  {values.guaranteesForOtherParties?.hasGuarantees && (
                    <LegalFormAccordian
                      formik={formik}
                      accordianState={
                        formik?.values?.guaranteesForOtherPartiesAcc
                      }
                      accordianName={'guaranteesForOtherPartiesAcc'}
                      isHistoryMode={showHistory}
                      isReadOnly={showBox}
                      currentHistoryData={currentHistoryData}
                    />
                  )}
                  {values.guaranteesForOtherParties?.hasGuarantees && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Additional Details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Additional Details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={(e) =>
                              handleChange(
                                'guaranteesForOtherParties.details',
                                e?.currentTarget?.value
                              )
                            }
                            value={
                              values.guaranteesForOtherParties?.details ?? ''
                            }
                            name="guaranteesForOtherParties.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              currentHistoryData?.updatedData
                                ?.guaranteesForOtherParties &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.guaranteesForOtherParties &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              currentHistoryData?.updatedData
                                ?.guaranteesForOtherParties &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.guaranteesForOtherParties &&
                              'white'
                            }
                          />
                        </Box>

                        {touched.guaranteesForOtherParties?.details &&
                          errors.guaranteesForOtherParties?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.guaranteesForOtherParties?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box
                    display={'flex'}
                    flexDir={{ base: 'column', lg: 'row' }}
                    justifyContent={'space-between'}
                    gap={{ base: 2, lg: 5 }}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        Does the company have one or more related companies?
                      </li>
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        colorScheme="green"
                        isChecked={
                          values.relatedCompanies.hasRelatedCompanies === true
                        }
                        onChange={(e) => {
                          handleChange(
                            'relatedCompanies.hasRelatedCompanies',
                            e.target.checked
                          );
                        }}
                        readOnly={showBox}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={
                          values.relatedCompanies.hasRelatedCompanies === false
                        }
                        onChange={(e) => {
                          handleChange('relatedCompanies', {
                            hasRelatedCompanies: !e.target.checked,
                            companies: [
                              {
                                name: null,
                                natureOfOperations: null,
                                ownershipStructure: null,
                              },
                            ],
                          });
                        }}
                        readOnly={showBox}
                      >
                        No
                      </Checkbox>
                    </Stack>
                  </Box>

                  {values.relatedCompanies?.hasRelatedCompanies &&
                    values?.relatedCompaniesAcc &&
                    values?.relatedCompaniesAcc?.length > 0 && (
                      <LegalFormAccordian
                        formik={formik}
                        accordianState={
                          formik?.values?.relatedCompaniesAcc ?? []
                        }
                        accordianName={'relatedCompaniesAcc'}
                      />

                      //Global and Domestic ultimate Accordian
                      // <Accordion
                      //   variant="filled"
                      //   className="help_accordian_section"
                      //   // defaultValue={accordianSectionName}
                      // >
                      //   {[
                      //     { parentAccName: 'Global Ultimate' },
                      //     { parentAccName: 'Domestic Ultimate' },
                      //   ]?.map((item: any, index: number) => (
                      //     <Accordion.Item
                      //       key={item?.id}
                      //       value={item?.parentAccName}
                      //       className={'help_item'}
                      //     >
                      //       <Accordion.Control
                      //       // disabled={isOpen && item.name !== accordianSectionName}
                      //       >
                      //         {item?.parentAccName}
                      //       </Accordion.Control>
                      //       <Accordion.Panel className="accordion-content">
                      //         <LegalFormAccordian
                      //           formik={formik}
                      //           accordianState={
                      //             formik?.values?.relatedCompaniesAcc
                      //           }
                      //           accordianName={'relatedCompaniesAcc'}
                      //         />
                      //       </Accordion.Panel>
                      //     </Accordion.Item>
                      //   ))}
                      // </Accordion>
                    )}

                  {values.relatedCompanies?.hasRelatedCompanies && (
                    <Box>
                      {formik?.values?.relatedCompanies?.companies?.map(
                        (item: any, i: number) => (
                          <>
                            <Box
                              display={{ base: 'block', md: 'flex' }}
                              flex={{ base: 1, md: 1 }}
                              gap={3}
                            >
                              <Box
                                maxWidth={'50%'}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'space-between'}
                              >
                                <Box>
                                  <Text
                                    fontWeight="600"
                                    color="#114684"
                                    textAlign="start"
                                    fontSize={13}
                                  >
                                    <li>Name</li>
                                  </Text>
                                </Box>
                                <Box>
                                  <Box
                                    display="flex"
                                    mb={{ base: '20px', md: '5px' }}
                                    mt={2}
                                  >
                                    <Input
                                      fontSize={'13px'}
                                      w="100%"
                                      type="text"
                                      placeholder="Name"
                                      borderRadius="5px"
                                      focusBorderColor="#7f7f7f"
                                      borderColor={'#7f7f7f'}
                                      _hover={{ borderColor: '#7f7f7f' }}
                                      onChange={(e) =>
                                        handleChange(
                                          `relatedCompanies.companies[${i}].name`,
                                          e?.currentTarget?.value
                                        )
                                      }
                                      value={
                                        values.relatedCompanies?.companies?.[i]
                                          ?.name ?? ''
                                      }
                                      name={`relatedCompanies.companies[${i}].name`}
                                      readOnly={showBox}
                                      bgColor={
                                        showHistory &&
                                        currentHistoryData?.updatedData &&
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                        'name' in
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[
                                            i
                                          ] &&
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i]
                                          ?.name !== null &&
                                        '#114684'
                                      }
                                      color={
                                        showHistory &&
                                        currentHistoryData?.updatedData &&
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                        'name' in
                                          currentHistoryData?.updatedData
                                            ?.relatedCompanies?.companies?.[
                                            i
                                          ] &&
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i]
                                          ?.name !== null &&
                                        'white'
                                      }
                                    />
                                  </Box>
                                </Box>
                              </Box>
                              <Box
                                maxWidth={'50%'}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'space-between'}
                              >
                                <Box>
                                  <Text
                                    fontWeight="600"
                                    color="#114684"
                                    textAlign="start"
                                    fontSize={13}
                                  >
                                    <li>
                                      Nature of operations of related company
                                    </li>
                                  </Text>
                                </Box>

                                <Box
                                  display="flex"
                                  mb={{ base: '20px', md: '5px' }}
                                  mt={2}
                                >
                                  <Input
                                    fontSize={'13px'}
                                    w="100%"
                                    type="text"
                                    placeholder="Nature of operations of related companies"
                                    borderRadius="5px"
                                    focusBorderColor="#7f7f7f"
                                    borderColor={'#7f7f7f'}
                                    _hover={{ borderColor: '#7f7f7f' }}
                                    onChange={(e) =>
                                      handleChange(
                                        `relatedCompanies.companies[${i}].natureOfOperations`,
                                        e?.currentTarget?.value
                                      )
                                    }
                                    value={
                                      values.relatedCompanies?.companies?.[i]
                                        ?.natureOfOperations ?? ''
                                    }
                                    name={`relatedCompanies.companies[${i}].natureOfOperations`}
                                    readOnly={showBox}
                                    bgColor={
                                      showHistory &&
                                      currentHistoryData?.updatedData &&
                                      currentHistoryData?.updatedData
                                        ?.relatedCompanies?.companies?.[i] &&
                                      'natureOfOperations' in
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                      '#114684'
                                    }
                                    color={
                                      showHistory &&
                                      currentHistoryData?.updatedData &&
                                      currentHistoryData?.updatedData
                                        ?.relatedCompanies?.companies?.[i] &&
                                      'natureOfOperations' in
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                      'white'
                                    }
                                  />
                                </Box>
                              </Box>
                              <Box
                                maxWidth={'50%'}
                                display={'flex'}
                                flexDirection={'column'}
                                justifyContent={'space-between'}
                              >
                                <Box>
                                  <Text
                                    fontWeight="600"
                                    color="#114684"
                                    textAlign="start"
                                    fontSize={13}
                                  >
                                    <li>
                                      What(%) is the ownership structure of this
                                      related company
                                    </li>
                                  </Text>
                                </Box>

                                <Box
                                  display="flex"
                                  mb={{ base: '20px', md: '5px' }}
                                  mt={2}
                                >
                                  <Input
                                    fontSize={'13px'}
                                    w="100%"
                                    type="text"
                                    placeholder="What % is the ownership structure of this related company "
                                    borderRadius="5px"
                                    focusBorderColor="#7f7f7f"
                                    borderColor={'#7f7f7f'}
                                    _hover={{ borderColor: '#7f7f7f' }}
                                    onChange={(e: any) => {
                                      // formik.handleChange(e);
                                      const value = handleMinMaxNumberInput({
                                        inputValue: e.target.value,
                                        max: 100,
                                      });
                                      handleChange(
                                        `relatedCompanies.companies[${i}].ownershipStructure`,
                                        value
                                      );
                                      // formik.setFieldValue(
                                      //   `relatedCompanies.companies[${i}].ownershipStructure`,
                                      //   value
                                      // );
                                    }}
                                    value={
                                      values.relatedCompanies?.companies?.[i]
                                        ?.ownershipStructure ?? ''
                                    }
                                    name={`relatedCompanies.companies[${i}].ownershipStructure`}
                                    readOnly={showBox}
                                    bgColor={
                                      showHistory &&
                                      currentHistoryData?.updatedData &&
                                      currentHistoryData?.updatedData
                                        ?.relatedCompanies?.companies?.[i] &&
                                      'ownershipStructure' in
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                      '#114684'
                                    }
                                    color={
                                      showHistory &&
                                      currentHistoryData?.updatedData &&
                                      currentHistoryData?.updatedData
                                        ?.relatedCompanies?.companies?.[i] &&
                                      'ownershipStructure' in
                                        currentHistoryData?.updatedData
                                          ?.relatedCompanies?.companies?.[i] &&
                                      'white'
                                    }
                                  />
                                </Box>
                              </Box>
                              {i === 0 ? (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  fontSize="xl"
                                  marginTop="18px"
                                  onClick={() => handleAddBox(i)}
                                  cursor={showBox ? 'not-allowed' : 'pointer'}
                                  pointerEvents={showBox ? 'none' : 'auto'}
                                >
                                  <CiCirclePlus />
                                </Box>
                              ) : (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  fontSize="xl"
                                  marginTop="18px"
                                  onClick={() => handleRemoveBox(i)}
                                  cursor={showBox ? 'not-allowed' : 'pointer'}
                                  pointerEvents={showBox ? 'none' : 'auto'}
                                >
                                  <CiCircleMinus />
                                </Box>
                              )}
                            </Box>
                          </>
                        )
                      )}
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Have there been any changes in the control, ownership,
                          or management of the company in the past 3 years or
                          are any such changes impending?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Box>
                        <Stack spacing={5} direction="row">
                          <Checkbox
                            colorScheme="green"
                            isChecked={
                              values.changesInManagement?.hasChanges === true
                            }
                            onChange={(e) => {
                              handleChange(
                                'changesInManagement.hasChanges',
                                e.target.checked
                              );
                            }}
                            readOnly={showBox}
                          >
                            Yes
                          </Checkbox>
                          <Checkbox
                            colorScheme="red"
                            isChecked={
                              values.changesInManagement?.hasChanges === false
                            }
                            onChange={(e) => {
                              handleChange(
                                'changesInManagement.hasChanges',
                                !e.target.checked
                              );
                            }}
                            readOnly={showBox}
                          >
                            No
                          </Checkbox>
                        </Stack>
                      </Box>
                    </Box>
                    {touched.changesInManagement?.hasChanges &&
                      errors.changesInManagement?.hasChanges && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {errors.changesInManagement?.hasChanges}
                        </div>
                      )}
                  </Box>
                  {values.changesInManagement?.hasChanges && (
                    <LegalFormAccordian
                      formik={formik}
                      accordianState={formik?.values?.changesInManagementAcc}
                      accordianName={'changesInManagementAcc'}
                    />
                  )}
                  {values.changesInManagement?.hasChanges && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            Additional Details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Additional Details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={(e) =>
                              handleChange(
                                'changesInManagement.explanation',
                                e?.currentTarget?.value
                              )
                            }
                            value={
                              values.changesInManagement?.explanation ?? ''
                            }
                            name="changesInManagement.explanation"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.changesInManagement &&
                              'explanation' in
                                currentHistoryData?.updatedData
                                  ?.changesInManagement &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.changesInManagement &&
                              'explanation' in
                                currentHistoryData?.updatedData
                                  ?.changesInManagement &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.changesInManagement?.explanation &&
                          errors.changesInManagement?.explanation && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.changesInManagement?.explanation}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Are there any liens filed against you by anyone?{' '}
                          <span style={{ color: 'red' }}>
                            {' '}
                            <span style={{ color: 'red' }}>*</span>
                          </span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.liensFiledAgainstCompany?.hasLiens === true
                          }
                          onChange={(e) => {
                            handleChange(
                              'liensFiledAgainstCompany.hasLiens',
                              e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.liensFiledAgainstCompany?.hasLiens === false
                          }
                          onChange={(e) => {
                            handleChange(
                              'liensFiledAgainstCompany.hasLiens',
                              !e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.liensFiledAgainstCompany?.hasLiens &&
                      errors.liensFiledAgainstCompany?.hasLiens && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.liensFiledAgainstCompany?.hasLiens
                          )}
                        </div>
                      )}
                  </Box>

                  {values.liensFiledAgainstCompany?.hasLiens &&
                    values?.hasLiensFiledAgainstCompany && (
                      // <LegalFormAccordian
                      //   formik={formik}
                      //   accordianState={
                      //     formik?.values?.liensFiledAgainstCompanyAcc
                      //   }
                      //   accordianName={'liensFiledAgainstCompanyAcc'}
                      // />
                      <LegalFormEditableTable
                        columns={liensFiledAgainstCompanyColumns}
                        tableData={liensFiledAgainstCompanyTableData}
                      />
                    )}
                  {values.liensFiledAgainstCompany?.hasLiens && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      gap={5}
                    >
                      {/* <Box flex={{ base: "1", md: "1" }}>
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                          >
                            <li>
                              {" "}
                              If yes, what is the $ amount?{" "}
                              <span style={{ color: "red" }}>*</span>
                            </li>
                          </Text>

                          <Box
                            display="flex"
                            mb={{ base: "20px", md: "5px" }}
                            mt={2}
                          >
                            <Input
                              fontSize={"13px"}
                              w="100%"
                              type="number"
                              onWheel={(e) =>
                                (e.target as HTMLInputElement).blur()
                              }
                              placeholder="If yes, what is the $ amount?"
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={"#7f7f7f"}
                              _hover={{ borderColor: "#7f7f7f" }}
                              onChange={formik.handleChange}
                              value={
                                values.liensFiledAgainstCompany
                                  ?.amount ?? ""
                              }
                              name="liensFiledAgainstCompany.amount"
                              readOnly={showBox}
                              onKeyDown={preventAlphabetsTyping}
                              bgColor={
                                showHistory &&
                                currentHistoryData?.updatedData?.liensFiledAgainstCompany &&
                                "amount" in
                                currentHistoryData?.updatedData?.liensFiledAgainstCompany &&
                                "#114684"
                              }
                              color={
                                showHistory &&
                                currentHistoryData?.updatedData?.liensFiledAgainstCompany &&
                                "amount" in
                                currentHistoryData?.updatedData?.liensFiledAgainstCompany &&
                                "white"
                              }
                            />
                          </Box>
                          {touched.liensFiledAgainstCompany?.amount &&
                            errors.liensFiledAgainstCompany?.amount && (
                              <div
                                style={{
                                  color: "red",
                                  fontSize: "13px",
                                  textAlign: "start",
                                  fontWeight: "500",
                                }}
                              >
                                {errors.liensFiledAgainstCompany?.amount}
                              </div>
                            )}
                        </Box> */}
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Additional Details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Additional Details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={(e) =>
                              handleChange(
                                'liensFiledAgainstCompany.details',
                                e?.currentTarget?.value
                              )
                            }
                            value={
                              values.liensFiledAgainstCompany?.details ?? ''
                            }
                            name="liensFiledAgainstCompany.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.liensFiledAgainstCompany &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.liensFiledAgainstCompany &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.liensFiledAgainstCompany &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.liensFiledAgainstCompany &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.liensFiledAgainstCompany?.details &&
                          errors.liensFiledAgainstCompany?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.liensFiledAgainstCompany?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                {/* {AccoutingFormData?.country === 'United States' && ( */}
                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box
                    display={'flex'}
                    flexDir={{ base: 'column', lg: 'row' }}
                    justifyContent={'space-between'}
                    gap={{ base: 2, lg: 5 }}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        Are there any state or federal tax liens that are open?{' '}
                        {/* <span style={{ color: "red" }}>
                          {" "}
                          <span style={{ color: "red" }}>*</span>
                        </span> */}
                      </li>
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        colorScheme="green"
                        isChecked={values.stateFederalTax === true}
                        onChange={(e) => {
                          handleChange('stateFederalTax', true);
                        }}
                        readOnly={showBox}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={values.stateFederalTax === false}
                        onChange={(e) => {
                          handleChange('paymentPlan', null);
                          handleChange('stateFederalTax', false);
                          // setFieldValue('taxingEntityDoc', null);
                        }}
                        readOnly={showBox}
                      >
                        No
                      </Checkbox>
                    </Stack>
                  </Box>

                  {values.stateFederalTax && values?.hasStateFederalTax && (
                    <LegalFormEditableTable
                      columns={stateFederalTaxColumns}
                      tableData={stateFederalTaxTableData}
                    />
                  )}

                  {/* {values.stateFederalTax && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Is there a payment plan in place?
                          Provide a copy of
                          the agreement or documentation from the taxing entity.{' '}
                          <span style={{ color: "red" }}>
                          {" "}
                          <span style={{ color: "red" }}>*</span>
                        </span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row" alignItems={'center'}>
                        {values.paymentPlan && (
                          <Tooltip
                            fontSize="md"
                            label="Upload the document under Documents tab under Other"
                            placement={'auto'}
                          >
                            <Box display={{ base: 'none', lg: 'block' }}>
                              <HiMiniInformationCircle
                                size={22}
                                cursor={'pointer'}
                                color="#114684"
                              />
                            </Box>
                          </Tooltip>
                          <Box display={{ base: 'none', lg: 'block' }}>
                            <Button
                              bg={'#114684'}
                              color={'white'}
                              _hover={{ bg: '#114684', color: 'white' }}
                              onClick={() => setActiveStep(6)}
                              isDisabled={showBox}
                            >
                              Upload Documents
                            </Button>
                          </Box>
                        )}
                        <Checkbox
                          colorScheme="green"
                          isChecked={values.paymentPlan === true}
                          onChange={(e) => {
                            setFieldValue('paymentPlan', e.target.checked);
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasLiens: e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={values.paymentPlan === false}
                          onChange={(e) => {
                            setFieldValue('paymentPlan', !e.target.checked);
                            // setFieldValue('taxingEntityDoc', null);
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasLiens: !e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                        {values.paymentPlan && (
                          <Tooltip
                            fontSize="md"
                            label="Upload the document under Documents tab under Other"
                            placement={'auto'}
                          >
                            <Box display={{ base: 'block', lg: 'none' }}>
                              <HiMiniInformationCircle
                                size={22}
                                cursor={'pointer'}
                                color="#114684"
                              />
                            </Box>
                          </Tooltip>
                          <Box display={{ base: 'block', lg: 'none' }}>
                            <Button
                              bg={'#114684'}
                              color={'white'}
                              _hover={{ bg: '#114684', color: 'white' }}
                              onClick={() => setActiveStep(6)}
                              isDisabled={showBox}
                            >
                              Upload Documents
                            </Button>
                          </Box>
                        )}
                      </Stack>
                    </Box>
                  )} */}

                  {/* {values.stateFederalTax && values?.paymentPlan && (
                        <Box
                          display={'flex'}
                          flexDir={{ base: 'column', lg: 'row' }}
                          justifyContent={'space-between'}
                          gap={{ base: 2, lg: 5 }}
                        >
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                          >
                            <li>Upload document from taxing entity. </li>
                          </Text>
                          <Stack spacing={5} direction="row">
                            <Checkbox
                              colorScheme="green"
                              isChecked={values.taxingEntityDoc === true}
                              onChange={(e) => {
                                setFieldValue(
                                  'taxingEntityDoc',
                                  e.target.checked
                                );
                              }}
                              readOnly={showBox}
                            >
                              Yes
                            </Checkbox>
                            <Checkbox
                              colorScheme="red"
                              isChecked={values.taxingEntityDoc === false}
                              onChange={(e) => {
                                setFieldValue(
                                  'taxingEntityDoc',
                                  !e.target.checked
                                );
                              }}
                              readOnly={showBox}
                            >
                              No
                            </Checkbox>
                          </Stack>
                        </Box>
                      )} */}
                </Box>
                {/* )} */}

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Are there any judgments, suits or claims outstanding
                          against your company its officers or any company of
                          either? <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.outstandingJudgments
                              .hasOutstandingJudgments === true
                          }
                          onChange={(e) => {
                            handleChange(
                              'outstandingJudgments.hasOutstandingJudgments',
                              e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.outstandingJudgments
                              .hasOutstandingJudgments === false
                          }
                          onChange={(e) => {
                            handleChange(
                              'outstandingJudgments.hasOutstandingJudgments',
                              !e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.outstandingJudgments?.hasOutstandingJudgments &&
                      errors.outstandingJudgments?.hasOutstandingJudgments && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.outstandingJudgments?.hasOutstandingJudgments
                          )}
                        </div>
                      )}
                  </Box>

                  {/* {values.outstandingJudgments?.hasOutstandingJudgments && (
                    // <Accordion
                    //   variant="filled"
                    //   className="help_accordian_section"
                    // >
                    //   {formik?.values?.outstandingJudgmentsAcc?.map(
                    //     (item: any, index: number) => {
                    //       return (
                    //         <Accordion.Item
                    //           key={item?.id}
                    //           value={item?.name}
                    //           className={'help_item'}
                    //         >
                    //           <Accordion.Control>
                    //             {item?.name}
                    //           </Accordion.Control>
                    //           <Accordion.Panel className="accordion-content">
                    //             <AccordianContent
                    //               formik={formik}
                    //               AccordianFields={item?.fields}
                    //               accordianSectionName={item?.name}
                    //               isOpen={false}
                    //             />
                    //           </Accordion.Panel>
                    //         </Accordion.Item>
                    //       );
                    //     }
                    //   )}
                    // </Accordion>

                    <LegalFormEditableTable
                      columns={outstandingJudgmentsColumns}
                      tableData={outstandingJudgmentsTableData}
                    />
                  )} */}

                  {values.outstandingJudgments?.hasOutstandingJudgments &&
                    formik?.values?.outstandingJudgmentsAcc &&
                    formik?.values?.outstandingJudgmentsAcc?.length > 0 && (
                      <Accordion
                        variant="filled"
                        className="help_accordian_section"
                        // multiple
                        value={activeAcc?.outstandingJudgments}
                        onChange={(value) => {
                          // console.log('value', value);
                          setActiveAcc({
                            ...activeAcc,
                            outstandingJudgments: value,
                          });
                        }}
                      >
                        {formik?.values?.outstandingJudgmentsAcc?.map(
                          (item: any) => {
                            return (
                              <Accordion.Item
                                key={item?.id}
                                value={item?.name}
                                className={'help_item'}
                              >
                                <Accordion.Control>
                                  {item?.name} ({item?.fieldArray?.length})
                                </Accordion.Control>
                                <Accordion.Panel className="accordion-content">
                                  {activeAcc?.outstandingJudgments ===
                                    item?.name &&
                                    getOutstandingJudgmentsAccTable(item?.name)}
                                </Accordion.Panel>
                              </Accordion.Item>
                            );
                          }
                        )}
                      </Accordion>
                    )}

                  {values.outstandingJudgments?.hasOutstandingJudgments && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      gap={5}
                    >
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Additional Details{' '}
                            <span style={{ color: 'red' }}>
                              {' '}
                              <span style={{ color: 'red' }}>*</span>
                            </span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Additional Details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={(e) =>
                              handleChange(
                                'outstandingJudgments.details',
                                e?.currentTarget?.value
                              )
                            }
                            value={values.outstandingJudgments?.details ?? ''}
                            name="outstandingJudgments.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.outstandingJudgments &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.outstandingJudgments &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.outstandingJudgments &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.outstandingJudgments &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.outstandingJudgments?.details &&
                          errors.outstandingJudgments?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.outstandingJudgments?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box
                    display={'flex'}
                    flexDir={{ base: 'column', lg: 'row' }}
                    justifyContent={'space-between'}
                    gap={{ base: 2, lg: 5 }}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        Are there any claims made against a surety bond?
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>
                    <Stack spacing={5} direction="row">
                      <Checkbox
                        colorScheme="green"
                        isChecked={values.bondClaims === true}
                        onChange={(e) => {
                          formik.setFieldValue('bondClaims', e.target.checked);
                        }}
                        readOnly={showBox}
                      >
                        Yes
                      </Checkbox>
                      <Checkbox
                        colorScheme="red"
                        isChecked={values.bondClaims === false}
                        onChange={(e) => {
                          formik.setFieldValue('bondClaims', !e.target.checked);
                          formik.setFieldValue('claimsPaid', null);
                          formik.setFieldValue('suretyMade', null);
                        }}
                        readOnly={showBox}
                      >
                        No
                      </Checkbox>
                    </Stack>
                  </Box>

                  {values?.bondClaims && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Were the claims paid?
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={values.claimsPaid === true}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'claimsPaid',
                              e.target.checked
                            );
                            formik.setFieldValue('suretyMade', null);
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={values.claimsPaid === false}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'claimsPaid',
                              !e.target.checked
                            );
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasOutstandingJudgments: !e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                  )}

                  {values?.bondClaims && values?.claimsPaid && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Was the surety made whole?
                          {/* <span style={{ color: "red" }}>*</span> */}
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={values.suretyMade === true}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'suretyMade',
                              e.target.checked
                            );
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasOutstandingJudgments: e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={values.suretyMade === false}
                          onChange={(e) => {
                            formik.setFieldValue(
                              'suretyMade',
                              !e.target.checked
                            );
                            // setShowTextarea({
                            //   ...showTextarea,
                            //   hasOutstandingJudgments: !e.target.checked,
                            // });
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                  )}

                  {values?.bondClaims &&
                    values?.claimsPaid &&
                    values.suretyMade === false && (
                      // <Accordion
                      //   variant="filled"
                      //   className="help_accordian_section"
                      // >
                      //   {formik?.values?.bondClaimsAcc?.map(
                      //     (item: any, index: number) => {
                      //       return (
                      //         <Accordion.Item
                      //           key={item?.id}
                      //           value={item?.name}
                      //           className={'help_item'}
                      //         >
                      //           <Accordion.Control>
                      //             {item?.name}
                      //           </Accordion.Control>
                      //           <Accordion.Panel className="accordion-content">
                      //             <AccordianContent
                      //               formik={formik}
                      //               AccordianFields={item?.fields}
                      //               accordianSectionName={item?.name}
                      //               isOpen={false}
                      //             />
                      //           </Accordion.Panel>
                      //         </Accordion.Item>
                      //       );
                      //     }
                      //   )}
                      // </Accordion>
                      <LegalFormAccordian
                        formik={formik}
                        accordianState={formik?.values?.bondClaimsAcc}
                        accordianName={'bondClaimsAcc'}
                      />
                    )}
                  {values?.bondClaims &&
                    values?.claimsPaid &&
                    values.suretyMade === false && (
                      <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                        <Box flex={{ base: '1', md: '1' }}>
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                          >
                            <li>
                              {' '}
                              Additional Details{' '}
                              <span style={{ color: 'red' }}>*</span>
                            </li>
                          </Text>

                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Textarea
                              fontSize={'13px'}
                              w="100%"
                              placeholder="Additional Details"
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onChange={(e) =>
                                handleChange(
                                  'whyNotMade',
                                  e?.currentTarget?.value
                                )
                              }
                              value={values?.whyNotMade ?? ''}
                              name="whyNotMade"
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData?.updatedData &&
                                'whyNotMade' in
                                  currentHistoryData?.updatedData &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                currentHistoryData?.updatedData &&
                                'whyNotMade' in
                                  currentHistoryData?.updatedData &&
                                'white'
                              }
                            />
                          </Box>
                          {touched.whyNotMade && errors.whyNotMade && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.whyNotMade}
                            </div>
                          )}
                        </Box>
                      </Box>
                    )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Are there significant disputes for any work which you
                          did or failed to do?{' '}
                          <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.disputesWithOthers?.hasDisputes === true
                          }
                          onChange={(e) => {
                            handleChange(
                              'disputesWithOthers.hasDisputes',
                              e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.disputesWithOthers?.hasDisputes === false
                          }
                          onChange={(e) => {
                            handleChange(
                              'disputesWithOthers.hasDisputes',
                              !e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>

                    {touched.disputesWithOthers?.hasDisputes &&
                      errors.disputesWithOthers?.hasDisputes && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.disputesWithOthers?.hasDisputes
                          )}
                        </div>
                      )}
                  </Box>
                  {values.disputesWithOthers?.hasDisputes && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Additional Details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Additional Details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={(e) =>
                              handleChange(
                                'disputesWithOthers.details',
                                e?.currentTarget?.value
                              )
                            }
                            value={values.disputesWithOthers?.details ?? ''}
                            name="disputesWithOthers.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.disputesWithOthers &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.disputesWithOthers &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.disputesWithOthers &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.disputesWithOthers &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.disputesWithOthers?.details &&
                          errors.disputesWithOthers?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.disputesWithOthers?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Are there any liens filed by you against a third
                          party? <span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.liensFiledByCompany?.hasLiens === true
                          }
                          onChange={(e) => {
                            handleChange(
                              'liensFiledByCompany.hasLiens',
                              e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.liensFiledByCompany?.hasLiens === false
                          }
                          onChange={(e) => {
                            handleChange(
                              'liensFiledByCompany.hasLiens',
                              !e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                    {touched.liensFiledByCompany?.hasLiens &&
                      errors.liensFiledByCompany?.hasLiens && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.liensFiledByCompany?.hasLiens
                          )}
                        </div>
                      )}
                  </Box>

                  {values.liensFiledByCompany?.hasLiens &&
                    values?.hasLiensFiledByCompany && (
                      // <LegalFormAccordian
                      //   formik={formik}
                      //   accordianState={
                      //     formik?.values?.liensFiledByCompanyAcc
                      //   }
                      //   accordianName={'liensFiledByCompanyAcc'}
                      // />
                      <LegalFormEditableTable
                        columns={liensFiledByCompanyColumns}
                        tableData={liensFiledByCompanyTableData}
                      />
                    )}
                  {values.liensFiledByCompany?.hasLiens && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Additional Details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Additional Details"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={(e) =>
                              handleChange(
                                'liensFiledByCompany.details',
                                e?.currentTarget?.value
                              )
                            }
                            value={values.liensFiledByCompany?.details ?? ''}
                            name="liensFiledByCompany.details"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.liensFiledByCompany &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.liensFiledByCompany &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData
                                ?.liensFiledByCompany &&
                              'details' in
                                currentHistoryData?.updatedData
                                  ?.liensFiledByCompany &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.liensFiledByCompany?.details &&
                          errors.liensFiledByCompany?.details && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.liensFiledByCompany?.details}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          {' '}
                          Is the company presently engaged in any dispute with
                          suppliers or sub trades relative to their work or
                          materials?<span style={{ color: 'red' }}>*</span>
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={
                            values.disputesWithSuppliers?.hasDisputes === true
                          }
                          onChange={(e) => {
                            handleChange(
                              'disputesWithSuppliers.hasDisputes',
                              e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={
                            values.disputesWithSuppliers?.hasDisputes === false
                          }
                          onChange={(e) => {
                            handleChange(
                              'disputesWithSuppliers.hasDisputes',
                              !e.target.checked
                            );
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                    {touched.disputesWithSuppliers?.hasDisputes &&
                      errors.disputesWithSuppliers?.hasDisputes && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {capitalizeFirstLetter(
                            errors.disputesWithSuppliers?.hasDisputes
                          )}
                        </div>
                      )}
                  </Box>
                  {values.disputesWithSuppliers?.hasDisputes && (
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      gap={5}
                    >
                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDir={'column'}
                        justifyContent={'space-between'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            Additional Details{' '}
                            <span style={{ color: 'red' }}>*</span>
                          </li>
                        </Text>
                        <Box>
                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Textarea
                              fontSize={'13px'}
                              w="100%"
                              placeholder="Additional Details"
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onChange={(e) =>
                                handleChange(
                                  'disputesWithSuppliers.details',
                                  e?.currentTarget?.value
                                )
                              }
                              value={
                                values.disputesWithSuppliers?.details ?? ''
                              }
                              name="disputesWithSuppliers.details"
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData?.updatedData
                                  ?.disputesWithSuppliers &&
                                'details' in
                                  currentHistoryData?.updatedData
                                    ?.disputesWithSuppliers &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                currentHistoryData?.updatedData
                                  ?.disputesWithSuppliers &&
                                'details' in
                                  currentHistoryData?.updatedData
                                    ?.disputesWithSuppliers &&
                                'white'
                              }
                            />
                          </Box>
                          {touched.disputesWithSuppliers?.details &&
                            errors.disputesWithSuppliers?.details && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.disputesWithSuppliers?.details}
                              </div>
                            )}
                        </Box>
                      </Box>
                      <Box
                        flex={{ base: '1', md: '1' }}
                        display={'flex'}
                        flexDir={'column'}
                        justifyContent={'space-between'}
                      >
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                          display={'flex'}
                          gap={'3px'}
                          alignItems={'flex-start'}
                          flexWrap={'nowrap'}
                        >
                          <li>
                            By what means is it funded? Provide a copy of the
                            agreement.{' '}
                            <span style={{ color: 'red', marginRight: '2px' }}>
                              *
                            </span>
                          </li>
                          <Tooltip
                            fontSize="md"
                            label="Upload the agreement under Documents tab under Other"
                            placement={'auto'}
                            // shouldWrapChildren={true}
                          >
                            <Box marginBottom={'-7px'}>
                              <HiMiniInformationCircle
                                size={22}
                                cursor={'pointer'}
                                color="#114684"
                              />
                            </Box>
                          </Tooltip>
                        </Text>
                        <Box>
                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Textarea
                              fontSize={'13px'}
                              w="100%"
                              placeholder="By what means is it funded? Provide a copy of the agreement."
                              borderRadius="5px"
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              onChange={(e) =>
                                handleChange(
                                  'disputesWithSuppliers.fundingMeans',
                                  e?.currentTarget?.value
                                )
                              }
                              value={
                                values.disputesWithSuppliers.fundingMeans ?? ''
                              }
                              name="disputesWithSuppliers.fundingMeans"
                              readOnly={showBox}
                              bgColor={
                                showHistory &&
                                currentHistoryData?.updatedData
                                  ?.disputesWithSuppliers &&
                                'fundingMeans' in
                                  currentHistoryData?.updatedData
                                    ?.disputesWithSuppliers &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                currentHistoryData?.updatedData
                                  ?.disputesWithSuppliers &&
                                'fundingMeans' in
                                  currentHistoryData?.updatedData
                                    ?.disputesWithSuppliers &&
                                'white'
                              }
                            />
                          </Box>
                          {touched.disputesWithSuppliers?.fundingMeans &&
                            errors.disputesWithSuppliers?.fundingMeans && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {errors.disputesWithSuppliers?.fundingMeans}
                              </div>
                            )}
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>

                <Box
                  bgColor={'#f5f5f5'}
                  borderRadius={'8px'}
                  p={3}
                  display={'flex'}
                  flexDir={'column'}
                  gap={5}
                >
                  <Box>
                    <Box
                      display={'flex'}
                      flexDir={{ base: 'column', lg: 'row' }}
                      justifyContent={'space-between'}
                      gap={{ base: 2, lg: 5 }}
                    >
                      <Text
                        fontWeight="600"
                        color="#114684"
                        textAlign="start"
                        fontSize={13}
                      >
                        <li>
                          Is there a formal Buy/Sell agreement.{' '}
                          {/* <span style={{ color: 'red' }}>*</span> */}
                        </li>
                      </Text>
                      <Stack spacing={5} direction="row">
                        <Checkbox
                          colorScheme="green"
                          isChecked={values.buySellAgreement === true}
                          onChange={(e) =>
                            handleChange('buySellAgreement', e.target.checked)
                          }
                          readOnly={showBox}
                        >
                          Yes
                        </Checkbox>
                        <Checkbox
                          colorScheme="red"
                          isChecked={values.buySellAgreement === false}
                          onChange={(e) => {
                            handleChange('agreementDetails', null);
                            handleChange('buySellAgreement', !e.target.checked);
                          }}
                          readOnly={showBox}
                        >
                          No
                        </Checkbox>
                      </Stack>
                    </Box>
                    {touched.buySellAgreement && errors.buySellAgreement && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '13px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {capitalizeFirstLetter(errors.buySellAgreement)}
                      </div>
                    )}
                  </Box>

                  {/* {values.buySellAgreement && ( */}
                  {/* <Accordion
                    hidden={!values.buySellAgreement}
                    variant="filled"
                    className="help_accordian_section"
                  >
                    {formik?.values?.buySellAgreementAcc?.map(
                      (item: any, index: number) => {
                        return (
                          <Accordion.Item
                            key={item?.id}
                            value={item?.name}
                            className={'help_item'}
                          >
                            <Accordion.Control>{item?.name}</Accordion.Control>
                            <Accordion.Panel className="accordion-content">
                              <AccordianContent
                                formik={formik}
                                AccordianFields={item?.fields}
                                accordianSectionName={item?.name}
                                isOpen={false}
                              />
                            </Accordion.Panel>
                          </Accordion.Item>
                        );
                      }
                    )}
                  </Accordion> */}
                  {/* )} */}

                  {values.buySellAgreement && (
                    <LegalFormAccordian
                      formik={formik}
                      accordianState={formik?.values?.buySellAgreementAcc}
                      accordianName={'buySellAgreementAcc'}
                    />
                  )}
                  {values.buySellAgreement && (
                    <Box display={{ base: 'block', md: 'flex' }} gap={5}>
                      <Box flex={{ base: '1', md: '1' }}>
                        <Text
                          fontWeight="600"
                          color="#114684"
                          textAlign="start"
                          fontSize={13}
                        >
                          <li>
                            {' '}
                            Provide further details regarding the agreement{' '}
                            {/* <span style={{ color: 'red' }}>*</span> */}
                          </li>
                        </Text>

                        <Box
                          display="flex"
                          mb={{ base: '20px', md: '5px' }}
                          mt={2}
                        >
                          <Textarea
                            fontSize={'13px'}
                            w="100%"
                            placeholder="Provide further details regarding the agreement"
                            borderRadius="5px"
                            focusBorderColor="#7f7f7f"
                            borderColor={'#7f7f7f'}
                            _hover={{ borderColor: '#7f7f7f' }}
                            onChange={(e) =>
                              handleChange(
                                'agreementDetails',
                                e?.currentTarget?.value
                              )
                            }
                            value={values.agreementDetails ?? ''}
                            name="agreementDetails"
                            readOnly={showBox}
                            bgColor={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              'agreementDetails' in
                                currentHistoryData?.updatedData &&
                              '#114684'
                            }
                            color={
                              showHistory &&
                              currentHistoryData?.updatedData &&
                              'agreementDetails' in
                                currentHistoryData?.updatedData &&
                              'white'
                            }
                          />
                        </Box>
                        {touched.agreementDetails &&
                          errors.agreementDetails && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {errors.agreementDetails}
                            </div>
                          )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
            <Box
              display={'flex'}
              justifyContent={'end'}
              marginTop={'10px'}
              gap={2}
            >
              {!showBox && (
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  onClick={
                    userType === 'broker' && formData?.assignToApplicant
                      ? () => OnHandleReturnToBroker()
                      : () => OnHandelReturnToSender()
                  }
                  type={
                    userType === 'broker' && formData?.assignToApplicant
                      ? 'button'
                      : 'submit'
                  }
                  isLoading={loader?.btnLoader}
                  // isDisabled={
                  //   userType === 'broker' && formData?.assignToApplicant
                  //     ? true
                  //     : false
                  // }
                >
                  {isApplicantUser
                    ? 'Return to Broker'
                    : userType === 'broker' && formData?.assignToApplicant
                      ? 'Edit - Rescind Assignment from Applicant'
                      : 'Assign to applicant'}
                </Button>
              )}
              {!showBox && (
                <Box>
                  <Button
                    bg={'#114684'}
                    color={'white'}
                    _hover={{ bg: '#114684', color: 'white' }}
                    type="submit"
                    isLoading={S3UploadLoading}
                  >
                    Update
                  </Button>
                </Box>
              )}

              {isCourtFilingUpload && selectedEditRow && (
                <LegalFormCourtFilingUpload
                  isOpen={isCourtFilingUpload}
                  onClose={close}
                  modalTitle={'Legal Form'}
                  formik={formik}
                  uploadState={selectedEditRow?.uploadState ?? ''}
                  name={selectedEditRow?.name ?? ''}
                  value={selectedEditRow?.value ?? []}
                />
              )}
            </Box>
          </form>
        </Container>
        {showHistory && (
          <>
            <Box
              height="calc(100vh - 150px)"
              w={{
                base: '100%',
                lg: '360px',
              }}
              sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
              bg={'white'}
              display={'flex'}
              flexDir={'column'}
              gap={'10px'}
              borderRadius={'12px'}
              boxShadow="0 0 14px -6px rgba(0,0,0,0.3)"
              p={4}
              minW={'280px'}
            >
              <Box style={{ fontSize: '25px', fontWeight: 'bold' }}>
                History
              </Box>
              <Box
                display={'flex'}
                flexDir={'column'}
                gap={'10px'}
                overflow={'auto'}
              >
                {HistoryData?.data?.map((item: any, index: number) => {
                  const formattedDate = formatDate(item?.createdAt);
                  return (
                    <Box
                      key={item?.historyId}
                      bgColor={historyIndex == index ? '#1146844a' : '#f5f5f5'}
                      p={3}
                      borderRadius={'12px'}
                      cursor={'pointer'}
                      mr={2}
                      border={
                        historyIndex == index
                          ? '1px solid #114684'
                          : '1px solid gray'
                      }
                      onClick={() => setHistoryIndex(index)}
                    >
                      <Box
                        fontWeight={500}
                        mt={2}
                        display={'flex'}
                        height={'18px'}
                        alignItems={'center'}
                      >
                        <Box marginRight={1}>Date :</Box>
                        <Text> {formattedDate.date}</Text>
                        {/* <Text
                          mx={2}
                          borderLeft="1px solid #114684"
                          height="auto"
                          boxShadow="none"
                        ></Text> */}
                        <Divider
                          orientation="vertical"
                          borderColor="black"
                          mx={1}
                        />
                        <Text>{formattedDate.time}</Text>
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Status : {item?.applicationStatus}
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Initiated By : {item?.initiatedBy}
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Updated By : {item?.broker?.firstName}
                        &nbsp;
                        {item?.broker?.lastName}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  }
);

export default LegalForm;
