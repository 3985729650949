import { Accordion, Container } from '@mantine/core';
import parse from 'html-react-parser';
import React from 'react';
import { IFaqs } from 'src/Redux/Plans/state';
import './FaqSimple.css';

interface FaqSimpleProps {
  faqs: IFaqs[];
}

export const FaqSimple: React.FC<FaqSimpleProps> = ({ faqs }) => {
  console.log('faqs', faqs);

  return (
    <Container>
      <Accordion variant="separated">
        {faqs.map((faq, index) => (
          <Accordion.Item
            key={faq.faqId}
            className={'item'}
            value={faq.question}
          >
            <Accordion.Control>{faq.question}</Accordion.Control>
            <Accordion.Panel className="accordion-content">
              {parse(faq.answer)}
            </Accordion.Panel>
          </Accordion.Item>
        ))}
      </Accordion>
    </Container>
  );
};
