import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';

interface AccountantState {
  user: any;
  loading: boolean;
  error: null;
}

const initialState: AccountantState = {
  user: [],
  loading: false,
  error: null,
};

export const Owner = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

// Accountant account
export const AddAccountant = createAsyncThunk(
  'Accountant/AddAccountant',
  async (
    AddOwner: {
      name: string;
      email: string;
      nameOfAccountingFirm: string;
      phoneNumber: number | null;
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/user/accountant/create`,
          AddOwner,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

//Accountant all data

export const AccountantAllData = createAsyncThunk(
  'accountant/AccountantAllData',
  async () => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/user/accountant/getall`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

//Accountant data by Applicant
export const AccountantDataByApplicant = createAsyncThunk(
  'accountant/AccountantDataByApplicant',
  async ({ id }: { id: number | null }) => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/user/accountant/getall?applicantId=${id}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

//Delete  data

export const AccountantDelete = createAsyncThunk(
  'accountant/AccountantDelete',
  async (userId: string, { rejectWithValue }) => {
    try {
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.delete(
          `${BASEURL}/user/accountant/delete/${userId}`,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return userId;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
// id to get data
export const accountantgetUserById = createAsyncThunk(
  'accountant/accountantgetUserById',
  async (userId: string, { rejectWithValue }) => {
    try {
      const Token = localStorage.getItem('Token');
      const response = await axios.get(
        `${BASEURL}/user/accountant/get/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${Token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      if (!!response?.headers['token']) {
        localStorage.setItem('Token', response?.headers['token']);
      }
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);
//update

export const Updateaccountant = createAsyncThunk(
  'accountant/Updateaccountant',
  async (updatedData: any, { rejectWithValue }) => {
    try {
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/accountant/update/${updatedData.accountId}`,
          updatedData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const accountantSlice = createSlice({
  name: 'accountant',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // accountant account

      .addCase(AddAccountant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(AddAccountant.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.user = action.payload;
      })
      .addCase(AddAccountant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      //accountant all data
      .addCase(AccountantAllData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AccountantAllData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(AccountantAllData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      //accountant all data by applicant
      .addCase(AccountantDataByApplicant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AccountantDataByApplicant.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(AccountantDataByApplicant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //delete
      .addCase(AccountantDelete.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AccountantDelete.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          if (Array.isArray(state.user)) {
            state.user = state.user.filter(
              (user: any) => user.ownerId !== action.payload
            );
          }
        }
      )
      .addCase(AccountantDelete.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //id to get data
      .addCase(accountantgetUserById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        accountantgetUserById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(accountantgetUserById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      // update owner
      .addCase(Updateaccountant.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        Updateaccountant.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(Updateaccountant.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      });
  },
});

export default accountantSlice.reducer;
