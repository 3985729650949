export const updatedOtherFieldValue = (
  data: any,
  key: string,
  returnKey: string,
  otherFieldValue: string | null
) => {
  return data?.map((item: any, i: number) => {
    if (item[`${key}`] === 'Others') {
      return { ...item, [`${returnKey}`]: otherFieldValue };
    } else {
      return item;
    }
  });
};

export const getCmsUpdatedValues = (values: TAboutForm) => {
  let updatedValues = { ...values };

  let contractorsOther = updatedOtherFieldValue(
    values?.contractors,
    'type',
    'otherContractorType',
    values?.contractorTypeOther
  );
  let salesPercentageByOwnerTypeOther = updatedOtherFieldValue(
    values?.salesPercentageByOwnerType,
    'ownerType',
    'otherOwnerType',
    values?.salesPercentageByOwnerTypeOther
  );
  let typeOfWorkOther = updatedOtherFieldValue(
    values?.typeOfWork,
    'type',
    'otherType',
    values?.typeOfWorkOther
  );
  let workSubletOther = updatedOtherFieldValue(
    values?.workSublet,
    'type',
    'otherSublet',
    values?.workSubletOther
  );
  if (contractorsOther?.length <= 1) {
    contractorsOther = contractorsOther?.map((item: any, i: number) => {
      return { ...item, annualSalesPercentage: 100 };
    });
  } else {
    contractorsOther = contractorsOther?.map((item: any) => {
      const matchingContract = updatedValues?.fixedContract.find(
        (contract) => item.type === contract.contractorType
      );

      if (matchingContract) {
        return {
          ...item,
          annualSalesPercentage: matchingContract.annualSales,
        };
      }

      // If no matching contract found, return the original item
      return item;
    });
  }
  let managementVal =
    values?.managementControlCheckbox &&
    values?.managementControlCheckbox?.length > 0
      ? values?.managementControlCheckbox?.map((item: any, i: number) => {
          if (item === 'Other') {
            return {
              jobType: item,
              otherJobType: values?.managementControlOther,
            };
          } else {
            return { jobType: item };
          }
        })
      : [];
  if (values?.managementControlRadio) {
    managementVal.push({ jobType: values?.managementControlRadio });
  }

  updatedValues = Object.assign(updatedValues, {
    contractors: contractorsOther,
    salesPercentageByOwnerType: salesPercentageByOwnerTypeOther,
    typeOfWork: typeOfWorkOther,
    workSublet: workSubletOther,
    managementControl: managementVal,
  });

  return updatedValues;
};
