import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { getToken } from './helpers';
import Cookies from 'js-cookie';

// Edit for content or reponse type
// interface ICallAPI {
//   apiURL: string;
//   apiMethod: string;
//   apiData?: object | null;
//   resolveDataAsReponse?: boolean;
//   contentType?: "application/json";
// }

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${getToken()}`;
axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

// CANCLE SAME REQ LOGIC START HERE
let cancelTokenSource: CancelTokenSource;
const requestMap: Record<string, any> = {};

axiosInstance.interceptors.request.use(
  (config) => {
    const requestKey = config.url!; // Use request URL as the key

    // If there's an existing request with the same URL, cancel it
    if (requestMap[requestKey]) {
      requestMap[requestKey].cancel('Repeated request canceled');
      delete requestMap[requestKey]; // Remove the entry from the map
    }

    // Create a new CancelToken source for the current request
    const { CancelToken } = axios;
    requestMap[requestKey] = CancelToken.source();

    // Attach the cancel token to the request config
    config.cancelToken = requestMap[requestKey].token;

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
// CANCLE SAME REQ LOGIC END HERE

const callAPI = (
  apiURL: string,
  apiMethod: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
  apiData: object | null = null,
  cancelPrevious: boolean = false,
  contentType: string = 'application/json'
): Promise<AxiosResponse<any, any>> => {
  let headers = {
    'Content-Type': contentType,
  };

  if (cancelPrevious && cancelTokenSource) {
    cancelTokenSource.cancel('Previous request canceled');
  }

  cancelTokenSource = axios.CancelToken.source();

  return new Promise<AxiosResponse<any, any>>((resolve, reject) => {
    const config: any = {
      url: apiURL,
      method: apiMethod,
      headers,
      cancelToken: cancelTokenSource?.token,
    };
    if (apiData) {
      config['data'] = apiData;
    }
    return axiosInstance(config)
      .then((response) => {
        if (!!response?.headers['token']) {
          localStorage.setItem('Token', response?.headers['token']);
          Cookies.set('Token', response?.headers['token']);
          axiosInstance.defaults.headers.common['Authorization'] =
            `Bearer ${response?.headers['token']}`;
        }
        resolve(response);
      })
      .catch((err) => {
        if (
          err.response?.data &&
          err.response?.status === 401 &&
          err.response?.data?.msg === 'Token has expired.'
        ) {
          localStorage.removeItem('Token');
          localStorage.removeItem('token');
          Cookies.remove('token');
          localStorage.removeItem('userType');
          window.location.pathname = '/login';
        }
        reject(err.response);
      });
  });
};

export default callAPI;
