// export type User = {
//   progressId: string;
//   job: string | number;
//   projectName: string;
//   projectStatus: string | number;
//   approvedChangeRequest: string | number;
//   revisedContractValue: string | number;
//   billedToDate: string | number;
//   originalBudget: string | number;
//   approvedChanges: string | number;
//   revisedBudget: string | number;
//   costToDate: string | number;
//   remainingBudget: string | number;
//   percentEarned: string | number;
//   earnedValue: string | number;
//   billingsInExcessOfEarnedValue: string | number;
//   costInExcessOfBillings: string | number;
//   forecastedGP: string | number;
//   forecastedGPMargin: string | number;
//   currentGP: string | number;
//   currentGPMargin: string | number;
//   underOverBillings: string | number;
//   originalContractValue: string | number;
//   isDeleted: boolean;
// };

export interface ProgressInfoAttributes extends Record<string, unknown> {
  progressId: string;
  // job: number | null;
  projectName: string;
  projectStatus: 'active' | 'completed';
  originalContractValue: number | null;
  // approvedChangeRequest: number | null;
  revisedContractValue: number | null;
  totalBilled: number;
  toBeBilled: number | null;
  originalBudget: number | null;
  // approvedChanges: number | null;
  revisedBudget: number | null;
  costToDate: number;
  remainingBudget: number | null;
  percentEarned: number | null;
  earnedValue: number | null;
  billingsInExcessOfEarnedValue: number | null;
  costInExcessOfBillings: number | null;
  forecastedGP: number | null;
  forecastedGPMargin: number | null;
  currentGP: number | null;
  currentGPMargin: number | null;
  billings: number | null;
  isDeleted: boolean | false;
  underOverBillings: number | null;
  projectBonded: boolean | null;
}

export const WIPExceleColumns: Record<string, string>[] = [
  {
    accessorKey: 'projectName',
    header: 'Project Name',
  },
  {
    accessorKey: 'projectStatus',
    header: 'Project Status',
  },
  {
    accessorKey: 'originalContractValue',
    header: 'Total Contract Value $',
  },
  {
    accessorKey: 'totalBilled',
    header: 'Total Billed $',
  },
  {
    accessorKey: 'toBeBilled',
    header: 'To Be Billed $',
  },
  {
    accessorKey: 'originalBudget',
    header: 'Total Estimated Cost $',
  },
  {
    accessorKey: 'costToDate',
    header: 'Cost to Date $',
  },
  {
    accessorKey: 'remainingBudget',
    header: 'Remaining Budget $',
  },
  {
    accessorKey: 'percentEarned',
    header: '% Earned',
  },
  {
    accessorKey: 'earnedValue',
    header: 'Earned Value $',
  },
  {
    accessorKey: 'billingsInExcessOfEarnedValue',
    header: 'Billings in Excess of Earned Value $',
  },
  {
    accessorKey: 'costInExcessOfBillings',
    header: 'Cost in Excess of Billings $',
  },
  {
    accessorKey: 'forecastedGP',
    header: 'Estimated GP $',
  },
  {
    accessorKey: 'forecastedGPMargin',
    header: 'Estimated GM %',
  },
  {
    accessorKey: 'currentGP',
    header: 'Current GP $',
  },
  {
    accessorKey: 'currentGPMargin',
    header: 'Current GM %',
  },
  {
    accessorKey: 'underOverBillings',
    header: '+/- Billings $',
  },
  {
    accessorKey: 'projectBonded',
    header: 'Is this project bonded?',
  },
];
