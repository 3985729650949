import {
  bankingOption,
  corporateOrgShareholdersOption,
  financialYearOption,
  otherOptionOptions,
  workersCompensationOption,
} from './data';

export const getSecondDropDownOption = (value: string) => {
  switch (value) {
    case 'Financial Statement':
      return financialYearOption;
    case 'Corporate Organization & Shareholders':
      return corporateOrgShareholdersOption;
    case 'Banking':
      return bankingOption;
    case 'Workers Compensation':
      return workersCompensationOption;
    case 'Key Personnel Resume':
      return financialYearOption;
    case 'Other':
      return otherOptionOptions;
    default:
      return financialYearOption;
  }
};
