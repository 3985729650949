import {
  Link as ChakraLink,
  Flex,
  Icon,
  Menu,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';

interface SidebarItemProps {
  icon: IconType;
  title: string;
  description?: string;
  active: boolean;
  navSize: 'small' | 'large';
  to?: string;
  style?: any;
  TooltipTitle?: string;

  onClick?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
}

const SidebarItem: React.FC<SidebarItemProps> = ({
  icon,
  title,
  active,
  navSize,
  to,
  onClick,
  onMouseEnter,
  onMouseLeave,
  style,
  TooltipTitle,
}) => {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  return (
    <Flex
      flexDir="column"
      w="100%"
      alignItems={navSize === 'small' ? 'center' : 'flex-start'}
    >
      <Menu placement="right">
        <ChakraLink
          as={Link}
          to={to}
          borderRadius={
            navSize === 'small' ? '0px 50px 50px 0px ' : '0px 50px 50px 0px '
          }
          width={navSize === 'large' ? '100%' : '100%'}
          bg={active ? '#ffa011' : undefined}
          onClick={onClick}
          _hover={{
            bg: '#ffa011',
            color: 'white',
            cursor: 'pointer',
          }}
        >
          <Tooltip
            label={TooltipTitle ? TooltipTitle : title}
            isOpen={tooltipOpen}
            placement="right"
            hasArrow
            bg="gray.300"
            color="black"
          >
            {/* <MenuButton
              w="100%"
              onClick={() => setTooltipOpen(false)}
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
            > */}
            <Flex
              p={2}
              style={style}
              onClick={() => setTooltipOpen(false)}
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
            >
              <Icon
                as={icon}
                bg={active ? '#114684 ' : '#114684'}
                color={active ? 'white' : 'white'}
                fontWeight="bold"
                borderRadius="13px"
                h={{
                  base: '27px',
                  sm: '27px',
                  md: '29px',
                  lg: '29px',
                  xl: '29px',
                }}
                p="5px"
                w={{
                  base: '27px',
                  sm: '27px',
                  md: '29px',
                  lg: '29px',
                  xl: '29px',
                }}
              />

              <Text
                ml={1}
                display={navSize === 'small' ? 'none' : 'flex'}
                alignItems={'center'}
                fontWeight="600"
                fontSize={{
                  base: '10px',
                  sm: '11px',
                  md: '12px',
                  lg: '13px',
                  xl: '14px',
                }}
                color={active ? '#114684' : '#114684'}
                whiteSpace="nowrap"
                overflow="hidden"
                textOverflow="ellipsis"
              >
                {title}
              </Text>
            </Flex>
            {/* </MenuButton> */}
          </Tooltip>
        </ChakraLink>
      </Menu>
    </Flex>
  );
};

export default SidebarItem;
