import { Box, Button, Text, useToast } from '@chakra-ui/react';
import { useState } from 'react';
import Swal from 'sweetalert2';
// import { permission } from "../../../Redux/Broker/Dashboard/BrokerageDashboard";

import { useDispatch } from 'react-redux';
// import { PermissionSchema } from "../../../ValidationSchema/Pages/Index";
import { useNavigate } from 'react-router-dom';
import QBOReqDeniedModal, {
  QBODeniedFormValues,
} from 'src/Component/Common/QBO/QBOReqDeniedModal';
import { permissionAction } from 'src/Redux/AccessRequest/slice';

function Permission() {
  const Toast = useToast();
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState<{ [key: number]: boolean }>({});
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const urlParams = new URLSearchParams(window.location.search);
  const id: any | null | string = urlParams.get('id');
  const token: any | null | string = urlParams.get('token');
  const userType = urlParams.get('userType');
  const parsedId: any | null | number = id ? parseInt(id, 10) : null;

  const initialValues: QBODeniedFormValues = {
    id: parsedId,
    token: token,
    userType: userType,
    permission: true,
    deniedReason: null,
  };

  const handleOpenPopup = () => {
    setIsOpen(true);
  };

  const OnhandelClick = async (values: QBODeniedFormValues) => {
    try {
      setLoading((prevState) => ({
        ...prevState,
        [values?.id as number]: true,
      }));
      const actionResult = await dispatch(permissionAction(values) as any);
      const response = actionResult.payload;
      const resStatus = response.status;

      if (resStatus === 200) {
        // setLoading((prevState) => ({
        //   ...prevState,
        //   [response?.applicationId]: false,
        // }));
        setLoading({});
        Swal.fire({
          icon: 'success',
          title: response.msg,
          position: 'center',
          showConfirmButton: false,
          showCloseButton: true,
        });
      } else {
        setLoading({});
        Swal.fire({
          icon: 'error',
          title: response.msg,
          showCloseButton: true,
          position: 'center',
          showConfirmButton: false,
        });
      }
      setTimeout(() => {
        window.close();
      }, 2000);
    } catch (error: any) {
      if (error) {
        Swal.fire({
          icon: 'error',
          title: error,
          timer: 1000,
          position: 'center',
          showConfirmButton: false,
        });
      }
    }
  };

  return (
    <>
      <QBOReqDeniedModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        initialValues={initialValues}
        loading={loading}
        setLoading={setLoading}
      />
      <Box
        position="relative"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          margin={'auto'}
          borderRadius={10}
          width={'38%'}
          p={5}
          boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        >
          <Box>
            <Text textAlign="center" fontSize={30} color={'#114684'}>
              {userType === 'owner'
                ? 'Credit Data Permission'
                : 'QBO Permission'}
            </Text>
          </Box>
          <Box display="flex" width={'100%'} alignItems="center" gap={2} p={5}>
            <Button
              w="100%"
              type="submit"
              bg="#114684"
              color="white"
              _hover={{ bg: '#114684' }}
              onClick={() => OnhandelClick(initialValues)}
              isLoading={loading[initialValues?.id ?? 0] && !isOpen}
            >
              Yes
            </Button>
            <Button
              w="100%"
              type="submit"
              bg="#114684"
              color="white"
              _hover={{ bg: '#114684' }}
              onClick={handleOpenPopup}
            >
              No
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Permission;
