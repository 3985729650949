import React, { ReactNode, useState } from 'react';
import { Box, Flex, useBreakpointValue } from '@chakra-ui/react';
import Navbar from '../Navbar';
import Sidebar from './Sidebar';

interface LayoutProps {
  children: ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [navSize, changeNavSize] = useState<'small' | 'large'>('large');

  const mlValue = useBreakpointValue({
    base: '178px',
    sm: '219px',
    md: '255px',
    lg: '300px',
  });

  const mlValue2 = useBreakpointValue({
    base: '50px',
    sm: '50px',
    md: ' 50px',
    lg: '50px',
  });

  return (
    <Box>
      <Flex>
        <Sidebar navSize={navSize} changeNavSize={changeNavSize} />
        <Box width={'100%'}>
          <Navbar navSize={navSize} changeNavSize={changeNavSize} />
          <Box
            height={{ base: 'calc(100vh - 64px)', md: 'calc(100vh - 80px)' }}
            overflow={'auto'}
            transition={'0.3s'}
            pl={{ base: 0, md: navSize !== 'small' ? mlValue : mlValue2 }}
          >
            {children}
          </Box>
        </Box>
      </Flex>
    </Box>
  );
};

export default Layout;
