import { useToast } from '@chakra-ui/react';
import { jwtDecode } from 'jwt-decode';
import { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  getApplicationById,
  getApplicationUserkey,
} from 'src/Redux/Applications/slice';
import { useAppSelector } from 'src/Redux/Store';
import { checkOwnerFormStatus } from '../../Redux/Applicant/Owner/Owner';
import { PFinfoUpdate } from '../../Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { SubmitPersonalFinanceForms } from '../../Redux/Broker/Dashboard/BrokerageDashboard';
import { decrypt, decryptedPayload, insertAtIndex } from '../../utils/helpers';
import PersonalFinancialInfo from '../BrokerPages/CreateApplicationForm/PersonalFinancialInfo';
import FormLinkExpired from './FormLinkExpired';
import FormStatusLoading from './FormStatusLoading';
import FormSubmitedSuccess from './FormSubmitedSuccess';

const OwnerPersonalFinanceForm = () => {
  const [ownerData, setOwnerData] = useState<Record<string, any>>({});
  const [otherOwnersData, setOtherOwnersData] = useState([]);
  const [personalFinanceInfoList, setPersonalFinanceInfoList] = useState<
    Record<string, any>
  >({});
  const [submitForm, setSubmitForm] = useState(false);
  const [isFormSubmitedSuccess, setIsFormSubmitedSuccess] = useState(false);
  const [isFormLinkAssigned, setIsFormLinkAssigned] = useState<boolean | null>(
    null
  );
  const [isReturnToSender, setIsReturnToSender] = useState(false);
  const [assignToId, setAssignToId] = useState(0);
  const [urlParams, setSearchParams] = useSearchParams();
  const [isLoading, setIsLoading] = useState(false);
  const [applicationData, setApplicationData] = useState<IApplication | null>(
    null
  );
  const [applicationKey, setApplicationUserKey] = useState<string | null>(null);
  const appliactionsSlice = useAppSelector((state) => state.appliactionsSlice);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const toast = useToast();
  const token = urlParams.get('token') || '';
  const userId = urlParams.get('userId') || '';
  const applicantId = urlParams.get('applicantId') || '';
  const applicationId = urlParams.get('applicationId') || '';
  const userType = urlParams.get('userType') || '';

  useEffect(() => {
    if (applicationId) {
      dispatch(
        getApplicationUserkey({
          applicationId: Number(applicationId),
        }) as any
      );
    }
  }, [applicationId]);

  useEffect(() => {
    const { status, type, currentApplication, userKey } = appliactionsSlice;
    switch (status) {
      case 'loading':
        if (
          type === 'GET_APPLICATION_BY_ID' ||
          type === 'GET_APPLICATION_USER_KEY'
        ) {
          setIsLoading(true);
        }
        break;
      case 'succeed':
        if (type === 'GET_APPLICATION_BY_ID' && currentApplication) {
          setApplicationData(currentApplication);
          setIsLoading(false);
          getPFFormData(currentApplication);
        }
        if (type === 'GET_APPLICATION_USER_KEY') {
          setIsLoading(false);
          const decoded: Record<string, any> = jwtDecode(userKey as string);
          const applicationKey = decrypt(
            decoded?.usKey,
            process.env.REACT_APP_SS_KEY as string
          );
          setApplicationUserKey(applicationKey);

          checkFormStatus();
        }
        break;
      case 'failed':
        if (
          type === 'GET_APPLICATION_BY_ID' ||
          type === 'GET_APPLICATION_USER_KEY'
        ) {
          setIsLoading(false);
        }
        break;

      default:
        break;
    }
  }, [appliactionsSlice.status]);

  const getPFFormData = (currentApplication: IApplication) => {
    if (!!localStorage.getItem('Token') && currentApplication) {
      let PfFormAPIData: Record<string, any> =
        currentApplication?.financialInfo;

      if (currentApplication?.financialInfo?.ownersFinancialInfo) {
        let decodedOwnerDetails = decryptedPayload(
          currentApplication?.financialInfo?.ownersFinancialInfo,
          applicationKey as string
        );
        PfFormAPIData = {
          ...PfFormAPIData,
          ownersFinancialInfo: decodedOwnerDetails,
        };
      }

      const financialInfo = PfFormAPIData?.ownersFinancialInfo?.find(
        (info: any) => info?.ownerId === +userId
      );
      const financialInfoIndex = PfFormAPIData?.ownersFinancialInfo?.findIndex(
        (info: any) => info?.ownerId === +userId
      );
      console.log('financialInfo', financialInfo, financialInfoIndex);

      const otherOwnersData =
        PfFormAPIData?.ownersFinancialInfo?.filter(
          (info: any) => info?.ownerId !== +userId
        ) || [];

      setOtherOwnersData(otherOwnersData);

      setOwnerData({ details: financialInfo, index: financialInfoIndex });
      refreshUrlToken();
    } else {
      navigate('/login');
    }
  };

  // const fetchOngoingApplicant = async () => {
  //   if (!!localStorage.getItem('Token')) {
  //     let actionResult = await dispatch(
  //       OngoingApplicant({ id: +applicantId }) as any
  //     );
  //     if (actionResult?.payload?.status === 401) {
  //       navigate('/login');
  //       return;
  //     }

  //     if (actionResult?.payload?.status === 200) {
  //       const { data } = actionResult?.payload;
  //       if (data) {
  //         const financialInfo = data?.financialInfo?.ownersFinancialInfo?.find(
  //           (info: any) => info?.ownerId === +userId
  //         );
  //         const financialInfoIndex =
  //           data?.financialInfo?.ownersFinancialInfo?.findIndex(
  //             (info: any) => info?.ownerId === +userId
  //           );
  //         console.log('financialInfo', financialInfo, financialInfoIndex);

  //         const otherOwnersData =
  //           data?.financialInfo?.ownersFinancialInfo?.filter(
  //             (info: any) => info?.ownerId !== +userId
  //           ) || [];

  //         setOtherOwnersData(otherOwnersData);

  //         setApplicationData(data);
  //         setOwnerData({ details: financialInfo, index: financialInfoIndex });
  //         refreshUrlToken();
  //       }
  //     }
  //   } else {
  //     navigate('/login');
  //   }
  // };

  const handleSetChildFormData = useCallback(
    ({ key, formData, applicationId, isReturnToSender, flag }: any) => {
      setPersonalFinanceInfoList((prev) => {
        return {
          ...prev,
          [`${key}`]: formData,
          applicationId: applicationId,
          returnToSender: isReturnToSender,
          flag: flag,
        };
      });
    },
    []
  );

  const submit = async () => {
    const { flag, returnToSender } = personalFinanceInfoList;
    const newDetails = Object.values(personalFinanceInfoList).filter(
      (item) => typeof item === 'object' && item !== null
    );
    if (!newDetails?.length) return;

    const data = {
      applicationId: applicationData?.applicationId,
      applicantId: +applicantId,
      brokerId: applicationData?.brokerId,
      applicationName: applicationData?.applicationName,
      returnToSender: returnToSender,
      ownersFinancialInfo: insertAtIndex(
        otherOwnersData,
        ownerData?.index,
        newDetails?.[0]
      ),
      ownerId: userId || assignToId || undefined,
      assignToOwner: false,
    };

    let res = '';
    let resStatus = 400;
    try {
      if (flag === 'OWNER_ACTION') {
        if (returnToSender) {
          const updatedData = {
            ...data,
            returnToSender: true,
          };
          const actionResult = await dispatch(
            PFinfoUpdate({
              applicationId: personalFinanceInfoList?.applicationId,
              updatedData: updatedData,
            }) as any
          );
          const response = actionResult?.payload;
          res = response?.msg;
          resStatus = response?.status;
          setIsReturnToSender(false);
        } else {
          // This condition never runs as client removed the update button from owner screen
          const updatedData = {
            ...data,
            returnToSender: false,
          };

          const actionResult = await dispatch(
            PFinfoUpdate({
              applicationId: personalFinanceInfoList?.applicationId,
              updatedData: updatedData,
            }) as any
          );

          const response = actionResult?.payload;
          res = response?.msg;
          resStatus = response?.status;

          // const actionResult = await dispatch(PFinfo(data) as any);
          // const response = actionResult.payload;
        }

        if (resStatus === 200) {
          // toast({
          //     title: res,
          //     status: "success",
          //     isClosable: true,
          //     duration: 3000,
          //     position: "top-right",
          // });
          setIsFormSubmitedSuccess(true);
        } else {
          if (resStatus === 401) {
            navigate('/login');
          } else {
            toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      }
    } catch (error: any) {
      console.log('error', error?.message);
    }

    setPersonalFinanceInfoList({});
    setSubmitForm(() => false);
    await dispatch(SubmitPersonalFinanceForms(false) as any);
  };

  const refreshUrlToken = () => {
    const newToken = localStorage.getItem('Token') || token;
    setSearchParams({
      token: newToken,
      userId: userId,
      applicantId: applicantId,
      applicationId: applicationId,
      userType: userType,
    });
  };

  const checkFormStatus = async () => {
    if (!!localStorage.getItem('Token') || token) {
      let actionResult = await dispatch(
        checkOwnerFormStatus({
          applicationId: applicationId,
          ownerId: userId,
        }) as any
      );
      if (actionResult?.payload?.status === 401) {
        navigate('/login');
        return;
      }

      if (actionResult?.payload?.status === 200) {
        console.log('actionResult?.payload', actionResult?.payload);

        const formStatus = actionResult?.payload?.data?.formStatus;
        setIsFormLinkAssigned(formStatus);
        if (formStatus) {
          // fetchOngoingApplicant();
          if (applicationId) {
            dispatch(
              getApplicationById({
                applicationId: Number(applicationId),
              }) as any
            );
          }
        }
      }
    }
  };

  // Set Token in localStorage
  useLayoutEffect(() => {
    if (token && userType === 'owner') localStorage.setItem('Token', token);
  }, []);

  // useEffect(() => {
  //   checkFormStatus();
  // }, []);

  // Submit Form
  useEffect(() => {
    if (submitForm) {
      submit();
    }
    setSubmitForm(() => false);
  }, [submitForm, personalFinanceInfoList]);

  if (isFormSubmitedSuccess) {
    return <FormSubmitedSuccess />;
  }

  if (isFormLinkAssigned === true) {
    return (
      <div>
        <PersonalFinancialInfo
          isOwnerForm={true}
          ownerDetails={
            Object.keys(ownerData)?.length > 0
              ? {
                  ...ownerData?.details?.ownerInfo,
                  ownerId: ownerData?.details?.ownerId,
                }
              : ownerData
          }
          ownersFinancialInfo={[ownerData?.details]}
          ownersCount={0}
          index={0}
          setAssignToId={setAssignToId}
          assignToId={assignToId}
          handleSetChildFormData={handleSetChildFormData}
          setIsReturnToSender={setIsReturnToSender}
          isReturnToSender={isReturnToSender}
          showBox={false}
          setSubmitForm={setSubmitForm}
          submitForm={submitForm}
          formData={applicationData?.financialInfo}
        />
      </div>
    );
  } else if (isFormLinkAssigned === false) {
    return <FormLinkExpired />;
  } else if (isLoading) {
    return <FormStatusLoading />;
  }
};

export default OwnerPersonalFinanceForm;
