export const BalanceSheetColumns = [
  {
    accessorKey: 'title',
    header: 'Name',
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
  },
];

export const AccountPayableColumns = [
  {
    accessorKey: 'Vendor',
    header: 'Vendor',
  },
  {
    accessorKey: 'Current',
    header: 'Current',
  },
  {
    accessorKey: '1 - 30',
    header: '1 - 30',
  },
  {
    accessorKey: '31 - 60',
    header: '31 - 60',
  },
  {
    accessorKey: '61 - 90',
    header: '61 - 90',
  },
  {
    accessorKey: '91 and over',
    header: '91 and over',
  },
  {
    accessorKey: 'Total',
    header: 'Total',
  },
];

export const AccountReceivableColumns = [
  {
    accessorKey: 'Customer',
    header: 'Customer',
  },
  {
    accessorKey: 'Current',
    header: 'Current',
  },
  {
    accessorKey: '1 - 30',
    header: '1 - 30',
  },
  {
    accessorKey: '31 - 60',
    header: '31 - 60',
  },
  {
    accessorKey: '61 - 90',
    header: '61 - 90',
  },
  {
    accessorKey: '91 and over',
    header: '91 and over',
  },
  {
    accessorKey: 'Total',
    header: 'Total',
  },
  {
    accessorKey: 'retentionValue',
    header: 'Holdback / Retention',
  },
];

export const ProfitLossColumns = [
  {
    accessorKey: 'title',
    header: 'Name',
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
  },
];

export const WICColumns = [
  {
    accessorKey: 'keyName',
    header: 'Allowable Assets',
  },
  {
    accessorKey: 'amount',
    header: 'Amount',
  },
  {
    accessorKey: 'adjustment',
    header: 'Adjustment (%)',
  },
  {
    accessorKey: 'adjustmentAmount',
    header: 'Revised Amount',
  },
];
