import { Box, Heading, Text } from '@chakra-ui/react';
import { Accordion, Container } from '@mantine/core';
import parse from 'html-react-parser';
import { helpPageContent } from 'src/data/helpPage/help';

const Help = () => {
  return (
    <Box>
      <Heading
        p="4"
        fontSize={'24px'}
        fontWeight={'600'}
        borderBottomWidth="1px"
      >
        Help
      </Heading>
      {helpPageContent?.map((item: any) => (
        <Container key={item?.sectionId} mt={15} maw={'80em'}>
          <Text fontSize={[18, 20, 22, 24]} fontWeight={600} px={'15px'} my={5}>
            {item?.sectionName}
          </Text>
          <Container my={10} maw={'80em'}>
            <Accordion
              variant="filled"
              // transitionDuration={500}
              className="help_accordian_section"
            >
              {item?.sectionContent?.map((faq: any) => (
                <Accordion.Item
                  key={faq?.id}
                  className={'help_item'}
                  value={faq?.question}
                >
                  <Accordion.Control>{faq?.question}</Accordion.Control>
                  <Accordion.Panel className="accordion-content">
                    {parse(faq?.answer)}
                  </Accordion.Panel>
                </Accordion.Item>
              ))}
            </Accordion>
          </Container>
        </Container>
      ))}
    </Box>
  );
};

export default Help;
