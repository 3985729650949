import { Box } from '@chakra-ui/react';
import {
  MantineReactTable,
  MRT_ColumnDef,
  useMantineReactTable,
} from 'mantine-react-table';

const AccordianTable = ({
  tableData,
  formik,
  title,
  formikState,
  columns,
}: {
  tableData: LegalFormAccordianTable[] | [];
  formik: Record<string, any>;
  title: string;
  formikState: string;
  columns: MRT_ColumnDef<LegalFormAccordianTable>[] | [];
}) => {
  // const handleSaveguaranteesForOtherPartiesCell = (
  //   cell: MRT_Cell<Record<string, any>>,
  //   value: any
  // ) => {
  //   console.log('cell', cell, value);

  //   let formikInstance = formik?.values?.[`${formikState}`];

  //   let tData =
  //     formikInstance?.find((item: any) => item.name === title)?.tableData || [];

  //   if (value == '') {
  //     value = null;
  //   }
  //   tData[cell.row.index][cell.column.id as keyof LegalFormAccordianTable] =
  //     value;

  //   const updatedTData = formikInstance?.map((item: any) => {
  //     if (item.name === title) {
  //       return {
  //         ...item,
  //         tableData: tData,
  //       };
  //     } else {
  //       return item;
  //     }
  //   });

  //   formik.setFieldValue(formikState, updatedTData);
  // };

  // const columns = useMemo<MRT_ColumnDef<LegalFormAccordianTable>[]>(
  //   () => [
  //     {
  //       accessorKey: 'name',
  //       size: 2,
  //       maxSize: 2,
  //       header: 'Name',
  //       enableColumnActions: false,
  //       enableSorting: false,
  //       enableEditing: false,
  //       mantineTableHeadCellProps: {
  //         width: '10px !important',
  //         className: 'px-10',
  //       },
  //       mantineTableBodyCellProps: {
  //         className: 'px-10',
  //       },
  //     },
  //     {
  //       accessorKey: 'value[0]',
  //       size: 40,
  //       maxSize: 40,
  //       header: 'Value',
  //       enableColumnActions: false,
  //       enableSorting: false,
  //       enableEditing: true,
  //       mantineTableBodyCellProps: {
  //         sx: {
  //           outline: 'none !important',
  //         },
  //       },
  //       mantineEditTextInputProps: {
  //         placeholder: '',
  //       },
  //     },
  //   ],
  //   []
  // );

  const table = useMantineReactTable({
    columns: columns as any,
    data: tableData ?? [],
    enableEditing: true,
    editDisplayMode: 'table',
    getRowId: (row, index) => row?.id ?? index + '',
    mantineTableContainerProps: {
      // className: 'contractor-table cms-contractor-table',
      // className: 'upload-reports-table',
      className: 'legal-form-table',
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: 'auto',
      },
    },
    enableToolbarInternalActions: false,
    enableTopToolbar: false,
    enablePagination: false,
    enableBottomToolbar: false,
    enableRowActions: false,
    enableColumnPinning: true,
    defaultColumn: { minSize: 200, maxSize: 200, size: 200 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['name'] },
    },
    // enableTableHead: false,
    // mantineEditTextInputProps: ({ cell, column, row, table }) => ({
    //   onChange: (event: any) => {
    //     handleSaveguaranteesForOtherPartiesCell(cell, event.target.value);
    //   },
    //   type: 'text',
    //   variant: 'unstyled',
    // }),
  });

  return (
    <Box
      maxW={'1550px'}
      width={'100%'}
      // minW={'500px'}
      mx={'auto'}
    >
      <MantineReactTable table={table} />
    </Box>
  );
};

export default AccordianTable;
