import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrokerAllData } from '../../../Redux/Brokerage/BrokerageProfile/BrokerageProfile';

import { RootState } from '../../../Redux/Store';
import { useNavigate } from 'react-router-dom';

function BrokererageManagment() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userData = useSelector(
    (state: RootState) => state?.brokerage?.brokerManager
  );
  let userType = userData && userData?.data;
  const [isInitializePage, setIsInitializePage] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        setIsInitializePage(true);
        const actionResult = await dispatch(BrokerAllData() as any);
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
        setIsInitializePage(false);
      } else {
        navigate('/login');
      }
    };
    fetchData();
  }, []);
  return (
    <>
      <TableContainer mt={5} border="0px solid black" p={5}>
        <Table size="md">
          <Thead border="1px solid black" bg="#114684">
            <Tr>
              <Th color="white" border="1px solid black" fontSize={'9px'}>
                SR NO
              </Th>
              <Th color="white" border="1px solid black" fontSize={'9px'}>
                Broker Name
              </Th>
              <Th color="white" border="1px solid black" fontSize={'9px'}>
                Email
              </Th>
              <Th color="white" border="1px solid black" fontSize={'9px'}>
                Contact Number
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array?.isArray(userType) && userType?.length > 0
              ? userType?.map((user: any, index: number) => (
                  <Tr key={index}>
                    <Td border="1px solid #0000002e" fontSize={'13px'}>
                      {index + 1}
                    </Td>
                    <Td border="1px solid #0000002e" fontSize={'13px'}>
                      {user?.firstName + ' ' + user?.lastName}
                    </Td>
                    <Td border="1px solid #0000002e" fontSize={'13px'}>
                      {user?.email}
                    </Td>
                    <Td border="1px solid #0000002e" fontSize={'13px'}>
                      {user?.contactNumber}
                    </Td>
                  </Tr>
                ))
              : !isInitializePage && (
                  <Tr>
                    <Td
                      colSpan={10}
                      border="1px solid #0000002e"
                      textAlign="center"
                    >
                      No data available
                    </Td>
                  </Tr>
                )}
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default BrokererageManagment;
