import { Box, Text } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CiWarning } from 'react-icons/ci';

function GrantingPermission() {
  const navigate = useNavigate();
  const OnHandelLogin = () => {
    navigate('/login');
  };
  return (
    <>
      <Box
        position="relative"
        height="100vh"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          margin={'auto'}
          borderRadius={10}
          width={'38%'}
          p={5}
          boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        >
          <Box>
            <Box display={'flex'} justifyContent={'center'}>
              <CiWarning fontSize={'95px'} color="red" />
            </Box>
            <Text textAlign="center" fontSize={30} color={'red'}>
              You cannot access for granting permission because the 7-day's
              limit has been exceeded.
            </Text>
            <Text
              fontWeight={500}
              color="#114684"
              textAlign="center"
              fontSize={16}
              onClick={OnHandelLogin}
              cursor={'pointer'}
            >
              Go back to login
            </Text>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default GrantingPermission;
