import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';

interface BrokerageState {
  user: any;
  loading: boolean;
  error: null;
}

const initialState: BrokerageState = {
  user: null,
  loading: false,
  error: null,
};

export const Brokerage = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

interface RequestData {
  brokerageId: number | null;
}
//brokerage all data

export const BrokerageAllData = createAsyncThunk(
  'brokerage/BrokerageAllData',
  async () => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/user/brokerage/getall`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const ApplicantUpdate = createAsyncThunk(
  'brokerage/ApplicantUpdate',
  async (requestData: RequestData, { rejectWithValue }) => {
    try {
      const userId = localStorage.getItem('userID');
      const Token = localStorage.getItem('Token');
      try {
        const response = await axios.put(
          `${BASEURL}/user/applicant/update/${userId}`,
          requestData,
          {
            headers: {
              Authorization: `Bearer ${Token}`,
              'Content-Type': 'application/json',
            },
          }
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const brokerageIDSlice = createSlice({
  name: 'brokerageID',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //brokerage all data
      .addCase(BrokerageAllData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        BrokerageAllData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(BrokerageAllData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      //   applicant update
      .addCase(ApplicantUpdate.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        ApplicantUpdate.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(ApplicantUpdate.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      });
  },
});

export default brokerageIDSlice.reducer;
