import React, { useState } from 'react';
import {
  Input,
  Container,
  Flex,
  Box,
  Image,
  Text,
  Checkbox,
  useBreakpointValue,
  Button,
  Link,
  IconButton,
  Stack,
  RadioGroup,
  Radio,
} from '@chakra-ui/react';
import { ForgetEmailSchema } from '../../ValidationSchema/Auth/Index';

import { MdEmail } from 'react-icons/md';
import { Navigate, useNavigate } from 'react-router-dom';
import { ForgetPassword } from '../../Redux/Auth/Auth';
import { useDispatch, useSelector } from 'react-redux';
import { useToast } from '@chakra-ui/react';
import { useFormik } from 'formik';

function ForgetEmail() {
  const navigate = useNavigate();
  const [isForgetPasswordButtonLoading, setIsForgetPasswordButtonLoading] =
    useState(false);
  const formWidth = useBreakpointValue({ base: '96%', sm: '57%' });

  const dispatch = useDispatch();
  const Toast = useToast();

  interface FormValues {
    userType: string;
    email: string;
  }

  const initialValues: FormValues = {
    userType: 'applicant',
    email: '',
  };

  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: ForgetEmailSchema,
    onSubmit: async (values: FormValues) => {
      setIsForgetPasswordButtonLoading(true);
      let res = '';
      try {
        const actionResult = await dispatch(ForgetPassword(values) as any);
        const response = actionResult?.payload;
        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 200) {
          Toast({
            title: response?.data,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
          navigate('/login');
        } else {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          Toast({
            title: '404',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      setIsForgetPasswordButtonLoading(false);
    },
  });
  const OnHandelUser = () => {
    navigate('/login');
  };
  const OnHandelLogin = () => {
    navigate('/login');
  };
  return (
    <>
      <Box h="100%">
        <Flex>
          <Container maxW="100%" p={['0px', '0px', '0px']}>
            <Flex flexWrap="wrap" height="100vh  " gap={[5, 0, 0, 0]}>
              <Box
                bg="#114684"
                borderRadius={[
                  '0px 200px 0px 0px',
                  '0px 245px 0px 0px',
                  '0px 350px 0px 0px',
                ]}
                w={['100%', '40%']}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDir={'column'}
                gap={'10px'}
              >
                <Box display="flex" justifyContent="center">
                  <Image
                    src="Image/insurance3.jpg"
                    alt="insurance"
                    width={['700px', '400px', '500px', '700px']}
                    boxSize={['250px', '230px', '260px', '300px']}
                    objectFit="cover"
                  />
                </Box>
                <Text
                  fontWeight="bold"
                  color="white"
                  textAlign="center"
                  fontSize={20}
                >
                  If you don't have an account?
                </Text>
                <Box textAlign="center">
                  <Button
                    bg="#114684"
                    color="white"
                    variant="outline"
                    _hover={{ bg: '#114684' }}
                    onClick={OnHandelUser}
                    mb={5}
                  >
                    Sign In
                  </Button>
                </Box>
              </Box>

              <Container
                textAlign="center"
                w={formWidth}
                boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
                h="auto"
                margin="auto"
                p="18px"
                borderRadius="20px"
                display={'flex'}
                flexDir={'column'}
                gap={'0.1rem'}
              >
                <Text
                  fontWeight="bold"
                  color="#114684"
                  textAlign="start"
                  fontSize={25}
                >
                  Recover your Insurance Password
                </Text>

                <Text
                  fontWeight="bold"
                  color="#114684"
                  textAlign="start"
                  fontSize={21}
                >
                  Enter your registered Email
                </Text>

                <Box>
                  <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="on"
                    style={{
                      display: 'flex',
                      gap: '10px',
                      flexDirection: 'column',
                    }}
                  >
                    <Text
                      fontWeight="bold"
                      color="#114684"
                      display="flex"
                      justifyContent="start"
                      textAlign="center"
                      fontSize={20}
                    >
                      Select Your Account
                    </Text>

                    <RadioGroup
                      onChange={(value) =>
                        formik.setFieldValue('userType', value)
                      }
                      defaultValue={formik.values.userType}
                      name="userType"
                    >
                      <Stack
                        direction="row"
                        color="#114684"
                        fontWeight="500"
                        flexWrap="wrap"
                      >
                        <Radio value="applicant">Applicant</Radio>
                        <Radio value="broker">Broker</Radio>
                        <Radio value="brokerage">Brokerage</Radio>
                      </Stack>
                    </RadioGroup>
                    {formik.touched.userType && formik.errors.userType && (
                      <div
                        style={{
                          color: 'red',
                          fontSize: '15px',
                          textAlign: 'start',
                          fontWeight: '500',
                        }}
                      >
                        {formik.errors.userType}
                      </div>
                    )}
                    <Box display="flex" flexDirection={'column'}>
                      <Text
                        fontWeight="bold"
                        color="#114684"
                        display="flex"
                        justifyContent="start"
                        textAlign="center"
                        fontSize={20}
                      >
                        Email Address
                      </Text>

                      <Box display="flex">
                        <Box
                          mr={2}
                          fontSize={25}
                          color="#114684"
                          m={0}
                          borderRadius="5px 0px 0px 5px"
                          backgroundColor="#f0f5f9"
                        >
                          <MdEmail
                            style={{
                              marginTop: '7px',
                              marginLeft: '6px',
                              marginRight: '6px',
                            }}
                          />
                        </Box>

                        <Input
                          ml="-2px"
                          fontSize="lg"
                          border="none"
                          backgroundColor="#f0f5f9"
                          focusBorderColor="transparent"
                          w="100%"
                          name="email"
                          type="email"
                          placeholder="Email Address"
                          borderRadius="0px 5px 5px 0px"
                          fontFamily='"Montserrat", sans-se"'
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          _hover={{ borderColor: '#114684' }}
                        />
                      </Box>
                      {formik.touched.email && formik.errors.email && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '15px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.email}
                        </div>
                      )}
                    </Box>
                    <Box>
                      <Button
                        isLoading={isForgetPasswordButtonLoading}
                        loadingText="Send Email"
                        type="submit"
                        bg="#114684"
                        color="white"
                        w="100%"
                        _hover={{ bg: '#114684', color: 'white' }}
                      >
                        Send Email
                      </Button>
                    </Box>
                    <Text
                      fontWeight={500}
                      color="#fbb03b"
                      textAlign="center"
                      fontSize={16}
                      onClick={OnHandelLogin}
                      cursor={'pointer'}
                    >
                      Go back to login
                    </Text>
                  </form>
                </Box>
              </Container>
            </Flex>
          </Container>
        </Flex>
      </Box>
    </>
  );
}

export default ForgetEmail;
