import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Container,
} from '@chakra-ui/react';
import React from 'react';

function AccountantDashboard() {
  return (
    <>
      <TableContainer mt={5} border="0px solid black">
        <Table size="md">
          <Thead border="1px solid black" bg="#114684">
            <Tr>
              <Th color="white" border="1px solid black">
                Awaiting Approval
              </Th>
              <Th color="white" border="1px solid black">
                Completed
              </Th>
              <Th color="white" border="1px solid black">
                Cancelled
              </Th>
              <Th color="white" border="1px solid black">
                All
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td border="1px solid black">2</Td>
              <Td border="1px solid black"> 3</Td>
              <Td border="1px solid black">4</Td>
              <Td border="1px solid black">9</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
    </>
  );
}

export default AccountantDashboard;
