import { Box, Flex, Spinner, Text } from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import { FaHistory } from 'react-icons/fa';
import { IoEyeSharp } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ApplicationTableAction from 'src/Component/Common/TableActions/Header';
import { useApplicationNavigation } from 'src/hooks/useApplicationNavigation';
import { fetchDashboardDetails } from '../../../../Redux/Notification/Notification';
import { RootState } from '../../../../Redux/Store';

function Drafts() {
  const { handelEdit, handelHistory, handelView } = useApplicationNavigation();
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.notification.user);
  const data = userData && userData?.data?.applications;
  const location = useLocation();
  const { status } = location.state || {};
  const [isLoading, setIsLoading] = useState(true);

  const dispatch = useDispatch();
  const userType = localStorage.getItem('userType');

  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        let actionResult = await dispatch(
          fetchDashboardDetails({ applicationFilter: 'Drafts' }) as any
        );
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
        setIsLoading(false);
      } else {
        navigate('/login');
      }
    };
    fetchData();
  }, []);

  return (
    <>
      {isLoading ? (
        <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <Box display={'flex'} flexDirection={'column'} gap={2} mt={2}>
            <Box pl={[2, 2, 4, 4, 4]} pr={[2, 2, 4, 4, 4]}>
              <Box
                bg="#114684"
                display={'flex'}
                justifyContent={'space-between'}
                h={'auto'}
                borderRadius={3}
                alignItems={'center'}
                p={2}
                gap={[1, 1, 2, 2, 2]}
              >
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  w={'100%'}
                  color={'white'}
                  fontWeight={500}
                  fontSize={[10, 13, 13, 13, 13]}
                  gap={1}
                  width={'100%'}
                >
                  <Box width={'40%'}>
                    <Text textAlign={'start'}>Application Code</Text>
                  </Box>
                  <Box width={'40%'}>
                    <Text textAlign={'start'}>Applicant Name</Text>
                  </Box>
                  {userType !== 'applicant' && (
                    <Box width={'100%'}>
                      <Text textAlign={'start'}>Applicant Status</Text>
                    </Box>
                  )}
                  <Box width={'40%'}>
                    <Text textAlign={'start'}>Broker Name</Text>
                  </Box>
                  {userType !== 'broker' && (
                    <Box width={'100%'}>
                      <Text textAlign={'start'}>Broker Status</Text>
                    </Box>
                  )}
                </Box>
                <ApplicationTableAction icons={{ edit: false }} />
              </Box>
            </Box>
            {Array.isArray(data) && data?.length > 0 ? (
              data?.map((user: any, index: number) => (
                <>
                  <Box pl={[2, 2, 4, 4, 4]} pr={[2, 2, 4, 4, 4]}>
                    <Box
                      bg="#ffa011"
                      display={'flex'}
                      justifyContent={'space-between'}
                      gap={2}
                      // h={10}
                      h={'auto'}
                      borderRadius={3}
                      alignItems={'center'}
                      p={2}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        w={'100%'}
                        color={'black'}
                        fontWeight={500}
                        fontSize={13}
                        width={'100%'}
                        gap={[3, 3, 0, 0, 0]}
                      >
                        <Box width={'40%'}>
                          <Text textAlign={'start'}>
                            {user.applicationName}
                          </Text>
                        </Box>
                        <Box width={'40%'}>
                          <Text textAlign={'start'}>
                            {user?.applicant?.companyName}
                          </Text>
                        </Box>
                        {userType !== 'applicant' && (
                          <Box width={'100%'}>
                            <Text textAlign={'start'}>
                              {user.applicantDashboard}
                            </Text>
                          </Box>
                        )}
                        <Box width={'40%'}>
                          <Text textAlign={'start'}>
                            {user?.broker?.firstName} {user?.broker?.lastName}
                          </Text>
                        </Box>
                        {userType !== 'broker' && (
                          <Box width={'100%'}>
                            <Text textAlign={'start'}>
                              {user.brokerDashboard}
                            </Text>
                          </Box>
                        )}
                      </Box>
                      <Box
                        display={'flex'}
                        justifyContent={'end'}
                        gap={[3, 6, 7, 7, 7]}
                        w={'100%'}
                        color={'black'}
                        fontSize={13}
                        width={'40%'}
                      >
                        <Box
                          cursor={'pointer'}
                          _hover={{ color: '#114684' }}
                          onClick={() =>
                            handelView({ applicationId: user.applicationId })
                          }
                        >
                          <IoEyeSharp size={20} />
                        </Box>
                        {/* <Box
                          cursor={"pointer"}
                          _hover={{ color: "#114684" }}
                          onClick={() => handelEdit({applicationId:user.applicationId})}
                        >
                          <FaUserEdit size={20} />
                        </Box> */}
                        <Box
                          cursor={'pointer'}
                          _hover={{ color: '#114684' }}
                          // onClick={() => handelHistory(user.applicationId, user.applicantId)}
                          onClick={() =>
                            handelHistory({
                              applicationId: user.applicationId,
                              applicantId: user.applicantId,
                            })
                          }
                        >
                          <FaHistory size={18} />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </>
              ))
            ) : (
              <>
                <Box pl={4} pr={4}>
                  <Box
                    bg="#ffa011"
                    display={'flex'}
                    justifyContent={'space-between'}
                    gap={2}
                    h={10}
                    borderRadius={3}
                    alignItems={'center'}
                    p={2}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      w={'100%'}
                      color={'black'}
                      fontWeight={500}
                      fontSize={15}
                    >
                      <Box width={'100%'}>
                        <Text textAlign={'center'}>Not found</Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
        </>
      )}
    </>
  );
}

export default Drafts;
