import { Box, Button, Container, Input, Text } from '@chakra-ui/react';
import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { RootState } from '../../../Redux/Store';
import UploadFiles from '../../Upload/index';

const Documents = forwardRef<any, any>(({ isSubmited, setIsSubmited }, ref) => {
  const data: any = useSelector(
    (state: RootState) => state.brokerageDashboard.user
  );
  const applicantHeaderName = data?.data?.applicant?.companyName;
  const location = useLocation();
  const { showBox, showHistory, applicationStatus, applicantId } =
    location.state || {};
  return (
    <Box
      display="flex"
      gap={4}
      w="min(88rem,95%)"
      margin={'-236px auto 0'}
      flexDirection={{ base: 'column', lg: 'row' }}
    >
      <Container
        maxW={'container.lg'}
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        h="auto"
        p="20px"
        borderRadius="20px"
        gap={5}
        bg={'white'}
        // overflow="auto"
        // height="calc(100vh - 150px)"
        // sx={{ "&::-webkit-scrollbar": { display: "none" } }}
      >
        <Box display={'flex'} flexDirection="column" gap={3}>
          <Box>
            <Text
              fontWeight="600"
              color="#114684"
              textAlign="center"
              fontSize={20}
            >
              Documents{' '}
              {/* {applicantHeaderName && applicantHeaderName.length > 0
                    ? `: ${applicantHeaderName} `
                    : null} */}
            </Text>
          </Box>
          <UploadFiles callesFrom="Documents" />
        </Box>
        {/* <form onSubmit={() => {}}>
            <Box
              display={'flex'}
              justifyContent={'end'}
              marginTop={'10px'}
              gap={2}
            >
              {!showBox && (
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                    onClick={OnHandelReturnToSender}
                  type="submit"
                    isDisabled={
                      userType === 'broker' &&
                      insuranceFormData?.assignToApplicant
                    }
                >
                  {isApplicantUser ? 'Return to Broker' : 'Assign to applicant'}
                  Assign to applicant
                </Button>
              )}
              {!showBox && (
                <Box>
                  <Button
                    bg={'#114684'}
                    color={'white'}
                    _hover={{ bg: '#114684', color: 'white' }}
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              )}
            </Box>
          </form> */}
      </Container>
    </Box>
  );
});

export default Documents;
