import { Box, Flex, Select, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { ApplicantBrokerage } from 'src/Redux/Broker/Dashboard/BrokerageDashboard';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import AccountPayableTable from '../components/summaryTables/AccountPayableTable';
import { TFilterAccountPayable } from 'src/Redux/Broker/Reports/APAgingSummary/state';
import {
  getAccountPayableData,
  getAccountReceivableData,
  getBalanceSheetData,
  getProfitLossData,
} from 'src/Redux/Broker/Reports/APAgingSummary/slice';
import { useAppToast } from 'src/hooks';
import { useLocation } from 'react-router-dom';
import AccountReceivableTable from '../components/summaryTables/AccountReceivableTable';
import ProfitLossTable from '../components/summaryTables/ProfitLossTable';
import BalanceSheetTable from '../components/summaryTables/BalanceSheetTable';
import { getRandomUUID } from 'src/utils/helpers';

const APAgingSummary = () => {
  const dispatch = useAppDispatch();
  const brokerageDashboard = useAppSelector(
    (state) => state.brokerageDashboard
  );
  const accountPayableReport = useAppSelector((state) => state.accountPayable);
  const applicantProfile = useAppSelector((state) => state.applicantProfile);
  const [applicantOptions, setApplicantOptions] = useState<
    Record<string, any>[]
  >([]);
  const [selectedBrokerageId, setSelectedBrokerageId] = useState<string>('');
  const [selectedOption, setSelectedOption] = useState<TFilterAccountPayable>(
    'This Fiscal Year-to-date'
  );
  const [APReportData, setAPReportData] = useState<Record<string, any>[]>();
  const [selectApplicantData, setSelectApplicantData] = useState<
    Record<string, any>
  >({});
  const [loader, setLoader] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reportType, setReportType] = useState({
    APReport: false,
    ARReport: false,
    balanceSheetReport: false,
    profitLossReport: false,
  });
  const [ADAData, setADAData] = useState<Record<string, any>>({
    Total: {
      ada: null,
      amountOrPerc: null,
    },
    '1 - 30': {
      ada: null,
      amountOrPerc: null,
    },
    '31 - 60': {
      ada: null,
      amountOrPerc: null,
    },
    '61 - 90': {
      ada: null,
      amountOrPerc: null,
    },
    Current: {
      ada: null,
      amountOrPerc: null,
    },
    adaType: null,
    valueType: null,
    '91 and over': {
      ada: null,
      amountOrPerc: null,
    },
  });
  const userType = localStorage.getItem('userType');
  const toast = useAppToast();
  const location = useLocation();

  useEffect(() => {
    const pathName = location.pathname.split('/').pop();
    switch (pathName) {
      case 'account-payable':
        setReportType({ ...reportType, APReport: true });
        break;
      case 'account-receivable':
        setReportType({ ...reportType, ARReport: true });
        break;
      case 'balance-sheet':
        setReportType({ ...reportType, balanceSheetReport: true });
        break;
      case 'profit-loss':
        setReportType({ ...reportType, profitLossReport: true });
        break;
      default:
        break;
    }
  }, [location]);

  useEffect(() => {
    if (userType === 'broker' && brokerageDashboard.applicantsByBrokrageData) {
      const selectedApplicantData =
        brokerageDashboard.applicantsByBrokrageData.find(
          (elm: any) => elm.applicantId == selectedBrokerageId
        );
      if (selectedApplicantData) {
        setSelectApplicantData(selectedApplicantData);
      }
    } else if (userType === 'applicant' && applicantProfile?.user) {
      setSelectApplicantData(applicantProfile.user.data);
    }
  }, [selectedBrokerageId]);

  useEffect(() => {
    if (selectedBrokerageId && selectedOption) {
      setIsLoading(true);
      if (reportType?.APReport) {
        dispatch(
          getAccountPayableData({
            applicantId: Number(selectedBrokerageId),
            filter: selectedOption,
          })
        );
      } else if (reportType?.ARReport) {
        dispatch(
          getAccountReceivableData({
            applicantId: Number(selectedBrokerageId),
            filter: selectedOption,
          })
        );
      } else if (reportType?.profitLossReport) {
        dispatch(
          getProfitLossData({
            applicantId: Number(selectedBrokerageId),
            filter: selectedOption,
          })
        );
      } else if (reportType?.balanceSheetReport) {
        dispatch(
          getBalanceSheetData({
            applicantId: Number(selectedBrokerageId),
            filter: selectedOption,
          })
        );
      }
    }
  }, [selectedBrokerageId, selectedOption]);

  useEffect(() => {
    if (!brokerageDashboard.applicantsByBrokrageData && userType === 'broker') {
      setLoader(true);
      dispatch(ApplicantBrokerage());
    }
  }, []);

  useEffect(() => {
    const { status, type, applicantsByBrokrageData } = brokerageDashboard;
    if (status === 'succeed') {
      if (
        type === 'GET_APPLICANT_BY_BROKERAGE' &&
        !!applicantsByBrokrageData?.length
      ) {
        setApplicantOptions(applicantsByBrokrageData);
        setLoader(false);
      }
    }
  }, [brokerageDashboard.status, selectedBrokerageId]);

  useEffect(() => {
    if (userType && userType === 'applicant') {
      const userID = localStorage.getItem('userID') || '';
      handleBrokerageSelect(userID);
    }
  }, []);

  const getAdjustTotal = (key: string) => {
    return (
      Number(
        accountPayableReport.data?.reportData?.[
          accountPayableReport.data?.reportData?.length - 1
        ]?.[key]
      ) - (accountPayableReport.data?.adaData?.[key]?.ada ?? 0)
    );
  };

  useEffect(() => {
    switch (accountPayableReport.status) {
      case 'succeed': {
        if (accountPayableReport.type === 'GET_ACCOUNT_PAYABLE_DATA') {
          setAPReportData(accountPayableReport.data?.reportData);
          setIsLoading(false);
        } else if (
          accountPayableReport.type === 'GET_ACCOUNT_RECEIVABLE_DATA'
        ) {
          // setAPReportData(accountPayableReport.data?.reportData);
          if (accountPayableReport.data?.reportData) {
            let tableAdjustedTotalRow = {
              Customer: 'Adjusted Total',
              Current:
                accountPayableReport.data?.adjustedTotal?.Current ??
                getAdjustTotal('Current'),
              '1 - 30':
                accountPayableReport.data?.adjustedTotal?.['1 - 30'] ??
                getAdjustTotal('1 - 30'),
              '31 - 60':
                accountPayableReport.data?.adjustedTotal?.['31 - 60'] ??
                getAdjustTotal('31 - 60'),
              '61 - 90':
                accountPayableReport.data?.adjustedTotal?.['61 - 90'] ??
                getAdjustTotal('61 - 90'),
              '91 and over':
                accountPayableReport.data?.adjustedTotal?.['91 and over'] ??
                getAdjustTotal('91 and over'),
              Total:
                accountPayableReport.data?.adjustedTotal?.Total ??
                getAdjustTotal('Total'),
              question: null,
              holdbackRetention: null,
              retentionValue: null,
              id: getRandomUUID(),
            };
            const adaData = {
              Customer: 'Allowance Doubtful Account (ADA)',
              Current: accountPayableReport.data?.adaData?.Current?.ada,
              '1 - 30': accountPayableReport.data?.adaData?.['1 - 30']?.ada,
              '31 - 60': accountPayableReport.data?.adaData?.['31 - 60']?.ada,
              '61 - 90': accountPayableReport.data?.adaData?.['61 - 90']?.ada,
              '91 and over':
                accountPayableReport.data?.adaData?.['91 and over']?.ada,
              Total: accountPayableReport.data?.adaData?.Total?.ada,
              question: accountPayableReport.data?.adaData?.valueType,
              applyADA: accountPayableReport.data?.adaData?.adaType,
              holdbackRetention: null,
              retentionValue: null,
              id: getRandomUUID(),
            };
            let updatedTable = [
              ...accountPayableReport.data?.reportData,
              adaData,
              tableAdjustedTotalRow,
            ];
            setAPReportData(updatedTable);
            setADAData(accountPayableReport.data?.adaData);
          }
          setIsLoading(false);
        } else if (accountPayableReport.type === 'GET_PROFIT_LOSS_DATA') {
          setAPReportData(accountPayableReport.data?.reportData);
          setIsLoading(false);
        } else if (accountPayableReport.type === 'GET_BALANCE_SHEET_DATA') {
          setAPReportData(accountPayableReport.data?.reportData);
          setIsLoading(false);
        } else if (
          accountPayableReport.type === 'UPDATE_ACCOUNT_RECEIVABLE_RECORD'
        ) {
          dispatch(
            getAccountReceivableData({
              applicantId: Number(selectedBrokerageId),
              filter: selectedOption,
            })
          );
        }
        return;
      }
      case 'loading': {
        if (
          accountPayableReport.type === 'GET_ACCOUNT_PAYABLE_DATA' ||
          accountPayableReport.type === 'GET_ACCOUNT_RECEIVABLE_DATA' ||
          accountPayableReport.type === 'GET_PROFIT_LOSS_DATA' ||
          accountPayableReport.type === 'GET_BALANCE_SHEET_DATA'
        ) {
          setIsLoading(true);
        }
        return;
      }
      case 'failed': {
        selectedBrokerageId !== '' &&
          accountPayableReport.error &&
          toast({
            title: accountPayableReport.error,
            status: 'error',
          });
        setIsLoading(false);
        setAPReportData([]);
        return;
      }
    }
  }, [accountPayableReport.status]);

  const handleBrokerageSelect = async (
    event: React.ChangeEvent<HTMLSelectElement> | string
  ) => {
    const selectedId = typeof event === 'string' ? event : event.target.value;
    setSelectedBrokerageId(selectedId);
  };

  const showTable =
    (selectedBrokerageId !== '' || userType === 'applicant') && !loader;

  return (
    <Box py={5}>
      {!loader && selectedBrokerageId === '' && userType === 'broker' && (
        <Text fontSize={'medium'}>Please Select Applicant</Text>
      )}
      <Flex alignItems={'left'} flexDirection={'column'} gap={15} mb={5}>
        <Flex alignItems={'center'} mb={5} gap={1}>
          {userType === 'broker' && (
            <Box maxW="max-content">
              <Select
                w="100%"
                onChange={handleBrokerageSelect}
                value={selectedBrokerageId}
                name="userData.brokerageId"
                borderRadius="5px"
                bg={'#114684'}
                color={'white'}
                textAlign={'center'}
              >
                <option
                  value={''}
                  style={{ color: 'black' }}
                  label="Select Applicant"
                />
                {applicantOptions?.map((user?: any, index?: number) => (
                  <option
                    style={{ color: 'black' }}
                    key={index}
                    value={user?.applicantId ?? ''}
                    label={user?.companyName ?? ''}
                  />
                ))}
              </Select>
            </Box>
          )}
          <Box maxW="max-content">
            <Select
              w="100%"
              onChange={(e) => setSelectedOption(e.target.value as any)}
              value={selectedOption ?? ''}
              name="userData.brokerageId"
              borderRadius="5px"
              bg={'#114684'}
              color={'white'}
              textAlign={'center'}
            >
              <option
                value="This Fiscal Year-to-date"
                style={{ color: 'black' }}
                label="This Fiscal Year-to-date"
              />
              <option
                value="Last Fiscal Year"
                style={{ color: 'black' }}
                label="Last Fiscal Year"
              />
            </Select>
          </Box>
        </Flex>
        <Flex direction={'column'} alignItems={'center'}>
          {showTable && reportType?.APReport && (
            <Box maxW={'1550px'} w={'100%'}>
              <AccountPayableTable
                tableData={APReportData}
                filterType={selectedOption}
                isInitialLoading={isLoading}
                selectApplicantData={selectApplicantData}
                applicantId={selectedBrokerageId}
              />
            </Box>
          )}
          {showTable && reportType?.ARReport && (
            <Box maxW={'1550px'} w={'100%'}>
              <AccountReceivableTable
                tableData={APReportData}
                filterType={selectedOption}
                isInitialLoading={isLoading}
                selectApplicantData={selectApplicantData}
                applicantId={selectedBrokerageId}
                ADAData={ADAData}
              />
            </Box>
          )}
          {showTable && reportType?.profitLossReport && (
            <Box maxW={'1550px'} w={'100%'}>
              <ProfitLossTable
                tableData={APReportData}
                filterType={selectedOption}
                isInitialLoading={isLoading}
                selectApplicantData={selectApplicantData}
                applicantId={selectedBrokerageId}
              />
            </Box>
          )}
          {showTable && reportType?.balanceSheetReport && (
            <Box maxW={'1550px'} w={'100%'}>
              <BalanceSheetTable
                tableData={APReportData}
                filterType={selectedOption}
                isInitialLoading={isLoading}
                selectApplicantData={selectApplicantData}
                applicantId={selectedBrokerageId}
              />
            </Box>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};

export default APAgingSummary;
