import { DatePickerInput } from 'rc-datepicker';
import AccordianFieldWithDatePicker from './AccordianFieldWithDatePicker';
import AccordianFieldWithInput from './AccordianFieldWithInput';
import AccordianFieldWithUpload from './AccordianFieldWithUpload';

const AccordianSectionFields = ({
  AccordianFields,
  formik,
  accordianSectionName,
  isOpen,
  accordianState,
  accordianName,
  accordianParentSectionName = '',
  fileList,
  setFileList,
  handleRemoveFile,
  isHistoryMode,
  isReadOnly,
  currentHistoryData,
}: {
  formik: Record<string, any>;
  AccordianFields: {
    label: string;
    name: string;
    type?: string;
    uploadState?: string;
    refState?: React.RefObject<DatePickerInput>;
  }[];
  accordianSectionName: string;
  isOpen: boolean;
  accordianState: any[];
  accordianName: string;
  accordianParentSectionName?: string;
  fileList?: Record<string, any>;
  setFileList?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  handleRemoveFile?: (
    arg1: {
      link: string;
      linkId: string;
      urlKey: string;
    },
    arg2: string,
    arg3: string
  ) => void;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
}) => {
  return AccordianFields?.map(
    (
      item: {
        label: string;
        name: string;
        type?: string;
        uploadState?: string;
        refState?: React.RefObject<DatePickerInput>;
      },
      index: number
    ) => {
      const keyNames = item?.name?.split('.');
      const formikvalue = keyNames.reduce((acc, key) => {
        return acc?.[key];
      }, formik?.values);

      console.log('uploadState-sectionFileds', item?.uploadState);

      if (item?.type && item?.type === 'file' && item?.uploadState) {
        return (
          <AccordianFieldWithUpload
            label={item?.label}
            name={item?.name}
            value={formikvalue}
            onChange={formik.handleChange}
            type={item?.type ?? 'text'}
            uploadState={item?.uploadState}
            formik={formik}
            accordianSectionName={accordianSectionName}
            isOpen={isOpen}
            accordianState={accordianState}
            accordianName={accordianName}
            accordianParentSectionName={accordianParentSectionName}
            fileList={fileList}
            setFileList={setFileList}
            handleRemoveFile={handleRemoveFile}
            isHistoryMode={isHistoryMode}
            isReadOnly={isReadOnly}
            currentHistoryData={currentHistoryData}
          />
        );
      } else if (
        item?.type &&
        item?.type === 'date' &&
        item?.refState &&
        !isOpen
      ) {
        return (
          <AccordianFieldWithDatePicker
            label={item?.label}
            name={item?.name}
            value={formikvalue}
            onChange={formik.handleChange}
            type={item?.type ?? 'text'}
            refState={item?.refState}
            formik={formik}
            isHistoryMode={isHistoryMode}
            isReadOnly={isReadOnly}
            currentHistoryData={currentHistoryData}
          />
        );
      } else {
        if (!isOpen) {
          return (
            <AccordianFieldWithInput
              label={item?.label}
              name={item?.name}
              value={formikvalue}
              formik={formik}
              type={'text'}
              isHistoryMode={isHistoryMode}
              isReadOnly={isReadOnly}
              currentHistoryData={currentHistoryData}
            />
          );
        }
      }

      return null;
    }
  );
};

export default AccordianSectionFields;
