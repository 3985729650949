import {
  Box,
  Button,
  Tooltip as ChakraTooltip,
  Flex,
  Select,
} from '@chakra-ui/react';
import { ActionIcon, Divider, Progress, Text, Tooltip } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { ModalsProvider, modals } from '@mantine/modals';
import { IconDownload, IconEdit, IconTrash } from '@tabler/icons-react';
import {
  MRT_ColumnDef,
  MRT_GlobalFilterTextInput,
  MRT_Row,
  MRT_TableInstance,
  MRT_TablePagination,
  MRT_ToolbarInternalButtons,
  MantineReactTable,
  useMantineReactTable,
} from 'mantine-react-table';
import { useEffect, useMemo, useState } from 'react';
import { CgFileDocument } from 'react-icons/cg';
import { HiMiniInformationCircle } from 'react-icons/hi2';

import axios from 'axios';
import JSZip from 'jszip';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { exportDocumentDownloadOptions } from 'src/Pages/Upload/data';
import { TZipName } from 'src/Pages/Upload/types';
import { getLegalFormDocuments } from 'src/Redux/Broker/Documents/slice';
import { deleteReport } from 'src/Redux/UploadReports/slice';
import { IUploadReportsData } from 'src/Redux/UploadReports/state';
import {
  useAppToast,
  useZipFromLinks,
  useZipFromLinksWithFolder,
} from 'src/hooks';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../../Redux/Store';
import { getFileNameFromURL, getRandomUUID } from '../../../../utils/helpers';
import {
  FileInfoModal,
  HandleEditRowManually,
} from './components/TableComponents';
import {
  cleanCaseNumberObjects,
  cleanObject,
  getFolderName,
  renameKeys,
  restructureObject,
} from './utils';

export type uploadTable = {
  financial: string;
  financialYearStatement: string;
  // financialYear: string | null;
  // financialStatement: string;
  id: number;
  files?: {
    comment: string | null;
    link: string | null;
    linkId?: number | null;
    urlKey: string | null;
  }[];
  updatedInfo: string;
};

export type FormObj = {
  applicationId: string | null;
  applicantId: string | null;
  financial: string | null;
  financialYear: string | null;
  financialStatement: string | null;
  financialFileWithComment: { file: File; comment?: string | null }[] | null;
};

export interface UploadFilesTableProps {
  formState: FormObj;
  isTableLoading: boolean;
  zipName: TZipName;
}

export const getDocumentTableData = ({
  applicantReportsData,
}: {
  applicantReportsData: IUploadReportsData[];
}) => {
  const data = applicantReportsData?.map((report) => ({
    financial: report.reportInfo.type,
    financialYearStatement: report.reportInfo.timeFrame
      ? `${report.reportInfo.timeFrame + '-'}${report.reportInfo.reportName}`
      : `${report.reportInfo.reportName}`,
    financialYear: report.reportInfo.timeFrame,
    id: report.id,
    files: report.reportLinks,
    updatedInfo: report.updatedInfo,
  }));
  return data;
};

const UploadFilesTable = ({
  formState,
  isTableLoading,
  zipName,
}: UploadFilesTableProps) => {
  // hookes
  const [openedFileInfo, { open: openFileInfo, close: closeFileInfo }] =
    useDisclosure(false);
  const dispatch = useAppDispatch();
  const { createZipFromLinks, loading, error } = useZipFromLinks();
  const {
    createZipFromFolderAndLinks,
    error: folderZipError,
    loading: folderZipLoading,
  } = useZipFromLinksWithFolder();
  const location = useLocation();
  const { showBox } = location.state || {};
  const toast = useAppToast();
  // states
  const [selectedEditRow, setSelectedEditRow] = useState<{
    row: MRT_Row<uploadTable> | null;
    table: MRT_TableInstance<uploadTable> | null;
  }>({
    row: null,
    table: null,
  });
  const [opened, { open, close }] = useDisclosure(false);
  const [tableData, setTableData] = useState<uploadTable[]>([]);
  const [isEditSaving, setIsEditSaving] = useState<boolean>(false);
  const [fileInfoIndex, setFileInfoIndex] = useState<number>(0);
  const [currentUpdateRowId, setCurrentUpdateRowId] = useState<number | null>(
    null
  );
  const [downloadDocOption, setDownloadDocOption] =
    useState<string>('All Documents');
  const [loader, setLoader] = useState({
    btnLoader: false,
    isZipDownloading: false,
  });
  const [legalFormDocuments, setLegalFormDocuments] = useState<
    Record<string, any>
  >({});
  const userData = useSelector(
    (state: RootState) => state.brokerageDashboard.user
  );
  const uploadReports = useSelector(
    (state: RootState) => state.uploadReportsSlice
  );
  const currentApplicationId = userData?.data?.applicationId;
  // let Applicantbrokerage = userData && userData.data;

  const uploadData = useSelector(
    (state: RootState) => state.documentSlice?.data
  );

  const documents = useAppSelector((state: RootState) => state.documentSlice);

  console.log('formState', formState, documents);

  // useEffect(() => {
  //   if (currentApplicationId) {
  //     const reportData = {
  //       applicationId: 30,
  //       type: 'Financial Statement',
  //       timeFrame: null,
  //       reportName: 'Cashflow',
  //     };
  //     dispatch(getReportList(reportData));
  //   }
  // }, [currentApplicationId, formState]);

  useEffect(() => {
    const { status, type, applicantReportsData } = uploadReports;
    switch (status) {
      case 'loading': {
        break;
      }
      case 'succeed': {
        if (type === 'GET_APPLICANT_REPORTS' && applicantReportsData) {
          const data = getDocumentTableData({
            applicantReportsData,
          });
          data && setTableData(data);
          setLegalFormDocuments({});
        }
        if (type === 'UPDATE_REPORT') {
          close();
        }
        break;
      }
      case 'failed': {
        break;
      }

      default:
        break;
    }
    return () => {};
  }, [uploadReports.status]);

  useEffect(() => {
    const { status, type, legalFormDocuments, error } = documents;
    switch (status) {
      case 'loading': {
        if (type === 'GET_LEGAL_FORM_DOCUMENTS') {
          setLoader({ ...loader, btnLoader: true });
        }
        break;
      }
      case 'succeed': {
        if (type === 'GET_LEGAL_FORM_DOCUMENTS') {
          const renamedLegalData = renameKeys(legalFormDocuments);

          // remove null key-value and blank object/array value contain keys
          const removeNullKeyValue = cleanObject(renamedLegalData);
          console.log('removeNullKeyValue', removeNullKeyValue);

          //arrange zip folderwise data
          //if object key has multiple object then add folder,if single object then don't add folder,and add nested object key is array then add that upload field folder.
          const folderStructureFormat = restructureObject(removeNullKeyValue);
          console.log('folderStructureFormat', folderStructureFormat);

          //clean caseNumberKey and then cleanObject to remove blank array
          const cleanCaseNumber = cleanObject(
            cleanCaseNumberObjects(folderStructureFormat)
          );
          console.log('cleanCaseNumber', cleanCaseNumber);

          setLegalFormDocuments(cleanCaseNumber);

          setLoader({ ...loader, btnLoader: false });
        }
        break;
      }
      case 'failed': {
        if (type === 'GET_LEGAL_FORM_DOCUMENTS') {
          setLoader({ ...loader, btnLoader: false });
          toast({
            title: error,
            status: 'error',
          });
        }
        break;
      }

      default:
        break;
    }
    return () => {};
  }, [documents.status]);

  useEffect(() => {
    if (
      downloadDocOption === 'Legal Form Documents' &&
      Object.keys(legalFormDocuments)?.length === 0
    ) {
      dispatch(
        getLegalFormDocuments({
          applicationId: Number(formState?.applicationId),
        })
      );
    }
  }, [downloadDocOption]);

  const columns = useMemo<MRT_ColumnDef<uploadTable>[]>(
    () => [
      {
        accessorKey: 'financial',
        header: 'Type',
        size: 20,
      },
      {
        accessorKey: 'financialYearStatement',
        header: 'Description',
        size: 20,
        maxSize: 20,
      },
      {
        accessorKey: 'updatedInfo',
        header: 'Time',
        size: 20,
        maxSize: 20,
      },
      {
        accessorKey: 'files',
        header: 'Files',
        Cell: ({ cell, row }) => {
          return (
            <Flex gap={5} alignItems={'center'}>
              <ChakraTooltip
                fontSize="md"
                label="File Download"
                placement="auto"
              >
                <Box>
                  <CgFileDocument
                    size={20}
                    onClick={() => {
                      createZipFromLinks({
                        links: (cell?.getValue() as any[])?.map(
                          (item: any) => item?.link
                        ),
                        zipName: `${zipName.applicantName} - ${zipName.applicationName || ''}`,
                      });
                    }}
                  />
                </Box>
              </ChakraTooltip>
              <ChakraTooltip fontSize="md" label="Files Info" placement="auto">
                <div>
                  <HiMiniInformationCircle
                    size={22}
                    cursor={'pointer'}
                    onClick={() => {
                      openFileInfo();
                      setFileInfoIndex(row.index);
                    }}
                  />
                </div>
              </ChakraTooltip>
            </Flex>
          );
        },
      },
    ],
    []
  );

  // DELETE REPORT HANDLER
  const handleDeleteReport = (row: MRT_Row<uploadTable>) =>
    modals.openConfirmModal({
      title: `DELETE`,
      children: (
        <Text>
          Are you sure you want to delete {row.original.id}? This action cannot
          be undone.
        </Text>
      ),
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red' },
      onConfirm: () => {
        if (!isNaN(row?.original?.id)) {
          dispatch(
            deleteReport({
              reportID: row?.original?.id,
            })
          );
        }
      },
    });

  const isInitialTableLoading =
    uploadReports.status === 'loading' &&
    uploadReports.type === 'GET_APPLICANT_REPORTS';

  const handleExportData = async () => {
    setLoader({ ...loader, isZipDownloading: true });
    const zipFolderName = `${zipName.applicantName} - ${zipName.applicationName}`;
    console.log('tableData:::', tableData, zipFolderName);

    const links = tableData.map((data) => ({ links: data.files }));

    await createZipFromFolderAndLinks({
      folderWisedata: tableData.map((data) => {
        // let subFolder = data.;
        // if (data.financial === 'Financial Statement') {
        //   folderName = `${data.financial} ${data.financialYearStatement || ''}`;
        // }
        return {
          mainFolder: data.financial!,
          subFolder: data.financialYearStatement!,
          links: data.files?.map((file) => file.link!)!,
        };
      }),
      zipName: zipFolderName,
    });
    setLoader({ ...loader, isZipDownloading: false });
  };

  // function used to create folder/subfolder same as res object structure(to ctreate nested subfolder for nested object)
  const processNestedData = async (
    obj: any,
    folder: any,
    mainKey: string
  ): Promise<void> => {
    // console.log('Object.keys(obj)', Object.keys(obj));
    const promises = Object.keys(obj).map(async (key, index) => {
      // console.log('key333', key, mainKey);

      if (Array.isArray(obj[key])) {
        // Only create the folder if the array has items
        if (obj[key].length > 0) {
          // console.log('obj[key]222', obj[key], obj, key);

          // const subFolderName = getFolderName(key); // Rename numeric keys
          // console.log('subFolderName', subFolderName, key);

          const subFolder = folder.folder(key); // Create folder for the array
          const links = obj[key]?.map((y: Record<string, any>) => y.link);

          await Promise.all(
            links.map(async (link: string, i: number) => {
              try {
                const response = await axios.get(link, {
                  responseType: 'arraybuffer',
                });
                axios.defaults.headers.post['access-control-allow-origin'] =
                  '*';
                const name = getFileNameFromURL(link);
                // console.log('file-response', response.data, name);

                subFolder &&
                  subFolder.file(`${name || `file` + i}`, response.data); // Adjust file name and extension
              } catch (err) {
                // console.log('api-err', err);
              }
            })
          );
        }
      } else if (typeof obj[key] === 'object') {
        // Recursively process nested objects, skipping empty objects or arrays
        // console.log('obj[key]111', obj[key], obj, key, mainKey);

        const subFolderName = getFolderName(key, mainKey, obj[key]); // Rename numeric keys
        const subFolder = folder.folder(subFolderName); // Create a folder for the object key
        await processNestedData(obj[key], subFolder, mainKey); // Recursively process nested objects
      }
    });
    await Promise.all(promises);
  };

  const createZipStructure = async (data: Record<string, any>) => {
    const zipFolderName = `${zipName.applicantName} - ${zipName.applicationName}_Legal`;
    const zip = new JSZip();

    // Loop over top-level keys in the data object
    const promises = Object.keys(data).map(async (mainKey) => {
      const mainValue = data[mainKey];

      // Create a main folder for each top-level key
      const mainFolder = zip.folder(mainKey);

      // Process the nested structure inside each main folder
      await processNestedData(mainValue, mainFolder, mainKey);
    });

    await Promise.all(promises);

    // Generate the zip
    const zipBlob = await zip.generateAsync({ type: 'blob' });

    console.log('zipBlob', zipBlob);
    // Create a download link for the zip file
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipBlob);
    downloadLink.download = `${zipFolderName}.zip`;
    downloadLink.click();
  };

  const handleExportLegalFormData = async () => {
    setLoader({ ...loader, isZipDownloading: true });

    // await createZipStructure(folderStructureFormat);
    await createZipStructure(legalFormDocuments);
    setLoader({ ...loader, isZipDownloading: false });
  };

  const handleChangeDownloadOption = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    // console.log('value', e.target.value);
    setDownloadDocOption(e.target.value);
  };

  const table = useMantineReactTable({
    columns: columns,
    data: tableData as any,
    mantineCreateRowModalProps: {
      className: '',
    },
    // layoutMode: 'grid',
    createDisplayMode: 'modal', //default ('row', and 'custom' are also available)
    editDisplayMode: 'custom', //default ('row', 'cell', 'table', and 'custom' are also available)
    enableEditing: true,
    getRowId: (row, index) => getRandomUUID(),
    defaultColumn: { minSize: 10, maxSize: 10, size: 10 },
    initialState: {
      density: 'xs',
      columnPinning: { left: ['mrt-row-actions'] },
    },
    // mantineTableProps: {
    //   sx: {
    //     tableLayout: 'fixed',
    //   },
    // },
    mantineTableContainerProps: {
      sx: {
        tableLayout: 'fixed',
        width: '100%',
        overflow: isInitialTableLoading ? 'hidden' : 'auto',
      },
      className: 'upload-reports-table',
    },
    mantineTableHeadCellProps: {
      sx: {
        '& .mantine-TableHeadCell-Content': {
          justifyContent: 'space-between',
        },
      },
    },
    displayColumnDefOptions: {
      'mrt-row-actions': {
        header: 'Actions', //change header text
        size: 1, //make actions column wider
        maxSize: 1,
        minSize: 1,
        mantineTableHeadCellProps: {
          sx: {
            // maxWidth: '100px',
            zIndex: 999,
          },
        },
      },
      'mrt-row-expand': {
        size: 2, //make actions column wider
        maxSize: 2,
        minSize: 2,
        mantineTableHeadCellProps: {
          sx: {
            zIndex: 999,
          },
        },
      },
    },
    enableTopToolbar: true,
    enablePagination: true,
    positionPagination: 'none',
    enableBottomToolbar: false,
    // enableExpanding: false,
    renderRowActions: ({ row, table }) => (
      <Flex gap="md">
        <Tooltip label="Edit" position="right">
          <ActionIcon
            disabled={showBox}
            onClick={() => {
              setSelectedEditRow({
                row,
                table,
              });
              open();
            }}
            // onClick={() => table.setEditingRow(row)}
            size={'sm'}
          >
            <IconEdit />
          </ActionIcon>
        </Tooltip>
        <Tooltip label="Delete" position="right">
          <ActionIcon
            disabled={showBox}
            color="red"
            onClick={() => handleDeleteReport(row)}
            size={'sm'}
          >
            <IconTrash />
          </ActionIcon>
        </Tooltip>
      </Flex>
    ),
    // renderTopToolbar: ({ table }) =>
    //   renderTopToolbar({
    //     table,
    //     isTableLoading,
    //     handleExportData,
    //     isExportDisabled: !!tableData.length,
    //   }),
    renderTopToolbar: ({ table }) => {
      console.log('tableData', tableData, legalFormDocuments);

      return (
        <Flex direction={'column'}>
          <Flex
            justifyContent={'space-between'}
            alignItems={'center'}
            flexWrap={'wrap'}
          >
            <Box
              sx={{
                display: 'flex',
                gap: '5px',
                padding: '12px',
                flexWrap: 'wrap',
              }}
            >
              <Select
                // placeholder="Select option"
                maxW={'max-content'}
                onChange={handleChangeDownloadOption}
              >
                {exportDocumentDownloadOptions?.map((item: any) => (
                  <option value={item?.value}>{item?.label}</option>
                ))}
              </Select>
              <Button
                color="lightblue"
                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                onClick={() =>
                  downloadDocOption === 'All Documents'
                    ? handleExportData()
                    : handleExportLegalFormData()
                }
                leftIcon={<IconDownload />}
                variant="filled"
                className="primary-btn"
                isDisabled={
                  downloadDocOption === 'All Documents'
                    ? tableData.length === 0
                    : downloadDocOption === 'Legal Form Documents'
                      ? Object.keys(legalFormDocuments)?.length === 0
                      : false
                }
                isLoading={loader?.btnLoader || loader?.isZipDownloading}
                loadingText="Download"
              >
                Download
              </Button>
            </Box>
            <Flex py={1} alignItems={'center'} flexWrap={'wrap'}>
              <Flex alignItems={'center'} gap={4} p={3}>
                <MRT_GlobalFilterTextInput table={table} />
                <MRT_ToolbarInternalButtons table={table} />
              </Flex>
              <Divider
                size="md"
                orientation="vertical"
                h={40}
                style={{ alignSelf: 'center' }}
              />
              <Box className="pagination-wrapper">
                <MRT_TablePagination position="top" table={table} />
              </Box>
            </Flex>
          </Flex>
          {isTableLoading && <Progress value={100} animate={true} />}
        </Flex>
      );
    },
    state: {
      showProgressBars: isTableLoading,
      isLoading: isInitialTableLoading,
    },
  });

  return (
    <Box py={1}>
      <Flex direction={'column'} gap={12} alignItems={'center'}>
        <Box maxW={'1550px'} w={'100%'} py={5}>
          <ModalsProvider>
            <MantineReactTable table={table} key={'upload'} />
          </ModalsProvider>
          {selectedEditRow.table && selectedEditRow.row && (
            <HandleEditRowManually
              table={selectedEditRow.table}
              row={selectedEditRow.row}
              applicantReportsData={uploadReports?.applicantReportsData}
              isEditSaving={isEditSaving}
              modalOpen={opened}
              onClose={close}
              status={uploadReports.status}
            />
          )}
          {openedFileInfo && (
            <FileInfoModal
              isOpen={openedFileInfo}
              onClose={closeFileInfo}
              filesInfo={
                uploadReports?.applicantReportsData?.[fileInfoIndex]
                  ?.reportLinks!
              }
            />
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default UploadFilesTable;
