import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes,
} from 'react-router-dom';
import ApplicantDashboardTable from 'src/Pages/ApplicantPages/ApplicantDashboard/ApplicantDashboardTable';
import BiilingPage from 'src/Pages/BrokeragePages/Billing';
import ThreeTierPricing from 'src/Pages/BrokeragePages/Plans';
import Checkout from 'src/Pages/BrokeragePages/Plans/components/Checkout';
import BrokerApplicantProfile from 'src/Pages/BrokerPages/BrokerApplicantProfile';
import Help from 'src/Pages/BrokerPages/Help';
import APAgingSummary from 'src/Pages/Reports/APAgingSummary';
import NotFoundPage from '../Component/Common/404';
import Layout from '../Component/Common/Sidebar/Layout';
import SocketEvents from '../Component/Common/SocketEvents';
import ForgetEmail from '../Component/ForgotPassword/ForgotEmail';
import ForgetPassword from '../Component/ForgotPassword/ForgotPassword';
import Login from '../Component/Login/Login';
import ApplicantAccount from '../Component/SignUp/ApplicantAccount';
import BrokerAccount from '../Component/SignUp/BrokerAccount';
import BrokererageAccount from '../Component/SignUp/BrokerageAccount';
import FullScreenLayout from '../Layout/FullScreenLayout';
import AccountantDashboard from '../Pages/Account/AccountantDashboard';
import AccountantSignupLink from '../Pages/Account/AccountantSignupLink';
import AccountTabel from '../Pages/ApplicantPages/Accountant/AccountantTabel';
import ApplicantAccountant from '../Pages/ApplicantPages/AppliantAccountant/ApplicantAccountant';
import ApplicantBroker from '../Pages/ApplicantPages/ApplicantBrokerage/ApplicantBrokerage';
import ApplicantAwaitingUnderwriting from '../Pages/ApplicantPages/ApplicantDashboard/ApplicantDashboardList/ApplicantAwaitingUnderwriting';
import ApplicantUnderwriting from '../Pages/ApplicantPages/ApplicantDashboard/ApplicantDashboardList/ApplicantUnderwriting';
import Drafts from '../Pages/ApplicantPages/ApplicantDashboard/ApplicantDashboardList/Drafts';
import ApplicantProfile from '../Pages/ApplicantPages/ApplicantProfile/ApplicantProfile';
import ApplicantQBO from '../Pages/ApplicantPages/ApplicantQBO/ApplicantQBO';
import QboConnect from '../Pages/ApplicantPages/ApplicantQBO/QboConnect';
import QboSuccess from '../Pages/ApplicantPages/ApplicantQBO/QboSuccess';
import QuickbookAlldata from '../Pages/ApplicantPages/ApplicantQBO/QuickbookAlldata';
import OwnerTabel from '../Pages/ApplicantPages/Owner/OwnerTabel';
import BrokerageCLform from '../Pages/BrokeragePages/BrokerageEnterCl/BrokerageCLform';
import BrokererageEnterCl from '../Pages/BrokeragePages/BrokerageEnterCl/BrokerageEnterCl';
import BrokererageEnterEAndO from '../Pages/BrokeragePages/BrokerageEnterEAndO/BrokerageEnterEAndO';
import BrokererageManagment from '../Pages/BrokeragePages/BrokerageManagment/BrokerageManagment';
import BrokerageProfile from '../Pages/BrokeragePages/Brokerageprofile/BrokerageProfile';
import BrokerAccessRequest from '../Pages/BrokerPages/BrokerAccessRequest';
import BrokerDashboard from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboard';
import ApplicantAwaitingUnderwritingBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/ApplicantAwaitingUnderwriting';
import ApplicantUnderwritingBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/ApplicantUnderwriting';
import ApprovedBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/Approved';
import DeclinedBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/Declined';
import DraftsBroker from '../Pages/BrokerPages/BrokerDashboard/BrokerDashboardList/Drafts';
import GrantingPermission from '../Pages/BrokerPages/BrokerDashboard/Permission/GrantingPermission';
import Permission from '../Pages/BrokerPages/BrokerDashboard/Permission/Permission';
import PermissionVerify from '../Pages/BrokerPages/BrokerDashboard/Permission/PermissionVerify';
import BrokerProfile from '../Pages/BrokerPages/BrokerProfile/BrokerProfile';
import Stepper from '../Pages/BrokerPages/CreateApplicationForm/Stepper';
import OwnerPersonalFinanceForm from '../Pages/OwnerPages/OwnerPersonalFinanceForm';
import ReportWorkingCapital from '../Pages/Reports/WorkingCapital';
import ReportWorkingProgress from '../Pages/Reports/WorkingProgress';
import Upload from '../Pages/Upload';

interface RoutingProps {
  token: string | null;
  userType: string | null;
}

function Routing({ token, userType }: RoutingProps) {
  const userTypeLatest = localStorage.getItem('userType');
  const tokenLatest = localStorage.getItem('Token');

  let userResponse = userType || userTypeLatest;
  let tokenResponse = token || tokenLatest;

  const PrivateRoute = ({ children }: any) => {
    return tokenResponse ? (
      <SocketEvents>{children}</SocketEvents>
    ) : (
      <Navigate to="/login" />
    );
  };

  return (
    <>
      <Router>
        <Routes>
          <>
            <Route path="/login" element={<Login />} />
            {/* <Route path="/pdf" element={<PDFView />} /> */}
            {/* <Route
                path="/temp"
                element={
                  <PDFViewer width="100%" height="700" className="app">
                    <ApplicationPDF
                      title={'title'}
                      heading={'Heading'}
                      summary={
                        'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.'
                      }
                      logo={
                        ''
                        // 'https://source.unsplash.com/random/100x100/?company'
                      }
                      companyName="Company Name"
                      qboCompanyName="test123"
                      contactInfo={true}
                      brokerInfo={{
                        firstName: 'fname',
                        lastName: 'lname',
                        contactNumber: 1234567890,
                        brokerageName: 'brokerage name',
                        email: 'test@yopmail.com',
                      }}
                      allFormData={{
                        accountingSystem: {
                          applicationId: 27,
                          brokerId: 22,
                          applicantId: 73,
                          applicationName: 'a3195',
                          legalName: '',
                          businessEstablishedDate: '2024-05-21T11:12:44.000Z',
                          email: 'test@yopmail.com',
                          website: 'https://www.google.com/',
                          street: 'surat',
                          city: 'surat',
                          country: 'United States',
                          province: 'aa1',
                          postalCode: '454343',
                          mailingAddressSameAsOffice: false,
                          mailingStreet: 'testt',
                          mailingCity: 'surat',
                          mailingCountry: 'United States',
                          mailingProvince: 'gujarat',
                          mailingPostalCode: '12345',
                          telephone: '+1-212-456-7897',
                          accountingFirmName: 'test firm',
                          contactName: 'Macon Hoffman',
                          phoneNumber: null,
                          interimStatementInterval: 'annually',
                          grossProfitAtRecentFiscalYearEnd: null,
                          dateCompleted: '2024-05-06T11:13:47.000Z',
                          bankInfo: [
                            {
                              address:
                                'Suite 1500, 4710 Kingsway, Burnaby, BC, V5H4M2',
                              bankName: 'MERIDIAN ONECAP CREDIT CORP.',
                              securedVal: null,
                              creditSecured: null,
                              bankPhoneNumber: null,
                              otherSecuredVal: null,
                              usedLineOfCredit: null,
                              withBankSinceYear: null,
                              accountManagerName: null,
                              authorizedLineOfCredit: 0,
                            },
                            {
                              address:
                                '20 KING STREET W-4TH FLOOR, TORONTO, ON, M5H1C4',
                              bankName: 'ROYAL BANK OF CANADA',
                              securedVal: 'other',
                              creditSecured: true,
                              bankPhoneNumber: null,
                              otherSecuredVal: 'test other',
                              usedLineOfCredit: null,
                              withBankSinceYear: null,
                              accountManagerName: null,
                              authorizedLineOfCredit: 0,
                            },
                            {
                              address:
                                '20 KING STREET WEST - 4TH FLOOR, TORONTO, ON, M5H1C4',
                              bankName: 'ROYAL BANK OF CANADA',
                              securedVal: 'inventory',
                              creditSecured: true,
                              bankPhoneNumber: null,
                              otherSecuredVal: null,
                              usedLineOfCredit: null,
                              withBankSinceYear: null,
                              accountManagerName: null,
                              authorizedLineOfCredit: 0,
                            },
                            {
                              address: '',
                              bankName: '',
                              securedVal: '',
                              creditSecured: null,
                              bankPhoneNumber: null,
                              otherSecuredVal: null,
                              usedLineOfCredit: null,
                              withBankSinceYear: null,
                              accountManagerName: null,
                              authorizedLineOfCredit: 0,
                            },
                          ],
                          bankName: null,
                          address: null,
                          authorizedLineOfCredit: null,
                          accountManagerName: null,
                          bankPhoneNumber: null,
                          withBankSinceYear: null,
                          usedLineOfCredit: null,
                          assignToApplicant: true,
                          createdAt: '2024-05-20T11:12:31.000Z',
                          updatedAt: '2024-05-20T11:14:37.000Z',
                        },
                        creditBureau: {
                          bureauId: 27,
                          brokerId: 22,
                          applicationId: 27,
                          applicationName: 'a3195',
                          receivershipOrBankruptcyHistory: {
                            hasHistory: true,
                            details: 'test est set estete',
                          },
                          guaranteesForOtherParties: null,
                          relatedCompanies: {
                            hasRelatedCompanies: true,
                            companies: [
                              {
                                name: 'test',
                                natureOfOperations: 'sdsd',
                                ownershipStructure: 'qqqqqqqqqqqqq',
                              },
                              {
                                name: 'test1',
                                natureOfOperations: 'sdsd1',
                                ownershipStructure:
                                  'qqqqqqqqqqqqq1 qqqqqqqqqqqqq1',
                              },
                            ],
                          },
                          changesInManagement: {
                            hasChanges: true,
                            explanation:
                              'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industrys standard dummy text ever since the 1500s, when an unknown.',
                          },
                          workOutsideCanada: null,
                          liensFiledAgainstCompany: {
                            hasLiens: true,
                            details: 'test etest  ett t',
                          },
                          stateFederalTax: true,
                          paymentPlan: null,
                          outstandingJudgments: {
                            hasOutstandingJudgments: true,
                            details: 'hfdjf uereur erueriuei ereru',
                          },
                          bondClaims: true,
                          claimsPaid: true,
                          suretyMade: false,
                          whyNotMade: 'fdf etyeyt eyt',
                          disputesWithOthers: {
                            hasDisputes: true,
                            details: 'test',
                          },
                          liensFiledByCompany: {
                            hasLiens: true,
                            details: 'jdkdfdjfdf',
                          },
                          disputesWithSuppliers: {
                            hasDisputes: true,
                            details: 'teteeedsdhgsd',
                            fundingMeans: 'hdjhgsgus',
                          },
                          buySellAgreement: null,
                          assignToApplicant: false,
                          createdAt: '2024-05-20T11:12:31.000Z',
                          updatedAt: '2024-05-20T11:12:31.000Z',
                        },
                        financialInfo: {
                          financialInfoId: 3,
                          brokerId: 2,
                          applicationId: 3,
                          applicationName: 'A5E908',
                          ownersFinancialInfo: [
                            {
                              ownerId: 2,
                              ownerInfo: {
                                position: 'sdsds',
                                nameHoldco: 'Mohini Lakade',
                                dateOfBirth: '1999-12-31T18:30:00.000Z',
                                inPositionSince: 32323,
                                ownershipPercentage: 50,
                              },
                              assetsPrice: [
                                {
                                  id: '64633b45-b5b3-485a-9fd9-140aca623cdc',
                                  type: 'Cash/GIC',
                                  value: 10,
                                },
                                {
                                  id: '9fac79ce-3f94-4239-b995-bf517ea534fc',
                                  type: 'RRSP',
                                  value: 0,
                                },
                                {
                                  id: '3d969042-4d34-4796-abad-1ecf5155458a',
                                  type: 'Others',
                                  value: 0,
                                  otherAssetType: 'test other',
                                },
                                {
                                  id: '3d969042-4d34-4796-abad-1ecf5155458a',
                                  type: 'Others',
                                  value: 0,
                                  otherAssetType: 'test othersss',
                                },
                                {
                                  id: '9fac79ce-3f94-4239-b995-bf517ea534fc',
                                  type: 'RRSP',
                                  value: 0,
                                },
                              ],
                              totalAssets: 12,
                              assetsRealEstate: [
                                {
                                  id: 'e0c3ce81-c135-4913-9132-bbe0863dd744',
                                  value: 0,
                                  address: 'rwewew',
                                },
                                {
                                  id: 'e0c3ce81-c135-4913-9132-bbe0863dd744',
                                  value: 3433,
                                  address: 'qwewrerewewewe',
                                },
                                {
                                  id: 'e0c3ce81-c135-4913-9132-bbe0863dd744',
                                  value: 0,
                                  address: '',
                                },
                              ],
                              liabilitiesPrice: [
                                {
                                  id: 'e73d58f7-1a9f-4fe8-9d0b-ade18856af9b',
                                  type: 'Personal loan/Lines of Credit Outstanding',
                                  value: null,
                                },
                              ],
                              personalNetWorth: 14,
                              totalLiabilities: 13,
                              assetsResidential: {
                                value: null,
                                address: null,
                              },
                              businessInterests: [
                                {
                                  id: '1ee07849-20c2-4819-a9bc-6e33c1b36264',
                                  value: 0,
                                  legalName: 'test',
                                  ownershipPercentage: 0,
                                },
                                {
                                  id: '1ee07849-20c2-4819-a9bc-6e33c1b36264',
                                  value: 0,
                                  legalName: '',
                                  ownershipPercentage: 0,
                                },
                              ],
                              liabilitiesResidence: {
                                value: null,
                                mortgage: null,
                              },
                              liabilitiesRealEstate: [
                                {
                                  id: '65ec679c-882d-4219-bea1-1b3778ce140c',
                                  value: null,
                                  mortgage: null,
                                },
                              ],
                            },
                            {
                              ownerId: 6,
                              ownerInfo: {
                                position: null,
                                nameHoldco: '',
                                dateOfBirth: '',
                                inPositionSince: null,
                                ownershipPercentage: null,
                              },
                              assetsPrice: [
                                {
                                  id: '6822ee74-3c98-4d81-8102-cd21824f53fb',
                                  type: 'Cash/GIC',
                                  value: null,
                                },
                              ],
                              totalAssets: null,
                              assetsRealEstate: [
                                {
                                  id: '8f0e2576-a5d4-478f-8d11-f598e647165d',
                                  value: null,
                                  address: null,
                                },
                              ],
                              liabilitiesPrice: [
                                {
                                  id: 'e7c9e1ce-a58b-4d63-b22b-0bd535b0113c',
                                  type: 'Personal loan/Lines of Credit Outstanding',
                                  value: null,
                                },
                              ],
                              personalNetWorth: null,
                              totalLiabilities: null,
                              assetsResidential: {
                                value: null,
                                address: null,
                              },
                              businessInterests: [
                                {
                                  id: '4c5e84f5-9840-4af2-92dd-f38a6cbe498f',
                                  value: null,
                                  legalName: null,
                                  ownershipPercentage: null,
                                },
                              ],
                              liabilitiesResidence: {
                                value: null,
                                mortgage: null,
                              },
                              liabilitiesRealEstate: [
                                {
                                  id: '34413aea-c2fd-43d8-b074-0fde86e5d338',
                                  value: null,
                                  mortgage: null,
                                },
                              ],
                            },
                          ],
                          assignToApplicant: false,
                          createdAt: '2024-05-24T12:33:45.000Z',
                          updatedAt: '2024-05-24T12:35:26.000Z',
                        },
                        cms: {
                          cmsId: 3,
                          brokerId: 2,
                          applicationId: 3,
                          applicationName: 'A5E908',
                          name: 'Mohini',
                          position: 'CEO',
                          hiringDate: '2011-05-31T18:30:00.000Z',
                          geoArea: 'USA',
                          workOutsideDetails: {
                            where: null,
                            howOften: null,
                            workOutside: false,
                          },
                          managementControl: [
                            {
                              jobType: 'Personally',
                            },
                            {
                              jobType: 'Weekly',
                            },
                          ],
                          contractors: [
                            {
                              type: 'Excavation',
                              annualSalesPercentage: 30,
                            },
                            {
                              type: 'Concrete',
                              annualSalesPercentage: 70,
                            },
                          ],
                          fixedContract: [
                            {
                              id: 'b96a8956-a2da-4fdb-b8d1-2d338e1af0d3',
                              location: 'USA',
                              annualSales: 30,
                              contractPrice: 10000,
                              contractorType: 'Excavation',
                            },
                            {
                              id: 'd5c0b93c-46c3-4ec1-b21f-cbfc22962aab',
                              location: null,
                              annualSales: 70,
                              contractPrice: null,
                              contractorType: 'Concrete',
                            },
                          ],
                          largestWorkAmount: '50',
                          numberOfContracts: 70,
                          workSubletPercentage: 50,
                          workUndertakenOwnForces: 'Building',
                          workSublet: [
                            {
                              type: 'Excavation',
                            },
                            {
                              type: 'Electrical',
                            },
                            {
                              type: 'Concrete',
                            },
                          ],
                          totalWorkProgramOneTime: null,
                          totalWorkProgram12Months: null,
                          obtainBondsFromSubcontractors: {
                            hasBonds: false,
                            minimumContract: null,
                          },
                          salesPercentageByOwnerType: [
                            {
                              ownerType: 'Government',
                              salesPercentage: 50,
                            },
                            {
                              ownerType: 'Institutions',
                              salesPercentage: 30,
                            },
                            {
                              ownerType: 'Others',
                              otherOwnerType: 'assddd',
                              salesPercentage: 20,
                            },
                          ],
                          workOutsideCanada: {
                            performWorkOutside: true,
                            annualSalesPercentage: [
                              {
                                country: 'India',
                                percentage: 50,
                              },
                              {
                                country: 'Canada',
                                percentage: '25',
                              },
                            ],
                          },
                          employeesUnionized: false,
                          payUnionScaleWages: false,
                          ownerName: 'Mohini',
                          contractPrice: '500000',
                          typeOfProjectOwner: {
                            type: 'Utilities',
                            otherType: null,
                          },
                          typeOfWork: [
                            {
                              type: 'Excavation',
                            },
                          ],
                          assignToApplicant: false,
                          estimatedSubletBudget: '4654',
                          keyPersonnel: [
                            {
                              name: null,
                              position: 'Backend Developer',
                              hiringDate: '2024-06-11T18:30:00.000Z',
                            },
                            {
                              name: 'Femil',
                              position: 'Developer',
                              hiringDate: '2024-06-11T18:30:00.000Z',
                            },
                          ],
                          createdAt: '2024-05-24T12:33:45.000Z',
                          updatedAt: '2024-06-13T12:27:18.000Z',
                        },
                        surety: {
                          suretyId: 18,
                          brokerId: 26,
                          applicationId: 18,
                          applicationName: 'appli233',
                          suretyName: 'sds',
                          totalWorkOnHand: 12,
                          singleJob: 12,
                          reasonChangingSurety: '112',
                          refusedBond: true,
                          refusedBondExplanation: 'sffdff',
                          whoSignSeal: [
                            {
                              lastName: '21',
                              position: '21',
                              firstName: '21',
                            },
                          ],
                          assignToApplicant: false,
                          createdAt: '2024-05-31T08:11:49.000Z',
                          updatedAt: '2024-05-31T12:55:46.000Z',
                        },
                        insurance: {
                          insuranceId: 18,
                          brokerId: 26,
                          applicationId: 18,
                          applicationName: 'appli233',
                          keyManLifeInsurance: {
                            details: [
                              {
                                amount: 546,
                                keyPerson: '12121',
                                beneficiary: '5656',
                              },
                            ],
                            hasKeyManLifeInsurance: true,
                          },
                          commercialGeneralLiabilityInsurance: {
                            limit: 12,
                            provider: '1',
                            hasCommercialGeneralLiabilityInsurance: true,
                          },
                          environmentalInsurance: {
                            limit: 121,
                            provider: '21',
                            hasEnvironmentalInsurance: true,
                          },
                          errorsAndOmissionsInsurance: {
                            limit: 21,
                            provider: '21',
                            hasErrorsAndOmissionsInsurance: true,
                          },
                          fidelityInsurance: {
                            limit: 21,
                            provider: '21',
                            hasFidelityInsurance: true,
                          },
                          equipmentInsurance: {
                            limit: 21,
                            provider: '21',
                            hasEquipmentInsurance: true,
                          },
                          otherInsurance: {
                            details: [
                              {
                                type: '1',
                                limit: 21,
                                provider: '21',
                              },
                            ],
                            hasOtherInsurance: true,
                          },
                          assignToApplicant: false,
                          createdAt: '2024-05-31T08:11:49.000Z',
                          updatedAt: '2024-05-31T12:44:11.000Z',
                        },
                      }}
                    />
                  </PDFViewer>
                }
              /> */}
            <Route path="/applicant-account" element={<ApplicantAccount />} />
            <Route path="/broker-account" element={<BrokerAccount />} />
            <Route path="/auth/resetPassword" element={<ForgetPassword />} />
            <Route path="/request-email" element={<ForgetEmail />} />
            <Route
              path="/brokererage-account"
              element={<BrokererageAccount />}
            />
            <Route path="/permission/verification" element={<Permission />} />
            <Route
              path="/permission/verification/permission-verify"
              element={
                <PermissionVerify
                  permissionInfo={`Data auto populate consent link expired , Your first attempt reached !!`}
                />
              }
            />
            <Route
              path="/permission/verification/permission-granting"
              element={<GrantingPermission />}
            />
            <Route
              path="/permission/verification/resetPassword"
              element={
                <PermissionVerify
                  permissionInfo={`Reset Password link has been expired!!`}
                />
              }
            />
            <Route path="/qbo/connect" element={<QboConnect />} />
            <Route path="/qbo/success" element={<QboSuccess />} />

            {/* Owner Public Route */}
            <Route path="/owner/pf" element={<OwnerPersonalFinanceForm />} />
          </>

          {userResponse === 'applicant' ? (
            <>
              <Route
                path="/applicant/profile"
                element={
                  <PrivateRoute>
                    <Layout>
                      <ApplicantProfile />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/add-your-brokerage"
                element={
                  <PrivateRoute>
                    <Layout>
                      <ApplicantBroker />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/applicant-accountant"
                element={
                  <PrivateRoute>
                    <Layout>
                      <ApplicantAccountant />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/applicantqbo"
                element={
                  <PrivateRoute>
                    <Layout>
                      <ApplicantQBO />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/company-info"
                element={
                  <PrivateRoute>
                    <Layout>
                      <QuickbookAlldata />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Layout>
                      <ApplicantDashboardTable />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/owner-table"
                element={
                  <PrivateRoute>
                    <Layout>
                      <OwnerTabel />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/accountant-table"
                element={
                  <PrivateRoute>
                    <Layout>
                      <AccountTabel />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/application/:id"
                element={
                  <PrivateRoute>
                    <Stepper />
                  </PrivateRoute>
                }
              />
              <Route
                path="/application/awaiting-underwriting"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Application Submitted. Awaiting Underwriting.">
                      <ApplicantAwaitingUnderwriting />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/application/awaiting-input"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Awaiting Input">
                      <ApplicantUnderwriting />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              {/* <Route
                path="/application/awaiting-consent"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Awaiting consent to the accounting system">
                      <ApplicantApproval />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              /> */}
              <Route
                path="/applications/declined-by-underwriter"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Declined by Underwriter">
                      <DeclinedBroker />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applications/approved-by-underwriter"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Approved by Underwriter">
                      <ApprovedBroker />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applications/drafts"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Drafts">
                      <Drafts />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/report/working_progress"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Work in Progress">
                      <ReportWorkingProgress />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/report/working_capital"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Working Capital">
                      <ReportWorkingCapital />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/upload"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Upload callesFrom="Upload" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/report/account-payable"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Account Payable Aging Report">
                      <APAgingSummary />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/report/account-receivable"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Account Receivable Aging Report">
                      <APAgingSummary />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/report/profit-loss"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Profit and Loss Report">
                      <APAgingSummary />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/report/balance-sheet"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Balance Sheet Report">
                      <APAgingSummary />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/access-request"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokerAccessRequest />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applicant/help"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Help />
                    </Layout>
                  </PrivateRoute>
                }
              />
            </>
          ) : (
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <Layout>
                    <NotFoundPage
                      token={tokenResponse}
                      userType={userResponse}
                    />
                  </Layout>
                </PrivateRoute>
              }
            />
          )}

          {userResponse === 'broker' ? (
            <>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokerDashboard />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/create-application"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Stepper />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/application/:id"
                element={
                  <PrivateRoute>
                    <Stepper />
                  </PrivateRoute>
                }
              />
              <Route
                path="/applications/awaiting-underwriting"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Application Submitted. Awaiting Underwriting.">
                      <ApplicantAwaitingUnderwritingBroker />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applications/awaiting-input"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Awaiting Input">
                      <ApplicantUnderwritingBroker />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              {/* <Route
                path="/applications/awaiting-consent"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Awaiting consent to the accounting system">
                      <ApplicantApprovalBroker />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              /> */}
              <Route
                path="/applications/declined-by-underwriter"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Declined by Underwriter">
                      <DeclinedBroker />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applications/approved-by-underwriter"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Approved by Underwriter">
                      <ApprovedBroker />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/applications/drafts"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Drafts">
                      <DraftsBroker />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/create-application/:id"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Stepper />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/profile"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokerProfile />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/report/working_progress"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Work in Progress">
                      <ReportWorkingProgress />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/report/working_capital"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Working Capital">
                      <ReportWorkingCapital />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/upload"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Upload callesFrom="Upload" />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/applicant-profile"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokerApplicantProfile />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/report/account-payable"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Account Payable Aging Report">
                      <APAgingSummary />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/report/account-receivable"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Account Receivable Aging Report">
                      <APAgingSummary />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/report/profit-loss"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Profit and Loss Report">
                      <APAgingSummary />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/report/balance-sheet"
                element={
                  <PrivateRoute>
                    <FullScreenLayout title="Balance Sheet Report">
                      <APAgingSummary />
                    </FullScreenLayout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/access-request"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokerAccessRequest />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/broker/help"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Help />
                    </Layout>
                  </PrivateRoute>
                }
              />
            </>
          ) : (
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <Layout>
                    <NotFoundPage
                      token={tokenResponse}
                      userType={userResponse}
                    />
                  </Layout>
                </PrivateRoute>
              }
            />
          )}

          {userResponse === 'brokerage' ? (
            <>
              <Route
                path="/"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokerageProfile />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/brokerage/brokerage-managment"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokererageManagment />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/brokerage/brokerage-enterCl"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokererageEnterCl />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/brokerage/plans"
                element={
                  <PrivateRoute>
                    <Layout>
                      <ThreeTierPricing />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/brokerage/billing"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BiilingPage />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/brokerage/checkout"
                element={
                  <PrivateRoute>
                    <Layout>
                      <Checkout />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/brokerage/brokerage-enterEAndO"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokererageEnterEAndO />
                    </Layout>
                  </PrivateRoute>
                }
              />
              <Route
                path="/brokerage/brokerageCLfrom"
                element={
                  <PrivateRoute>
                    <Layout>
                      <BrokerageCLform />
                    </Layout>
                  </PrivateRoute>
                }
              />
            </>
          ) : (
            <Route
              path="*"
              element={
                <PrivateRoute>
                  <Layout>
                    <NotFoundPage
                      token={tokenResponse}
                      userType={userResponse}
                    />
                  </Layout>
                </PrivateRoute>
              }
            />
          )}

          <Route
            path="/accountant/accountantSignupLink"
            element={
              <PrivateRoute>
                <Layout>
                  <AccountantSignupLink />
                </Layout>
              </PrivateRoute>
            }
          />
          <Route
            path="/accountant/accountantDashboard"
            element={
              <PrivateRoute>
                <Layout>
                  <AccountantDashboard />
                </Layout>
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default Routing;
