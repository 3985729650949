import { useEffect, useRef, useState } from 'react';

type ObjectComparatorHook = {
  areDifferent: boolean;
  setInitialValues: (obj: Record<string, any>) => void;
  setNewValues: (obj: Record<string, any>) => void;
};

const useObjectComparator = (): ObjectComparatorHook => {
  const [areDifferent, setAreDifferent] = useState(false);
  const [initialValues, setInitialValues] = useState<Record<string, any>>({});
  const [newValues, setNewValues] = useState<Record<string, any>>({});
  const objRef = useRef(initialValues);

  useEffect(() => {
    const keysInitial = Object.keys(initialValues);
    const keysNew = Object.keys(newValues);

    if (keysInitial.length !== keysNew.length) {
      setAreDifferent(true);
      return;
    }

    // for (let key of keysInitial) {
    //     if (initialValues[key] !== newValues[key]) {
    //         setAreDifferent(true);
    //         return;
    //     }
    // }

    for (let key of keysInitial) {
      if (
        initialValues[key] !== newValues[key] &&
        typeof initialValues[key] !== 'object'
      ) {
        setAreDifferent(true);
        return;
      } else if (
        JSON.stringify(initialValues[key]) !== JSON.stringify(newValues[key])
      ) {
        setAreDifferent(true);
        return;
      }
    }

    setAreDifferent(false);
  }, [initialValues, newValues]);

  const setInitial = (obj: Record<string, any>) => {
    setInitialValues(obj);
  };

  const setNew = (obj: Record<string, any>) => {
    setNewValues(obj);
  };

  // Update the ref to keep track of the previous object
  useEffect(() => {
    objRef.current = initialValues;
  }, [initialValues]);

  return { areDifferent, setInitialValues: setInitial, setNewValues: setNew };
};

export default useObjectComparator;
