import {
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Box,
  Text,
  Flex,
  Textarea,
  Button,
  Modal,
  ModalOverlay,
  ModalCloseButton,
  ModalFooter,
  ModalContent,
  ModalHeader,
  ModalBody,
  Tooltip,
  Container,
  Input,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { OwnerAllData } from '../../../Redux/Applicant/Owner/Owner';
import { OwnerDelete } from '../../../Redux/Applicant/Owner/Owner';

import { RootState } from '../../../Redux/Store';
import { FaUserEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { useToast } from '@chakra-ui/react';
import Swal from 'sweetalert2';
import { useFormik } from 'formik';

import { AddOwner } from '../../../Redux/Applicant/Owner/Owner';
import { UpdateOwner } from '../../../Redux/Applicant/Owner/Owner';
import { DatePickerInput } from 'rc-datepicker';
import 'rc-datepicker/lib/style.css';
import 'react-datepicker/dist/react-datepicker.css';
import {
  addFocusEventListenerOnInput,
  removeFocusEventListenerOnInput,
} from '../../../utils/helpers';
import { OwnerSchema } from '../../../ValidationSchema/Pages/Index';
import AutoComplete from 'react-google-autocomplete';
import { FaTimes } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useObjectComparator from '../../../hooks/useObjectComparator';
import { format, parse } from 'date-fns';

const GOOGLE_MAPS_API_KEY = 'AIzaSyDvWG26al2Ons0JrDhxHWuaDPha0TA7kzs';

function OwnerTabel() {
  const dispatch = useDispatch();
  const Toast = useToast();
  const [isOwnerUpdate, setIsOwnerUpdate] = useState(false);
  const [isInitializePage, setIsInitializePage] = useState(false);
  const { areDifferent, setInitialValues, setNewValues } =
    useObjectComparator();
  const [autoCompleteKey, setAutoCompleteKey] = useState(0);
  const bithDateRef = useRef<DatePickerInput>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(null);
  const [showClear, setShowClear] = useState(false);
  const autoCompleteRef: any = useRef(null);
  const navigate = useNavigate();
  const userResponse = useSelector((state: RootState) => state.owner.user);
  let userData = userResponse && userResponse.data;
  const [selectedUser, setSelectedUser] = useState<FormValues | null>(null);

  const handleEdit = (userId: any) => {
    const user = userData?.find((user: any) => user.ownerId === userId);
    setSelectedUser(user);
    setId(userId);
    setIsOpen(true);
  };

  useEffect(() => {
    fetchOwnerData();
  }, []);

  const fetchOwnerData = async () => {
    if (!!localStorage.getItem('Token')) {
      setIsInitializePage(true);
      const actionResult = await dispatch(OwnerAllData() as any);
      const response = actionResult?.payload;
      if (response?.payload?.status === 401) {
        navigate('/login');
      }
      setIsInitializePage(false);
    } else {
      navigate('/login');
    }
  };

  useEffect(() => {
    const today = new Date();

    if (selectedUser) {
      formik.setValues({
        firstName: selectedUser.firstName || '',
        middleName: selectedUser.middleName || '',
        lastName: selectedUser.lastName || '',
        email: selectedUser.email || '',
        contactNumber: selectedUser.contactNumber || '',
        SIN_SSN: selectedUser.SIN_SSN ? selectedUser.SIN_SSN : null,

        dateOfBirth: selectedUser.dateOfBirth
          ? new Date(selectedUser.dateOfBirth)
          : today,
        homeAddress: selectedUser.homeAddress || '',
      });
      setInitialValues({
        firstName: selectedUser.firstName || '',
        middleName: selectedUser.middleName || '',
        lastName: selectedUser.lastName || '',
        email: selectedUser.email || '',
        contactNumber: selectedUser.contactNumber || '',
        SIN_SSN: selectedUser.SIN_SSN ? selectedUser.SIN_SSN : null,

        dateOfBirth: selectedUser.dateOfBirth
          ? new Date(selectedUser.dateOfBirth)
          : today,
        homeAddress: selectedUser.homeAddress || '',
      });
      setShowClear(!!selectedUser.homeAddress);
    } else {
      formik.setValues({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        contactNumber: '',
        SIN_SSN: null,
        dateOfBirth: new Date(),
        homeAddress: '',
      });

      setShowClear(false);
    }
  }, [selectedUser]);

  interface FormValues {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    contactNumber: string;
    SIN_SSN: number | null;
    dateOfBirth: Date | null;
    homeAddress: string;
  }

  const initialValues: FormValues = {
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    SIN_SSN: null,
    dateOfBirth: new Date(),
    homeAddress: '',
  };

  const formik = useFormik({
    initialValues,

    validationSchema: OwnerSchema,

    onSubmit: async (values: FormValues, { resetForm }) => {
      setIsOwnerUpdate(true);
      let res = '';
      try {
        if (id) {
          if (!areDifferent) {
            setIsOwnerUpdate(false);
            return;
          }
          const actionResult = await dispatch(
            UpdateOwner({ ownerId: id, ...values }) as any
          );
          const response = actionResult?.payload;
          res = response?.msg;
          const resStatus = response?.status;

          if (resStatus === 200) {
            Toast({
              title: response?.data,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            setIsOpen(false);
            fetchOwnerData();
            resetForm();
            handleClosePopup();
          } else {
            if (resStatus === 401) {
              navigate('/login');
            } else {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            }
          }
        } else {
          const actionResult = await dispatch(AddOwner(values) as any);
          const response = actionResult?.payload;
          res = response?.msg;
          const resStatus = response?.status;

          if (resStatus === 200) {
            Toast({
              title: response?.data,
              status: 'success',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
            setIsOpen(false);
            fetchOwnerData();
            resetForm();
          } else {
            if (resStatus === 401) {
              navigate('/login');
            } else {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            }
          }
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      setIsOwnerUpdate(false);
    },
  });

  useEffect(() => {
    setNewValues(formik.values);
  }, [formik.values]);

  const handleDelete = (userId: any) => {
    Swal.fire({
      title: 'Do you want to Delete this Record?',
      icon: 'error',
      showCancelButton: true,
      confirmButtonColor: '#DC3741',
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed === true) {
        dispatch(OwnerDelete(userId) as any)
          .then((res: any) => {
            if (res.meta.requestStatus === 'fulfilled') {
              fetchOwnerData();
              Swal.fire({
                title: 'Deleted Successfully.',
                icon: 'success',
                showConfirmButton: false,
                timer: 2000,
              });
            }
          })
          .catch((err: any) => {});
      }
    });
  };

  const formatDate = (dateString: string) => {
    const date = format(new Date(dateString), 'MM-dd-yyyy');
    return date;
  };

  const handleOpenPopup = () => {
    setIsOpen(true);
    if (formik.values.homeAddress) {
      setShowClear(true);
    }
  };

  const handleClosePopup = () => {
    setIsOpen(false);
    setSelectedUser(null);
    setId(null);
    setShowClear(false);
    formik.resetForm();
  };

  const handleClear = () => {
    formik.setFieldValue('homeAddress', '');
    setShowClear(false);
    setAutoCompleteKey((prevKey) => prevKey + 1);
  };
  return (
    <>
      <Box display={'flex'} justifyContent={'end'} p={5}>
        <Button
          bg="#114684"
          color={'white'}
          _hover={{ bg: '#114684' }}
          onClick={handleOpenPopup}
        >
          Add Owner
        </Button>
      </Box>

      <TableContainer
        border="0px solid black"
        p={5}
        whiteSpace={'break-spaces'}
      >
        <Table size="sm">
          <Thead border="1px solid black" bg="#114684">
            <Tr>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                First Name
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Middle Name
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Last Name
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Email
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Contact Number
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                SIN_SSN
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Date Of Birth
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Home Address
              </Th>
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Edit
              </Th>{' '}
              <Th color="white" border="1px solid #0000002e" fontSize={'9px'}>
                Delete
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            {Array?.isArray(userData) && userData?.length > 0
              ? userData?.map((user: any, index: number) => (
                  <Tr key={index}>
                    <Td border="1px solid #0000002e">{user?.firstName}</Td>
                    <Td border="1px solid #0000002e">{user?.middleName}</Td>
                    <Td border="1px solid #0000002e">{user?.lastName}</Td>
                    <Td border="1px solid #0000002e">{user?.email}</Td>
                    <Td border="1px solid #0000002e">{user?.contactNumber}</Td>
                    <Td border="1px solid #0000002e">{user?.SIN_SSN}</Td>
                    <Td border="1px solid #0000002e">
                      {formatDate(user?.dateOfBirth)}
                    </Td>
                    <Td border="1px solid #0000002e">{user?.homeAddress}</Td>
                    <Tooltip label="Edit" placement="right" hasArrow>
                      <Td
                        border="1px solid #0000002e"
                        cursor={'pointer'}
                        fontSize={'larger'}
                        onClick={() => handleEdit(user?.ownerId)}
                      >
                        <FaUserEdit />
                      </Td>
                    </Tooltip>
                    <Tooltip label="Delete" placement="right" hasArrow>
                      <Td
                        cursor={'pointer'}
                        border="1px solid #0000002e"
                        fontSize={'larger'}
                        onClick={() => handleDelete(user?.ownerId)}
                      >
                        <MdDelete />
                      </Td>
                    </Tooltip>
                  </Tr>
                ))
              : !isInitializePage && (
                  <Tr>
                    <Td colSpan={10} textAlign="center">
                      No data available
                    </Td>
                  </Tr>
                )}
          </Tbody>
        </Table>
        <Modal
          isOpen={isOpen}
          onClose={handleClosePopup}
          closeOnOverlayClick={false}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader color={'#114684'}>
              {id ? 'Edit Owner ' : 'Add Owner'}
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Container textAlign="center" h="auto" borderRadius="20px" p={0}>
                <form onSubmit={formik.handleSubmit}>
                  <Box display={'flex'} flexDirection={'column'} gap={2}>
                    <Box display="flex" flexDirection={'column'}>
                      <Tooltip label="First Name" placement="right" hasArrow>
                        <Input
                          bg={'#f0f5f9'}
                          border="none"
                          focusBorderColor="transparent"
                          size="md"
                          w="100%"
                          type="text"
                          placeholder="First Name"
                          name="firstName"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          value={formik.values.firstName}
                          onBlur={formik.handleBlur}
                        />
                      </Tooltip>

                      {formik.touched.firstName && formik.errors.firstName && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.firstName}
                        </div>
                      )}
                    </Box>

                    <Box display="flex" flexDirection={'column'}>
                      <Tooltip label="Middle Name" placement="right" hasArrow>
                        <Input
                          focusBorderColor="transparent"
                          bg={'#f0f5f9'}
                          border="none"
                          size="md"
                          w="100%"
                          type="text"
                          placeholder="Middle Name"
                          name="middleName"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          value={formik.values.middleName}
                        />
                      </Tooltip>
                    </Box>

                    <Box display="flex" flexDirection={'column'}>
                      <Tooltip label="Last Name" placement="right" hasArrow>
                        <Input
                          focusBorderColor="transparent"
                          bg={'#f0f5f9'}
                          border="none"
                          size="md"
                          w="100%"
                          type="text"
                          placeholder="Last Name"
                          name="lastName"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          value={formik.values.lastName}
                          onBlur={formik.handleBlur}
                        />
                      </Tooltip>
                      {formik.touched.lastName && formik.errors.lastName && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.lastName}
                        </div>
                      )}
                    </Box>

                    <Box display="flex" flexDirection={'column'}>
                      <Tooltip label="Email Address" placement="right" hasArrow>
                        <Input
                          focusBorderColor="transparent"
                          bg={'#f0f5f9'}
                          border="none"
                          size="md"
                          w="100%"
                          type="Email"
                          placeholder="Email Address"
                          name="email"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          value={formik.values.email}
                          onBlur={formik.handleBlur}
                        />
                      </Tooltip>
                      {formik.touched.email && formik.errors.email && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.email}
                        </div>
                      )}
                    </Box>

                    <Box display="flex" flexDirection={'column'}>
                      <Tooltip
                        label="Contact Number"
                        placement="right"
                        hasArrow
                      >
                        <Input
                          focusBorderColor="transparent"
                          bg={'#f0f5f9'}
                          border="none"
                          size="md"
                          w="100%"
                          type="tel"
                          placeholder="Contact Number"
                          name="contactNumber"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          value={formik.values.contactNumber}
                          onBlur={formik.handleBlur}
                        />
                      </Tooltip>
                      {formik.touched.contactNumber &&
                        formik.errors.contactNumber && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '13px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors.contactNumber}
                          </div>
                        )}
                    </Box>

                    <Box display="flex" flexDirection={'column'}>
                      <Tooltip
                        label="This information is strictly used for identity validation only"
                        placement="right"
                        hasArrow
                      >
                        <Input
                          focusBorderColor="transparent"
                          bg={'#f0f5f9'}
                          border="none"
                          size="md"
                          w="100%"
                          type="number"
                          onWheel={(e) => (e.target as HTMLInputElement).blur()}
                          placeholder="SIN_SSN"
                          name="SIN_SSN"
                          borderRadius="5px"
                          onChange={formik.handleChange}
                          value={formik.values.SIN_SSN ?? ''}
                          onBlur={formik.handleBlur}
                        />
                      </Tooltip>
                      {formik.touched.SIN_SSN && formik.errors.SIN_SSN && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.SIN_SSN}
                        </div>
                      )}
                    </Box>
                    <Tooltip label="Date Of Birth" placement="right" hasArrow>
                      <Box display="flex" flexDirection={'column'}>
                        <DatePickerInput
                          ref={bithDateRef}
                          value={
                            formik.values.dateOfBirth !== null
                              ? formik.values.dateOfBirth
                              : undefined
                          }
                          onChange={(date: Date | null) => {
                            formik.setFieldValue('dateOfBirth', date);
                          }}
                          locale="en"
                          placeholder="Date of Birth"
                          className="react-datepicker-component react-datepicker-input input null-border"
                          maxDate={new Date()}
                          showOnInputClick={true}
                          onShow={() =>
                            addFocusEventListenerOnInput(bithDateRef)
                          }
                          onHide={() =>
                            removeFocusEventListenerOnInput(bithDateRef)
                          }
                        />
                        {formik.touched.dateOfBirth &&
                          formik.errors.dateOfBirth && (
                            <div
                              style={{
                                color: 'red',
                                fontSize: '13px',
                                textAlign: 'start',
                                fontWeight: '500',
                              }}
                            >
                              {formik.errors.dateOfBirth as any}
                            </div>
                          )}
                      </Box>
                    </Tooltip>

                    <Tooltip label="Home Address" placement="right" hasArrow>
                      <Box display="flex" position="relative" mt={1}>
                        <AutoComplete
                          key={autoCompleteKey} // Key for dynamic remounting
                          apiKey={GOOGLE_MAPS_API_KEY}
                          defaultValue={formik.values.homeAddress ?? ''}
                          onPlaceSelected={(place) => {
                            if (place && place.formatted_address) {
                              formik.setFieldValue(
                                'homeAddress',
                                place.formatted_address
                              );
                              setShowClear(true);
                            } else {
                              formik.setFieldValue('homeAddress', '');
                              setShowClear(false);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          className="googlelocation"
                          ref={autoCompleteRef}
                          options={{
                            types: [],
                          }}
                        />
                        <Box
                          display="flex"
                          alignItems="center"
                          bg="#f0f5f9"
                          mr={2}
                          cursor="pointer"
                          _hover={{ color: '#114684' }}
                          position="absolute"
                          top="50%"
                          transform="translateY(-50%)"
                          right={0}
                        >
                          {showClear && <FaTimes onClick={handleClear} />}
                        </Box>
                      </Box>
                    </Tooltip>
                    {formik.touched.homeAddress &&
                      formik.errors.homeAddress && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.homeAddress}
                        </div>
                      )}

                    <Box mt={7} display={'flex'} gap={2}>
                      <Button
                        bg="#114684"
                        color="white"
                        w="100%"
                        _hover={{ bg: '#114684' }}
                        onClick={handleClosePopup}
                      >
                        Close
                      </Button>
                      <Button
                        isLoading={isOwnerUpdate}
                        loadingText={id ? 'Update' : 'Submit'}
                        type="submit"
                        bg="#114684"
                        color="white"
                        w="100%"
                        _hover={{
                          bg: '#114684',
                          color: 'white',
                          cursor: areDifferent ? 'pointer' : 'not-allowed',
                        }}
                        isActive={!areDifferent}
                      >
                        {id ? 'Update' : 'Submit'}
                      </Button>
                    </Box>
                  </Box>
                </form>
              </Container>
            </ModalBody>
          </ModalContent>
        </Modal>
      </TableContainer>
    </>
  );
}

export default OwnerTabel;
