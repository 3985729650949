import { APIStatus } from '../../types/unions';

export interface IReportLinks {
  link: string;
  urlKey: string;
  comment: string | null;
  linkId?: number | null;
}

export interface IUploadReportsData {
  id: number;
  applicationId: number;
  reportInfo: {
    type: string;
    timeFrame: string | null;
    reportName: string;
  };
  reportLinks: IReportLinks[];
  reportUploadedBy: number;
  updatedInfo: string;
}

interface IInitialState {
  error: any;
  uploadReportsData?: IUploadReportsData | null;
  applicantReportsData?: IUploadReportsData[] | null;
  status: APIStatus | null;
  type?:
    | 'POST_UPLOAD_REPORTS'
    | 'GET_APPLICANT_REPORTS'
    | 'DELETE_REPORT'
    | 'UPDATE_REPORT';
}

const initialState: IInitialState = {
  error: null,
  status: null,
  uploadReportsData: null,
  applicantReportsData: null,
};

export default initialState;
