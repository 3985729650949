import {
  Box,
  Button,
  Checkbox,
  Container,
  Input,
  Stack,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { isNil, omitBy } from 'lodash';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { CiCircleMinus, CiCirclePlus } from 'react-icons/ci';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAppToast } from 'src/hooks';
import { getApplicationById } from 'src/Redux/Applications/slice';
import {
  insurance,
  InsuranceUpdate,
  RescindFromApplicant,
} from '../../../Redux/Broker/CreateApplicationForm/CreateApplicationForm';
import { getApplicantHistory } from '../../../Redux/Broker/Dashboard/History';
import {
  RootState,
  useAppDispatch,
  useAppSelector,
} from '../../../Redux/Store';
import {
  deepObjectComparison,
  formatDate,
  preventAlphabetsTyping,
  trimStringValues,
} from '../../../utils/helpers';
import { IsSubmited } from './Stepper';

const Insurance = forwardRef<any, any>(
  (
    {
      isSubmited,
      setIsSubmited,
      formData,
    }: {
      formData: TInsurenceAPIData;
      isSubmited: IsSubmited;
      setIsSubmited: (pros: IsSubmited) => void;
    },
    ref
  ) => {
    console.log('Insurance-formData', formData);

    const Toast = useToast();
    const dispatch = useAppDispatch();
    const [isReturnToSender, setIsReturnToSender] = useState(false);
    const [loader, setLoader] = useState<{ btnLoader: boolean }>({
      btnLoader: false,
    });
    const [rescindFromApplicant, setRescindFromApplicant] =
      useState<boolean>(false);
    const navigate = useNavigate();
    const [historyIndex, setHistoryIndex] = useState(0);
    const [historyData, setHistoryData] = useState<Partial<TInsurence> | null>(
      null
    );

    const location = useLocation();
    const { showBox, showHistory, applicantId } = location.state || {};
    const [showTextarea, setShowTextarea] = useState({
      hasKeyManLifeInsurance: false,
      hasCommercialGeneralLiabilityInsurance: false,
      hasEnvironmentalInsurance: false,
      hasErrorsAndOmissionsInsurance: false,
      hasFidelityInsurance: false,
      hasEquipmentInsurance: false,
      hasOtherInsurance: false,
    });
    // const userData = useSelector(
    //   (state: RootState) => state.createApplicationFormSlice.user
    // );
    // let userGetidData = userData && userData.data;
    // let brokerid = userGetidData && userGetidData.brokerId;
    // let applicationId = userGetidData && userGetidData.applicationId;
    // const data: any = useSelector(
    //   (state: RootState) => state.brokerageDashboard.user
    // );

    const HistoryData: any = useSelector(
      (state: RootState) => state.history.user
    );
    const createApplicationForm = useAppSelector(
      (state: RootState) => state.createApplicationFormSlice
    );
    const toast = useAppToast();
    const currentHistoryData = HistoryData?.data[historyIndex]?.details;

    // let insuranceFormData = data && data?.data?.insurance;
    // let ongoingapplicationId =
    //   data && data?.data?.accountingSystem?.applicationId;

    // let ongoingbrokerId = data && data?.data?.accountingSystem?.brokerId;
    const paramsData = useParams<{ id: string }>();
    const ongoingID = paramsData.id ? parseInt(paramsData.id, 10) : null;

    // const applicantFetch = useSelector(
    //   (state: RootState) => state.notification.user
    // );
    // const applicantFetchData =
    //   applicantFetch && applicantFetch?.data?.applications;
    // const applicantObject = applicantFetchData?.find(
    //   (applicant: any) => applicant.applicationId === ongoingID
    // );

    const userType = localStorage.getItem('userType');
    const isApplicantUser = userType === 'applicant';
    const applicationId = formData?.applicationId;
    const brokerId = formData?.brokerId;

    // useEffect(() => {
    //   if (ongoingID) {
    //     fetchOngoingApplicant();
    //   }
    //   if (!showHistory) {
    //     fetchDashboardData();
    //   }
    // }, [dispatch, ongoingID]);

    useEffect(() => {
      if (HistoryData?.data[historyIndex]?.details?.updatedData) {
        const data = omitBy(
          HistoryData?.data[historyIndex]?.details?.updatedData,
          isNil
        );
        setHistoryData(data);
      }
    }, [HistoryData, historyIndex]);

    useEffect(() => {
      const { status, type, error, user } = createApplicationForm;

      switch (status) {
        case 'loading': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: true });
          }
          break;
        }
        case 'succeed': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: false });
            setRescindFromApplicant(false);
            toast({
              status: 'success',
              title: user?.data,
            });
            fetchOngoingApplication();
          }
          break;
        }
        case 'failed': {
          if (
            type === 'RESCIND_APPLICATION_FROM_APPLICANT' &&
            rescindFromApplicant
          ) {
            setLoader({ ...loader, btnLoader: false });
            setRescindFromApplicant(false);
            toast({
              status: 'error',
              title: error,
            });
          }
          break;
        }

        default:
          break;
      }
    }, [createApplicationForm.status]);

    // const fetchOngoingApplicant = async () => {
    //   if (!!localStorage.getItem('Token')) {
    //     let id = !showHistory && ongoingID ? ongoingID : 0;
    //     id = showHistory && applicantId ? applicantId : id;
    //     let actionResult = await dispatch(OngoingApplicant({ id: id }) as any);
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    // const fetchDashboardData = async () => {
    //   if (!!localStorage.getItem('Token')) {
    //     let actionResult = await dispatch(
    //       fetchDashboardDetails({ applicationFilter: '' }) as any
    //     );
    //     if (actionResult?.payload?.status === 401) {
    //       navigate('/login');
    //     }
    //   } else {
    //     navigate('/login');
    //   }
    // };

    useEffect(() => {
      const fetchData = async () => {
        if (!!localStorage.getItem('Token')) {
          if (ongoingID && showHistory) {
            let actionResult = await dispatch(
              getApplicantHistory({
                applicationId: ongoingID,
                modelName: 'Insurance',
              }) as any
            );
            if (actionResult?.payload?.status === 401) {
              navigate('/login');
            }
          }
        } else {
          navigate('/login');
        }
      };
      fetchData();
    }, []);

    const [initialValues, setInitialValues] = useState<TInsurence>({
      applicationId: applicationId,
      applicantId: formData?.applicantId,

      brokerId: brokerId,
      keyManLifeInsurance: {
        hasKeyManLifeInsurance: null,
        details: [
          {
            keyPerson: null,
            amount: null,
            beneficiary: null,
          },
        ],
      },

      commercialGeneralLiabilityInsurance: {
        hasCommercialGeneralLiabilityInsurance: null,

        limit: null,
        provider: null,
        repBrokerage: null,
      },
      environmentalInsurance: {
        hasEnvironmentalInsurance: null,

        limit: null,
        provider: null,
        repBrokerage: null,
      },
      errorsAndOmissionsInsurance: {
        hasErrorsAndOmissionsInsurance: null,

        limit: null,
        provider: null,
        repBrokerage: null,
      },
      fidelityInsurance: {
        hasFidelityInsurance: null,

        limit: null,
        provider: null,
        repBrokerage: null,
      },
      equipmentInsurance: {
        hasEquipmentInsurance: null,

        limit: null,
        provider: null,
        repBrokerage: null,
      },
      cyberInsurance: {
        hasCyberInsurance: null,

        limit: null,
        provider: null,
        repBrokerage: null,
      },

      otherInsurance: {
        hasOtherInsurance: null,
        details: [
          {
            type: null,
            limit: null,
            provider: null,
            brokerage: null,
          },
        ],
      },

      returnToSender: userType === 'broker' ? false : null,
      applicationName: '',
    });

    useEffect(() => {
      if (formData) {
        const otherInsurance = formData?.otherInsurance?.details?.map(
          (company) => ({
            type: company.type || null,
            limit: company.limit || null,
            provider: company.provider || null,
            brokerage: company.brokerage || null,
          })
        );
        const keyManLifeInsuranceDetails = formData?.keyManLifeInsurance
          ?.details
          ? formData?.keyManLifeInsurance?.details?.map((contract) => ({
              keyPerson: contract.keyPerson || null,
              amount: contract.amount || null,
              beneficiary: contract.beneficiary || null,
            }))
          : initialValues?.keyManLifeInsurance?.details;

        const apiValues: TInsurence = {
          applicationId: formData?.applicationId,
          brokerId: formData?.brokerId || null,
          applicantId: formData?.applicantId,

          keyManLifeInsurance: {
            hasKeyManLifeInsurance:
              formData?.keyManLifeInsurance?.hasKeyManLifeInsurance,
            details: keyManLifeInsuranceDetails,
          },
          commercialGeneralLiabilityInsurance: {
            hasCommercialGeneralLiabilityInsurance:
              formData?.commercialGeneralLiabilityInsurance
                ?.hasCommercialGeneralLiabilityInsurance,
            limit: formData?.commercialGeneralLiabilityInsurance?.limit,
            provider: formData?.commercialGeneralLiabilityInsurance?.provider,
            repBrokerage:
              formData?.commercialGeneralLiabilityInsurance?.repBrokerage,
          },
          environmentalInsurance: {
            hasEnvironmentalInsurance:
              formData?.environmentalInsurance?.hasEnvironmentalInsurance,
            limit: formData?.environmentalInsurance?.limit,
            provider: formData?.environmentalInsurance?.provider,
            repBrokerage: formData?.environmentalInsurance?.repBrokerage,
          },
          errorsAndOmissionsInsurance: {
            hasErrorsAndOmissionsInsurance:
              formData?.errorsAndOmissionsInsurance
                ?.hasErrorsAndOmissionsInsurance,
            limit: formData?.errorsAndOmissionsInsurance?.limit,
            provider: formData?.errorsAndOmissionsInsurance?.provider,
            repBrokerage: formData?.errorsAndOmissionsInsurance?.repBrokerage,
          },
          fidelityInsurance: {
            hasFidelityInsurance:
              formData?.fidelityInsurance?.hasFidelityInsurance,
            limit: formData?.fidelityInsurance?.limit,
            provider: formData?.fidelityInsurance?.provider,
            repBrokerage: formData?.fidelityInsurance?.repBrokerage,
          },
          equipmentInsurance: {
            hasEquipmentInsurance:
              formData?.equipmentInsurance?.hasEquipmentInsurance,
            limit: formData?.equipmentInsurance?.limit,
            provider: formData?.equipmentInsurance?.provider,
            repBrokerage: formData?.equipmentInsurance?.repBrokerage,
          },
          cyberInsurance: {
            hasCyberInsurance: formData?.cyberInsurance?.hasCyberInsurance,
            limit: formData?.cyberInsurance?.limit,
            provider: formData?.cyberInsurance?.provider,
            repBrokerage: formData?.cyberInsurance?.repBrokerage,
          },
          otherInsurance: {
            hasOtherInsurance: formData?.otherInsurance?.hasOtherInsurance,
            details: otherInsurance,
          },
          returnToSender: userType === 'broker' ? false : null,
          applicationName: formData?.applicationName,
        };

        setShowTextarea({
          hasKeyManLifeInsurance:
            formData?.keyManLifeInsurance?.hasKeyManLifeInsurance ?? false,
          hasCommercialGeneralLiabilityInsurance:
            formData?.commercialGeneralLiabilityInsurance
              ?.hasCommercialGeneralLiabilityInsurance ?? false,
          hasEnvironmentalInsurance:
            formData?.environmentalInsurance?.hasEnvironmentalInsurance ??
            false,
          hasErrorsAndOmissionsInsurance:
            formData?.errorsAndOmissionsInsurance
              ?.hasErrorsAndOmissionsInsurance ?? false,
          hasFidelityInsurance:
            formData?.fidelityInsurance?.hasFidelityInsurance ?? false,
          hasEquipmentInsurance:
            formData?.equipmentInsurance?.hasEquipmentInsurance ?? false,
          hasOtherInsurance:
            formData?.otherInsurance?.hasOtherInsurance ?? false,
        });
        setInitialValues(apiValues);
      }
    }, [formData]);

    const OnHandelReturnToSender = () => {
      setIsReturnToSender(true);
    };

    const OnHandleReturnToBroker = () => {
      setRescindFromApplicant(true);
      dispatch(
        RescindFromApplicant({
          applicationId: formData?.applicationId,
          formName: 'insurance',
        })
      );
    };

    // const fetchId = insuranceId ? insuranceId : ongoinginsuranceId;
    // const fetchId = true;

    const formik = useFormik({
      enableReinitialize: true,
      initialValues,
      // validationSchema: InsuranceSchema,

      onSubmit: async (values: TInsurence) => {
        setIsSubmited({ ...isSubmited, Insurance: true });
        let res = '';
        try {
          if (!isReturnToSender) {
            let editedValues: any = {};
            const updatedData: Partial<TInsurence> = deepObjectComparison(
              values,
              formData
            );
            // const keys: (keyof TInsurence)[] = Object.keys(
            //   values
            // ) as (keyof TInsurence)[];

            // for (const key of keys) {
            //   if (!deepObjectComparison(values[key], insuranceFormData[key])) {
            //     (editedValues as any)[key] = values[key];
            //   }
            // }

            editedValues = {
              ...values,
              updatedData: updatedData,
              // returnToSender: isApplicantUser,
              // returnToSender: false,
              applicationId: formData?.applicationId,
              applicantId: formData?.applicantId,
              brokerId: formData?.brokerId,
              applicationName: formData?.applicationName,
            };
            const trimedValues = trimStringValues(editedValues);
            // return;
            const actionResult = await dispatch(
              InsuranceUpdate({
                updatedData: trimedValues,
                applicationId: applicationId,
              }) as any
            );
            const response = actionResult.payload;
            res = response.msg;
            const resStatus = response.status;

            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              Toast({
                title: res,
                status: 'error',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
            }
          } else if (isReturnToSender) {
            let editedValues: any = {};
            const updatedData: Partial<TInsurence> = deepObjectComparison(
              values,
              formData
            );
            // const keys: (keyof TInsurence)[] = Object.keys(
            //   values
            // ) as (keyof TInsurence)[];

            // for (const key of keys) {
            //   if (!deepObjectComparison(values[key], insuranceFormData[key])) {
            //     (editedValues as any)[key] = values[key];
            //   }
            // }

            editedValues = {
              ...values,
              updatedData: updatedData,
              // returnToSender: isApplicantUser,
              returnToSender: userType === 'applicant' ? false : true,
              applicationId: formData?.applicationId,
              applicantId: formData?.applicantId,
              brokerId: formData?.brokerId,
              applicationName: formData?.applicationName,
            };
            const trimedValues = trimStringValues(editedValues);
            setLoader({ ...loader, btnLoader: true });
            const actionResult = await dispatch(
              InsuranceUpdate({
                updatedData: trimedValues,
                applicationId: applicationId,
              }) as any
            );
            if (userType === 'broker') {
              const response = actionResult.payload;
              res = response.msg;
              const resStatus = response.status;

              if (resStatus === 200) {
                Toast({
                  title: response.msg,
                  status: 'success',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
                fetchOngoingApplication();
              } else {
                Toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              }
            } else {
              navigate('/');
            }
            setIsReturnToSender(false);
            setLoader({ ...loader, btnLoader: false });
          } else {
            const actionResult = await dispatch(insurance(values));
            const response = actionResult?.payload;
            res = response?.msg;
            const resStatus = response?.status;

            if (resStatus === 200) {
              Toast({
                title: response.msg,
                status: 'success',
                isClosable: true,
                duration: 3000,
                position: 'top-right',
              });
              fetchOngoingApplication();
            } else {
              if (resStatus !== 401) {
                Toast({
                  title: res,
                  status: 'error',
                  isClosable: true,
                  duration: 3000,
                  position: 'top-right',
                });
              } else {
                navigate('/login');
              }
            }
          }
        } catch (error: any) {
          if (res) {
            Toast({
              title: res,
              status: 'error',
              isClosable: true,
              duration: 3000,
              position: 'top-right',
            });
          }
        }
      },
    });

    useImperativeHandle(ref, () => ({
      formik: formik,
    }));

    const fetchOngoingApplication = async () => {
      dispatch(
        getApplicationById({
          applicationId,
        }) as any
      );
    };

    // function capitalizeFirstLetter(str: any) {
    //     return (
    //         str.charAt(0).toUpperCase() +
    //         str.slice(1).replace(/[A-Z]/g, (match: any) => " " + match.toLowerCase())
    //     );
    // }

    function capitalizeFirstLetter(str: any) {
      const dotIndex = str.indexOf('.');
      const modifiedStr = dotIndex !== -1 ? str.slice(0, dotIndex) : str;
      return (
        modifiedStr.charAt(0).toUpperCase() +
        modifiedStr
          .slice(1)
          .replace(/[A-Z]/g, (match: any) => ' ' + match.toLowerCase()) +
        ' can not be null.'
      );
    }

    const handleAddBox = (index: number) => {
      formik.setValues((prevValues: any) => {
        const newValues = { ...prevValues };
        let keyManLifeInsurance = newValues?.keyManLifeInsurance || {};
        const details = newValues?.keyManLifeInsurance?.details || [];

        details?.push({
          keyPerson: null,
          amount: null,
          beneficiary: null,
        });

        keyManLifeInsurance = { ...keyManLifeInsurance, details: details };

        return {
          ...newValues,
          keyManLifeInsurance,
        };
      });
    };

    const handleRemoveBox = (index: number) => {
      formik.setValues((prevValues) => {
        const newValues = { ...prevValues };
        let keyManLifeInsurance = newValues?.keyManLifeInsurance || {};
        const details = newValues?.keyManLifeInsurance?.details || [];

        if (details?.length > 1) {
          const removeIndex = index;
          details?.splice(removeIndex, 1);
          keyManLifeInsurance = { ...keyManLifeInsurance, details: details };

          return {
            ...newValues,
            keyManLifeInsurance,
          };
        }

        return newValues;
      });
    };

    return (
      <Box
        display="flex"
        gap={4}
        w="min(88rem,95%)"
        margin={'-236px auto 0'}
        flexDirection={{ base: 'column', lg: 'row' }}
      >
        <Container
          maxW={'container.lg'}
          boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
          h="auto"
          p="20px"
          borderRadius="20px"
          gap={5}
          bg={'white'}
          // overflow="auto"
          // height="calc(100vh - 150px)"
          // sx={{ "&::-webkit-scrollbar": { display: "none" } }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box display={'flex'} flexDirection="column" gap={3}>
              <Box>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="center"
                  fontSize={20}
                >
                  Insurance{' '}
                  {/* {applicantHeaderName && applicantHeaderName.length > 0
                    ? `: ${applicantHeaderName} `
                    : null} */}
                </Text>
              </Box>
              {/* <Box
                bgColor={"#f5f5f5"}
                borderRadius={"8px"}
                p={3}
                display={"flex"}
                flexDir={"column"}
                gap={5}
              > */}
              <Box display={'flex'} justifyContent={'space-between'} gap={4}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    Is there Key man life insurance?{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={
                      formik.values.keyManLifeInsurance
                        ?.hasKeyManLifeInsurance === true
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'keyManLifeInsurance.hasKeyManLifeInsurance',
                        e.target.checked
                      );
                      setShowTextarea({
                        ...showTextarea,
                        hasKeyManLifeInsurance: e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      formik.values.keyManLifeInsurance
                        ?.hasKeyManLifeInsurance === false
                    }
                    onChange={(e) => {
                      // formik.setFieldValue(
                      //   "keyManLifeInsurance.hasKeyManLifeInsurance",
                      //   !e.target.checked
                      // )

                      formik.setFieldValue('keyManLifeInsurance', {
                        hasKeyManLifeInsurance: !e.target.checked,
                        details: [
                          {
                            keyPerson: null,
                            amount: null,
                            beneficiary: null,
                          },
                        ],
                      });
                      setShowTextarea({
                        ...showTextarea,
                        hasKeyManLifeInsurance: !e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>

              {formik.touched.keyManLifeInsurance?.hasKeyManLifeInsurance &&
                formik.errors.keyManLifeInsurance?.hasKeyManLifeInsurance && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {capitalizeFirstLetter(
                      formik.errors.keyManLifeInsurance?.hasKeyManLifeInsurance
                    )}
                  </div>
                )}
              {showTextarea.hasKeyManLifeInsurance && (
                <Box>
                  {formik?.values?.keyManLifeInsurance?.details?.map(
                    (item: any, i: number) => (
                      <Box
                        display={{ base: 'block', md: 'flex' }}
                        gap={5}
                        key={i}
                      >
                        <Box flex={{ base: '1', md: '1' }}>
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                          >
                            <li>
                              {' '}
                              Key person
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </li>
                          </Text>

                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Input
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              fontSize={'13px'}
                              w="100%"
                              type="text"
                              placeholder=" Key person"
                              borderRadius="5px"
                              onChange={formik.handleChange}
                              readOnly={showBox}
                              value={
                                formik.values.keyManLifeInsurance?.details?.[i]
                                  ?.keyPerson ?? ''
                              }
                              name={`keyManLifeInsurance.details.[${i}].keyPerson`}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                historyData?.keyManLifeInsurance?.details?.[
                                  i
                                ] &&
                                'keyPerson' in
                                  historyData?.keyManLifeInsurance?.details?.[
                                    i
                                  ] &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                historyData?.keyManLifeInsurance?.details?.[
                                  i
                                ] &&
                                'keyPerson' in
                                  historyData?.keyManLifeInsurance?.details?.[
                                    i
                                  ] &&
                                'white'
                              }
                            />
                          </Box>
                          {formik.touched.keyManLifeInsurance &&
                            formik.errors.keyManLifeInsurance &&
                            formik.errors.keyManLifeInsurance?.details &&
                            typeof formik.errors.keyManLifeInsurance?.details?.[
                              i
                            ] !== 'string' &&
                            (
                              formik.errors.keyManLifeInsurance?.details?.[
                                i
                              ] as any
                            )?.keyPerson && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {
                                  // ?.details?.[i]
                                  (
                                    formik.errors.keyManLifeInsurance
                                      ?.details?.[i] as any
                                  )?.keyPerson
                                }
                              </div>
                            )}
                        </Box>
                        <Box flex={{ base: '1', md: '1' }}>
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                          >
                            <li>
                              Amount
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </li>
                          </Text>

                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Input
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              fontSize={'13px'}
                              w="100%"
                              type="number"
                              placeholder="$ Amount"
                              borderRadius="5px"
                              onChange={formik.handleChange}
                              onKeyDown={preventAlphabetsTyping}
                              readOnly={showBox}
                              value={
                                formik.values.keyManLifeInsurance?.details?.[i]
                                  ?.amount ?? ''
                              }
                              name={`keyManLifeInsurance.details[${i}].amount`}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                historyData?.keyManLifeInsurance?.details?.[
                                  i
                                ] &&
                                'amount' in
                                  historyData?.keyManLifeInsurance?.details?.[
                                    i
                                  ] &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                historyData?.keyManLifeInsurance?.details?.[
                                  i
                                ] &&
                                'amount' in
                                  historyData?.keyManLifeInsurance?.details?.[
                                    i
                                  ] &&
                                'white'
                              }
                            />
                          </Box>
                          {formik.touched.keyManLifeInsurance &&
                            formik.errors.keyManLifeInsurance &&
                            formik.errors.keyManLifeInsurance?.details &&
                            typeof formik.errors.keyManLifeInsurance?.details?.[
                              i
                            ] !== 'string' &&
                            // formik.errors.keyManLifeInsurance?.details?.[i]
                            (
                              formik.errors.keyManLifeInsurance?.details?.[
                                i
                              ] as any
                            )?.amount && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {
                                  // ?.details?.[0]
                                  (
                                    formik.errors.keyManLifeInsurance
                                      ?.details?.[i] as any
                                  )?.amount
                                }
                              </div>
                            )}
                        </Box>
                        <Box flex={{ base: '1', md: '1' }}>
                          <Text
                            fontWeight="600"
                            color="#114684"
                            textAlign="start"
                            fontSize={13}
                          >
                            <li>
                              {' '}
                              Beneficiary
                              {/* <span style={{ color: "red" }}>*</span> */}
                            </li>
                          </Text>

                          <Box
                            display="flex"
                            mb={{ base: '20px', md: '5px' }}
                            mt={2}
                          >
                            <Input
                              focusBorderColor="#7f7f7f"
                              borderColor={'#7f7f7f'}
                              _hover={{ borderColor: '#7f7f7f' }}
                              fontSize={'13px'}
                              w="100%"
                              type="text"
                              placeholder="$ Beneficiary"
                              borderRadius="5px"
                              onChange={formik.handleChange}
                              readOnly={showBox}
                              value={
                                formik.values.keyManLifeInsurance?.details?.[i]
                                  ?.beneficiary ?? ''
                              }
                              name={`keyManLifeInsurance.details[${i}].beneficiary`}
                              bgColor={
                                showHistory &&
                                currentHistoryData &&
                                historyData?.keyManLifeInsurance?.details?.[
                                  i
                                ] &&
                                'beneficiary' in
                                  historyData?.keyManLifeInsurance?.details?.[
                                    i
                                  ] &&
                                '#114684'
                              }
                              color={
                                showHistory &&
                                currentHistoryData &&
                                historyData?.keyManLifeInsurance?.details?.[
                                  i
                                ] &&
                                'beneficiary' in
                                  historyData?.keyManLifeInsurance?.details?.[
                                    i
                                  ] &&
                                'white'
                              }
                            />
                          </Box>
                          {formik.touched.keyManLifeInsurance &&
                            formik.errors.keyManLifeInsurance &&
                            formik.errors.keyManLifeInsurance?.details &&
                            typeof formik.errors.keyManLifeInsurance?.details?.[
                              i
                            ] !== 'string' &&
                            // formik.errors.keyManLifeInsurance?.details?.[0]
                            (
                              formik.errors.keyManLifeInsurance?.details?.[
                                i
                              ] as any
                            )?.beneficiary && (
                              <div
                                style={{
                                  color: 'red',
                                  fontSize: '13px',
                                  textAlign: 'start',
                                  fontWeight: '500',
                                }}
                              >
                                {
                                  // formik.errors.keyManLifeInsurance
                                  // ?.details?.[0]
                                  (
                                    formik.errors.keyManLifeInsurance
                                      ?.details?.[i] as any
                                  )?.beneficiary
                                }
                              </div>
                            )}
                        </Box>
                        {i === 0 ? (
                          <Box
                            display="flex"
                            alignItems="center"
                            fontSize="xl"
                            marginTop="18px"
                            onClick={() => handleAddBox(i)}
                            cursor={showBox ? 'not-allowed' : 'pointer'}
                            pointerEvents={showBox ? 'none' : 'auto'}
                          >
                            <CiCirclePlus />
                          </Box>
                        ) : (
                          <Box
                            display="flex"
                            alignItems="center"
                            fontSize="xl"
                            marginTop="18px"
                            onClick={() => handleRemoveBox(i)}
                            cursor={showBox ? 'not-allowed' : 'pointer'}
                            pointerEvents={showBox ? 'none' : 'auto'}
                          >
                            <CiCircleMinus />
                          </Box>
                        )}
                      </Box>
                    )
                  )}
                </Box>
              )}
              {/* </Box> */}

              <Box display={'flex'} justifyContent={'space-between'} gap={5}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    {' '}
                    Do you have commercial general liability insurance?{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={
                      formik.values.commercialGeneralLiabilityInsurance
                        ?.hasCommercialGeneralLiabilityInsurance === true
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'commercialGeneralLiabilityInsurance.hasCommercialGeneralLiabilityInsurance',
                        e.target.checked
                      );
                      setShowTextarea({
                        ...showTextarea,
                        hasCommercialGeneralLiabilityInsurance:
                          e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      formik.values.commercialGeneralLiabilityInsurance
                        .hasCommercialGeneralLiabilityInsurance === false
                    }
                    onChange={(e) => {
                      // formik.setFieldValue(
                      //   'commercialGeneralLiabilityInsurance.hasCommercialGeneralLiabilityInsurance',
                      //   !e.target.checked
                      // );
                      formik.setFieldValue(
                        'commercialGeneralLiabilityInsurance',
                        {
                          hasCommercialGeneralLiabilityInsurance:
                            !e.target.checked,
                          limit: null,
                          provider: null,
                          repBrokerage: null,
                        }
                      );
                      setShowTextarea({
                        ...showTextarea,
                        hasCommercialGeneralLiabilityInsurance:
                          !e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>

              {formik.touched.commercialGeneralLiabilityInsurance
                ?.hasCommercialGeneralLiabilityInsurance &&
                formik.errors.commercialGeneralLiabilityInsurance
                  ?.hasCommercialGeneralLiabilityInsurance && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {capitalizeFirstLetter(
                      formik.errors.commercialGeneralLiabilityInsurance
                        ?.hasCommercialGeneralLiabilityInsurance
                    )}
                    .
                  </div>
                )}

              {showTextarea.hasCommercialGeneralLiabilityInsurance && (
                <Box
                  display={{ base: 'block', md: 'flex' }}
                  gap={5}
                  flexWrap={'wrap'}
                >
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        What are the limit(s){' '}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="$ What are the limit(s)"
                        borderRadius="5px"
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        onChange={formik.handleChange}
                        // onKeyDown={preventAlphabetsTyping}
                        readOnly={showBox}
                        value={
                          formik.values.commercialGeneralLiabilityInsurance
                            .limit ?? ''
                        }
                        name="commercialGeneralLiabilityInsurance.limit"
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.commercialGeneralLiabilityInsurance &&
                          'limit' in
                            historyData?.commercialGeneralLiabilityInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.commercialGeneralLiabilityInsurance &&
                          'limit' in
                            historyData?.commercialGeneralLiabilityInsurance &&
                          'white'
                        }
                      />
                    </Box>
                    {formik.touched.commercialGeneralLiabilityInsurance
                      ?.limit &&
                      formik.errors.commercialGeneralLiabilityInsurance
                        ?.limit && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {
                            formik.errors.commercialGeneralLiabilityInsurance
                              ?.limit
                          }
                        </div>
                      )}
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is the insurer?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is the insurer?"
                        borderRadius="5px"
                        value={
                          formik.values.commercialGeneralLiabilityInsurance
                            ?.provider ?? ''
                        }
                        name="commercialGeneralLiabilityInsurance.provider"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.commercialGeneralLiabilityInsurance &&
                          'provider' in
                            historyData?.commercialGeneralLiabilityInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.commercialGeneralLiabilityInsurance &&
                          'provider' in
                            historyData?.commercialGeneralLiabilityInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is your representing brokerage?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is your representing brokerage"
                        borderRadius="5px"
                        value={
                          formik.values.commercialGeneralLiabilityInsurance
                            ?.repBrokerage ?? ''
                        }
                        name="commercialGeneralLiabilityInsurance.repBrokerage"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.commercialGeneralLiabilityInsurance &&
                          'repBrokerage' in
                            historyData?.commercialGeneralLiabilityInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.commercialGeneralLiabilityInsurance &&
                          'repBrokerage' in
                            historyData?.commercialGeneralLiabilityInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              <Box display={'flex'} justifyContent={'space-between'} gap={5}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    Do you have environmental (pollution) Insurance?{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={
                      formik.values.environmentalInsurance
                        .hasEnvironmentalInsurance === true
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'environmentalInsurance.hasEnvironmentalInsurance',
                        e.target.checked
                      );
                      setShowTextarea({
                        ...showTextarea,
                        hasEnvironmentalInsurance: e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      formik.values.environmentalInsurance
                        .hasEnvironmentalInsurance === false
                    }
                    onChange={(e) => {
                      // formik.setFieldValue(
                      //   'environmentalInsurance.hasEnvironmentalInsurance',
                      //   !e.target.checked
                      // );
                      formik.setFieldValue('environmentalInsurance', {
                        hasEnvironmentalInsurance: !e.target.checked,
                        limit: null,
                        provider: null,
                        repBrokerage: null,
                      });
                      setShowTextarea({
                        ...showTextarea,
                        hasEnvironmentalInsurance: !e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>

              {formik.touched.environmentalInsurance
                ?.hasEnvironmentalInsurance &&
                formik.errors.environmentalInsurance
                  ?.hasEnvironmentalInsurance && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {capitalizeFirstLetter(
                      formik.errors.environmentalInsurance
                        ?.hasEnvironmentalInsurance
                    )}
                    .
                  </div>
                )}
              {showTextarea.hasEnvironmentalInsurance && (
                <Box
                  display={{ base: 'block', md: 'flex' }}
                  gap={5}
                  flexWrap={'wrap'}
                >
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        What are the limit(s){' '}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="$ What are the limit(s)"
                        borderRadius="5px"
                        onChange={formik.handleChange}
                        // onKeyDown={preventAlphabetsTyping}
                        readOnly={showBox}
                        value={
                          formik.values.environmentalInsurance?.limit ?? ''
                        }
                        name="environmentalInsurance.limit"
                        bgColor={
                          showHistory &&
                          historyData?.environmentalInsurance?.limit &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          historyData?.environmentalInsurance?.limit &&
                          'white'
                        }
                      />
                    </Box>
                    {formik.touched.environmentalInsurance?.limit &&
                      formik.errors.environmentalInsurance?.limit && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.environmentalInsurance?.limit}
                        </div>
                      )}
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is the insurer?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is the insurer?"
                        borderRadius="5px"
                        value={
                          formik.values.environmentalInsurance?.provider ?? ''
                        }
                        name="environmentalInsurance.provider"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.environmentalInsurance &&
                          'provider' in historyData?.environmentalInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.environmentalInsurance &&
                          'provider' in historyData?.environmentalInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is your representing brokerage?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is your representing brokerage"
                        borderRadius="5px"
                        value={
                          formik.values.environmentalInsurance?.repBrokerage ??
                          ''
                        }
                        name="environmentalInsurance.repBrokerage"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.environmentalInsurance &&
                          'repBrokerage' in
                            historyData?.environmentalInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.environmentalInsurance &&
                          'repBrokerage' in
                            historyData?.environmentalInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              <Box display={'flex'} justifyContent={'space-between'} gap={5}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    Do you have Errors & Omissions Insurance?{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={
                      formik.values.errorsAndOmissionsInsurance
                        .hasErrorsAndOmissionsInsurance === true
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'errorsAndOmissionsInsurance.hasErrorsAndOmissionsInsurance',
                        e.target.checked
                      );
                      setShowTextarea({
                        ...showTextarea,
                        hasErrorsAndOmissionsInsurance: e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      formik.values.errorsAndOmissionsInsurance
                        .hasErrorsAndOmissionsInsurance === false
                    }
                    onChange={(e) => {
                      // formik.setFieldValue(
                      //   'errorsAndOmissionsInsurance.hasErrorsAndOmissionsInsurance',
                      //   !e.target.checked
                      // );
                      formik.setFieldValue('errorsAndOmissionsInsurance', {
                        hasErrorsAndOmissionsInsurance: !e.target.checked,
                        limit: null,
                        provider: null,
                        repBrokerage: null,
                      });
                      setShowTextarea({
                        ...showTextarea,
                        hasErrorsAndOmissionsInsurance: !e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>

              {formik.touched.errorsAndOmissionsInsurance
                ?.hasErrorsAndOmissionsInsurance &&
                formik.errors.errorsAndOmissionsInsurance
                  ?.hasErrorsAndOmissionsInsurance && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {capitalizeFirstLetter(
                      formik.errors.errorsAndOmissionsInsurance
                        ?.hasErrorsAndOmissionsInsurance
                    )}
                    .
                  </div>
                )}
              {showTextarea.hasErrorsAndOmissionsInsurance && (
                <Box
                  display={{ base: 'block', md: 'flex' }}
                  gap={5}
                  flexWrap={'wrap'}
                >
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        What are the limit(s){' '}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="$ What are the limit(s)"
                        borderRadius="5px"
                        onChange={formik.handleChange}
                        // onKeyDown={preventAlphabetsTyping}
                        readOnly={showBox}
                        value={
                          formik.values.errorsAndOmissionsInsurance?.limit ?? ''
                        }
                        name="errorsAndOmissionsInsurance.limit"
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.errorsAndOmissionsInsurance &&
                          'limit' in historyData?.errorsAndOmissionsInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.errorsAndOmissionsInsurance &&
                          'limit' in historyData?.errorsAndOmissionsInsurance &&
                          'white'
                        }
                      />
                    </Box>
                    {formik.touched.errorsAndOmissionsInsurance?.limit &&
                      formik.errors.errorsAndOmissionsInsurance?.limit && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.errorsAndOmissionsInsurance?.limit}
                        </div>
                      )}
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is the insurer?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is the insurer?"
                        borderRadius="5px"
                        value={
                          formik.values.errorsAndOmissionsInsurance?.provider ??
                          ''
                        }
                        name="errorsAndOmissionsInsurance.provider"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.errorsAndOmissionsInsurance &&
                          'provider' in
                            historyData?.errorsAndOmissionsInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.errorsAndOmissionsInsurance &&
                          'provider' in
                            historyData?.errorsAndOmissionsInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is your representing brokerage?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is your representing brokerage"
                        borderRadius="5px"
                        value={
                          formik.values.errorsAndOmissionsInsurance
                            ?.repBrokerage ?? ''
                        }
                        name="errorsAndOmissionsInsurance.repBrokerage"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.errorsAndOmissionsInsurance &&
                          'repBrokerage' in
                            historyData?.errorsAndOmissionsInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.errorsAndOmissionsInsurance &&
                          'repBrokerage' in
                            historyData?.errorsAndOmissionsInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              <Box display={'flex'} justifyContent={'space-between'} gap={5}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    Do you have crime (fidelity) insurance?{' '}
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={
                      formik.values.fidelityInsurance?.hasFidelityInsurance ===
                      true
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'fidelityInsurance.hasFidelityInsurance',
                        e.target.checked
                      );
                      setShowTextarea({
                        ...showTextarea,
                        hasFidelityInsurance: e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      formik.values.fidelityInsurance?.hasFidelityInsurance ===
                      false
                    }
                    onChange={(e) => {
                      // formik.setFieldValue(
                      //   'fidelityInsurance.hasFidelityInsurance',
                      //   !e.target.checked
                      // );
                      formik.setFieldValue('fidelityInsurance', {
                        hasFidelityInsurance: !e.target.checked,
                        limit: null,
                        provider: null,
                        repBrokerage: null,
                      });
                      setShowTextarea({
                        ...showTextarea,
                        hasFidelityInsurance: !e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>

              {formik.touched.fidelityInsurance?.hasFidelityInsurance &&
                formik.errors.fidelityInsurance?.hasFidelityInsurance && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {capitalizeFirstLetter(
                      formik.errors.fidelityInsurance?.hasFidelityInsurance
                    )}
                    .
                  </div>
                )}
              {showTextarea.hasFidelityInsurance && (
                <Box
                  display={{ base: 'block', md: 'flex' }}
                  gap={5}
                  flexWrap={'wrap'}
                >
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        {' '}
                        What are the limit(s){' '}
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="$ What are the limit(s)"
                        borderRadius="5px"
                        onChange={formik.handleChange}
                        // onKeyDown={preventAlphabetsTyping}
                        readOnly={showBox}
                        value={formik.values.fidelityInsurance?.limit ?? ''}
                        name="fidelityInsurance.limit"
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.fidelityInsurance &&
                          'limit' in historyData?.fidelityInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.fidelityInsurance &&
                          'limit' in historyData?.fidelityInsurance &&
                          'white'
                        }
                      />
                    </Box>
                    {formik.touched.fidelityInsurance?.limit &&
                      formik.errors.fidelityInsurance?.limit && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.fidelityInsurance?.limit}
                        </div>
                      )}
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is the insurer?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is the insurer?"
                        borderRadius="5px"
                        value={formik.values.fidelityInsurance?.provider ?? ''}
                        name="fidelityInsurance.provider"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.fidelityInsurance &&
                          'provider' in historyData?.fidelityInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.fidelityInsurance &&
                          'provider' in historyData?.fidelityInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is your representing brokerage?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is your representing brokerage"
                        borderRadius="5px"
                        value={
                          formik.values.fidelityInsurance?.repBrokerage ?? ''
                        }
                        name="fidelityInsurance.repBrokerage"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.fidelityInsurance &&
                          'repBrokerage' in historyData?.fidelityInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.fidelityInsurance &&
                          'repBrokerage' in historyData?.fidelityInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              <Box display={'flex'} justifyContent={'space-between'} gap={5}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    Do you have equipment insurance?
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={
                      formik.values.equipmentInsurance
                        ?.hasEquipmentInsurance === true
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'equipmentInsurance.hasEquipmentInsurance',
                        e.target.checked
                      );
                      setShowTextarea({
                        ...showTextarea,
                        hasEquipmentInsurance: e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      formik.values.equipmentInsurance
                        ?.hasEquipmentInsurance === false
                    }
                    onChange={(e) => {
                      // formik.setFieldValue(
                      //   "equipmentInsurance.hasEquipmentInsurance",
                      //   !e.target.checked
                      // );
                      setShowTextarea({
                        ...showTextarea,
                        hasEquipmentInsurance: !e.target.checked,
                      });
                      formik.setFieldValue('equipmentInsurance', {
                        hasEquipmentInsurance: !e.target.checked,
                        limit: null,
                        provider: null,
                        repBrokerage: null,
                      });
                    }}
                    readOnly={showBox}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>

              {formik.touched.equipmentInsurance?.hasEquipmentInsurance &&
                formik.errors.equipmentInsurance?.hasEquipmentInsurance && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {capitalizeFirstLetter(
                      formik.errors.equipmentInsurance?.hasEquipmentInsurance
                    )}
                    .
                  </div>
                )}
              {showTextarea.hasEquipmentInsurance && (
                <Box
                  display={{ base: 'block', md: 'flex' }}
                  gap={5}
                  flexWrap={'wrap'}
                >
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        What are the limit(s)
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="$ What are the limit(s)"
                        borderRadius="5px"
                        onChange={formik.handleChange}
                        // onKeyDown={preventAlphabetsTyping}
                        readOnly={showBox}
                        value={formik.values.equipmentInsurance?.limit ?? ''}
                        name="equipmentInsurance.limit"
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.equipmentInsurance &&
                          'limit' in historyData?.equipmentInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.equipmentInsurance &&
                          'limit' in historyData?.equipmentInsurance &&
                          'white'
                        }
                      />
                    </Box>
                    {formik.touched.equipmentInsurance?.limit &&
                      formik.errors.equipmentInsurance?.limit && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.equipmentInsurance?.limit}
                        </div>
                      )}
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is the insurer?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is the insurer?"
                        borderRadius="5px"
                        value={formik.values.equipmentInsurance?.provider ?? ''}
                        name="equipmentInsurance.provider"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.equipmentInsurance &&
                          'provider' in historyData?.equipmentInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.equipmentInsurance &&
                          'provider' in historyData?.equipmentInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is your representing brokerage?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is your representing brokerage"
                        borderRadius="5px"
                        value={
                          formik.values.equipmentInsurance?.repBrokerage ?? ''
                        }
                        name="equipmentInsurance.repBrokerage"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.equipmentInsurance &&
                          'repBrokerage' in historyData?.equipmentInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.equipmentInsurance &&
                          'repBrokerage' in historyData?.equipmentInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}

              <Box display={'flex'} justifyContent={'space-between'} gap={5}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>
                    Do you have cyber Insurance?
                    {/* <span style={{ color: "red" }}>*</span> */}
                  </li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={
                      formik.values.cyberInsurance?.hasCyberInsurance === true
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'cyberInsurance.hasCyberInsurance',
                        e.target.checked
                      );
                    }}
                    readOnly={showBox}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      formik.values.cyberInsurance?.hasCyberInsurance === false
                    }
                    onChange={(e) => {
                      formik.setFieldValue('cyberInsurance', {
                        hasCyberInsurance: !e.target.checked,
                        limit: null,
                        provider: null,
                        repBrokerage: null,
                      });
                    }}
                    readOnly={showBox}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>

              {formik.touched.cyberInsurance?.hasCyberInsurance &&
                formik.errors.cyberInsurance?.hasCyberInsurance && (
                  <div
                    style={{
                      color: 'red',
                      fontSize: '13px',
                      textAlign: 'start',
                      fontWeight: '500',
                    }}
                  >
                    {capitalizeFirstLetter(
                      formik.errors.cyberInsurance?.hasCyberInsurance
                    )}
                    .
                  </div>
                )}
              {formik?.values?.cyberInsurance?.hasCyberInsurance && (
                <Box
                  display={{ base: 'block', md: 'flex' }}
                  gap={5}
                  flexWrap={'wrap'}
                >
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>
                        What are the limit(s)
                        {/* <span style={{ color: "red" }}>*</span> */}
                      </li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="$ What are the limit(s)"
                        borderRadius="5px"
                        onChange={formik.handleChange}
                        // onKeyDown={preventAlphabetsTyping}
                        readOnly={showBox}
                        value={formik.values.cyberInsurance?.limit ?? ''}
                        name="cyberInsurance.limit"
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.cyberInsurance &&
                          'limit' in historyData?.cyberInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.cyberInsurance &&
                          'limit' in historyData?.cyberInsurance &&
                          'white'
                        }
                      />
                    </Box>
                    {formik.touched.cyberInsurance?.limit &&
                      formik.errors.cyberInsurance?.limit && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '13px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.cyberInsurance?.limit}
                        </div>
                      )}
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is the insurer?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is the insurer?"
                        borderRadius="5px"
                        value={formik.values.cyberInsurance?.provider ?? ''}
                        name="cyberInsurance.provider"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.cyberInsurance &&
                          'provider' in historyData?.cyberInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.cyberInsurance &&
                          'provider' in historyData?.cyberInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is your representing brokerage?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is your representing brokerage"
                        borderRadius="5px"
                        value={formik.values.cyberInsurance?.repBrokerage ?? ''}
                        name="cyberInsurance.repBrokerage"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.cyberInsurance &&
                          'repBrokerage' in historyData?.cyberInsurance &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.cyberInsurance &&
                          'repBrokerage' in historyData?.cyberInsurance &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
              <Box display={'flex'} justifyContent={'space-between'} gap={5}>
                <Text
                  fontWeight="600"
                  color="#114684"
                  textAlign="start"
                  fontSize={13}
                >
                  <li>Do you have any other type of insurance?</li>
                </Text>
                <Stack spacing={5} direction="row">
                  <Checkbox
                    colorScheme="green"
                    isChecked={
                      formik.values.otherInsurance?.hasOtherInsurance === true
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'otherInsurance.hasOtherInsurance',
                        e.target.checked
                      );
                      setShowTextarea({
                        ...showTextarea,
                        hasOtherInsurance: e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    Yes
                  </Checkbox>
                  <Checkbox
                    colorScheme="red"
                    isChecked={
                      formik.values.otherInsurance?.hasOtherInsurance === false
                    }
                    onChange={(e) => {
                      formik.setFieldValue(
                        'otherInsurance.hasOtherInsurance',
                        !e.target.checked
                      );
                      setShowTextarea({
                        ...showTextarea,
                        hasOtherInsurance: !e.target.checked,
                      });
                    }}
                    readOnly={showBox}
                  >
                    No
                  </Checkbox>
                </Stack>
              </Box>
              {showTextarea.hasOtherInsurance && (
                <Box
                  display={{ base: 'block', md: 'flex' }}
                  gap={5}
                  flexWrap={'wrap'}
                >
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Please list what type</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Please list what type"
                        borderRadius="5px"
                        value={
                          formik.values.otherInsurance?.details?.[0]?.type ?? ''
                        }
                        name="otherInsurance.details[0].type"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.otherInsurance?.details?.[0] &&
                          'type' in historyData?.otherInsurance?.details?.[0] &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.otherInsurance?.details?.[0] &&
                          'type' in historyData?.otherInsurance?.details?.[0] &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>What are the limit(s)</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="$ What are the limit(s)"
                        borderRadius="5px"
                        value={
                          formik.values.otherInsurance?.details?.[0]?.limit ??
                          ''
                        }
                        name="otherInsurance.details[0].limit"
                        onChange={formik.handleChange}
                        // onKeyDown={preventAlphabetsTyping}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.otherInsurance?.details?.[0] &&
                          'limit' in
                            historyData?.otherInsurance?.details?.[0] &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.otherInsurance?.details?.[0] &&
                          'limit' in
                            historyData?.otherInsurance?.details?.[0] &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Who is the insurer?</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Who is the insurer?"
                        borderRadius="5px"
                        value={
                          formik.values.otherInsurance?.details?.[0]
                            ?.provider ?? ''
                        }
                        name="otherInsurance.details[0].provider"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.otherInsurance?.details?.[0] &&
                          'provider' in
                            historyData?.otherInsurance?.details?.[0] &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.otherInsurance?.details?.[0] &&
                          'provider' in
                            historyData?.otherInsurance?.details?.[0] &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                  <Box
                    flex={{ base: '1', md: '1' }}
                    display={'flex'}
                    flexDir={'column'}
                    justifyContent={'space-between'}
                  >
                    <Text
                      fontWeight="600"
                      color="#114684"
                      textAlign="start"
                      fontSize={13}
                    >
                      <li>Add your brokerage</li>
                    </Text>

                    <Box display="flex" mb={{ base: '20px', md: '5px' }} mt={2}>
                      <Input
                        focusBorderColor="#7f7f7f"
                        borderColor={'#7f7f7f'}
                        _hover={{ borderColor: '#7f7f7f' }}
                        fontSize={'13px'}
                        w="100%"
                        type="text"
                        placeholder="Add your brokerage"
                        borderRadius="5px"
                        value={
                          formik.values.otherInsurance?.details?.[0]
                            ?.brokerage ?? ''
                        }
                        name="otherInsurance.details[0].brokerage"
                        onChange={formik.handleChange}
                        readOnly={showBox}
                        bgColor={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.otherInsurance?.details?.[0] &&
                          'brokerage' in
                            historyData?.otherInsurance?.details?.[0] &&
                          '#114684'
                        }
                        color={
                          showHistory &&
                          currentHistoryData &&
                          historyData?.otherInsurance?.details?.[0] &&
                          'brokerage' in
                            historyData?.otherInsurance?.details?.[0] &&
                          'white'
                        }
                      />
                    </Box>
                  </Box>
                </Box>
              )}
            </Box>
            <Box
              display={'flex'}
              justifyContent={'end'}
              marginTop={'10px'}
              gap={2}
            >
              {!showBox && (
                <Button
                  bg={'#114684'}
                  color={'white'}
                  _hover={{ bg: '#114684', color: 'white' }}
                  onClick={
                    userType === 'broker' && formData?.assignToApplicant
                      ? () => OnHandleReturnToBroker()
                      : () => OnHandelReturnToSender()
                  }
                  type={
                    userType === 'broker' && formData?.assignToApplicant
                      ? 'button'
                      : 'submit'
                  }
                  isLoading={loader?.btnLoader}
                  // isDisabled={
                  //   userType === 'broker' && formData?.assignToApplicant
                  //     ? true
                  //     : false
                  // }
                >
                  {isApplicantUser
                    ? 'Return to Broker'
                    : userType === 'broker' && formData?.assignToApplicant
                      ? 'Edit - Rescind Assignment from Applicant'
                      : 'Assign to applicant'}
                </Button>
              )}
              {!showBox && (
                <Box>
                  <Button
                    bg={'#114684'}
                    color={'white'}
                    _hover={{ bg: '#114684', color: 'white' }}
                    type="submit"
                  >
                    Update
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        </Container>
        {showHistory && (
          <>
            <Box
              height="calc(100vh - 150px)"
              w={{
                base: '100%',
                lg: '360px',
              }}
              sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
              bg={'white'}
              display={'flex'}
              flexDir={'column'}
              gap={'10px'}
              borderRadius={'12px'}
              boxShadow="0 0 14px -6px rgba(0,0,0,0.3)"
              p={4}
            >
              <Box style={{ fontSize: '25px', fontWeight: 'bold' }}>
                History
              </Box>
              <Box
                display={'flex'}
                flexDir={'column'}
                gap={'10px'}
                overflow={'auto'}
              >
                {HistoryData?.data?.map((item: any, index: number) => {
                  const formattedDate = formatDate(item?.createdAt);
                  return (
                    <Box
                      bgColor={historyIndex == index ? '#1146844a' : '#f5f5f5'}
                      p={3}
                      borderRadius={'12px'}
                      cursor={'pointer'}
                      mr={2}
                      border={
                        historyIndex == index
                          ? '1px solid #114684'
                          : '1px solid gray'
                      }
                      onClick={() => setHistoryIndex(index)}
                    >
                      <Box fontWeight={500} mt={2} display={'flex'}>
                        <Box marginRight={1}>Date :</Box>
                        <Text> {formattedDate.date}</Text>
                        <Text
                          mx={2}
                          borderLeft="1px solid #114684"
                          height="auto"
                          boxShadow="none"
                        ></Text>
                        <Text>{formattedDate.time}</Text>
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Status : {item?.applicationStatus}
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Initiated By : {item?.initiatedBy}
                      </Box>
                      <Box fontSize="15px" fontWeight={500}>
                        Updated By : {item?.broker?.firstName}
                        &nbsp;
                        {item?.broker?.lastName}
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </>
        )}
      </Box>
    );
  }
);

export default Insurance;
