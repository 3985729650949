export interface Props {
  value: string;
  label: string;
}

const internationalBanksData = {
  internationalBanks: [
    'HSBC',
    'Citibank',
    'Standard Chartered',
    'BNP Paribas',
    'Deutsche Bank',
    'Barclays',
    'Credit Suisse',
    'UBS',
    'ING',
    'Santander',
    'BBVA',
    'Mizuho Bank',
    'Bank of Tokyo-Mitsubishi UFJ',
    'Royal Bank of Canada',
    'Scotiabank',
    'ANZ Bank',
    'Westpac',
    'National Australia Bank',
    'HSBC Canada',
    'DBS Bank',
    'OCBC Bank',
    'UOB',
    'Maybank',
    'Bank Mandiri',
    'Bank Rakyat Indonesia',
    'Standard Bank',
    'Absa Bank',
    'FirstRand Bank',
    'Nedbank',
    'Capitec Bank',
    'Emirates NBD',
    'First Abu Dhabi Bank',
    'Bank of China',
    'Industrial and Commercial Bank of China (ICBC)',
    'China Construction Bank',
    'Bank of Communications',
    'Agricultural Bank of China',
    'Bank of America',
    'JPMorgan Chase',
    'Wells Fargo',
    'Goldman Sachs',
    'Morgan Stanley',
    'Bank of New York Mellon',
    'BNY Mellon',
    'State Street',
    'American Express Bank',
    'PNC Bank',
    'Capital One',
    'TD Bank',
    'BB&T (now Truist Bank)',
    'SunTrust Bank (now Truist Bank)',
    'Ally Bank',
    'Discover Bank',
    'Charles Schwab Bank',
    'Banco Santander',
    'BBVA USA',
    'CitiBank',
    'Union Bank',
    'US Bank',
    'Regions Bank',
    'Fifth Third Bank',
    'KeyBank',
    'Huntington Bank',
    'Synchrony Bank',
    'Citizens Bank',
    'Comerica Bank',
    'BMO Harris Bank',
    'HDFC Bank',
    'State Bank of India',
    'ICICI Bank',
    'Axis Bank',
    'Bank of Baroda',
    'Yes Bank',
    'IndusInd Bank',
    'Kotak Mahindra Bank',
    'Canara Bank',
    'Bank of Maharashtra',
    'IDBI Bank',
    'Federal Bank',
    'South Indian Bank',
    'UCO Bank',
    'Punjab National Bank',
    'Corporation Bank',
    'Vijaya Bank',
    'Dena Bank',
    'Syndicate Bank',
    'Andhra Bank',
    'Indian Overseas Bank',
    'Central Bank of India',
    'Bank of India',
    'Punjab & Sind Bank',
    'Oriental Bank of Commerce',
    'United Bank of India',
    'Allahabad Bank',
    'Indian Bank',
    'UCO Bank',
    'Canara Bank',
    'Union Bank of India',
    'Bank of Baroda',
    'Andhra Bank',
    'Corporation Bank',
    'Dena Bank',
    'IDBI Bank',
    'Indian Bank',
  ],
};

export const bankOptions: Props[] =
  internationalBanksData.internationalBanks.map((bank) => ({
    value: bank,
    label: bank,
  }));
