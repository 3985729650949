import React from 'react';
import {
  Input,
  Container,
  Box,
  Text,
  Button,
  Checkbox,
} from '@chakra-ui/react';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { MdEmail, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { RiLockPasswordFill } from 'react-icons/ri';

function AccountantSignupLink() {
  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        h="auto"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        p="0px 22px 0px 22px"
        borderRadius="20px"
      >
        <Box>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={30}
            pt="20px"
            mb={5}
          >
            Accountant Signup Link
          </Text>
        </Box>

        <Box>
          <Text
            fontWeight="bold"
            color="#114684"
            display="flex"
            justifyContent="start"
            textAlign="center"
            fontSize={16}
          >
            Company Name
          </Text>

          <Box display="flex" mb={['20px', '5px']} mt={2}>
            <Box
              mr={2}
              fontSize={25}
              color="#114684"
              m={0}
              border="1.5px solid #0000001a"
              borderRadius="5px 0px 0px 5px"
            >
              <MdDriveFileRenameOutline
                style={{
                  marginTop: '7px',
                  marginLeft: '6px',
                  marginRight: '6px',
                }}
              />
            </Box>
            <Input
              fontSize={18}
              w="100%"
              type="name"
              placeholder=" Company Name"
              name="userData.companyname"
              borderRadius="0px 5px 5px 0px"
            />
          </Box>

          <Text
            fontWeight="bold"
            color="#114684"
            display="flex"
            justifyContent="start"
            textAlign="center"
            fontSize={16}
          >
            Email Address
          </Text>

          <Box display="flex" mb={['20px', '5px']} mt={2}>
            <Box
              mr={2}
              fontSize={25}
              color="#114684"
              m={0}
              border="1.5px solid #0000001a"
              borderRadius="5px 0px 0px 5px"
            >
              <MdEmail
                style={{
                  marginTop: '7px',
                  marginLeft: '6px',
                  marginRight: '6px',
                }}
              />
            </Box>
            <Input
              fontSize={18}
              w="100%"
              type="Email"
              placeholder="Email Address"
              name="userData.Email  "
              borderRadius="0px 5px 5px 0px"
            />
          </Box>
          <Text
            fontWeight="bold"
            color="#114684"
            display="flex"
            justifyContent="start"
            textAlign="center"
            fontSize={16}
          >
            Contact number
          </Text>

          <Box display="flex" mb={['20px', '5px']} mt={2}>
            <Box
              mr={2}
              fontSize={25}
              color="#114684"
              m={0}
              border="1.5px solid #0000001a"
              borderRadius="5px 0px 0px 5px"
            >
              <MdDriveFileRenameOutline
                style={{
                  marginTop: '7px',
                  marginLeft: '6px',
                  marginRight: '6px',
                }}
              />
            </Box>
            <Input
              fontSize={18}
              w="100%"
              type="name"
              placeholder="Contact number"
              name="userData.companyname"
              borderRadius="0px 5px 5px 0px"
            />
          </Box>

          <Text
            fontWeight="bold"
            color="#114684"
            display="flex"
            justifyContent="start"
            textAlign="center"
            fontSize={16}
          >
            Password
          </Text>

          <Box display="flex" mb={['20px', '5px']} mt={2}>
            <Box
              mr={2}
              fontSize={25}
              color="#114684"
              m={0}
              border="1.5px solid #0000001a"
              borderRadius="5px 0px 0px 5px"
            >
              <RiLockPasswordFill
                style={{
                  marginTop: '7px',
                  marginLeft: '6px',
                  marginRight: '6px',
                }}
              />
            </Box>
            <Input
              fontSize={18}
              w="100%"
              type="Password"
              placeholder="Password"
              borderRadius="0px 5px 5px 0px"
              name="userData.Password  "
            />
          </Box>
          <Text
            fontWeight="bold"
            color="#114684"
            display="flex"
            justifyContent="start"
            textAlign="center"
            fontSize={16}
          >
            Confirm Password
          </Text>

          <Box display="flex" mb={['20px', '5px']} mt={2}>
            <Box
              mr={2}
              fontSize={25}
              color="#114684"
              m={0}
              border="1.5px solid #0000001a"
              borderRadius="5px 0px 0px 5px"
            >
              <RiLockPasswordFill
                style={{
                  marginTop: '7px',
                  marginLeft: '6px',
                  marginRight: '6px',
                }}
              />
            </Box>
            <Input
              fontSize={18}
              w="100%"
              type="password"
              borderRadius="0px 5px 5px 0px"
              placeholder="Confirm  Password"
              name="userData.Reset Password  "
            />
          </Box>
          <Box display="flex" justifyContent="start" mb={5} mt={5}>
            <Checkbox
              name="userData.termCondition"
              color="#fbb03b"
              borderColor="#fbb03b"
              colorScheme="yellow"
            >
              I agree to Terms and Conditions
            </Checkbox>
          </Box>

          <Button
            type="submit"
            bg="#114684"
            color="white"
            w="100%"
            mb={5}
            _hover={{ bg: '#114684', color: 'black' }}
          >
            Sign up
          </Button>
        </Box>
      </Container>
    </>
  );
}

export default AccountantSignupLink;
