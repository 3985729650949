import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';

interface HistoryState {
  user: any;
  loading: boolean;
  error: null;
}

const initialState: HistoryState = {
  user: null,
  loading: false,
  error: null,
};

export const HisotryDashboard = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

export const getApplicantHistory = createAsyncThunk(
  'BrokerageDashboard/getApplicantHistory',
  async ({
    applicationId,
    modelName,
  }: {
    applicationId: number | null;
    modelName: string | null;
  }) => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/history/list/?applicationId=${applicationId}&modelName=${modelName}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

const HisotrySlice = createSlice({
  name: 'HisotryDashboard',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      // applicant brokerage all data
      .addCase(getApplicantHistory.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        getApplicantHistory.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(getApplicantHistory.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      });
  },
});

export default HisotrySlice.reducer;
