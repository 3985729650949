export const getAccordianSectionName: { [key: string]: string } = {
  guaranteeDetails: 'Guarantee Details',
  impactOnFinancialHealth: 'Impact on Financial Health',
  legalAndRegulatoryCompliance: 'Legal and Regulatory Compliance',
  globalUltimate: 'Global Ultimate',
  domesticUltimate: 'Domestic Ultimate',
  parent: 'Parent',
};

export const getFormikStateOfAccordianState: { [key: string]: string } = {
  receivershipOrBankruptcyAcc: 'hasBankruptcyHistory',
  guaranteesForOtherPartiesAcc: 'hasGuarantees',
  relatedCompaniesAcc: 'hasRelatedCompanies',
  changesInManagementAcc: 'hasChangesInManagement',
  bondClaimsAcc: 'hasClaimsSuretybond',
  buySellAgreementAcc: 'hasBuySellAgreement',
  // outstandingJudgmentsAcc
};

export function getHistoryDataOfAccordianState(data: any, key: string) {
  // Check if the key exists in the data object
  if (data && data?.[key]) {
    return data[key];
  } else {
    return null;
  }
}
