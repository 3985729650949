import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import initialState, { IUploadReportsData } from './state';
import callAPI from '../../utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Reports/WorkInProgress/slice';

export const uploadReports = createAsyncThunk(
  'uploadReports/uploadReports',
  async (
    payload: Pick<
      IUploadReportsData,
      'applicationId' | 'reportInfo' | 'reportLinks'
    >,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('report/upload', 'POST', payload);
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const updateReports = createAsyncThunk(
  'uploadReports/updateReports',
  async (
    payload: Pick<IUploadReportsData, 'id' | 'reportLinks'>,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('report/update', 'PUT', payload);
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const listAllReports = createAsyncThunk(
  'uploadReports/listAllReports',
  async ({ applicationId }: { applicationId: number }, { rejectWithValue }) => {
    try {
      const payload = {
        applicationId,
      };
      const response = await callAPI(`report/list`, 'POST', payload);

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const deleteReport = createAsyncThunk(
  'uploadReports/deleteReport',
  async ({ reportID }: { reportID: number }, { rejectWithValue }) => {
    try {
      const response = await callAPI(`report/delete/${reportID}`, 'DELETE');
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const uploadReportsStore = createSlice({
  name: 'uploadReports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(uploadReports.pending, (state, action) => {
        state.type = 'POST_UPLOAD_REPORTS';
        state.status = 'loading';
      })
      .addCase(uploadReports.fulfilled, (state, action) => {
        state.type = 'POST_UPLOAD_REPORTS';
        state.status = 'succeed';
        state.uploadReportsData = action.payload;
      })
      .addCase(uploadReports.rejected, (state, action) => {
        state.type = 'POST_UPLOAD_REPORTS';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(listAllReports.pending, (state, action) => {
        state.type = 'GET_APPLICANT_REPORTS';
        state.status = 'loading';
      })
      .addCase(listAllReports.fulfilled, (state, action) => {
        state.type = 'GET_APPLICANT_REPORTS';
        state.status = 'succeed';
        state.applicantReportsData = action.payload.data;
      })
      .addCase(listAllReports.rejected, (state, action) => {
        state.type = 'GET_APPLICANT_REPORTS';
        state.status = 'failed';
        state.applicantReportsData = null;
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(deleteReport.pending, (state, action) => {
        state.type = 'DELETE_REPORT';
        state.status = 'loading';
      })
      .addCase(deleteReport.fulfilled, (state, action) => {
        state.type = 'DELETE_REPORT';
        state.status = 'succeed';

        state.applicantReportsData = action.payload.data;
      })
      .addCase(deleteReport.rejected, (state, action) => {
        state.type = 'DELETE_REPORT';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      })
      .addCase(updateReports.pending, (state, action) => {
        state.type = 'UPDATE_REPORT';
        state.status = 'loading';
      })
      .addCase(updateReports.fulfilled, (state, action) => {
        state.type = 'UPDATE_REPORT';
        state.status = 'succeed';

        // state.applicantReportsData = action.payload.data;
      })
      .addCase(updateReports.rejected, (state, action) => {
        state.type = 'UPDATE_REPORT';
        state.status = 'failed';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const uploadReportsSlice = uploadReportsStore.reducer;

export default uploadReportsSlice;
