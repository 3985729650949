import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Select,
  Stack,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import {
  FaArrowLeft,
  FaCreditCard,
  FaGooglePay,
  FaMoneyBill,
  FaPaypal,
  FaPhone,
  FaRegMoneyBillAlt,
} from 'react-icons/fa';
import { RiBankLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import { bankOptions } from 'src/data/bankOptions';

const PaymentForm = ({ onBack }: { onBack: () => void }) => {
  const [paymentMethod, setPaymentMethod] = useState<string>('creditCard');
  const [cardNumber, setCardNumber] = useState<string>('');
  const [expiryDate, setExpiryDate] = useState<string>('');
  const [cvv, setCVV] = useState<string>('');
  const [bankName, setBankName] = useState<string>('');
  const [name, setName] = useState<string>('');
  const [accountNumber, setAccountNumber] = useState<string>('');
  const [ifscNumber, setIfscNumber] = useState<string>('');
  const [onlineBankingMethod, setOnlineBankingMethod] = useState<string>('');
  const navigate = useNavigate();
  const handlePaymentMethodChange = (method: string) => {
    setPaymentMethod(method);
  };
  const handleOnlineBankingMethodChange = (method: string) => {
    setOnlineBankingMethod(method);
  };
  const handleGoBack = () => {
    navigate('/brokerage/plans');
  };
  const handleSubmit = () => {
    console.log('Submitting payment details:', {
      paymentMethod,
      cardNumber,
      expiryDate,
      cvv,
      bankName,
      name,
      accountNumber,
      ifscNumber,
    });
    // Add logic to submit payment details
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius={'xl'}
      p={6}
      boxShadow="lg"
      bg={'white'}
      flex={1}
      shadow="base"
      borderColor={'gray.300'}
    >
      <VStack spacing={4} align="start">
        <Box
          width="full"
          display="flex"
          justifyContent="flex-start"
          gap={2}
          justifyItems={'center'}
          alignItems={'center'}
        >
          <IconButton
            icon={<FaArrowLeft />}
            aria-label="Go Back"
            onClick={handleGoBack}
            //backgroundColor={'rgb(255, 160, 17)'}
          />
          <FormLabel as={'h1'} fontSize={18} fontWeight={600} m={0}>
            Payment Method
          </FormLabel>
        </Box>

        <Divider borderColor={'gray.700'} />
        <FormControl id="paymentMethod">
          <Stack direction="row" spacing={10}>
            <PaymentMethodButton
              icon={<FaCreditCard size={35} />}
              label="Credit Card"
              value="creditCard"
              selected={paymentMethod === 'creditCard'}
              onClick={() => handlePaymentMethodChange('creditCard')}
            />
            <PaymentMethodButton
              icon={<FaMoneyBill size={35} />}
              label="Debit Card"
              value="debitCard"
              selected={paymentMethod === 'debitCard'}
              onClick={() => handlePaymentMethodChange('debitCard')}
            />
            <PaymentMethodButton
              icon={<RiBankLine size={35} />}
              label="Bank Transfer"
              value="bankTransfer"
              selected={paymentMethod === 'bankTransfer'}
              onClick={() => handlePaymentMethodChange('bankTransfer')}
            />
            <PaymentMethodButton
              icon={<FaRegMoneyBillAlt size={35} />}
              label="Online Banking"
              value="onlineBanking"
              selected={paymentMethod === 'onlineBanking'}
              onClick={() => handlePaymentMethodChange('onlineBanking')}
            />
          </Stack>
        </FormControl>

        {/* Display payment form fields based on selected method */}
        {paymentMethod === 'creditCard' && (
          <>
            <PaymentDetails
              label="Credit Card Number"
              placeholder="1234 5678 9101 1121"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
            <PaymentDetails
              label="Expiry Date"
              placeholder="MM/YY"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
            />
            <PaymentDetails
              label="CVV"
              placeholder="123"
              value={cvv}
              onChange={(e) => setCVV(e.target.value)}
            />
          </>
        )}

        {paymentMethod === 'debitCard' && (
          <>
            <PaymentDetails
              label="Debit Card Number"
              placeholder="1234 5678 9101 1121"
              value={cardNumber}
              onChange={(e) => setCardNumber(e.target.value)}
            />
            <PaymentDetails
              label="Expiry Date"
              placeholder="MM/YY"
              value={expiryDate}
              onChange={(e) => setExpiryDate(e.target.value)}
            />
            <PaymentDetails
              label="CVV"
              placeholder="123"
              value={cvv}
              onChange={(e) => setCVV(e.target.value)}
            />
          </>
        )}

        {paymentMethod === 'bankTransfer' && (
          <>
            <FormControl id="name">
              <FormLabel>Name</FormLabel>
              <Input
                type="text"
                placeholder="Enter your name"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </FormControl>
            <FormControl id="accountNumber">
              <FormLabel>Account Number</FormLabel>
              <Input
                type="text"
                placeholder="Enter your account number"
                value={accountNumber}
                onChange={(e) => setAccountNumber(e.target.value)}
              />
            </FormControl>
            <FormControl id="ifscNumber">
              <FormLabel>IFSC Number</FormLabel>
              <Input
                type="text"
                placeholder="Enter IFSC number"
                value={ifscNumber}
                onChange={(e) => setIfscNumber(e.target.value)}
              />
            </FormControl>
            <FormControl id="bankName">
              <FormLabel>Bank Name</FormLabel>
              <Select
                placeholder="Select bank"
                value={bankName}
                onChange={(e) => setBankName(e.target.value)}
              >
                {bankOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </Select>
            </FormControl>
          </>
        )}
        {paymentMethod === 'onlineBanking' && (
          <>
            <PaymentMethodButton
              icon={<FaPaypal size={20} />}
              label="Paytm"
              value="paytm"
              selected={onlineBankingMethod === 'paytm'}
              onClick={() => handleOnlineBankingMethodChange('paytm')}
            />
            <PaymentMethodButton
              icon={<FaGooglePay size={20} />}
              label="Google Pay"
              value="googlePay"
              selected={onlineBankingMethod === 'googlePay'}
              onClick={() => handleOnlineBankingMethodChange('googlePay')}
            />
            <PaymentMethodButton
              icon={<FaPhone size={20} />}
              label="PhonePe"
              value="phonePe"
              selected={onlineBankingMethod === 'phonePe'}
              onClick={() => handleOnlineBankingMethodChange('phonePe')}
            />
          </>
        )}

        <Button
          backgroundColor="#114684"
          textColor={'white'}
          w="full"
          onClick={handleSubmit}
        >
          Submit Payment
        </Button>
      </VStack>
    </Box>
  );
};

const PaymentMethodButton = ({
  icon,
  label,
  value,
  selected,
  onClick,
}: {
  icon: React.ReactElement;
  label: string;
  value: string;
  selected: boolean;
  onClick: () => void;
}) => {
  return (
    <IconButton
      icon={icon}
      aria-label={label}
      variant={selected ? 'solid' : 'outline'}
      backgroundColor={selected ? 'brand.blue' : 'white'}
      color={selected ? 'white' : 'black'}
      onClick={onClick}
      p={2}
      w={12}
      h={12}
      overflow={'hidden'}
      _hover={{
        color: 'white',
        bg: 'brand.blue',
      }}
      scale={1}
    />
  );
};

const PaymentDetails = ({
  label,
  placeholder,
  value,
  onChange,
}: {
  label: string;
  placeholder: string;
  value: string;
  onChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => void;
}) => {
  return (
    <FormControl id={label.toLowerCase().replace(/\s/g, '')}>
      <FormLabel>{label}</FormLabel>
      <Input
        type="text"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default PaymentForm;
