import {
  MultiSelectStylesParams,
  Select,
  SelectItem,
  SelectProps,
  SelectStylesNames,
  Styles,
} from '@mantine/core';
import { SelectSharedProps } from '@mantine/core/lib/Select/Select';
import { BaseSelectStylesNames } from '@mantine/core/lib/Select/types';
import React from 'react';

interface Props extends SelectProps {
  value: string;
  onChange: (value: string) => void;
  searchable?: boolean;
  readOnly?: boolean;
  placeholder: string;
  styles: Styles<BaseSelectStylesNames, Record<string, any>> | undefined;
}

const AppSelect = ({
  value,
  onChange,
  searchable = false,
  readOnly = false,
  placeholder = '',
  styles,
  ...rest
}: Props) => {
  return (
    <Select
      placeholder={placeholder}
      clearable
      value={value}
      onChange={onChange}
      searchable={searchable}
      readOnly={readOnly}
      styles={{
        ...styles,
        input: {
          width: '100%',
          height: '100%',
          fontSize: '13px',
          borderRadius: '5px',
          borderColor: '#7f7f7f',
          _hover: { borderColor: '#7f7f7f' },
          readOnly: readOnly,
        },
        dropdown: {
          border: '1px solid #7f7f7f',
        },
        item: {
          ':hover': {
            background: '#114684',
            color: '#ffffff',
          },
        },
      }}
      {...rest}
    />
  );
};

export default AppSelect;
