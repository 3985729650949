import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASEURL } from '../../App';
import { tokenExipry } from '../tokenExpiry';

interface notificationState {
  user: any;
  applicationMeta: Record<string, any>;
  loading: boolean;
  error: null;
}

const initialState: notificationState = {
  user: [],
  applicationMeta: {},
  loading: false,
  error: null,
};

//cms id threw get
export const fetchSocketData = createAsyncThunk(
  'notificationFormState/fetchSocketData',
  async () => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/notification/list`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

// read notification
export const readNotificationData = createAsyncThunk(
  'notificationFormState/readNotification',
  async (
    { notificationId, allRead }: { notificationId?: number; allRead: boolean },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.put(
          `${BASEURL}/notification/read`,
          { notificationId, allRead },
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const fetchDashboardDetails = createAsyncThunk(
  'notificationFormState/fetchDashboardDetails',
  async ({ applicationFilter }: { applicationFilter: string | null }) => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/application/listApplications?applicationFilter=${applicationFilter}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios.request(config);

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

export const fetchApplicationMetaData = createAsyncThunk(
  'notificationFormState/fetchApplicationMetaData',
  async ({ applicantId }: { applicantId: string | number }) => {
    try {
      const Token = localStorage.getItem('Token');

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `${BASEURL}/application/consent-status?applicantId=${applicantId}`,
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };

      try {
        const response = await axios.request(config);

        return { data: response.data, applicantId: applicantId };
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error) {
      throw error;
    }
  }
);

const notificationFormStateSlice = createSlice({
  name: 'notificationFormState',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSocketData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchSocketData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchSocketData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // dashboard state
      .addCase(fetchDashboardDetails.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchDashboardDetails.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(fetchDashboardDetails.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      // read notification state
      .addCase(readNotificationData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        readNotificationData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(readNotificationData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })

      // fetch Application Meta Data
      .addCase(fetchApplicationMetaData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchApplicationMetaData.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.applicationMeta =
            {
              ...state.applicationMeta,
              [action.payload?.applicantId]: action.payload?.data?.data || {},
            } || {};
        }
      )
      .addCase(fetchApplicationMetaData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      });
  },
});

export default notificationFormStateSlice.reducer;
