import { Box } from '@chakra-ui/react';

interface ApplicationTableActionProps {
  icons?: {
    view?: boolean;
    edit?: boolean;
    history?: boolean;
    document?: boolean;
  };
}

const ApplicationTableAction = ({
  icons = { edit: true, document: false, history: true, view: true },
}: ApplicationTableActionProps) => {
  const { view = true, edit = true, history = true, document = false } = icons;

  return (
    <Box
      display={'flex'}
      justifyContent={'end'}
      gap={[4, 4, 5, 5, 5]}
      w={'100%'}
      color={'white'}
      fontWeight={500}
      fontSize={[10, 13, 13, 13, 13]}
      width={'40%'}
    >
      {document && (
        <Box>
          <Box>Document</Box>
        </Box>
      )}
      {view && (
        <Box>
          <Box>View</Box>
        </Box>
      )}
      {edit && (
        <Box>
          <Box>Edit</Box>
        </Box>
      )}
      {history && (
        <Box>
          <Box>History</Box>
        </Box>
      )}
    </Box>
  );
};

export default ApplicationTableAction;
