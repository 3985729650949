import React from 'react';
import { Container, Box, Text, Button, useToast } from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { AccountantById } from '../../../Redux/Applicant/Qbo/AccountantQbo';

function QboConnect() {
  const dispatch = useDispatch();
  const Toast = useToast();
  const urlParams = new URLSearchParams(window.location.search);

  const id = urlParams.get('userId');
  const userId = parseInt(id!, 10);

  const HandelLogin = async () => {
    {
      let res = '';
      try {
        const actionResult: any = await dispatch(AccountantById(userId) as any);
        const response = actionResult?.payload;
        window.open(response?.data);

        res = response?.msg;
        Toast({
          title: response.msg,
          status: 'success',
          isClosable: true,
          duration: 3000,
          position: 'top-right',
        });
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          Toast({
            title: '404',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
    }
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      p={2}
    >
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        p="0px 22px 0px 22px"
        borderRadius="20px"
      >
        <Box>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={30}
            pt="20px"
          >
            Connect to QBO
          </Text>
        </Box>

        <Box mt="20px" mb={10}>
          <Button
            type="submit"
            bg="#114684"
            color="white"
            w="100%"
            _hover={{ bg: '#114684', color: 'white' }}
            onClick={HandelLogin}
          >
            Connect to QBO
          </Button>
        </Box>
      </Container>
    </Box>
  );
}

export default QboConnect;
