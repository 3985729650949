import React from 'react';
import { Container, Box, Text, Flex, Button, Input } from '@chakra-ui/react';
import { MdDriveFileRenameOutline } from 'react-icons/md';
import { MdEmail } from 'react-icons/md';

function ApplicantAccountant() {
  return (
    <>
      <Container
        textAlign="center"
        boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
        w={['96%', '96%', 'auto  ']}
        marginTop={10}
        marginBottom="auto"
        p="0px 22px 0px 22px"
        borderRadius="20px"
      >
        <Box>
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={30}
            pt="20px"
            mb={5}
          >
            Links For Accountant
          </Text>
        </Box>
        <Text
          fontWeight="bold"
          color="#114684"
          display="flex"
          justifyContent="start"
          textAlign="center"
          fontSize={16}
        >
          Name
        </Text>

        <Box display="flex" mb={['20px', '5px']} mt={2}>
          <Box
            mr={2}
            fontSize={25}
            color="#114684"
            m={0}
            border="1.5px solid #0000001a"
            borderRadius="5px 0px 0px 5px"
          >
            <MdDriveFileRenameOutline
              style={{
                marginTop: '7px',
                marginLeft: '6px',
                marginRight: '6px',
              }}
            />
          </Box>
          <Input
            fontSize={18}
            w="100%"
            type="name"
            placeholder=" Name"
            name="userData.companyname"
            borderRadius="0px 5px 5px 0px"
          />
        </Box>

        <Text
          fontWeight="bold"
          color="#114684"
          display="flex"
          justifyContent="start"
          textAlign="center"
          fontSize={16}
        >
          Email Address
        </Text>

        <Box display="flex" mb={['20px', '5px']} mt={2}>
          <Box
            mr={2}
            fontSize={25}
            color="#114684"
            m={0}
            border="1.5px solid #0000001a"
            borderRadius="5px 0px 0px 5px"
          >
            <MdEmail
              style={{
                marginTop: '7px',
                marginLeft: '6px',
                marginRight: '6px',
              }}
            />
          </Box>
          <Input
            fontSize={18}
            w="100%"
            type="Email"
            placeholder="Email Address"
            name="userData.Email  "
            borderRadius="0px 5px 5px 0px"
          />
        </Box>
        <Box mt="20px" mb={10}>
          <Button
            type="submit"
            bg="#114684"
            color="white"
            w="100%"
            _hover={{ bg: '#114684', color: 'white' }}
          >
            Send Invitation
          </Button>
        </Box>

        <Flex justifyContent="space-evenly" columnGap={5}>
          <Box mt="20px" w="50%" mb={10}>
            <Button
              type="submit"
              bg="#114684"
              color="white"
              w="100%"
              _hover={{ bg: '#114684', color: 'white' }}
            >
              Cancel
            </Button>
          </Box>
          <Box mt="20px" w="50%">
            <Button
              type="submit"
              bg="#114684"
              color="white"
              w="100%"
              _hover={{ bg: '#114684', color: 'white' }}
            >
              Save
            </Button>
          </Box>
        </Flex>
      </Container>
    </>
  );
}

export default ApplicantAccountant;
