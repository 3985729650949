import { Box, Text } from '@chakra-ui/react';
import { DatePickerInput } from 'rc-datepicker';
import AccordianSectionFields from './AccordianSectionFields';

const GuaranteesForOtherPartiesAccordian = ({
  formik,
  AccordianSection,
  accordianSectionName,
  isOpen,
  accordianState,
  accordianName,
  fileList,
  setFileList,
  handleRemoveFile,
  isHistoryMode,
  isReadOnly,
  currentHistoryData,
}: {
  formik: Record<string, any>;
  AccordianSection: {
    name: string;
    fields: {
      label: string;
      name: string;
      type?: string;
      uploadState?: string;
      refState?: React.RefObject<DatePickerInput>;
    }[];
    sectionNo: number;
  }[];
  accordianSectionName: string;
  isOpen: boolean;
  accordianState: any[];
  accordianName: string;
  fileList?: Record<string, any>;
  setFileList?: React.Dispatch<React.SetStateAction<Record<string, any>>>;
  handleRemoveFile?: (
    arg1: {
      link: string;
      linkId: string;
      urlKey: string;
    },
    arg2: string,
    arg3: string
  ) => void;
  isHistoryMode?: boolean;
  isReadOnly?: boolean;
  currentHistoryData?: Record<string, any>;
}) => {
  return AccordianSection?.map((item: any, index: number) => (
    <Box>
      {!isOpen && (
        <Text
          fontWeight={'600'}
          size={'18px'}
          mb={'8px'}
          mt={index !== 0 ? '10px' : '0'}
        >
          {item?.sectionNo}. {item?.name}:
        </Text>
      )}
      <Box
        display={'grid'}
        gridTemplateColumns={{
          base: 'repeat(1,1fr)',
          md: `${!isOpen ? `repeat(auto-fill, minmax(${item?.fields?.length > 2 ? '270px' : '320px'}, 1fr))` : 'repeat(1,1fr)'}`,
        }}
        gap={{ base: 1, md: !isOpen ? 5 : 1 }}
      >
        <AccordianSectionFields
          formik={formik}
          AccordianFields={item?.fields}
          accordianSectionName={accordianSectionName}
          isOpen={isOpen}
          accordianState={accordianState}
          accordianName={accordianName}
          fileList={fileList}
          handleRemoveFile={handleRemoveFile}
          isHistoryMode={isHistoryMode}
          isReadOnly={isReadOnly}
          currentHistoryData={currentHistoryData}
        />
      </Box>
    </Box>
  ));
};

export default GuaranteesForOtherPartiesAccordian;
