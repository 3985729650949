import { Box, Flex, Spinner, Text, Tooltip } from '@chakra-ui/react';

import { useDisclosure } from '@mantine/hooks';
import { useEffect, useState } from 'react';
import { FaHistory } from 'react-icons/fa';
import { IoEyeSharp } from 'react-icons/io5';
import { RiChatHistoryLine } from 'react-icons/ri';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ApplicationTableAction from 'src/Component/Common/TableActions/Header';
import PdfContent from 'src/Pages/Reports/components/PdfModal';
import { useApplicationNavigation } from 'src/hooks/useApplicationNavigation';
import { fetchDashboardDetails } from '../../../../Redux/Notification/Notification';
import { RootState } from '../../../../Redux/Store';
import GlobalHistoryModal from './components/GlobalHistoryModal';

function DeclinedBroker() {
  const [opened, { open, close }] = useDisclosure(false);
  const { handelHistory, handelView } = useApplicationNavigation();
  const navigate = useNavigate();
  const userData = useSelector((state: RootState) => state.notification.user);
  const data = userData && userData?.data?.applications;
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [applicationDetails, setApplicationDetails] = useState<Record<
    string,
    any
  > | null>(null);
  const { status } = location.state || {};

  const dispatch = useDispatch();
  const userType = localStorage.getItem('userType');

  useEffect(() => {
    const fetchData = async () => {
      if (!!localStorage.getItem('Token')) {
        let actionResult = await dispatch(
          fetchDashboardDetails({
            applicationFilter: status || 'Declined by Underwriter',
          }) as any
        );
        if (actionResult?.payload?.status === 401) {
          navigate('/login');
        }
        setIsLoading(false);
      } else {
        navigate('/login');
      }
    };
    fetchData();
  }, []);

  // const handelView = (applicantId: number) => {
  //   navigate(
  //     `${userType === 'broker' ? '/assign-broker' : '/assign-applicant'}/create-application/${applicantId}`,
  //     {
  //       state: { showBox: true, applicationStatus: status },
  //     }
  //   );
  // };

  // const OnHandelHistory = (applicationId: number, applicantId: number) => {
  //   navigate(
  //     `${userType === 'broker' ? '/assign-broker' : '/assign-applicant'}/create-application/${applicationId}`,
  //     {
  //       state: {
  //         showHistory: true,
  //         showBox: true,
  //         applicationStatus: status,
  //         applicantId: applicantId,
  //       },
  //     }
  //   );
  // };

  const getBrokerDetails = (broker: any) => {
    let brokerData = {
      firstName: broker?.firstName,
      lastName: broker?.lastName,
      contactNumber: broker?.contactNumber,
      brokerageName: broker?.brokerage?.legalName,
      email: broker?.email,
    };
    return brokerData;
  };

  const getFormData = (user: any) => {
    let formData = {
      accountingSystem: user?.accountingSystem,
      creditBureau: user?.creditBureau,
      financialInfo: user?.financialInfo,
      cms: user?.cms,
      surety: user?.surety,
      insurance: user?.insurance,
    };
    return formData;
  };

  return (
    <>
      {isLoading ? (
        <Flex h="20vh" alignItems={'center'} justifyContent={'center'}>
          <Spinner size="xl" />
        </Flex>
      ) : (
        <>
          <Box display={'flex'} flexDirection={'column'} gap={2} mt={2}>
            <Box pl={[2, 2, 4, 4, 4]} pr={[2, 2, 4, 4, 4]}>
              <Box
                bg="#114684"
                display={'flex'}
                justifyContent={'space-between'}
                h={'auto'}
                borderRadius={3}
                alignItems={'center'}
                p={2}
                gap={[1, 1, 2, 2, 2]}
              >
                <Box
                  display={'flex'}
                  justifyContent={'space-between'}
                  w={'100%'}
                  color={'white'}
                  fontWeight={500}
                  fontSize={[10, 13, 13, 13, 13]}
                  gap={1}
                  width={'100%'}
                >
                  <Box width={'40%'}>
                    <Text textAlign={'start'}>Application Code</Text>
                  </Box>
                  <Box width={'40%'}>
                    <Text textAlign={'start'}>Applicant Name</Text>
                  </Box>
                  <Box width={'40%'}>
                    <Text textAlign={'start'}>Broker Name</Text>
                  </Box>
                </Box>
                <ApplicationTableAction
                  icons={{
                    document: true,
                    edit: false,
                  }}
                />
              </Box>
            </Box>
            {Array.isArray(data) && data?.length > 0 ? (
              data?.map((user: any, index: number) => (
                <>
                  <Box pl={[2, 2, 4, 4, 4]} pr={[2, 2, 4, 4, 4]}>
                    <Box
                      bg="#ffa011"
                      display={'flex'}
                      justifyContent={'space-between'}
                      gap={2}
                      // h={10}
                      h={'auto'}
                      borderRadius={3}
                      alignItems={'center'}
                      p={2}
                    >
                      <Box
                        display={'flex'}
                        justifyContent={'space-between'}
                        w={'100%'}
                        color={'black'}
                        fontWeight={500}
                        fontSize={13}
                        width={'100%'}
                        gap={[3, 3, 0, 0, 0]}
                      >
                        <Box width={'40%'}>
                          <Text textAlign={'start'}>
                            {user.applicationName}
                          </Text>
                        </Box>
                        <Box width={'40%'}>
                          <Text textAlign={'start'}>
                            {user?.applicant?.companyName}
                          </Text>
                        </Box>
                        <Box width={'40%'}>
                          <Text textAlign={'start'}>
                            {user?.broker?.firstName} {user?.broker?.lastName}
                          </Text>
                        </Box>
                      </Box>
                      <Box
                        display={'flex'}
                        justifyContent={'end'}
                        gap={[4, 4, 5, 5, 5]}
                        w={'100%'}
                        color={'black'}
                        fontSize={13}
                        width={'40%'}
                      >
                        <Box cursor={'pointer'} _hover={{ color: '#114684' }}>
                          <PdfContent
                            applicationId={user?.applicationId}
                            companyName={user?.accountingSystem?.legalName}
                            brokerInfo={getBrokerDetails(user?.broker)}
                            allFormData={getFormData(user)}
                            qboCompanyName={
                              user?.autoPopulateStatus?.qboCompanyName
                            }
                            user={user}
                          />
                        </Box>
                        <Box
                          cursor={'pointer'}
                          _hover={{ color: '#114684' }}
                          onClick={() =>
                            handelView({ applicationId: user.applicationId })
                          }
                        >
                          <IoEyeSharp size={20} />
                        </Box>
                        <Flex gap={2} flexWrap={'nowrap'}>
                          <Box
                            cursor={'pointer'}
                            _hover={{ color: '#114684' }}
                            onClick={() => {
                              setApplicationDetails({
                                applicationCode: user?.applicationName,
                                applicantName: user?.applicant?.companyName,
                                brokerName: `${user?.broker?.firstName} ${user?.broker?.lastName}`,
                                applicationId: user.applicationId,
                              });
                              open();
                            }}
                          >
                            <Tooltip
                              fontSize="md"
                              label={'Global History'}
                              placement={'auto'}
                              hasArrow
                            >
                              <Box>
                                <RiChatHistoryLine size={20} />
                              </Box>
                            </Tooltip>
                          </Box>
                          <Box
                            cursor={'pointer'}
                            _hover={{ color: '#114684' }}
                            onClick={() =>
                              handelHistory({
                                applicationId: user.applicationId,
                                applicantId: user.applicantId,
                              })
                            }
                          >
                            <Tooltip
                              fontSize="md"
                              label={'History'}
                              placement={'auto'}
                              hasArrow
                            >
                              <Box>
                                <FaHistory size={18} />
                              </Box>
                            </Tooltip>
                          </Box>
                        </Flex>
                      </Box>
                    </Box>
                  </Box>
                </>
              ))
            ) : (
              <>
                <Box pl={4} pr={4}>
                  <Box
                    bg="#ffa011"
                    display={'flex'}
                    justifyContent={'space-between'}
                    gap={2}
                    h={10}
                    borderRadius={3}
                    alignItems={'center'}
                    p={2}
                  >
                    <Box
                      display={'flex'}
                      justifyContent={'space-between'}
                      w={'100%'}
                      color={'black'}
                      fontWeight={500}
                      fontSize={15}
                    >
                      <Box width={'100%'}>
                        <Text textAlign={'center'}>Not found</Text>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            )}
          </Box>
          <GlobalHistoryModal
            isOpen={opened}
            onClose={close}
            applicationDetails={applicationDetails}
          />
        </>
      )}
    </>
  );
}

export default DeclinedBroker;
