import { useEffect, useState } from 'react';
import JSZip from 'jszip';
import axios from 'axios'; // You might need axios or another library for downloading files
import { getFileNameFromURL } from 'src/utils/helpers';

const getUrlExtension = (url: string) => {
  return url.split(/[#?]/)[0].split('.').pop()?.trim();
};

type Props = {
  links: string[];
  zipName?: string;
};

const useZipFromLinks = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const createZipFromLinks = async ({ links, zipName = 'files' }: Props) => {
    setLoading(true);
    setError(null);

    const zip = new JSZip();

    // Download files from each link and add them to the zip

    await Promise.all(
      links.map(async (link, index) => {
        try {
          const response = await axios.get(link, {
            responseType: 'arraybuffer',
          }); // Adjust request as per file type and origin
          console.log('response', response);
          const extension = getUrlExtension(link);
          const name = getFileNameFromURL(link);
          zip.file(`${name || `file` + index}.${extension}`, response.data); // Adjust file name and extension
        } catch (error: any) {
          console.error(`Error downloading file from ${link}:`, error);
          // Handle errors as per your requirement (e.g., skip the file, show an error message)
          setError(`Error downloading file from ${link}: ${error.message}`);
        }
      })
    );

    // Generate the zip content
    const zipContent = await zip.generateAsync({ type: 'blob' });

    // Create a download link for the zip file
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(zipContent);
    downloadLink.download = `${zipName}.zip`;
    downloadLink.click();

    setLoading(false);
  };

  return { createZipFromLinks, loading, error };
};

export default useZipFromLinks;
