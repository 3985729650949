import {
  Box,
  Button,
  HStack,
  List,
  ListIcon,
  ListItem,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import { IPlanBase } from 'src/data/plans';
import PriceWrapper from './PriceWrapper';
import { getCurrencyDigit } from 'src/utils/helpers';

interface Props {
  plan: IPlanBase;
}

const PlanCard = ({ plan }: Props) => {
  const colorMode = useColorModeValue('gray.50', 'gray.700');
  const textColor = useColorModeValue('gray.900', 'gray.300');
  const navigate = useNavigate();

  const handleBuyNow = () => {
    navigate('/brokerage/checkout', { state: { plan } });
  };

  return (
    <PriceWrapper>
      <Box
        position="relative"
        height={'100%'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-around'}
        px={4}
        py={8}
        maxW={220}
      >
        {plan.isPopular && (
          <Box
            position="absolute"
            top="0"
            left="50%"
            style={{ transform: 'translate(-50%, -50%)' }}
          >
            <Text
              textTransform="uppercase"
              bg="#FFA011"
              px={2}
              py={1}
              color={textColor}
              fontSize="xs"
              fontWeight="600"
              rounded="xl"
            >
              Most Popular
            </Text>
          </Box>
        )}
        <Box mb={4}>
          <Text fontWeight="500" fontSize="2xl">
            {plan.planName}
          </Text>
          <HStack>
            <Text fontSize="3xl" fontWeight="900">
              {getCurrencyDigit({
                number: plan.price!,
                options: {
                  currency: plan.currency,
                },
              })}
            </Text>
            {plan.payby && (
              <Text fontSize="2xl" color="gray.500">
                /{plan.payby === 'M' ? 'month' : 'year'}
              </Text>
            )}
          </HStack>
        </Box>
        <List spacing={3} mb={6}>
          {plan.summary?.map((feature, index) => (
            <ListItem key={index}>
              <ListIcon as={FaCheckCircle} color="green.500" />
              {feature}
            </ListItem>
          ))}
        </List>
        <Button
          w="100%"
          colorScheme="#114684"
          bgColor={plan.isPopular ? '#114684' : ''}
          variant={plan.isPopular ? 'solid' : 'outline'}
          sx={{
            ...(plan.isPopular
              ? {}
              : {
                  borderColor: '#114684',
                  color: '#114684',
                }),
            '&:hover': {
              bg: '#114684',
              color: 'white',
            },
          }}
          onClick={handleBuyNow}
        >
          BUY NOW
        </Button>
      </Box>
    </PriceWrapper>
  );
};

export default PlanCard;
