import { APIStatus } from 'src/types/unions';

export interface AccessRequest {
  error: any;
  status: APIStatus | null;
  type?:
    | 'GET_ACCESS_REQUESTS'
    | 'RESEND_CONSENT_REQUEST'
    | 'PERMISSION_APPROVED_OR_DENIED';
  requests: any;
  user: {
    msg: string;
    applicationId: number;
  } | null;
}

const initialState: AccessRequest = {
  error: null,
  status: null,
  requests: [],
  user: null,
};

export default initialState;
