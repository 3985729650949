import {
  Box,
  Button,
  Container,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getAccessRequest,
  permissionAction,
} from 'src/Redux/AccessRequest/slice';
import { RootState, useAppSelector } from 'src/Redux/Store';
import { PermissionSchema } from 'src/ValidationSchema/Pages/Index';
import Swal from 'sweetalert2';

interface QBOProps {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  initialValues: QBODeniedFormValues;
  loading: { [key: number]: boolean };
  setLoading: React.Dispatch<
    React.SetStateAction<{
      [key: number]: boolean;
    }>
  >;
}

export interface QBODeniedFormValues {
  id: number | null;
  token: string | null;
  userType: string | null;
  permission: boolean | null;
  deniedReason: string | null;
}

const QBOReqDeniedModal = ({
  isOpen,
  setIsOpen,
  initialValues,
  loading,
  setLoading,
}: QBOProps) => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const accessRequest = useAppSelector(
    (state: RootState) => state.accessRequestSlice
  );

  useEffect(() => {
    formik.setValues(initialValues);
  }, []);

  useEffect(() => {
    const { status, error, type, user } = accessRequest;
    switch (status) {
      case 'succeed':
        if (type === 'PERMISSION_APPROVED_OR_DENIED') {
          // setLoading((prevState) => ({
          //   ...prevState,
          //   [user?.applicationId as number]: false,
          // }));
          setLoading({});
          Swal.fire({
            icon: 'success',
            title: user?.msg,
            position: 'center',
            showConfirmButton: false,
            showCloseButton: true,
          });
          handleClosePopup();
          dispatch(getAccessRequest() as any);
        }
        break;
      case 'failed':
        if (type === 'PERMISSION_APPROVED_OR_DENIED') {
          setLoading({});
          Swal.fire({
            icon: 'error',
            title: error,
            showCloseButton: true,
            position: 'center',
            showConfirmButton: false,
          });
          handleClosePopup();
        }
        break;
    }
  }, [accessRequest?.status]);

  const handleClosePopup = () => {
    setIsOpen(false);
    formik.resetForm();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema: PermissionSchema,

    onSubmit: async (values: QBODeniedFormValues, { resetForm }) => {
      try {
        const updatedValues = { ...values, permission: false };
        setLoading((prevState) => ({
          ...prevState,
          [values?.id as number]: true,
        }));
        await dispatch(permissionAction(updatedValues) as any);
      } catch (error: any) {
        Swal.fire({
          icon: 'error',
          title: error,
          timer: 1000,
          position: 'center',
          showConfirmButton: false,
        });
        handleClosePopup();
      }
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClosePopup}
      closeOnOverlayClick={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader color={'#114684'}>
          Please add QBO access denied reason
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Container textAlign="center" h="auto" p="10px" borderRadius="20px">
            <Box>
              <Container
                textAlign="center"
                h="auto"
                margin="auto"
                display={'flex'}
                flexDir={'column'}
                p={0}
                gap={'0.1rem'}
              >
                <form onSubmit={formik.handleSubmit}>
                  <Box display={'flex'} flexDirection={'column'} gap={3}>
                    <Box display="flex" flexDirection={'column'}>
                      <Textarea
                        fontSize={18}
                        w="100%"
                        placeholder=" Please Add Qbo Access Denied Reason "
                        name="deniedReason"
                        borderRadius="0px 5px 5px 0px"
                        value={formik.values.deniedReason ?? ''}
                        onChange={formik.handleChange}
                        focusBorderColor="transparent"
                        bg={'#f0f5f9'}
                        border="none"
                        onBlur={formik.handleBlur}
                      />

                      {formik.touched.deniedReason &&
                        formik.errors.deniedReason && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors.deniedReason}
                          </div>
                        )}
                    </Box>
                  </Box>

                  <Box display={'flex'} gap={2} mt={3}>
                    <Button
                      bg="#114684"
                      color="white"
                      w="100%"
                      _hover={{ bg: '#114684' }}
                      onClick={handleClosePopup}
                    >
                      Close
                    </Button>
                    <Button
                      type="submit"
                      bg="#114684"
                      color="white"
                      w="100%"
                      _hover={{ bg: '#114684', color: 'white' }}
                      isLoading={loading[formik?.values?.id ?? 0]}
                    >
                      Submit
                    </Button>
                  </Box>
                </form>
              </Container>
            </Box>
          </Container>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default QBOReqDeniedModal;
