import { Box, Container, Text } from '@chakra-ui/react';
import { IoLockClosedOutline } from 'react-icons/io5';

const FormLinkExpired = () => {
  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height="100vh"
        p={2}
      >
        <Container
          textAlign="center"
          // boxShadow="rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
          w={['96%', '96%', 'auto']}
          marginTop={10}
          marginBottom={10}
          minHeight={200}
          p="0px 22px 0px 22px"
          borderRadius="20px"
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <IoLockClosedOutline size={50} color="gray" />
          <Text
            fontWeight="bold"
            color="#114684"
            textAlign="center"
            fontSize={30}
            mt={5}
          >
            Link Expired for Owner Personal Financial Info form
          </Text>
        </Container>
      </Box>
    </>
  );
};

export default FormLinkExpired;
