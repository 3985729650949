import { io } from 'socket.io-client';

export let socket = io(`https://qa.subtraid.mcsanalytics.com`, {
  transports: ['websocket'],
});
// export let socket = io(`https://uat.subtraid.mcsanalytics.com`, {
//   transports: ["websocket"],
// });

socket.on('connect', () => {
  console.log('Connected to Socket io');
});
