import { Button, ButtonProps } from '@chakra-ui/react';

export const AppOutlineButton = (res: ButtonProps) => {
  return (
    <Button
      variant="outline"
      sx={{
        borderColor: '#114684',
        color: '#114684',
        '&:hover': {
          bg: '#114684',
          color: 'white',
        },
      }}
      {...res}
    />
  );
};

export const AppSolidButton = (res: ButtonProps) => {
  return (
    <Button
      variant="solid"
      sx={{
        bg: 'rgba(17, 70, 132,0.9)',
        color: '#ffffff',
        '&:hover': {
          bg: 'brand.blue',
        },
      }}
      {...res}
    />
  );
};
