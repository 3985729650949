import { useToast, UseToastOptions } from '@chakra-ui/react';

const useAppToast = (customOptions: Partial<UseToastOptions> = {}) => {
  const defaultOptions: Partial<UseToastOptions> = {
    isClosable: true,
    duration: 3000,
    position: 'top-right',
    status: 'success',
    ...customOptions, // Merge custom options with defaults
  };

  return useToast(defaultOptions);
};

export default useAppToast;
