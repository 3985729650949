import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { store } from './Redux/Store';
import { Provider } from 'react-redux';
import { StepsTheme as Steps } from 'chakra-ui-steps';

import { ChakraProvider, extendTheme } from '@chakra-ui/react';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const theme = extendTheme({
  colors: {
    brand: {
      yellow: '#ffa011',
      blue: '#114684',
    },
  },
  fonts: {
    body: '"Montserrat", sans-se"',
    heading: '"Montserrat", sans-se"',
  },
  components: {
    Steps,
  },
});

root.render(
  <Provider store={store}>
    {/* <React.StrictMode> */}
    <ChakraProvider theme={theme}>
      <App />
    </ChakraProvider>
    {/* </React.StrictMode> */}
  </Provider>
);
