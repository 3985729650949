import { IPlanBase } from 'src/data/plans';
import { APIStatus } from 'src/types/unions';

export interface IFaqs {
  faqId: string;
  question: string;
  answer: string;
}

export interface IInitialState {
  error: any;
  uploadReportsData?: IFaqs | null;
  faqsData?: IFaqs[];
  status: APIStatus | null;
  type?: 'GET_FAQS' | 'GET_PLANS';
  plans: IPlanBase[];
}

const initialState: IInitialState = {
  error: null,
  status: null,
  uploadReportsData: null,
  faqsData: [],
  plans: [],
};

export default initialState;
