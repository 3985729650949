import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callAPI from '../../../../utils/callAPI';
import initialState, {
  ICurrentAssets,
  ICurrentLiabilities,
  ITotalWIC,
  TFilterOption,
} from './state';
import { isRejectedActionWithPayload } from '../WorkInProgress/slice';

interface IUpdateWICdata {
  documentId: number;
  capitalId: number;
  currentLiabilities?: ICurrentLiabilities;
  currentAssets?: ICurrentAssets;
  totalAss?: ITotalWIC;
  totalLib?: ITotalWIC;
  totalWIC: ITotalWIC;
  meta: {
    type: 'CA' | 'CL';
  };
}

interface IRemoveWICdata {
  documentId: number;
  capitalId: number;
  type: 'CA' | 'CL';
  totalWIC: ITotalWIC;
  totalAss?: ITotalWIC;
  totalLib?: ITotalWIC;
  meta: {
    type: 'CA' | 'CL';
  };
}

interface IRefreshWICPayload {
  filter: TFilterOption;
  applicantId: number;
}

export const getWIPDataByApplicantIdAndFilter = createAsyncThunk(
  'workingCapitalSlice/getWIPDataByApplicantIdAndFilter',
  async (
    {
      applicantId,
      filter,
    }: {
      applicantId: number;
      filter: TFilterOption;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(
        'wic/create',
        'POST',
        {
          applicantId,
          filter,
        },
        true
      );

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const updateWorkingCapitalRow = createAsyncThunk(
  'workingCapitalSlice/updateWorkingCapitalRow',
  async (
    { documentId, meta, ...props }: IUpdateWICdata,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wic/update', 'PUT', {
        id: documentId,
        ...props,
      });

      return {
        ...response.data,
        meta,
      };
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const addWorkingCapitalRow = createAsyncThunk(
  'workingCapitalSlice/addWorkingCapitalRow',
  async (
    {
      documentId,
      meta,
      ...props
    }: Pick<
      IUpdateWICdata,
      | 'documentId'
      | 'totalWIC'
      | 'currentLiabilities'
      | 'currentAssets'
      | 'totalAss'
      | 'totalLib'
      | 'meta'
    >,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI('wic/update', 'PUT', {
        id: documentId,
        ...props,
      });

      return { ...response.data, meta };
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const removeworkingCapitalRow = createAsyncThunk(
  'workingCapitalSlice/removeWorkingCapitalRow',
  async (
    { documentId, meta, ...props }: IRemoveWICdata,
    { rejectWithValue }
  ) => {
    try {
      const response = await callAPI(`wic/remove`, 'PUT', {
        id: documentId,
        ...props,
      });

      return { ...response.data, meta };
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

export const refreshWorkingCapital = createAsyncThunk(
  'workingCapitalSlice/refreshWorkingCapital',
  async ({ filter, applicantId }: IRefreshWICPayload, { rejectWithValue }) => {
    try {
      const response = await callAPI(`wic/refresh`, 'POST', {
        filter,
        applicantId,
      });

      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }

      return rejectWithValue(error.data);
    }
  }
);

const workingCapitalSlice = createSlice({
  name: 'workInProgressSlice',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getWIPDataByApplicantIdAndFilter.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'GET_WIC_DATA_BY_APPLICATIONID_API';
      })
      .addCase(getWIPDataByApplicantIdAndFilter.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_WIC_DATA_BY_APPLICATIONID_API';
        state.data = action.payload.data;
      })
      .addCase(getWIPDataByApplicantIdAndFilter.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_WIC_DATA_BY_APPLICATIONID_API';
        state.error = isRejectedActionWithPayload(action);
        state.data = initialState.data;
      })
      .addCase(updateWorkingCapitalRow.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'UPDATE_WIC_ROW';
        state.meta = action?.meta?.arg?.meta;
      })
      .addCase(updateWorkingCapitalRow.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'UPDATE_WIC_ROW';
        // state.data = action.payload.data;
        state.meta = action.payload.meta;
      })
      .addCase(updateWorkingCapitalRow.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'UPDATE_WIC_ROW';
        state.error = isRejectedActionWithPayload(action);
        state.meta = action?.meta?.arg?.meta;
      })
      .addCase(addWorkingCapitalRow.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'ADD_WIC_ROW';
        state.meta = action?.meta?.arg?.meta;
      })
      .addCase(addWorkingCapitalRow.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'ADD_WIC_ROW';
        // state.data = action.payload.data;
        state.meta = action?.meta?.arg?.meta;
      })
      .addCase(addWorkingCapitalRow.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'ADD_WIC_ROW';
        state.error = isRejectedActionWithPayload(action);
        state.meta = action?.meta?.arg?.meta;
      })
      .addCase(removeworkingCapitalRow.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'REMOVE_WIC_API';
        state.meta = action?.meta?.arg?.meta;
      })
      .addCase(removeworkingCapitalRow.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'REMOVE_WIC_API';
        // state.data = action.payload.data;
        state.meta = action?.meta?.arg?.meta;
      })
      .addCase(removeworkingCapitalRow.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'REMOVE_WIC_API';
        state.error = isRejectedActionWithPayload(action);
        state.meta = action?.meta?.arg?.meta;
      })
      .addCase(refreshWorkingCapital.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'REFRESH_WIC_API';
      })
      .addCase(refreshWorkingCapital.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'REFRESH_WIC_API';
        // state.data = action.payload.data;
      })
      .addCase(refreshWorkingCapital.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'REFRESH_WIC_API';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const brokerWorkingCapitalSlice = workingCapitalSlice.reducer;

export default brokerWorkingCapitalSlice;
