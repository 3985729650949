export const removeCmsFields: string[] = [
  'contractorType',
  'contractorTypeOther',
  'salesPercentageByOwnerTypeOther',
  'typeOfWorkOther',
  'workSubletOther',
  'managementControlCheckbox',
  'managementControlRadio',
  'managementControlOther',
  'fixedContractorCopy',
];

export const interimStatementIntervalOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: 'Annually',
    value: 'Annually',
  },
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Quarterly',
    value: 'Quarterly',
  },
  {
    label: 'Semi-annually',
    value: 'Semi-annually',
  },
];
export const creditSecuredOptions: {
  label: string;
  value: string;
}[] = [
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Equipment',
    value: 'Equipment',
  },
  {
    label: 'Inventory',
    value: 'Inventory',
  },
  {
    label: 'Account Receivables',
    value: 'Account Receivables',
  },
  {
    label: 'Investments',
    value: 'Investments',
  },
  {
    label: 'Cash',
    value: 'Cash',
  },
  {
    label: 'Life Insurance',
    value: 'Life Insurance',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];
