import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { BASEURL } from '../../../App';
import { tokenExipry } from '../../tokenExpiry';

interface AccountantQboState {
  user: any;
  loading: boolean;
  error: null;
}

const initialState: AccountantQboState = {
  user: null,
  loading: false,
  error: null,
};

export const Brokerage = createSlice({
  name: 'counter',
  initialState,
  reducers: {},
  extraReducers() {},
});

//Accountant mail
export const AccountantMail = createAsyncThunk(
  'Accountant/AccountantMail',
  async (
    AddOwner: {
      accountantId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const Token = localStorage.getItem('Token');
      const config = {
        headers: {
          Authorization: `Bearer ${Token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const response = await axios.post(
          `${BASEURL}/qbo/accountant/sent-email`,
          AddOwner,
          config
        );

        return response.data;
      } catch (error: any) {
        if (error?.response?.status === 401) {
          const result = tokenExipry();
          return result;
        }
        return error?.response?.data;
      }
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

export const AccountantById = createAsyncThunk(
  'accountant/AccountantById',
  async (userId: number | null, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${BASEURL}/qbo/by-accoutant/auth-token?userId=${userId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const AccountantQboSlice = createSlice({
  name: 'AccountantQboSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder

      //Accountant mail
      .addCase(AccountantMail.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AccountantMail.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(AccountantMail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      })
      //Accountant id threw
      .addCase(AccountantById.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        AccountantById.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.user = action.payload;
        }
      )
      .addCase(AccountantById.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as null;
      });
  },
});

export default AccountantQboSlice.reducer;
