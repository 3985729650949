import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import callAPI from 'src/utils/callAPI';
import { isRejectedActionWithPayload } from '../Broker/Documents/slice';
import initialState from './state';

export const getFaqs = createAsyncThunk(
  'plans/getFaqs',
  async (_, { rejectWithValue }) => {
    try {
      const response = await callAPI('/faq/list', 'GET');
      return response.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

export const getAllPlans = createAsyncThunk(
  'plans/getAllPlans',
  async (_, { rejectWithValue }) => {
    try {
      const res = await callAPI('/subscription/list-plan', 'GET');
      return res.data;
    } catch (error: any) {
      if (!error.data) {
        throw error;
      }
      return rejectWithValue(error.data);
    }
  }
);

const plansStore = createSlice({
  name: 'plans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // GET =>  faqs
    builder
      .addCase(getFaqs.pending, (state) => {
        state.error = null;
        state.status = 'loading';
        state.type = 'GET_FAQS';
      })
      .addCase(getFaqs.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_FAQS';
        state.faqsData = action.payload.data;
      })
      .addCase(getFaqs.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_FAQS';
        state.error = isRejectedActionWithPayload(action);
      })
      // GET => all plans
      .addCase(getAllPlans.pending, (state, action) => {
        state.status = 'loading';
        state.type = 'GET_PLANS';
      })
      .addCase(getAllPlans.fulfilled, (state, action) => {
        state.status = 'succeed';
        state.type = 'GET_PLANS';
        state.plans = action.payload.data;
      })
      .addCase(getAllPlans.rejected, (state, action) => {
        state.status = 'failed';
        state.type = 'GET_PLANS';
        state.error = isRejectedActionWithPayload(action);
      });
  },
});

const plansSlice = plansStore.reducer;

export default plansSlice;
