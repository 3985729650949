import { REPORT_SHORTFORM } from 'src/types/unions';
import { IReportStatus } from '../Broker/Reports/APAgingSummary/state';

export type APIStatus = 'succeed' | 'failed' | 'loading';
export type FileData = {
  title: string | null;
  logoUrl: string | null;
  urlKey: string | null;
  summary: string | null;
  heading: string | null;
  contactInfo: boolean | null;
  generalBanking: string | null;
  legal: string | null;
  personalFinancial: string | null;
  about: string | null;
  surety: string | null;
  insurance: string | null;
  // documents: string | null;
  // reportNames: REPORT_SHORTFORM[];
};
export interface PdfCreatePayload {
  applicationId: number | null;
  fileData: FileData;
}
export interface PdfContentData {
  status: APIStatus | null;
  error: any;
  msg?: string;
  data?: any;
  type?: string;
}

const initialState: PdfContentData = {
  error: null,
  status: null,
};

export default initialState;
