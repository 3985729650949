import React, { useState } from 'react';
import {
  Input,
  Container,
  Flex,
  Box,
  Image,
  Text,
  Checkbox,
  useBreakpointValue,
  Button,
  Link,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { RiLockPasswordFill } from 'react-icons/ri';
import { MdEmail, MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Navigate, useNavigate } from 'react-router-dom';
import { RootState } from '../../Redux/Store';
import { useDispatch, useSelector } from 'react-redux';
import { ResetPassword } from '../../Redux/Auth/Auth';
import { useFormik } from 'formik';
import { ResetPasswordSchema } from '../../ValidationSchema/Auth/Index';

function ForgetPassword() {
  const navigate = useNavigate();
  const [isForgetPasswordButtonLoading, setIsForgetPasswordButtonLoading] =
    useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);

  const formWidth = useBreakpointValue({ base: '96%', sm: '57%' });
  const dispatch = useDispatch();
  const Toast = useToast();
  const urlParams = new URLSearchParams(window.location.search);

  const id = urlParams.get('id');
  const token = urlParams.get('token');
  const userType = urlParams.get('userType');

  interface FormValues {
    userType: string;
    password: string;
    id: string;
    token: string;
    confirmPassword: string;
  }

  const initialValues: FormValues = {
    userType: userType || '',
    password: '',
    id: id || '',
    token: token || '',
    confirmPassword: '',
  };

  const formik = useFormik({
    initialValues: initialValues,

    validationSchema: ResetPasswordSchema,
    onSubmit: async (values: FormValues) => {
      setIsForgetPasswordButtonLoading(true);
      let res = '';
      try {
        const actionResult = await dispatch(ResetPassword(values) as any);
        const response = actionResult?.payload;

        res = response?.msg;
        const resStatus = response?.status;

        if (resStatus === 200) {
          Toast({
            title: response?.msg,
            status: 'success',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
          navigate('/login');
        } else {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      } catch (error: any) {
        if (res) {
          Toast({
            title: res,
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        } else {
          Toast({
            title: '404',
            status: 'error',
            isClosable: true,
            duration: 3000,
            position: 'top-right',
          });
        }
      }
      setIsForgetPasswordButtonLoading(false);
    },
  });
  const handleTogglePassword = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };
  const handleTogglePassword1 = () => {
    setShowPassword1((prevShowPassword) => !prevShowPassword);
  };
  const OnHandelUser = () => {
    navigate('/login');
  };
  const OnHandelLogin = () => {
    navigate('/login');
  };
  return (
    <>
      <Box h="100%">
        <Flex>
          <Container maxW="100%" p={['0px', '0px', '0px']}>
            <Flex flexWrap="wrap" height="100vh  " gap={[5, 0, 0, 0]}>
              <Box
                bg="#114684"
                borderRadius={[
                  '0px 200px 0px 0px',
                  '0px 245px 0px 0px',
                  '0px 350px 0px 0px',
                ]}
                w={['100%', '40%']}
                display={'flex'}
                justifyContent={'center'}
                alignItems={'center'}
                flexDir={'column'}
                gap={'10px'}
              >
                <Box display="flex" justifyContent="center">
                  <Image
                    src="/Image/insurance3.jpg"
                    alt="insurance"
                    width={['700px', '400px', '500px', '700px']}
                    boxSize={['250px', '230px', '260px', '300px']}
                    objectFit="cover"
                  />
                </Box>
                <Text
                  fontWeight="bold"
                  color="white"
                  textAlign="center"
                  fontSize={20}
                >
                  If you don't have an account?
                </Text>
                <Box textAlign="center">
                  <Button
                    bg="#114684"
                    color="white"
                    variant="outline"
                    _hover={{ bg: '#114684' }}
                    onClick={OnHandelUser}
                    mb={5}
                  >
                    Sign In
                  </Button>
                </Box>
              </Box>

              <Container
                textAlign="center"
                w={formWidth}
                boxShadow=" rgba(0, 0, 0, 0.25) 0px 0.0625em 0.0625em, rgba(0, 0, 0, 0.25) 0px 0.125em 1.5em, rgba(255, 255, 255, 0.1) 0px 0px 0px 1px inset"
                h="auto"
                margin="auto"
                p="18px"
                borderRadius="20px"
                display={'flex'}
                flexDir={'column'}
                gap={'0.1rem'}
              >
                <Text
                  fontWeight="bold"
                  color="#114684"
                  textAlign="start"
                  fontSize={28}
                >
                  Reset Password
                </Text>

                <Box>
                  <form
                    onSubmit={formik.handleSubmit}
                    autoComplete="on"
                    style={{
                      display: 'flex',
                      gap: '10px',
                      flexDirection: 'column',
                    }}
                  >
                    <Box display="flex" flexDirection={'column'} mt={2}>
                      <Text
                        fontWeight="bold"
                        color="#114684"
                        display="flex"
                        justifyContent="start"
                        textAlign="center"
                        fontSize={17}
                      >
                        New Password
                      </Text>

                      <Box display="flex">
                        <Box
                          mr={2}
                          fontSize={25}
                          color="#114684"
                          m={0}
                          borderRadius="5px 0px 0px 5px"
                          backgroundColor="#f0f5f9"
                        >
                          <RiLockPasswordFill
                            style={{
                              marginTop: '7px',
                              marginLeft: '6px',
                              marginRight: '6px',
                            }}
                          />
                        </Box>
                        <Input
                          ml="-2px"
                          _hover={{ borderColor: '#114684' }}
                          border="none"
                          backgroundColor="#f0f5f9"
                          focusBorderColor="transparent"
                          w="100%"
                          name="password"
                          fontSize="lg"
                          type={showPassword ? 'text' : 'password'}
                          placeholder=" New Password"
                          borderRadius="0px"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.password}
                        />
                        <IconButton
                          aria-label={
                            showPassword ? 'Hide password' : 'Show password'
                          }
                          icon={
                            showPassword ? (
                              <MdVisibilityOff />
                            ) : (
                              <MdVisibility />
                            )
                          }
                          backgroundColor="#f0f5f9"
                          borderRadius="0px 5px 5px 0px"
                          ml="-2px"
                          bg="#f0f5f9"
                          _hover={{ bg: '#f0f5f9' }}
                          color="#114684"
                          fontSize={25}
                          onClick={handleTogglePassword}
                        />
                      </Box>
                      {formik.touched.password && formik.errors.password && (
                        <div
                          style={{
                            color: 'red',
                            fontSize: '15px',
                            textAlign: 'start',
                            fontWeight: '500',
                          }}
                        >
                          {formik.errors.password}
                        </div>
                      )}
                    </Box>
                    <Box display="flex" flexDirection={'column'}>
                      <Text
                        fontWeight="bold"
                        color="#114684"
                        display="flex"
                        justifyContent="start"
                        textAlign="center"
                        fontSize={17}
                      >
                        Confirm Password
                      </Text>

                      <Box display="flex">
                        <Box
                          mr={2}
                          fontSize={25}
                          color="#114684"
                          m={0}
                          borderRadius="5px 0px 0px 5px"
                          backgroundColor="#f0f5f9"
                        >
                          <RiLockPasswordFill
                            style={{
                              marginTop: '7px',
                              marginLeft: '6px',
                              marginRight: '6px',
                            }}
                          />
                        </Box>
                        <Input
                          ml="-2px"
                          _hover={{ borderColor: '#114684' }}
                          border="none"
                          backgroundColor="#f0f5f9"
                          focusBorderColor="transparent"
                          w="100%"
                          name="confirmPassword"
                          fontSize="lg"
                          type={showPassword1 ? 'text' : 'password'}
                          placeholder="Confirm Password"
                          borderRadius="0px"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmPassword}
                        />
                        <IconButton
                          aria-label={
                            showPassword1 ? 'Hide password' : 'Show password'
                          }
                          icon={
                            showPassword1 ? (
                              <MdVisibilityOff />
                            ) : (
                              <MdVisibility />
                            )
                          }
                          backgroundColor="#f0f5f9"
                          borderRadius="0px 5px 5px 0px"
                          ml="-2px"
                          bg="#f0f5f9"
                          _hover={{ bg: '#f0f5f9' }}
                          color="#114684"
                          fontSize={25}
                          onClick={handleTogglePassword1}
                        />
                      </Box>
                      {formik.touched.confirmPassword &&
                        formik.errors.confirmPassword && (
                          <div
                            style={{
                              color: 'red',
                              fontSize: '15px',
                              textAlign: 'start',
                              fontWeight: '500',
                            }}
                          >
                            {formik.errors.confirmPassword}
                          </div>
                        )}
                    </Box>

                    <Box>
                      <Button
                        isLoading={isForgetPasswordButtonLoading}
                        loadingText="Reset Password"
                        type="submit"
                        bg="#114684"
                        color="white"
                        w="100%"
                        _hover={{ bg: '#114684', color: 'white' }}
                      >
                        Reset Password
                      </Button>
                    </Box>
                    <Text
                      fontWeight={500}
                      color="#fbb03b"
                      textAlign="center"
                      fontSize={16}
                      onClick={OnHandelLogin}
                      cursor={'pointer'}
                    >
                      Go back to login
                    </Text>
                  </form>
                </Box>
              </Container>
            </Flex>
          </Container>
        </Flex>
      </Box>
    </>
  );
}

export default ForgetPassword;
