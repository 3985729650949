import {
  Box,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Skeleton,
  Spinner,
  Switch,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Container, Tabs, Title } from '@mantine/core';
import { useEffect, useState } from 'react';
import { oneTimePurchasePlans } from 'src/data/plans';
import { getAllPlans, getFaqs } from 'src/Redux/Plans/slice';
import { IFaqs } from 'src/Redux/Plans/state';
import { useAppDispatch, useAppSelector } from 'src/Redux/Store';
import { getPlanByBillingCycle } from 'src/utils/helpers';
import { FaqSimple } from './components/Faq';
import PlanCard from './components/PlanCard';
import './components/Plancard.css';
import './swiper-local.css';

const ThreeTierPricing = () => {
  const dispatch = useAppDispatch();
  const plansStore = useAppSelector((state) => state.plans);
  const [plantype, setPlantype] = useState<'yearly' | 'monthly'>('yearly');

  const [plan1, setPlans1] = useState(oneTimePurchasePlans);
  const [faqsData, setFaqsData] = useState<IFaqs[] | null>(null);
  const [loaders, setLoaders] = useState({
    faq: false,
    plans: false,
  });

  const toggleBillingPeriod = () => {
    setPlantype((val) => (val === 'yearly' ? 'monthly' : 'yearly'));
  };

  useEffect(() => {
    // dispatch(getFaqs());
    if (!plansStore.faqsData?.length) dispatch(getFaqs());
    if (!plansStore.plans.length) dispatch(getAllPlans());
  }, []);

  useEffect(() => {
    const { status, faqsData, type, plans } = plansStore;

    if (status === 'loading' && type === 'GET_FAQS') {
      alert('sdsd');
    }

    switch (status) {
      case 'loading': {
        if (type === 'GET_FAQS') setLoaders((data) => ({ ...data, faq: true }));
        if (type === 'GET_PLANS')
          setLoaders((data) => ({ ...data, plans: true }));
        break;
      }
      case 'succeed': {
        if (type === 'GET_FAQS') {
          setFaqsData(faqsData!);
          setLoaders((data) => ({ ...data, faq: false }));
        }
        if (type === 'GET_PLANS') {
          setLoaders((data) => ({ ...data, plans: false }));
        }
        break;
      }

      case 'failed': {
        break;
      }
    }
  }, [plansStore.status, plansStore.type]);

  return (
    <Box py={50}>
      <VStack spacing={2} alignItems={'center'}>
        <Heading
          as="h1"
          textAlign={'center'}
          fontSize="4xl"
          mb={4}
          px={2}
          position={'relative'}
        >
          Plans that fit your need
        </Heading>

        <Box position={'relative'}>
          <Tabs defaultValue="subscription">
            <Tabs.List
              className="tab-list"
              grow
              sx={{ maxWidth: '350px', marginInline: 'auto', width: '100%' }}
            >
              <Tabs.Tab value="subscription" className="tab">
                Subscription
              </Tabs.Tab>
              <Tabs.Tab value="one-Time-purchase" className="tab">
                One-Time Purchase
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="subscription" pt={26}>
              <FormControl
                display="flex"
                alignItems="center"
                justifyContent="center"
                pb={6}
              >
                <FormLabel htmlFor="billing-period" mb="0">
                  Pay by Month
                </FormLabel>
                <Switch
                  size="md"
                  width={10}
                  onChange={toggleBillingPeriod}
                  isChecked={plantype === 'yearly'}
                  id="billing-period"
                />
                <FormLabel ml={2} htmlFor="billing-period" mb="0">
                  Year
                </FormLabel>
              </FormControl>
              <Box
                display={'flex'}
                justifyContent={'center'}
                pb={12}
                gap={4}
                rowGap={6}
                maxW={'90rem'}
                mx={'auto'}
                flexWrap={'wrap'}
                w={'100%'}
                alignItems={'stretch'}
              >
                {!loaders.plans &&
                  getPlanByBillingCycle(plansStore.plans)[plantype].map(
                    (plan) => <PlanCard key={plan.planName} plan={plan} />
                  )}
                {loaders.plans &&
                  Array(4)
                    .fill(0)
                    .map((el) => (
                      <Skeleton
                        h={'300px'}
                        w={'220px'}
                        // isLoaded={!loaders.plans}
                        // fadeDuration={1}
                        shadow="base"
                        borderWidth="1px"
                        borderColor={'gray.300'}
                        borderRadius={'xl'}
                      />
                    ))}
              </Box>
            </Tabs.Panel>
            <Tabs.Panel value="one-Time-purchase">
              <Box py={12}>
                <Box
                  display={'flex'}
                  justifyContent={'center'}
                  gap={4}
                  rowGap={6}
                  maxW={'90rem'}
                  mx={'auto'}
                  flexWrap={'wrap'}
                  w={'100%'}
                  alignItems={'stretch'}
                  mb={2}
                >
                  {plan1?.map((plan: any) => (
                    <PlanCard key={plan.name} plan={plan} />
                  ))}
                </Box>
                <Box>
                  <Text fontSize={12} textAlign={'center'}>
                    * one-time purchase expire within 1 month
                  </Text>
                </Box>
              </Box>
            </Tabs.Panel>
          </Tabs>
          <Box position={'absolute'} top={0} right={-4}>
            <Text fontSize={12} transform={'translateX(100%)'}>
              * Prices in USD
            </Text>
          </Box>
        </Box>
      </VStack>

      {/* FAQS */}

      <Container>
        <Title align="center" mb={16}>
          FAQ
        </Title>
        {loaders.faq && (
          <Flex justifyItems={'center'}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
              mx={'auto'}
            />
          </Flex>
        )}
        {!loaders.faq && plansStore.faqsData && (
          <FaqSimple faqs={plansStore.faqsData} />
        )}
      </Container>
    </Box>
  );
};

export default ThreeTierPricing;
